import isEmpty from 'lodash/isEmpty';
import {
	PROMOTION_STATUSES,
	PROMOTION_STATUS_OPTIONS,
	PROMOTION_TYPE_LABELS,
	PERIOD_STATUSES,
	PERIOD_STATUS_LABELS,
	PERIOD_STATUS_COLOR,
	DISCOUNT_TYPE_LABELS,
	CART_CONDITION_TYPES,
	REWARD_TYPE_KEYS,
	REWARD_KEYS,
	DISCOUNT_TYPE_KEYS,
	DISCOUNT_TYPE_OPTIONS,
	PROMOTION_TYPES,
	CONDITION_KEYS,
	CONDITION_TYPES,
	CONDITION_RULE_KEYS,
	PURCHASE_SUBTRACT_DISCOUNT_OPTIONS,
	APPLY_DISCOUNT_EVERY_TYPE_KEYS,
	APPLY_DISCOUNT_EVERY_TYPE_OPTIONS,
	APPLY_TO_KEYS,
	COUPON_MODE_OPTIONS,
	CUSTOMER_REWARD_TYPES,
	FREEBIE_STOCK_OPTION_KEYS,
} from '../../../enums/promotions';
import {
	priceToAPI,
	priceFromAPI,
	pathOr,
	datetimeFormat,
	convertDateTimeToUTC,
	getRewardKeysFromRewardValue,
	cloneDeep,
} from '../helpers';

import { transformedProduct } from '../../../assets/js/transform/products';
import { transformedUser } from '../../../assets/js/transform/user';
import { getProductBySKUAPI } from '../../../services/api/sku.api';
import { getPromotionAPI } from '../../../services/api/promotions.api';


const getProductInfo = async (product) => {
	try {
		const params = {
			q: product.sku,
			is_allowed_freebie: true,
		};
		const { data: productData } = await getProductBySKUAPI(params);

		// eslint-disable-next-line no-use-before-define
		const stockOption = await getStockOptionProductInfo(product.stockOption);
		const stockOptionParam = stockOption ? { stockOption } : {};

		return {
			...product,
			...stockOptionParam,
			...transformedProduct(productData.data),
		};
	} catch {
		// Do nothing
	}
	return null;
};

const getStockOptionProductInfo = async (stockOption) => {
	if (!stockOption) {
		return null;
	}

	switch (stockOption.key) {
		case FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU: {
			const secondarySku = await getProductInfo(stockOption.params.secondarySku);
			return {
				...stockOption,
				params: {
					secondarySku,
				},
			};
		}
		default:
			return stockOption;
	}
};

export const transformedPromotion = (promotion = {}) => {
	const id = pathOr(null, ['id'])(promotion);
	const duplicateId = id;
	const name = pathOr(null, ['name'])(promotion);
	const rewardType = pathOr(null, ['reward_type'])(promotion);
	const promotionType = pathOr(null, ['type'])(promotion);

	const [, discountType] = getRewardKeysFromRewardValue(rewardType);
	const type = {
		value: promotionType,
		name: PROMOTION_TYPE_LABELS[promotionType],
		reward: DISCOUNT_TYPE_LABELS[discountType],
	};

	// reward_type of Tier is being at reward_param => rewards[0] => type
	if (promotionType === PROMOTION_TYPES.TIER) {
		const rewardTypeTier = pathOr(null, ['reward_param', 'rewards', 0, 'type'])(promotion);

		const [, discountTypeTier] = getRewardKeysFromRewardValue(rewardTypeTier);
		type.reward = DISCOUNT_TYPE_LABELS[discountTypeTier];
	}

	const priority = pathOr(null, ['priority'])(promotion);
	const couponCodes = pathOr(null, ['codes'])(promotion); // eCoupon only
	const firstCode = pathOr(null, ['first_code'])(promotion); // eCoupon only
	const totalCode = pathOr(0, ['total_code'])(promotion); // eCoupon only
	const statusValue = PROMOTION_STATUSES[pathOr(null, ['status'])(promotion)];
	const status = PROMOTION_STATUS_OPTIONS.find((option) => option.value === statusValue);
	const startAt = promotion.start_at ? datetimeFormat(promotion.start_at) : '-';
	const endAt = promotion.end_at ? datetimeFormat(promotion.end_at) : '-';
	const periodStatusValue = PERIOD_STATUSES[pathOr(null, ['period_status'])(promotion)];
	const periodStatus = {
		title: PERIOD_STATUS_LABELS[periodStatusValue],
		color: PERIOD_STATUS_COLOR[periodStatusValue],
	};
	const createdAt = promotion.created_at ? datetimeFormat(promotion.created_at) : '-';
	const updatedAt = promotion.updated_at ? datetimeFormat(promotion.updated_at) : '-';

	let couponCodesText = '';
	if (totalCode) {
		// Fully summary - First code and count on the rest
		couponCodesText = totalCode > 1
			? `${firstCode} and ${(totalCode - 1)} more codes`
			: firstCode;
	}

	const title = {
		name,
		couponCodesText,
	};

	const isDeleted = pathOr(false, ['is_deleted'])(promotion);
	return {
		id,
		name,
		title,
		type,
		priority,
		status,
		startAt,
		endAt,
		periodStatus,
		couponCodes,
		couponCodesText,
		createdAt,
		updatedAt,
		isDeleted,
		duplicateId,
	};
};

const getPromotionInfo = async (promotion) => {
	try {
		const { data: promotionData } = await getPromotionAPI(promotion.id);
		const mergedCouponPromotion = { ...promotionData.data, codes: promotion.couponCodes };
		return {
			...transformedPromotion(mergedCouponPromotion),
			...promotion, // Override codes, email template id with existing in current campaign
		};
	} catch {
		// Do nothing
	}
	return {
		id: promotion.id,
	};
};

export const transformedPromotionList = (promotions = []) => {
	return promotions.map(transformedPromotion);
};

/**
 * transformedProductSubCondition
 * @param {Object} condition {{type, rule, value, subConditions}}
 * @returns {Object} new condition with transformed {{type, param: {rule, value}, subConditions}}
 */
export const transformedProductSubCondition = (subCondition = {}) => {
	if (isEmpty(subCondition)) {
		return null;
	}


	const { type: productCondType, rule, value, subConditions } = subCondition;
	const transformSubConditions = subConditions
		? subConditions.map(transformedProductSubCondition)
		: null;
	return {
		type: productCondType,
		param: {
			rule,
			value,
		},
		...(!isEmpty(transformSubConditions) ? { subConditions: transformSubConditions } : null),
	};
};

/**
 * transformedProductCondition
 * @param {Object} condition {type, conditions:[{type, rule, value}]}
 * @returns {Object} new condition with transformed {type, param: {conditions: [{type, param: {rule, value}}]}}
 */
export const transformedProductCondition = (condition = {}) => {
	const type = pathOr(null, ['type'])(condition);
	const conditions = pathOr([], ['conditions'])(condition);

	const productConditions = conditions.map((cond) => {
		const { type: productCondType, rule, value, subConditions } = cond;
		const transformSubConditions = subConditions
			? subConditions.map(transformedProductSubCondition)
			: null;
		return {
			type: productCondType,
			param: {
				rule,
				value,
			},
			...(!isEmpty(transformSubConditions) ? { subConditions: transformSubConditions } : null),
		};
	});

	return {
		type,
		param: {
			conditions: productConditions,
		},
	};
};

/**
 * transformedItemConditions
 * @param {Object} condition
 * @returns {Object} new condition with transformed
 */
export const transformedItemConditions = (type, conditions = []) => {
	return {
		type,
		param: {
			conditions: conditions.map(transformedProductCondition),
		},
	};
};

/**
 * transformedFlatProductCondition
 * @param {Object} condition {type, param: {conditions: [{type, param: {rule, value}}}]}
 * @returns {Array} new condition with transformed {type, conditions:[{type, rule, value}]}]
 */
export const transformedFlatProductCondition = (condition = {}) => {
	const type = pathOr(null, ['type'])(condition);
	const param = pathOr(null, ['param'])(condition);
	const conditions = pathOr([], ['conditions'])(param);

	const flattedConditions = conditions.map((cond) => {
		const condType = pathOr(null, ['type'])(cond);
		const { rule: condRule, value: condValue, conditions: primaryConditions } = pathOr({}, ['param'])(cond);

		if (!isEmpty(primaryConditions)) {
			const primaryType = pathOr(null, [0, 'type'])(primaryConditions);
			const { rule: primaryRule, value: primaryValue, sub_conditions: rawSubConditions } = pathOr({}, [0, 'param'])(primaryConditions);
			const subConditions = rawSubConditions.map((subCond) => {
				const subType = pathOr(null, ['type'])(subCond);
				const { rule: subRule, value: subValue, conditions: attributeSubConditions } = pathOr({}, ['param'])(subCond);

				// If there are sub conditions in this level
				// It is attribute condition
				if (!isEmpty(attributeSubConditions)) {
					const transformAttributeSubCondition = attributeSubConditions.map((attributeCondition) => {
						return {
							type: {
								label: attributeCondition.param.attribute_key_name,
								value: attributeCondition.param.attribute_key_id,
							},
							rule: attributeCondition.param.rule,
							value: attributeCondition.param.value,
						};
					});
					return {
						type: CONDITION_KEYS.ATTRIBUTE,
						rule: subType,
						value: null,
						subConditions: transformAttributeSubCondition,
					};
				}

				return {
					type: subType,
					rule: subRule,
					value: [CONDITION_KEYS.PURCHASE_AMOUNT, CONDITION_KEYS.TOTAL_PURCHASE_AMOUNT].includes(subType) ? priceFromAPI(subValue) : subValue,
				};
			});

			return {
				type: primaryType,
				rule: primaryRule,
				value: primaryValue,
				subConditions,
			};
		}

		return {
			type: condType,
			rule: condRule,
			value: condValue,
		};
	});

	return {
		type,
		conditions: flattedConditions,
	};
};

/**
 * transformedCartConditions
 * @param {Object} condition {type, conditions:[{type, rule, value}]}
 * @returns {Object} new condition with transformed {type, param: {conditions: [{type, param: {rule, value}}]}}
 */
export const transformedCartConditions = (condition = {}) => {
	const type = pathOr(null, ['type'])(condition);
	const cartCondition = pathOr({}, ['condition'])(condition);
	const { purchaseAmount, itemQuantity } = cartCondition;

	const conditions = [];
	if (purchaseAmount) {
		conditions.push({
			type: CART_CONDITION_TYPES.PURCHASE_AMOUNT,
			param: {
				rule: purchaseAmount.rule,
				value: purchaseAmount.value,
			},
		});
	}

	if (itemQuantity) {
		conditions.push({
			type: CART_CONDITION_TYPES.QUANTITY,
			param: {
				rule: itemQuantity.rule,
				value: itemQuantity.value,
			},
		});
	}

	if (conditions.length <= 0) {
		return null;
	}

	return {
		type,
		param: {
			conditions,
		},
	};
};


/**
 * transformedTierCartConditions
 * @param {Object} condition {type, conditions:[{type, rule, value}]}
 * @returns {Object} new condition with transformed {type, param: {conditions: [{type, param: {rule, value}}]}}
 */
export const transformedTierCartConditions = (condition = {}) => {
	const type = PROMOTION_TYPES.TIER;
	const cartMinimumType = pathOr(null, ['cartMinimumType'])(condition);
	const tiers = pathOr([], ['tier'])(condition);

	const conditions = tiers.map((tier) => ({
		type: cartMinimumType,
		param: {
			rule: CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL, // Tier: There are not UI to select the Rule, so use '>=' for default rule
			value: tier.minimumPurchaseAmount,
		},
		...(tier.usageLimitPerTier ? { promotion_usage_limit: tier.usageLimitPerTier } : null),
		...(tier.usageLimitPerUser ? { user_usage_limit: tier.usageLimitPerUser } : null),
	}));

	if (conditions.length <= 0) {
		return null;
	}

	return {
		type,
		param: {
			conditions,
		},
	};
};

/**
 * transformedFlatCartCondition
 * @param {Object} condition {type, param: {rule, value}}
 * @returns {Array} new condition with transformed {purchaseAmount, itemQuantity}
 */
export const transformedFlatCartCondition = (conditions = []) => {
	let purchaseAmount = conditions.find((cond) => cond.type === CART_CONDITION_TYPES.PURCHASE_AMOUNT);
	if (purchaseAmount) {
		const { rule: condRule, value: condValue } = pathOr({}, ['param'])(purchaseAmount);
		purchaseAmount = {
			rule: condRule,
			value: condValue,
		};
	}

	let itemQuantity = conditions.find((cond) => cond.type === CART_CONDITION_TYPES.QUANTITY);
	if (itemQuantity) {
		const { rule: condRule, value: condValue } = pathOr({}, ['param'])(itemQuantity);
		itemQuantity = {
			rule: condRule,
			value: condValue,
		};
	}

	return {
		purchaseAmount,
		itemQuantity,
	};
};

/**
 * recursiveTransformedPrimaryConditionToAPI
 * @param {Object} condition {type, conditions} => brands: [{name, id}], categories: [{name, id}]
 * @returns {Object} new condition with transformed {type, conditions} => brands: [id1, id2], categories: [id1, id2]
 */
export const recursiveTransformedPrimaryConditionToAPI = (condition) => {
	if (isEmpty(condition)) {
		return;
	}

	// If primary key is Brand or Category,
	// Send API only array of Id.
	const { param, type } = condition;
	if ([CONDITION_KEYS.BRAND, CONDITION_KEYS.CATEGORY, CONDITION_KEYS.MAIN_CATEGORY].includes(type)) {
		param.value = param.value.map((item) => item.id);
	}

	// Loop inside sub conditions to transform Brand & Category Ids.
	if (param && param.conditions && Array.isArray(param.conditions)) {
		param.conditions.forEach((cond) => {
			recursiveTransformedPrimaryConditionToAPI(cond);
		});
	}
};

/**
 * transformedProductConditionToAPI
 * @param {Object} condition {type, param: {conditions:[{type, rule, value}]}}
 * @returns {Object} new condition with transformed {type, param: {conditions:[{type, rule, priceToAPI(value)}]}}
 */
export const transformedProductConditionToAPI = (condition = {}) => {
	if (!isEmpty(condition) && !isEmpty(condition.param) && !isEmpty(condition.param.conditions) && Array.isArray(condition.param.conditions)) {
		// Send only array of id to API
		const transformCondition = cloneDeep(condition);
		transformCondition.param.conditions.forEach((prodCondition) => {
			prodCondition.param.conditions = prodCondition.param.conditions.map((primaryCondition) => {
				const { param, type, subConditions = [] } = primaryCondition;
				recursiveTransformedPrimaryConditionToAPI(primaryCondition);

				// If there are subConditions
				// Need to wrap primary and sub condition in CONDITION ALL
				if (!isEmpty(subConditions)) {
					const transformSubConditions = subConditions.map((subCondition) => {
						if ([CONDITION_KEYS.PURCHASE_AMOUNT, CONDITION_KEYS.TOTAL_PURCHASE_AMOUNT].includes(subCondition.type)) {
							subCondition.param.value = priceToAPI(subCondition.param.value);
						}

						// If there are subConditions
						// It is attribute condition list
						if (!isEmpty(subCondition.subConditions)) {
							const attributeSubCondition = subCondition.subConditions.map((attributeCondition) => (
								{
									type: CONDITION_KEYS.ATTRIBUTE,
									param: {
										attribute_key_id: attributeCondition.type.value,
										rule: attributeCondition.param.rule,
										value: attributeCondition.param.value,
									},
								}
							));

							return {
								type: subCondition.param.rule,
								param: {
									conditions: attributeSubCondition,
								},
							};
						}

						return subCondition;
					});

					return {
						type: CONDITION_TYPES.ALL,
						param: {
							conditions: [
								{
									type,
									param: {
										...param,
										sub_conditions: transformSubConditions,
									},
								},
							],
						},
					};
				}

				return primaryCondition;
			});
		});

		return transformCondition;
	}

	return null;
};

/**
 * transformedCartMinimumConditionToAPI
 * @param {Object} condition {type, param: {conditions:[{type, rule, value}]}}
 * @returns {Object} new condition with transformed {type, param: {conditions:[{type, rule, priceToAPI(value)}]}}
 */
export const transformedCartMinimumConditionToAPI = (condition = {}) => {
	let cartMinimumCondition = null;
	if (!isEmpty(condition)) {
		cartMinimumCondition = cloneDeep(condition);
		const param = cartMinimumCondition.param;
		if (!isEmpty(param) && !isEmpty(param.conditions) && Array.isArray(param.conditions) && param.conditions.length) {
			const purchaseAmounts = param.conditions.filter((item) => item.type === CART_CONDITION_TYPES.PURCHASE_AMOUNT);
			purchaseAmounts.forEach((purchaseAmount) => {
				purchaseAmount.param.value = priceToAPI(purchaseAmount.param.value);
			});
		} else {
			return null; // Invalid condition
		}
	}

	return cartMinimumCondition;
};

/**
 * transformedBundleCartMinimumConditionToAPI
 * @param {Object} condition {type, param: {conditions:[{type, rule, value}]}}
 * @returns {Object} new condition with transformed {type, param: {conditions:[{type, rule, priceToAPI(value)}]}}
 */
export const transformedBundleCartMinimumConditionToAPI = (productBundleCondition = [], cartCondition = {}) => {
	if (isEmpty(productBundleCondition)) {
		return null;
	}

	const items = productBundleCondition.map((item) => {
		const { sku, quantity } = item;
		return {
			sku, quantity,
		};
	});

	return {
		type: PROMOTION_TYPES.BUNDLE,
		param: {
			items,
			normal_cart_condition: transformedCartMinimumConditionToAPI(cartCondition),
		},
	};
};

/**
 * transformedTierCartMinimumConditionParam
 * @param {Object} condition {conditions:[{type, rule, value}]}
 * @returns {Object} new condition with transformed {conditions:[{type, rule, priceFromAPI(value)}]}
 */
export const transformedTierCartMinimumConditionParam = (condition = {}) => {
	let cartMinimumConditionParam = null;
	if (!isEmpty(condition)) {
		cartMinimumConditionParam = cloneDeep(condition);
		const conditions = cartMinimumConditionParam.conditions;
		if (!isEmpty(conditions) && Array.isArray(conditions)) {
			const purchaseAmounts = conditions.filter((item) => item.type === CART_CONDITION_TYPES.PURCHASE_AMOUNT);
			purchaseAmounts.forEach((purchaseAmount) => {
				purchaseAmount.param.value = priceFromAPI(purchaseAmount.param.value);
			});
		}
	}

	if (isEmpty(cartMinimumConditionParam) || isEmpty(cartMinimumConditionParam.conditions)) {
		return [];
	}

	return cartMinimumConditionParam.conditions.map((cond) => ({
		cartMinimumType: cond.type,
		minimumPurchaseAmount: cond.param.value || null,
		usageLimitPerTier: cond.promotion_usage_limit || null,
		usageLimitPerUser: cond.user_usage_limit || null,
	}));
};

/**
 * transformedCartMinimumConditionParam
 * @param {Object} condition {conditions:[{type, rule, value}]}
 * @returns {Object} new condition with transformed {conditions:[{type, rule, priceFromAPI(value)}]}
 */
export const transformedCartMinimumConditionParam = (condition = {}) => {
	let cartMinimumConditionParam = null;
	if (!isEmpty(condition)) {
		cartMinimumConditionParam = cloneDeep(condition);
		const conditions = cartMinimumConditionParam.conditions;
		if (!isEmpty(conditions) && Array.isArray(conditions)) {
			const purchaseAmounts = conditions.filter((item) => item.type === CART_CONDITION_TYPES.PURCHASE_AMOUNT);
			purchaseAmounts.forEach((purchaseAmount) => {
				purchaseAmount.param.value = priceFromAPI(purchaseAmount.param.value);
			});
		}
	} else if (Array.isArray(condition)) {
		cartMinimumConditionParam = {}; // This is workaround when API return empty array
	}

	return cartMinimumConditionParam;
};

/**
 * transformedCustomerGroupListToAPI
 * @param {Array} list of object
 * @returns {Array} list of id || null
 */
export const transformedCustomerGroupListToAPI = (list = {}) => {
	if (list && Array.isArray(list) && list.length > 0) {
		return list.map(({ id }) => id);
	}
	return null;
};

/**
 * transformedExcludePromotionListToAPI
 * @param {Array} list of object
 * @returns {Array} list of id || null
 */
export const transformedExcludePromotionListToAPI = (list = {}) => {
	if (list && Array.isArray(list) && list.length > 0) {
		return list.map(({ id }) => id);
	}
	return null;
};

/**
 * transformedShippingListToAPI
 * @param {Array} list of object
 * @returns {Array} list of id || null
 */
export const transformedShippingListToAPI = (list = {}) => {
	if (list && Array.isArray(list) && list.length > 0) {
		return list.map(({ id }) => id);
	}
	return null;
};

/**
 * transformedPaymentMethodGroupListToAPI
 * @param {Array} list of object
 * @returns {Array} list of id || null
 */
export const transformedPaymentMethodGroupListToAPI = (list = {}) => {
	if (list && Array.isArray(list) && list.length > 0) {
		return list.map(({ id }) => id);
	}
	return null;
};

/**
 * transformedCustomerConditionToAPI
 * @param {Object} condition {type, param: {conditions:[{type, value}]}}
 * @returns {Object} new condition with transformed {type, param: {conditions:[{type, value}]}} || null
 */
export const transformedCustomerConditionToAPI = (condition = {}) => {
	if (isEmpty(condition) || isEmpty(condition.param)) {
		return null;
	}

	return condition;
};

/**
 * transformedPurchaseHistoryConditionToAPI
 * @param {Object} condition {type, param: {conditions:[{type, value}]}}
 * @returns {Object} new condition with transformed {type, param: {conditions:[{type, value}]}} || null
 */
export const transformedPurchaseHistoryConditionToAPI = (condition = {}) => {
	if (isEmpty(condition) || isEmpty(condition.param)) {
		return null;
	}

	return condition;
};

/**
 * transformedFreebieStockOption
 * @param {Object} stockOption {key, params: {notice_text_en, notice_text_th}}
 * @returns {Object} new stockOption with transformed || null
 */
export const transformedFreebieStockOption = (stockOption = {}) => {
	if (isEmpty(stockOption?.key)) {
		return null;
	}

	switch (stockOption.key) {
		case FREEBIE_STOCK_OPTION_KEYS.IGNORE_INSUFFICIENT_STOCK:
			return {
				key: FREEBIE_STOCK_OPTION_KEYS.IGNORE_INSUFFICIENT_STOCK,
				params: {
					noticeTextEn: stockOption.params.notice_text_en,
					noticeTextTh: stockOption.params.notice_text_th,
				},
			};
		case FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU: {
			return {
				key: FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU,
				params: {
					secondarySku: {
						sku: stockOption.params.sku,
						rewardQty: stockOption.params.reward_quantity,
					},
				},
			};
		}
		case FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT: {
			return {
				key: FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT,
				params: {
					amountDiscount: priceFromAPI(stockOption.params.amount),
				},
			};
		}
		default:
			return {
				key: FREEBIE_STOCK_OPTION_KEYS.DEFAULT,
			};
	}
};

/**
 * transformedFreebieStockOptionToAPI
 * @param {Object} stockOption {key, params: {noticeTextEn, noticeTextTh}}
 * @returns {Object} new stockOption with transformed || null
 */
export const transformedFreebieStockOptionToAPI = (stockOption = {}) => {
	if (isEmpty(stockOption?.key)) {
		return null;
	}

	switch (stockOption.key) {
		case FREEBIE_STOCK_OPTION_KEYS.IGNORE_INSUFFICIENT_STOCK:
			return {
				key: FREEBIE_STOCK_OPTION_KEYS.IGNORE_INSUFFICIENT_STOCK,
				params: {
					notice_text_en: stockOption.params.noticeTextEn,
					notice_text_th: stockOption.params.noticeTextTh,
				},
			};
		case FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU: {
			const { sku, rewardQty } = stockOption.params.secondarySku;
			return {
				key: FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU,
				params: {
					sku,
					reward_quantity: rewardQty,
				},
			};
		}
		case FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT: {
			return {
				key: FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT,
				params: {
					amount: priceToAPI(stockOption.params.amountDiscount),
				},
			};
		}
		default:
			return null;
	}
};

export const transformedOnTopPaymentListToAPI = (payments = []) => {
	return payments.map((payment) => {
		return {
			payment_method_id: payment?.paymentMethod?.id ?? null,
			payment_channel_id: payment?.paymentChannel?.id ?? null,
			campaign_code: payment?.campaignCode ?? '',
		};
	});
};

/**
 * transformedRewardToAPI
 * @param {Object} paramCondition to get purchase subtract discount condition
 * @param {Object} paramReward
 * @returns {Object} reward with transformed {type, param: { value: discount, max: priceToAPI(maximumDiscount) }}
 */
export const transformedRewardToAPI = (paramCondition, paramReward = {}) => {
	const { purchaseSubtractDiscountCondition = {} } = paramCondition;
	const {
		applyTo,
		applyToItems,
		discountType = {},
		discount,
		maximumDiscount,
		discountItemList = [],
		onTopPaymentList = [],
		applyDiscountEvery = false,
		applyDiscountEveryType = {},
		applyDiscountEveryValue,
		applyDiscountEveryLimitValue,
	} = paramReward;

	// Discount value
	const rewardType = REWARD_KEYS[applyTo][discountType.value];
	if (!rewardType) {
		return null;
	}

	let rewardParam = null;
	switch (rewardType) {
		case REWARD_TYPE_KEYS.CART_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.ITEM_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.BUNDLE_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.BUNDLE2_ITEM_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.CATEGORY_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.SKU_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.BUNDLE2_SKU_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.SHIPPING_PERCENT_DISCOUNT:
			rewardParam = {
				value: discount,
				max: priceToAPI(maximumDiscount),
			};
			break;
		case REWARD_TYPE_KEYS.CART_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.ITEM_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE2_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.CATEGORY_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE2_SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
			rewardParam = {
				value: discount,
				max: priceToAPI(maximumDiscount),
				payments: transformedOnTopPaymentListToAPI(onTopPaymentList),
			};
			break;
		case REWARD_TYPE_KEYS.CART_FREEBIE:
		case REWARD_TYPE_KEYS.ITEM_FREEBIE:
		case REWARD_TYPE_KEYS.BUNDLE_FREEBIE:
		case REWARD_TYPE_KEYS.CATEGORY_FREEBIE:
		case REWARD_TYPE_KEYS.SKU_FREEBIE:
		case REWARD_TYPE_KEYS.BUNDLE2_SET_FREEBIE: {
			const items = discountItemList.map((item) => {
				const { sku, rewardQty, stockOption } = item;

				if (stockOption && stockOption.key) {
					return {
						sku,
						quantity: rewardQty,
						stock_option: transformedFreebieStockOptionToAPI(stockOption),
					};
				}

				return {
					sku, quantity: rewardQty,
				};
			});
			rewardParam = {
				items,
			};
			break;
		}
		case REWARD_TYPE_KEYS.FREE_SHIPPING: break; // Leave param value be null
		case REWARD_TYPE_KEYS.COUPON: {
			const coupons = discountItemList.reduce((acc, currentDiscountItem) => {
				const { id, couponCodes, emailTemplateId } = currentDiscountItem;

				if (isEmpty(couponCodes)) {
					return [
						...acc,
						{
							promotion_id: id,
							code: null,
							template_id: emailTemplateId,
						},
					];
				}

				// Split coupon codes to be individual param
				const rewards = couponCodes.map((code) => ({
					promotion_id: id,
					code,
					template_id: emailTemplateId,
				}));

				return [
					...acc,
					...rewards,
				];
			}, []);

			rewardParam = {
				coupons,
			};
			break;
		}
		case REWARD_TYPE_KEYS.CART_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.ITEM_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE2_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.CATEGORY_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE2_SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT:
			rewardParam = {
				value: priceToAPI(discount),
				payments: transformedOnTopPaymentListToAPI(onTopPaymentList),
			};
			break;
		default:
			rewardParam = {
				value: priceToAPI(discount),
			};
			break;
	}

	// Apply discount for every XXX purchase amount/quantity
	if (applyDiscountEvery) {
		const applyEveryType = applyDiscountEveryType?.value;
		const isApplyEveryPurchaseAmount = applyEveryType === APPLY_DISCOUNT_EVERY_TYPE_KEYS.PURCHASE_AMOUNT;
		rewardParam = {
			...rewardParam,
			apply_every: {
				type: applyEveryType,
				value: isApplyEveryPurchaseAmount ? priceToAPI(applyDiscountEveryValue) : applyDiscountEveryValue,
				max: isApplyEveryPurchaseAmount ? priceToAPI(applyDiscountEveryLimitValue) : applyDiscountEveryLimitValue,
			},
		};
	}

	if ([APPLY_TO_KEYS.SPECIFIC_SKU, APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU].includes(applyTo)) {
		if (isEmpty(applyToItems)) {
			return null;
		}

		rewardParam = {
			skus: applyToItems,
			...rewardParam,
		};
	} else if (applyTo === APPLY_TO_KEYS.SPECIFIC_CATEGORY) {
		if (isEmpty(applyToItems)) {
			return null;
		}

		rewardParam = {
			category_ids: applyToItems,
			...rewardParam,
		};
	} else if (applyTo === APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION) {
		if (isEmpty(applyToItems)) {
			return null;
		}

		rewardParam = {
			skus: applyToItems,
			...rewardParam,
		};
	}

	if (purchaseSubtractDiscountCondition) {
		rewardParam = {
			...rewardParam,
			is_source_price_subtract_discount: purchaseSubtractDiscountCondition.value, // Field of calculation priority logic
		};
	}

	return {
		type: rewardType,
		...(rewardParam ? { param: rewardParam } : null),
	};
};

/**
 * transformedTierRewardToAPI
 * @param {Object} param
 * @returns {Object} reward with transformed {type, param: { value: discount, max: priceToAPI(maximumDiscount) }}
 */
export const transformedTierRewardToAPI = (paramCondition = {}, paramReward = {}, paramDisplaying = {}) => {
	let applyDiscountEveryType = APPLY_DISCOUNT_EVERY_TYPE_OPTIONS[0];
	const { cartCondition } = paramCondition;
	const cartConditions = cartCondition?.param?.conditions || [];
	if (cartConditions.length > 0) {
		applyDiscountEveryType = APPLY_DISCOUNT_EVERY_TYPE_OPTIONS.find((option) => option.value === cartConditions[0].type) || APPLY_DISCOUNT_EVERY_TYPE_OPTIONS[0];
	}

	const {
		customerRewardType,
		applyTo,
		applyToItems,
		discountType = {},
		applyDiscountEvery = false,
		tiers: tierRewards = [],
	} = paramReward;

	const { tiers: tierDisplayings = [] } = paramDisplaying;

	if (tierRewards.length > 0 && tierDisplayings.length > 0) {
		const transformedTierRewards = tierRewards.map((tier) => {
			const tierParam = {
				applyTo,
				applyToItems,
				discountType,
				discount: tier.discount,
				maximumDiscount: tier.maximumDiscount,
				discountItemList: tier.discountItemList,
				applyDiscountEvery,
				applyDiscountEveryType,
				applyDiscountEveryValue: tier.minimumPurchaseAmount,
			};
			return transformedRewardToAPI(paramCondition, tierParam);
		});

		// Assign displaying setting for each tier
		transformedTierRewards.forEach((tier, index) => {
			tier.cart_description_en = tierDisplayings[index].cartNotReachEN;
			tier.cart_description_th = tierDisplayings[index].cartNotReachTH;
			tier.cart_description_reached_en = tierDisplayings[index].cartReachEN;
			tier.cart_description_reached_th = tierDisplayings[index].cartReachTH;
		});

		return {
			type: PROMOTION_TYPES.TIER,
			param: {
				is_apply_all: customerRewardType === CUSTOMER_REWARD_TYPES.ALL,
				rewards: transformedTierRewards,
			},
		};
	}

	return null;
};

/**
 * transformedRewardParamPayments
 * @param {Array} payments
 * @returns {Array} transformed payments
 */
export const transformedRewardParamPayments = (payments = []) => {
	return payments.map((payment) => {
		return {
			paymentMethod: {
				id: pathOr(null, ['payment_method_id'])(payment),
			},
			paymentChannel: {
				id: pathOr(null, ['payment_channel_id'])(payment),
			},
			campaignCode: pathOr('', ['campaign_code'])(payment),
		};
	});
};

/**
 * transformedFlatReward
 * @param {Object} condition {reward_type, reward_param:{value, max?}}
 * @returns {Object} new reward with transformed {applyTo, discountType, discount, maximumDiscount?}
 */
export const transformedFlatReward = (type, param = {}) => {
	const [applyTo, discountType] = getRewardKeysFromRewardValue(type);
	const discountTypeOption = DISCOUNT_TYPE_OPTIONS.find((option) => option.value === discountType);
	const {
		value: discount,
		max: maximumDiscount,
		items = [],
		apply_every: applyEvery,
		skus,
		category_ids: categoryIds,
		coupons,
		payments = [],
	} = param;

	let rewardParam = {
		applyTo,
		discountType: discountTypeOption,
	};
	switch (type) {
		case REWARD_TYPE_KEYS.CART_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.ITEM_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.BUNDLE_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.BUNDLE2_ITEM_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.CATEGORY_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.SKU_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.BUNDLE2_SKU_PERCENT_DISCOUNT:
		case REWARD_TYPE_KEYS.SHIPPING_PERCENT_DISCOUNT:
			rewardParam = {
				...rewardParam,
				discount,
				maximumDiscount: priceFromAPI(maximumDiscount),
			};
			break;
		case REWARD_TYPE_KEYS.CART_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.ITEM_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE2_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.CATEGORY_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE2_SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT:
			rewardParam = {
				...rewardParam,
				discount,
				maximumDiscount: priceFromAPI(maximumDiscount),
				onTopPaymentList: transformedRewardParamPayments(payments),
			};
			break;
		case REWARD_TYPE_KEYS.CART_FREEBIE:
		case REWARD_TYPE_KEYS.ITEM_FREEBIE:
		case REWARD_TYPE_KEYS.BUNDLE_FREEBIE:
		case REWARD_TYPE_KEYS.CATEGORY_FREEBIE:
		case REWARD_TYPE_KEYS.SKU_FREEBIE:
		case REWARD_TYPE_KEYS.BUNDLE2_SET_FREEBIE: {
			const discountItemList = items.map((item) => {
				const { sku, quantity: rewardQty, stock_option: stockOption } = item;
				return {
					sku,
					rewardQty,
					stockOption: transformedFreebieStockOption(stockOption),
				};
			});
			rewardParam = {
				...rewardParam,
				discountItemList,
			};
			break;
		}
		case REWARD_TYPE_KEYS.COUPON: {
			const discountItemList = coupons.reduce((objectsByKeyValue, coupon) => {
				if (!coupon) {
					return objectsByKeyValue;
				}

				const promotionId = coupon.promotion_id;
				const keyIndex = objectsByKeyValue.findIndex((promotion) => promotion.id === promotionId);
				if (keyIndex >= 0) {
					// append coupon code to existing group
					objectsByKeyValue[keyIndex].couponCodes = [
						...objectsByKeyValue[keyIndex].couponCodes,
						coupon.code,
					];

					return objectsByKeyValue;
				}

				return [
					...objectsByKeyValue,
					{
						id: coupon.promotion_id,
						couponCodes: [coupon.code],
						emailTemplateId: coupon.template_id,
					},
				];
			}, []);

			rewardParam = {
				...rewardParam,
				discountItemList,
			};
			break;
		}
		case REWARD_TYPE_KEYS.CART_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.ITEM_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE2_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.CATEGORY_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT:
		case REWARD_TYPE_KEYS.BUNDLE2_SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT:
			rewardParam = {
				...rewardParam,
				discount: priceFromAPI(discount),
				onTopPaymentList: transformedRewardParamPayments(payments),
			};
			break;
		default:
			rewardParam = {
				...rewardParam,
				discount: priceFromAPI(discount),
			};
			break;
	}

	if (!isEmpty(applyEvery)) {
		const { type: applyEveryType, value: applyEveryValue, max: applyEveryLimitValue } = applyEvery;
		const isApplyEveryPurchaseAmount = applyEveryType === APPLY_DISCOUNT_EVERY_TYPE_KEYS.PURCHASE_AMOUNT;
		rewardParam = {
			...rewardParam,
			applyDiscountEvery: true,
			applyDiscountEveryType: APPLY_DISCOUNT_EVERY_TYPE_OPTIONS.find((option) => option.value === applyEveryType) || APPLY_DISCOUNT_EVERY_TYPE_OPTIONS[0],
			applyDiscountEveryValue: isApplyEveryPurchaseAmount ? priceFromAPI(applyEveryValue) : applyEveryValue,
			applyDiscountEveryLimitValue: isApplyEveryPurchaseAmount ? priceFromAPI(applyEveryLimitValue) : applyEveryLimitValue,
		};
	}

	if (!isEmpty(skus)) {
		rewardParam = {
			applyToItems: skus,
			...rewardParam,
		};
	}

	if (!isEmpty(categoryIds)) {
		rewardParam = {
			applyToItems: categoryIds,
			...rewardParam,
		};
	}

	return rewardParam;
};

/**
 * transformedPromotionDataToAPI
 * @param {Object} promotion information data
 * @returns {Object} transformed promotion data to be request payload
 */
export const transformedPromotionDataToAPI = (data = {}) => {
	if (!data || isEmpty(data)) {
		return null;
	}

	const { type: promotionType, steps } = data;

	const { formData: step1 } = steps[1];
	const { formData: step2 } = steps[2];
	const { formData: step3 } = steps[3];
	const { formData: step4 } = steps[4];
	const { formData: step5 } = steps[5] || {};

	const isEditMode = !!step1.id; // if there are id, it mean try to working in edit mode

	const isECoupon = data.type === PROMOTION_TYPES.COUPON;
	const isBundle = data.type === PROMOTION_TYPES.BUNDLE;
	const isTier = data.type === PROMOTION_TYPES.TIER;
	const stepECoupon = isECoupon ? step3 : null;
	const stepReward = isECoupon ? step4 : step3;
	const stepDisplaying = isECoupon ? step5 : step4;

	const postData = {
		type: promotionType,
		...(step1.id ? { id: step1.id } : null),
		name: step1.name,
		status: step1.status,
		priority: step1.priority,
		start_at: convertDateTimeToUTC(step1.startDate, step1.startTime),
		end_at: convertDateTimeToUTC(step1.endDate, step1.endTime),
		customer_group_ids: transformedCustomerGroupListToAPI(step1.customerGroups),
		user_usage_limit: step1.usageLimitPerUser,
		promotion_usage_limit: step1.usageLimitPerPromotion,
		payment_expire_hour: step1.overridePeriod && step1.overridePeriodValue ? step1.overridePeriodValue : null,
		excluded_promotion_ids: transformedExcludePromotionListToAPI(step1.excludePromotions),
		bundle_setting_ids: step2.bundleSettingIds ?? null,
		item_condition: transformedProductConditionToAPI(step2.productCondition),
		cart_minimum_condition: isBundle
			? transformedBundleCartMinimumConditionToAPI(step2.productBundleCondition, step2.cartCondition)
			: transformedCartMinimumConditionToAPI(step2.cartCondition),
		is_purchase_condition_subtract_discount: step2.purchaseSubtractDiscountCondition?.value ?? null,
		shipping_method_ids: transformedShippingListToAPI(step2.shippingMethods),
		payment_method_group_ids: transformedPaymentMethodGroupListToAPI(step2.paymentMethods),
		customer_condition: transformedCustomerConditionToAPI(step2.customerCondition),
		purchase_history_condition: transformedPurchaseHistoryConditionToAPI(step2.purchaseHistoryCondition),
		reward: isTier ? transformedTierRewardToAPI(step2, stepReward, stepDisplaying) : transformedRewardToAPI(step2, stepReward),
		is_detail_visible: stepDisplaying.isProductDetailVisible,
		display_name_en: stepDisplaying.promotionNameEN,
		display_name_th: stepDisplaying.promotionNameTH,
		coupon_title_en: stepDisplaying.couponTitleEN,
		coupon_title_th: stepDisplaying.couponTitleTH,
		detail_description_en: stepDisplaying.promotionDescriptionEN,
		detail_description_th: stepDisplaying.promotionDescriptionTH,
		term_condition_en: stepDisplaying.termConditionEN,
		term_condition_th: stepDisplaying.termConditionTH,
		is_cart_visible: stepDisplaying.isCartVisible || false,
		cart_description_en: stepDisplaying.cartNotReachEN || '',
		cart_description_th: stepDisplaying.cartNotReachTH || '',
		cart_description_reached_en: stepDisplaying.cartReachEN || '',
		cart_description_reached_th: stepDisplaying.cartReachTH || '',
		...(isBundle ? {
			bundle_name_en: stepDisplaying.bundleNameEN,
			bundle_name_th: stepDisplaying.bundleNameTH,
		} : null),
		...(isECoupon ? {
			code: {
				generation_type: stepECoupon.couponMode,
				quantity: stepECoupon.quantity,
				prefix: stepECoupon.prefix,
				suffix: stepECoupon.suffix,
				length: stepECoupon.length,
				usage_limit: stepECoupon.usageLimit,
			},
			codes: (isEditMode ? stepECoupon.newCouponCodes : stepECoupon.couponCodes), // if edit mode should send only new codes
		} : null),
	};

	return postData;
};

/**
 * transformedPromotionRemarkDetails
 * @param {Object} promotion remark data
 * @returns {Object} transformed promotion remark data
 */
export const transformedPromotionRemarkDetails = (remark = {}) => ({
	id: remark.id || null,
	message: remark.message || null,
	updatedAt: datetimeFormat(remark.updated_at),
	user: remark?.user ? transformedUser(remark.user) : null,
});

/**
 * transformedPromotionRemarkList
 * @param {Array} promotion remarks data
 * @returns {Array} transformed promotion remarks data
 */
export const transformedPromotionRemarkList = (remarks = []) => remarks.map(transformedPromotionRemarkDetails);

/**
 * getProductInformation
 * @param {Array} products required sku string
 * @returns {Array} transformed information of products
 */
export const getProductInformation = async (products) => {
	if (isEmpty(products)) {
		return [];
	}

	const productInfos = await Promise.all(products.map(getProductInfo));
	return productInfos;
};

/**
 * getPromotionInformation
 * @param {Array} promotion required id
 * @returns {Array} transformed information of promotions
 */
export const getPromotionInformation = async (promotions) => {
	if (isEmpty(promotions)) {
		return [];
	}

	const promotionInfos = await Promise.all(promotions.map(getPromotionInfo));
	return promotionInfos;
};


/**
 * transformedPromotionInfoToDataMultipleStep
 * @param {Object} promotion information data
 * @returns {Object} transformed promotion data
 */
export const transformedPromotionInfoToDataMultipleStep = async (data = {}) => {
	if (!data || isEmpty(data)) {
		return null;
	}

	const isECoupon = data.type === PROMOTION_TYPES.COUPON;
	const isBundle = data.type === PROMOTION_TYPES.BUNDLE;
	const isTier = data.type === PROMOTION_TYPES.TIER;
	const stepECoupon = isECoupon ? 3 : null;
	const stepReward = isECoupon ? 4 : 3;
	const stepDisplaying = stepReward + 1;

	const promotionData = {
		steps: {
			1: { formData: {} },
			2: { formData: {} },
			3: { formData: {} },
			4: { formData: {} },
			...(isECoupon ? { 5: { formData: {} } } : null),
		},
	};
	promotionData.promotionType = data.type;
	promotionData.steps[1].formData = {
		id: data.id,
		name: data.name,
		status: data.status,
		priority: data.priority,
		startDate: new Date(datetimeFormat(data.start_at, 'YYYY-MM-DD')),
		startTime: datetimeFormat(data.start_at, 'HH:mm'),
		endDate: new Date(datetimeFormat(data.end_at, 'YYYY-MM-DD')),
		endTime: datetimeFormat(data.end_at, 'HH:mm'),
		periodStatus: data.period_status,
		usageLimitPerUser: data.user_usage_limit,
		usageLimitPerPromotion: data.promotion_usage_limit,
		overridePeriod: data.payment_expire_hour > 0,
		overridePeriodValue: data.payment_expire_hour || null,
		customerGroups: data.customer_groups || [],
		excludePromotions: data.excluded_promotions ? transformedPromotionList(data.excluded_promotions) : [],
		remarks: transformedPromotionRemarkList(data.remarks || []),
	};

	promotionData.steps[2].formData = {
		bundleSettingIds: data.bundle_setting_ids ?? [],
		productCondition: {
			type: data.item_condition_type || CONDITION_TYPES.ALL,
			param: data.item_condition_param,
		},
		cartCondition: {
			type: data.cart_minimum_condition_type || CONDITION_TYPES.ALL,
			param: transformedCartMinimumConditionParam(data.cart_minimum_condition_param),
		},
		purchaseSubtractDiscountCondition: PURCHASE_SUBTRACT_DISCOUNT_OPTIONS.find((option) => option.value === data.is_purchase_condition_subtract_discount) || PURCHASE_SUBTRACT_DISCOUNT_OPTIONS[0],
		shippingMethods: data.shipping_methods || [],
		paymentMethods: data.payment_method_groups || [],
		customerCondition: {
			type: data.customer_condition_type || CONDITION_TYPES.ALL,
			param: data.customer_condition_param,
		},
		purchaseHistoryCondition: {
			type: data.purchase_history_condition_type || CONDITION_TYPES.ALL,
			param: data.purchase_history_condition_param,
		},
	};
	promotionData.steps[stepReward].formData = transformedFlatReward(data.reward_type, data.reward_param);
	promotionData.steps[stepDisplaying].formData = {
		isProductDetailVisible: data.is_detail_visible,
		promotionNameEN: data.display_name_en,
		promotionNameTH: data.display_name_th,
		promotionDescriptionEN: data.detail_description_en,
		promotionDescriptionTH: data.detail_description_th,
		isCartVisible: data.is_cart_visible,
		cartNotReachEN: data.cart_description_en,
		cartNotReachTH: data.cart_description_th,
		cartReachEN: data.cart_description_reached_en,
		cartReachTH: data.cart_description_reached_th,
		couponTitleTH: data.coupon_title_th,
		couponTitleEN: data.coupon_title_en,
		termConditionEN: data.term_condition_en,
		termConditionTH: data.term_condition_th,
	};
	if (isBundle) {
		const bundelProductItems = pathOr([], ['cart_minimum_condition_param', 'items'])(data);
		const normalCartMinimumCondition = pathOr({}, ['cart_minimum_condition_param', 'normal_cart_condition'])(data);

		const transformedProductBundles = await Promise.all(bundelProductItems.map(getProductInfo));

		promotionData.steps[2].formData = {
			...promotionData.steps[2].formData,
			productCondition: null,
			productBundleCondition: transformedProductBundles,
			cartCondition: {
				type: normalCartMinimumCondition.type || CONDITION_TYPES.ALL,
				param: transformedCartMinimumConditionParam(normalCartMinimumCondition.param),
			},
		};

		// Display bundle name
		promotionData.steps[stepDisplaying].formData = {
			...promotionData.steps[stepDisplaying].formData,
			bundleNameEN: data.bundle_name_en,
			bundleNameTH: data.bundle_name_th,
		};
	}
	if (isECoupon) {
		const codeInfo = data.code || {};
		promotionData.steps[stepECoupon].formData = {
			id: data.id,
			couponMode: (COUPON_MODE_OPTIONS.find((option) => option.value === codeInfo.generation_type) || {}).value || COUPON_MODE_OPTIONS[0].value,
			quantity: codeInfo.quantity,
			prefix: codeInfo.prefix,
			suffix: codeInfo.suffix,
			length: codeInfo.length,
			usageLimit: codeInfo.usage_limit,
			couponCodes: data.codes || [],
			newCouponCodes: [], // new coupon codes always is empty array
		};
	}

	if (isTier) {
		// Base Tier condition
		const tierConditions = transformedTierCartMinimumConditionParam(data.cart_minimum_condition_param);

		// #region Tier condition for Step2 (Condition).
		promotionData.steps[2].formData = {
			...promotionData.steps[2].formData,
			tierCondition: tierConditions,
		};
		// #endregion

		// #region Tier condition for Step3 (Reward)
		let tierConditionsReward = null;
		if (data.reward_param && data.reward_param.rewards && Array.isArray(data.reward_param.rewards)) {
			tierConditionsReward = data.reward_param.rewards.map((reward) => {
				return transformedFlatReward(reward.type, reward.param);
			});
		}

		const mergeTierConditionsStepReward = tierConditions.map((cond, index) => {
			if (tierConditionsReward && tierConditionsReward[index]) {
				return {
					...cond,
					...tierConditionsReward[index],
				};
			}

			return cond;
		});

		let customerRewardType = CUSTOMER_REWARD_TYPES.ALL; // Default as 'ALL'
		if (data.reward_param) {
			customerRewardType = data.reward_param.is_apply_all ? CUSTOMER_REWARD_TYPES.ALL : CUSTOMER_REWARD_TYPES.HIGHEST_TIER;
		}

		promotionData.steps[stepReward].formData = {
			...(tierConditionsReward && tierConditionsReward[0]
				? {
					applyTo: tierConditionsReward[0].applyTo,
					applyToItems: tierConditionsReward[0].applyToItems,
					applyDiscountEvery: tierConditionsReward[0].applyDiscountEvery,
					applyDiscountEveryType: tierConditionsReward[0].applyDiscountEveryType,
					discountType: tierConditionsReward[0].discountType,
				}
				: null
			),
			customerRewardType,
			tierCondition: mergeTierConditionsStepReward, // Send as Props for re-initail tiers value
		};

		// HACK: Get product information & stock for freebie list
		if (promotionData.steps[stepReward].formData && promotionData.steps[stepReward].formData.tierCondition) {
			const discountTypeValue = promotionData.steps[stepReward].formData.tierCondition[0].discountType.value;
			let discountItemList = null;
			let tierConditionWithProductInfo = null;

			if (discountTypeValue === DISCOUNT_TYPE_KEYS.COUPON) {
				tierConditionWithProductInfo = await Promise.all(promotionData.steps[stepReward].formData.tierCondition.map(async (tier) => {
					discountItemList = await getPromotionInformation(tier.discountItemList);
					return {
						...tier,
						discountItemList,
					};
				}));
			} else if (discountTypeValue === DISCOUNT_TYPE_KEYS.FREEBIE) {
				tierConditionWithProductInfo = await Promise.all(promotionData.steps[stepReward].formData.tierCondition.map(async (tier) => {
					discountItemList = await getProductInformation(tier.discountItemList);
					return {
						...tier,
						discountItemList,
					};
				}));
			} else {
				tierConditionWithProductInfo = promotionData.steps[stepReward].formData.tierCondition; // Original reward condition
			}

			promotionData.steps[stepReward].formData.tierCondition = tierConditionWithProductInfo;
		}
		// #endregion

		// #region Tier condition for Step4 (Displaying)
		let tierConditionsDisplaying = null;
		if (data.reward_param && data.reward_param.rewards && Array.isArray(data.reward_param.rewards)) {
			tierConditionsDisplaying = data.reward_param.rewards.map((reward) => {
				return {
					cartNotReachEN: reward.cart_description_en,
					cartNotReachTH: reward.cart_description_th,
					cartReachEN: reward.cart_description_reached_en,
					cartReachTH: reward.cart_description_reached_th,
				};
			});
		}

		const mergeTierConditionsStepDisplaying = tierConditions.map((cond, index) => {
			if (tierConditionsDisplaying && tierConditionsDisplaying[index]) {
				return {
					...cond,
					...tierConditionsDisplaying[index],
				};
			}

			return cond;
		});

		promotionData.steps[stepDisplaying].formData = {
			...promotionData.steps[stepDisplaying].formData,
			customerRewardType,
			tierCondition: mergeTierConditionsStepDisplaying, // Send as Props for re-initail tiers value
		};
		// #endregion
	} else if (promotionData.steps[stepReward].formData && promotionData.steps[stepReward].formData.discountItemList) {
		// HACK: Get product information & stock for freebie list
		if (!promotionData.steps[stepReward].formData || !promotionData.steps[stepReward].formData.discountType) {
			return promotionData;
		}

		const discountTypeValue = promotionData.steps[stepReward].formData.discountType.value;
		let discountItemList = null;

		if (discountTypeValue === DISCOUNT_TYPE_KEYS.COUPON) {
			discountItemList = await getPromotionInformation(promotionData.steps[stepReward].formData.discountItemList);
		} else if (discountTypeValue === DISCOUNT_TYPE_KEYS.FREEBIE || discountTypeValue === DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE) {
			discountItemList = await getProductInformation(promotionData.steps[stepReward].formData.discountItemList);
		}

		promotionData.steps[stepReward].formData.discountItemList = discountItemList;
	}

	return promotionData;
};

/**
 * @param {Array} currentPromotions list of promotion object
 * @param {Array} selectedPromotionIds list of selected promotion id
 * @param {Array} excludeIds list of promotion id should exclude from selectedPromotionIds
 * @returns {Array}
 */
export const transformExcludePromotionWithExcludeIds = (currentPromotions = [], selectedPromotionIds = [], excludeIds = []) => {
	if (Array.isArray(selectedPromotionIds) && selectedPromotionIds.length > 0) {
		return selectedPromotionIds.reduce((acc, id) => {
			const idString = `${id}`;

			// Check not in excludeIds
			// if not skip this promotion id
			if (Array.isArray(excludeIds) && excludeIds.length > 0) {
				const excludeIdsString = excludeIds.map((value) => `${value}`);

				if (excludeIdsString.includes(idString)) {
					return acc;
				}
			}

			// If id is same with current promotions
			// then use data from current promotion
			if (Array.isArray(currentPromotions) && currentPromotions.length > 0) {
				const promotionData = currentPromotions.find((promotion) => `${promotion.id}` === idString);
				if (promotionData) {
					return [...acc, promotionData];
				}
			}

			return [...acc, { id }];
		}, []);
	}

	return [];
};
