import { getProductStatusValue, getThumbnailFromProduct, pathOr } from '../helpers';
import { PRODUCT_STATUS_OPTIONS } from '../../../enums/products';

export const transformedProductMustHave = (productMustHave = {}) => {
	const id = pathOr(0, ['id'])(productMustHave);
	const uid = pathOr(0, ['uid'])(productMustHave);
	const name = pathOr('', ['name'])(productMustHave);
	const slug = pathOr('', ['slug'])(productMustHave);
	const sku = pathOr('', ['sku'])(productMustHave);
	// eslint-disable-next-line camelcase
	const isEOL = pathOr(0, ['is_eol'])(productMustHave);
	// eslint-disable-next-line camelcase
	const isActive = pathOr(0, ['is_active'])(productMustHave);
	const statusValue = getProductStatusValue(isEOL, isActive);
	const status = PRODUCT_STATUS_OPTIONS.find((option) => option.value === statusValue);
	const thumbnail = getThumbnailFromProduct(productMustHave);
	return {
		id,
		uid,
		name,
		slug,
		sku,
		thumbnail,
		status,
	};
};
export const transformedProductMustHaveList = (productMustHaveList = []) => {
	if (Array.isArray(productMustHaveList)) {
		return productMustHaveList.map(transformedProductMustHave);
	}
	return [];
};
