<template>
	<span :tooltip="message"><FontAwesomeIcon :icon="['far', 'info-circle']" /></span>
</template>

<script>
export default {
	name: 'BaseTooltip',

	props: {
		message: {
			type: String,
			default: '',
			Request: true,
		},
	},
};
</script>
