const state = {
	sidebarShow: 'responsive',
	sidebarMinimize: true,
	asideShow: false,
	darkMode: false,
};

/* eslint-disable */
const mutations = {
	toggleSidebarDesktop(state) {
		const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
		state.sidebarShow = sidebarOpened ? false : "responsive";
	},
	toggleSidebarMobile(state) {
		const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
		state.sidebarShow = sidebarClosed ? true : "responsive";
	},
	set(state, [variable, value]) {
		state[variable] = value;
	},
	toggle(state, variable) {
		state[variable] = !state[variable];
	}
};
/* eslint-enable */

export default {
	namespaced: true,
	state,
	mutations,
};
