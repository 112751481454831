<template>
	<div>
		<div class="position-relative">
			<table
				class="table table-fixed"
				:class="{
					clickable: clickableRows,
					'table-hover': clickableRows,
					'table-striped': striped,
					'table-draggable': isDraggable,
				}"
			>
				<thead>
					<tr>
						<th
							v-for="(head) in tableFields"
							:key="head.key"
							:class="head.class || ''"
						>
							{{ head.label }}
							<CButton
								v-if="head.sortKey"
								class="btn-sort ml-1 p-0"
								@click.prevent="handleSort(head.sortKey)"
							>
								<CIcon
									:name="head.sortIcon"
								/>
							</CButton>
						</th>
					</tr>
				</thead>
				<draggable
					v-if="!isLoading"
					:value="items"
					:class="[{ draggable: isDraggable }]"
					tag="tbody"
					ghost-class="is-placeholder-item"
					draggable=".draggable"
					@input="(sortedList) => $emit('drag', sortedList)"
				>
					<tr
						v-for="itemData in items"
						:key="itemData.key"
						:class="[{ draggable: itemData.draggable }, itemData.rowClass]"
						@click="handleClickRow(itemData.id)"
					>
						<template v-for="field in fields">
							<router-link
								v-if="linkTo"
								:key="field.key"
								:to="{
									name: linkTo,
									params: { id: itemData.id }
								}"
								:class="[field.cellClass, `align-${verticalAlign}`]"
								class="td-hyperlink"
								@click.native.stop
							>
								<slot
									v-if="$scopedSlots[field.key]"
									:name="field.key"
									:item="itemData[field.key]"
								></slot>
								<template v-else>
									{{ itemData[field.key] }}
								</template>
							</router-link>
							<td
								v-else
								:key="field.key"
								:class="field.cellClass"
							>
								<span>
									<slot
										v-if="$scopedSlots[field.key]"
										:name="field.key"
										:item="itemData[field.key]"
									></slot>
									<template v-else>
										{{ itemData[field.key] }}
									</template>
								</span>
							</td>
						</template>
					</tr>
				</draggable>
			</table>
		</div>
		<BaseLoading v-if="isLoading" />
		<slot v-else-if="!items.length" name="no-items-view"></slot>
		<CPagination
			v-if="pagination.pages > 1"
			:active-page="pagination.activePage"
			:pages="pagination.pages"
			class="sticky-container mr-4"
			align="end"
			show-dots
			@update:activePage="handlePaginationClick"
		/>
	</div>
</template>

<script>
import draggable from 'vuedraggable';

const SORT_ASC = 'asc';
const SORT_DESC = 'desc';

export default {
	name: 'BaseTable',

	components: {
		draggable,
	},
	props: {
		items: {
			type: [Object, Array],
			default: null,
		},
		fields: {
			type: Array,
			default: () => [],
		},
		pagination: {
			type: Object,
			default: () => ({}),
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		clickableRows: {
			type: Boolean,
			default: false,
		},
		striped: {
			type: Boolean,
			default: false,
		},
		verticalAlign: {
			type: String,
			default: 'middle',
			validator(value) {
				return ['top', 'middle', 'bottom'].indexOf(value) !== -1;
			},
		},
		linkTo: {
			type: String,
			default: null,
		},
		isDraggable: {
			type: Boolean,
			default: false,
		},
		sortKey: {
			type: String,
			default: null,
		},
		sortDirection: {
			type: String,
			default: null,
			validator: (val) => [SORT_ASC, SORT_DESC].includes(val),
		},
	},
	computed: {
		sortIcon() {
			return this.sortDirection === SORT_ASC
				? 'cil-sort-ascending'
				: 'cil-sort-descending';
		},

		tableFields() {
			return this.fields.map((field) => ({
				...field,
				sortIcon: (
					field.sortKey
					&& field.sortKey === this.sortKey
					&& this.sortDirection === SORT_DESC
				)
					? 'cil-sort-descending'
					: 'cil-sort-ascending',
			}));
		},
	},
	methods: {
		handlePaginationClick(page) {
			this.$emit('onPaginationClick', page);
		},

		handleClickRow(id) {
			this.$emit('onRowClick', id);
		},
		handleSort(key) {
			let sortDirection;

			// Always scroll to left side of screen to let user see loading icon in case the table content is wider than user's screen
			document.querySelector('.c-body').scrollTo({ left: 0, behavior: 'smooth' });

			if (key === this.sortKey) {
				sortDirection = this.sortDirection === SORT_ASC
					? SORT_DESC
					: SORT_ASC;
			} else {
				sortDirection = SORT_DESC;
			}

			this.$emit('onSortClick', {
				key,
				direction: sortDirection,
			});
		},
		getCellClass(key) {
			const found = this.fields.find((field) => field.key === key);
			return found ? found.cellClass : undefined;
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .table {
		position: relative;
		border-collapse: separate;
		border-spacing: 0;

		th,
		td {
			color: $color-black-90;
			font-size: rem(14);
			word-break: break-word;
		}

		thead > tr > th:first-child,
		tbody > tr > td:first-child {
			padding-left: rem(28);
		}

		th {
			z-index: $z-index-table-td;
			position: sticky;
			top: 0;
			background: $color-white;
			border-bottom: 1px solid $color-gray-300;
		}

		td,
		tr > a {
			// TODO: Remove 'tr > a' after refactor table .has-link
			border-bottom: 1px solid $color-gray-200;
			vertical-align: middle;
		}

		&-striped {
			th,
			td,
			tr > a {
				// TODO: Remove 'tr > a' after refactor table .has-link
				border-bottom: none;
			}
		}

		&-draggable {
			$draggable-border-color: $color-gray-100;

			th,
			td,
			tr > a {
				// TODO: Remove 'tr > a' after refactor table .has-link
				border-bottom: none;
			}

			tbody {
				tr {
					cursor: move;
					transition: $transition-duration background-color;

					&:not(.draggable) {
						td {
							background-color: $color-gray-300;
							cursor: initial;
						}
					}

					&:first-child {
						td {
							border-top: 2px solid $draggable-border-color;

							&:first-child {
								border-top-left-radius: 4px;
							}

							&:last-child {
								border-top-right-radius: 4px;
							}
						}
					}

					&:last-child {
						td {
							border-bottom: 2px solid $draggable-border-color;

							&:first-child {
								border-bottom-left-radius: 4px;
							}

							&:last-child {
								border-bottom-right-radius: 4px;
							}
						}
					}
				}

				td {
					background-color: $color-white;
					border: 1px solid $draggable-border-color;
					border-right: 0;
					border-left: 0;

					&:first-child {
						border-left: 2px solid $draggable-border-color;
					}

					&:last-child {
						border-right: 2px solid $draggable-border-color;
					}
				}

				.is-placeholder-item {
					opacity: 0.5;
				}
			}
		}

		.btn-sort {
			color: $color-gray-600;

			&:hover {
				color: $color-gray-400;
			}
		}
	}

	@media print {
		::v-deep .table {
			th {
				position: static;
				background-color: $color-gray-100 !important;
				padding-top: rem(6) !important;
				padding-bottom: rem(4) !important;
			}
		}
	}
</style>
