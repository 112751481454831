import { i18n } from '@/i18n';
import { pathOr } from '../../assets/js/helpers';
import {
	getFlashSalesAPI,
	getFlashSaleAPI,
	postFlashSaleAPI,
	updateFlashSaleAPI,
	deleteFlashSaleAPI,
	postFlashSaleRemarkAPI,
} from '../../services/api/flashSales.api';
import {
	GET_FLASHSALES_REQUEST,
	GET_FLASHSALES_SUCCESS,
	GET_FLASHSALES_FAILURE,
	CREATE_FLASHSALE_REQUEST,
	CREATE_FLASHSALE_SUCCESS,
	CREATE_FLASHSALE_FAILURE,
	GET_FLASHSALE_REQUEST,
	GET_FLASHSALE_SUCCESS,
	GET_FLASHSALE_FAILURE,
	UPDATE_FLASHSALE_REQUEST,
	UPDATE_FLASHSALE_SUCCESS,
	UPDATE_FLASHSALE_FAILURE,
	DELETE_FLASHSALE_REQUEST,
	DELETE_FLASHSALE_SUCCESS,
	DELETE_FLASHSALE_FAILURE,
	CREATE_FLASHSALE_REMARK_REQUEST,
	CREATE_FLASHSALE_REMARK_SUCCESS,
	CREATE_FLASHSALE_REMARK_FAILURE,
} from '../types';

import { transformedFlashSaleList, transformedFlashSale } from '../../assets/js/transform/flashsales';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		list: {
			isLoading: false,
			data: [],
			meta: [],
			error: false,
		},
	},
	getters: {
		getFlashSaleGeneralInfo(state) {
			return transformedFlashSale(state.edit.data);
		},
	},
	mutations: {
		[GET_FLASHSALES_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformedFlashSaleList();
			state.list.meta = transformedMeta();
			state.list.error = false;
		},
		[GET_FLASHSALES_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedFlashSaleList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_FLASHSALES_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		[CREATE_FLASHSALE_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_FLASHSALE_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_FLASHSALE_FAILURE](state) {
			state.create.isCreating = false;
		},
		[GET_FLASHSALE_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_FLASHSALE_SUCCESS](state, { data }) {
			state.edit.data = data;
			state.edit.isLoading = false;
		},
		[GET_FLASHSALE_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[UPDATE_FLASHSALE_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_FLASHSALE_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_FLASHSALE_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_FLASHSALE_REQUEST]() { },
		[DELETE_FLASHSALE_SUCCESS]() { },
		[DELETE_FLASHSALE_FAILURE]() { },
		[CREATE_FLASHSALE_REMARK_REQUEST]() { },
		[CREATE_FLASHSALE_REMARK_SUCCESS]() { },
		[CREATE_FLASHSALE_REMARK_FAILURE]() { },
	},

	actions: {
		async getFlashSales({ commit }, query) {
			try {
				commit(GET_FLASHSALES_REQUEST, query);
				const { data } = await getFlashSalesAPI(query);
				commit(GET_FLASHSALES_SUCCESS, data);
			} catch (error) {
				commit(GET_FLASHSALES_FAILURE);
			}
		},
		async getFlashSale({ commit }, id) {
			try {
				commit(GET_FLASHSALE_REQUEST);
				const { data } = await getFlashSaleAPI(id);
				commit(GET_FLASHSALE_SUCCESS, data);
			} catch (error) {
				commit(GET_FLASHSALE_FAILURE);
			}
		},
		async createFlashSale({ commit, dispatch }, params) {
			try {
				commit(CREATE_FLASHSALE_REQUEST, params);
				await postFlashSaleAPI(params);
				commit(CREATE_FLASHSALE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_FLASHSALE_FAILURE);
				const statusCode = pathOr(null, ['response', 'status'])(error);

				if (statusCode !== 422) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					}, { root: true });
				}
				throw error;
			}
		},
		async updateFlashSale({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_FLASHSALE_REQUEST);
				const { data } = await updateFlashSaleAPI(id, params);
				commit(UPDATE_FLASHSALE_SUCCESS, data);
				dispatch('getFlashSale', id);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_FLASHSALE_FAILURE);
				const statusCode = pathOr(null, ['response', 'status'])(error);

				if (statusCode !== 422) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					}, { root: true });
				}
				throw error;
			}
		},
		async deleteFlashSale({ commit, dispatch }, id) {
			try {
				commit(DELETE_FLASHSALE_REQUEST);
				await deleteFlashSaleAPI(id);
				commit(DELETE_FLASHSALE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Flash Sale' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_FLASHSALE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async createFlashSaleRemark({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(CREATE_FLASHSALE_REMARK_REQUEST);
				const { data } = await postFlashSaleRemarkAPI(id, params);
				commit(CREATE_FLASHSALE_REMARK_SUCCESS);
				dispatch(
					'toast/showCreateSuccessToast',
					'remark',
					{ root: true },
				);
				return data;
			} catch (error) {
				commit(CREATE_FLASHSALE_REMARK_FAILURE);
				dispatch(
					'toast/showCreateErrorToast',
					null,
					{ root: true },
				);
				throw error;
			}
		},
	},
};
