<template>
	<div>
		<label
			v-if="label"
			class="label"
			data-test-id="label"
		>
			{{ label }}
		</label>
		<div class="input-group" :class="{ 'is-invalid': !isValid }">
			<Cleave
				:id="id"
				:options="options"
				:value="value"
				:placeholder="placeholder"
				class="form-control form-control-number"
				@input="handleInput"
			/>
		</div>
		<div
			v-if="invalidFeedback"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
	</div>
</template>

<script>
import 'cleave.js/dist/addons/cleave-phone.th';
import Cleave from 'vue-cleave-component';

export default {
	name: 'BaseInputPhone',
	components: {
		Cleave,
	},
	props: {
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		value: {
			type: [String, Number],
			default: null,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			options: {
				phone: true,
				phoneRegionCode: 'TH',
				delimiter: '-',
			},
		};
	},
	methods: {
		handleInput(phone) {
			this.$emit('input', phone);
		},
	},
};
</script>

<style lang="scss" scoped>
	.input-group {
		&.is-invalid {
			.form-control {
				border: 1px solid $color-alert;

				&:focus {
					box-shadow: none;
				}
			}
		}
	}
</style>
