import { SET_BREADCRUMBS, ADD_BREADCRUMB } from '../types';

export default {
	namespaced: true,

	state: {
		items: [],
	},

	mutations: {
		[SET_BREADCRUMBS](state, items = []) {
			// clear items
			state.items = [];

			if (items !== false) {
				// add items
				items.forEach((item) => state.items.push(item));
			}
		},
		[ADD_BREADCRUMB](state, item) {
			// add an item
			state.items.push(item);
		},
	},

	actions: {
		setBreadcrumbs({ commit }, items) {
			commit(SET_BREADCRUMBS, items);
		},
		addBreadcrumb({ commit }, item) {
			commit(ADD_BREADCRUMB, item);
		},
	},
};
