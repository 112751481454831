import {
	getBannersGroupAPI,
	getBannerGroupAPI,
	createBannerGroupAPI,
	updateBannerGroupAPI,
	deleteBannerGroupAPI,
} from '../../services/api/banners.api';
import {
	GET_BANNERS_GROUP_REQUEST,
	GET_BANNERS_GROUP_SUCCESS,
	GET_BANNERS_GROUP_FAILURE,
	GET_BANNER_GROUP_REQUEST,
	GET_BANNER_GROUP_SUCCESS,
	GET_BANNER_GROUP_FAILURE,
	CREATE_BANNER_GROUP_REQUEST,
	CREATE_BANNER_GROUP_SUCCESS,
	CREATE_BANNER_GROUP_FAILURE,
	UPDATE_BANNER_GROUP_REQUEST,
	UPDATE_BANNER_GROUP_SUCCESS,
	UPDATE_BANNER_GROUP_FAILURE,
	DELETE_BANNER_GROUP_REQUEST,
	DELETE_BANNER_GROUP_SUCCESS,
	DELETE_BANNER_GROUP_FAILURE,
} from '../types';
import {
	transformedBannerGroupList,
	transformedBannerGroupDetail,
} from '../../assets/js/transform/banners';
import { pathOr } from '../../assets/js/helpers';
import { i18n } from '../../i18n';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: [],
			meta: transformedMeta(),
		},
		create: {
			isLoading: false,
			isSuccess: false,
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			isSuccess: false,
			data: {},
		},
		delete: {
			isLoading: false,
			isSuccess: false,
		},
	},

	getters: {
		getBannersGroupList(state) {
			return state.list.data.map(transformedBannerGroupList);
		},

		getBannerGroupDetail(state) {
			return transformedBannerGroupDetail(state.edit.data);
		},
	},

	mutations: {
		// Get banners
		[GET_BANNERS_GROUP_REQUEST](state) {
			state.list = {
				...state.list,
				data: [],
				isLoading: true,
			};
		},
		[GET_BANNERS_GROUP_SUCCESS](state, { data = [], meta }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
				meta: transformedMeta(meta),
			};
		},
		[GET_BANNERS_GROUP_FAILURE](state) {
			state.list = {
				...state.list,
				data: [],
				isLoading: false,
			};
		},

		// Get banner
		[GET_BANNER_GROUP_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_BANNER_GROUP_SUCCESS](state, { data = {} }) {
			state.edit = {
				...state.edit,
				isLoading: false,
				data,
			};
		},
		[GET_BANNER_GROUP_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},

		// Create banner
		[CREATE_BANNER_GROUP_REQUEST](state) {
			state.create = {
				...state.create,
				isLoading: true,
				isSuccess: false,
			};
		},
		[CREATE_BANNER_GROUP_SUCCESS](state) {
			state.create = {
				...state.create,
				isLoading: false,
				isSuccess: true,
			};
		},
		[CREATE_BANNER_GROUP_FAILURE](state) {
			state.create = {
				...state.create,
				isLoading: false,
				isSuccess: false,
			};
		},

		// Update banner
		[UPDATE_BANNER_GROUP_REQUEST](state) {
			state.edit.isUpdating = true;
			state.edit.isSuccess = false;
		},
		[UPDATE_BANNER_GROUP_SUCCESS](state) {
			state.edit.isUpdating = false;
			state.edit.isSuccess = true;
		},
		[UPDATE_BANNER_GROUP_FAILURE](state) {
			state.edit.isUpdating = false;
			state.edit.isSuccess = false;
		},

		// Delete banner
		[DELETE_BANNER_GROUP_REQUEST](state) {
			state.delete = {
				...state.delete,
				isLoading: true,
				isSuccess: false,
			};
		},
		[DELETE_BANNER_GROUP_SUCCESS](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
				isSuccess: true,
			};
		},
		[DELETE_BANNER_GROUP_FAILURE](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
				isSuccess: false,
			};
		},
	},

	actions: {
		async getBannersGroup({ commit }, params = {}) {
			try {
				commit(GET_BANNERS_GROUP_REQUEST);
				const { data } = await getBannersGroupAPI(params);

				commit(GET_BANNERS_GROUP_SUCCESS, data);
			} catch (error) {
				commit(GET_BANNERS_GROUP_FAILURE);
			}
		},

		async getBannerGroup({ commit }, { id, type }) {
			try {
				commit(GET_BANNER_GROUP_REQUEST);
				const { data } = await getBannerGroupAPI(id, type);

				commit(GET_BANNER_GROUP_SUCCESS, data);
			} catch (error) {
				commit(GET_BANNER_GROUP_FAILURE);
				window.location = '/404'; // Redirect to 404
			}
		},

		async createBannerGroup({ commit, dispatch }, bannerData) {
			try {
				commit(CREATE_BANNER_GROUP_REQUEST);

				await createBannerGroupAPI(bannerData);
				commit(CREATE_BANNER_GROUP_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageCreate', { type: 'page' }),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_BANNER_GROUP_FAILURE, error);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},

		async updateBannerGroup({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_BANNER_GROUP_REQUEST);

				const response = await updateBannerGroupAPI(id, params);
				const data = pathOr({}, ['data', 'data'])(response);

				commit(UPDATE_BANNER_GROUP_SUCCESS, { data });

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('page.banner.edit.successMessage', { type: 'page' }),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_BANNER_GROUP_FAILURE);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},

		async deleteBanner({ commit, dispatch }, id) {
			try {
				commit(DELETE_BANNER_GROUP_REQUEST);

				await deleteBannerGroupAPI(id);
				commit(DELETE_BANNER_GROUP_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageDelete', { type: 'Banner' }),
						header: i18n.t('global.successMessageTitleDelete'),
					},
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_BANNER_GROUP_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleDelete'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
