import {
	getPromotionBannerSetsAPI,
	getPromotionBannerSetAPI,
	postPromotionBannerSetAPI,
	putPromotionBannerSetAPI,
	deletePromotionBannerSetAPI,
} from '@/services/api/promotionBannerSets.api';
import {
	GET_PROMOTION_BANNER_SET_LIST_REQUEST,
	GET_PROMOTION_BANNER_SET_LIST_SUCCESS,
	GET_PROMOTION_BANNER_SET_LIST_FAILURE,
	GET_PROMOTION_BANNER_SET_REQUEST,
	GET_PROMOTION_BANNER_SET_SUCCESS,
	GET_PROMOTION_BANNER_SET_FAILURE,
	CREATE_PROMOTION_BANNER_SET_REQUEST,
	CREATE_PROMOTION_BANNER_SET_SUCCESS,
	CREATE_PROMOTION_BANNER_SET_FAILURE,
	UPDATE_PROMOTION_BANNER_SET_REQUEST,
	UPDATE_PROMOTION_BANNER_SET_SUCCESS,
	UPDATE_PROMOTION_BANNER_SET_FAILURE,
	DELETE_PROMOTION_BANNER_SET_REQUEST,
	DELETE_PROMOTION_BANNER_SET_SUCCESS,
	DELETE_PROMOTION_BANNER_SET_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import {
	transformedPromotionBannerSetList,
	transformedPromotionBannerSet,
} from '../../assets/js/transform/promotionBannerSets';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		create: {
			isSubmitting: false,
			isSuccess: false,
		},
		edit: {
			isLoading: true,
			isSubmitting: false,
			data: {},
		},
		delete: {
			isSubmitting: false,
		},
	},
	getters: {
		isCreateSuccess(state) {
			return state.create.isSuccess;
		},
		promotionBannerSetList(state) {
			return state.list.data;
		},
		promotionBannerSetInfo(state) {
			return state.edit.data;
		},
	},
	mutations: {
		[GET_PROMOTION_BANNER_SET_LIST_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...state.list.meta,
					currentPage: page,
				},
			};
		},
		[GET_PROMOTION_BANNER_SET_LIST_SUCCESS](state, { data, meta }) {
			state.list = {
				data,
				meta: transformedMeta(meta),
				isLoading: false,
			};
		},
		[GET_PROMOTION_BANNER_SET_LIST_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_PROMOTION_BANNER_SET_REQUEST](state) {
			state.create = {
				isSubmitting: true,
				isSuccess: false,
			};
		},
		[CREATE_PROMOTION_BANNER_SET_SUCCESS](state) {
			state.create = {
				isSubmitting: false,
				isSuccess: true,
			};
		},
		[CREATE_PROMOTION_BANNER_SET_FAILURE](state) {
			state.create = {
				isSubmitting: false,
				isSuccess: false,
			};
		},
		[GET_PROMOTION_BANNER_SET_REQUEST](state) {
			state.edit = {
				isLoading: true,
			};
		},
		[GET_PROMOTION_BANNER_SET_SUCCESS](state, data) {
			state.edit = {
				data,
				isLoading: false,
			};
		},
		[GET_PROMOTION_BANNER_SET_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[UPDATE_PROMOTION_BANNER_SET_REQUEST](state) {
			state.edit = {
				...state.edit,
				isSubmitting: true,
			};
		},
		[UPDATE_PROMOTION_BANNER_SET_SUCCESS](state) {
			state.edit = {
				...state.edit,
				isSubmitting: false,
			};
		},
		[UPDATE_PROMOTION_BANNER_SET_FAILURE](state) {
			state.edit = {
				...state.edit,
				isSubmitting: false,
			};
		},
		[DELETE_PROMOTION_BANNER_SET_REQUEST](state) {
			state.delete.isSubmitting = true;
		},
		[DELETE_PROMOTION_BANNER_SET_SUCCESS](state) {
			state.delete.isSubmitting = false;
		},
		[DELETE_PROMOTION_BANNER_SET_FAILURE](state) {
			state.delete.isSubmitting = false;
		},
	},
	actions: {
		async getPromotionBannerSets({ commit }, query) {
			try {
				commit(GET_PROMOTION_BANNER_SET_LIST_REQUEST, query);
				const response = await getPromotionBannerSetsAPI(query);
				const data = transformedPromotionBannerSetList(pathOr([], ['data', 'data'])(response));
				const meta = pathOr([], ['data', 'meta'])(response);
				commit(GET_PROMOTION_BANNER_SET_LIST_SUCCESS, { data, meta });
			} catch (error) {
				commit(GET_PROMOTION_BANNER_SET_LIST_FAILURE);
			}
		},
		async getPromotionBannerSet({ commit }, id) {
			try {
				commit(GET_PROMOTION_BANNER_SET_REQUEST, id);
				const response = await getPromotionBannerSetAPI(id);
				const data = transformedPromotionBannerSet(pathOr({}, ['data', 'data'])(response));
				commit(GET_PROMOTION_BANNER_SET_SUCCESS, data);
			} catch (error) {
				commit(GET_PROMOTION_BANNER_SET_FAILURE);
			}
		},
		async createPromotionBannerSet({ commit, dispatch }, data) {
			try {
				commit(CREATE_PROMOTION_BANNER_SET_REQUEST);
				await postPromotionBannerSetAPI(data);
				commit(CREATE_PROMOTION_BANNER_SET_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.create.successMessage', { type: 'promotion banner set' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_PROMOTION_BANNER_SET_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		async updatePromotionBannerSet({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_PROMOTION_BANNER_SET_REQUEST);

				const { data } = await putPromotionBannerSetAPI(id, params);

				commit(UPDATE_PROMOTION_BANNER_SET_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.edit.successMessage', { type: 'Promotion banner set' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_PROMOTION_BANNER_SET_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		async deletePromotionBannerSet({ commit, dispatch }, id) {
			try {
				commit(DELETE_PROMOTION_BANNER_SET_REQUEST);
				await deletePromotionBannerSetAPI(id);
				commit(DELETE_PROMOTION_BANNER_SET_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.general.delete.remove', { type: 'promotion banner set' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(DELETE_PROMOTION_BANNER_SET_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
