import { i18n } from '@/i18n';
import {
	getShippingRatesAPI,
	updateShippingMethodAPI,
	getShippingMethodListAPI,
	postShippingRateAPI,
	getShippingRateAPI,
	updateShippingRateAPI,
	deleteShippingRateAPI,
} from '../../services/api/shippings.api';
import { transformedShippingMethodList } from '../../assets/js/transform/shippings';
import { transfromedShippingRateList, transfromedShippingRate } from '../../assets/js/transform/shippingRates';
import { pathOr } from '../../assets/js/helpers';
import {
	UPDATE_SHIPPING_METHOD_REQUEST,
	UPDATE_SHIPPING_METHOD_SUCCESS,
	UPDATE_SHIPPING_METHOD_FAILURE,
	GET_SHIPPING_METHOD_LIST_REQUEST,
	GET_SHIPPING_METHOD_LIST_SUCCESS,
	GET_SHIPPING_METHOD_LIST_FAILURE,
	CREATE_SHIPPING_RATE_REQUEST,
	CREATE_SHIPPING_RATE_SUCCESS,
	CREATE_SHIPPING_RATE_FAILURE,
	GET_SHIPPING_RATE_REQUEST,
	GET_SHIPPING_RATE_SUCCESS,
	GET_SHIPPING_RATE_FAILURE,
	GET_SHIPPING_RATE_LIST_REQUEST,
	GET_SHIPPING_RATE_LIST_SUCCESS,
	GET_SHIPPING_RATE_LIST_FAILURE,
	UPDATE_SHIPPING_RATE_REQUEST,
	UPDATE_SHIPPING_RATE_SUCCESS,
	UPDATE_SHIPPING_RATE_FAILURE,
	DELETE_SHIPPING_RATE_REQUEST,
	DELETE_SHIPPING_RATE_SUCCESS,
	DELETE_SHIPPING_RATE_FAILURE,
	SET_SHIPPING_ACTIVE_TAB,
	SET_SHIPPING_PAYMENT_LIST,
	GET_SHIPPING_PAYMENT_REQUEST,
	GET_SHIPPING_PAYMENT_SUCCESS,
} from '../types';

export default {
	namespaced: true,
	state: {
		activeTab: 0,
		shippingMethods: {
			list: {
				data: [],
				isLoading: false,
			},
			edit: {
				isUpdating: false,
			},
		},
		shippingRates: {
			list: {
				data: [],
				isLoading: false,
			},
			create: {
				isCreating: false,
			},
			edit: {
				data: {},
				isLoading: false,
				isUpdating: false,
			},
		},
		shippingPaymentMethods: {
			isLoading: false,
			list: [],
		},
	},
	getters: {
		activeTab(state) {
			return state.activeTab;
		},
		shippingMethodList(state) {
			return state.shippingMethods.list.data;
		},
		shippingRatesByShippingMethodId: (state) => (shippingMethodId) => {
			const data = state.shippingRates.list.data
				.filter((shippingRate) => shippingRate.shippingMethodId === shippingMethodId);

			return {
				data,
				isLoading: state.shippingRates.list.isLoading,
			};
		},
		rateInfo(state) {
			return state.shippingRates.edit.data;
		},
		getCurrentShippingMethod(state) {
			return state.shippingMethods.list.data[state.activeTab];
		},
	},
	mutations: {
		[GET_SHIPPING_METHOD_LIST_REQUEST](state) {
			state.shippingMethods.list.isLoading = true;
		},
		[GET_SHIPPING_METHOD_LIST_SUCCESS](state, { shippingMethods }) {
			state.shippingMethods.list.data = shippingMethods;
			state.shippingMethods.list.isLoading = false;
		},
		[GET_SHIPPING_METHOD_LIST_FAILURE](state) {
			state.shippingMethods.list.isLoading = false;
		},
		[GET_SHIPPING_RATE_LIST_REQUEST](state) {
			state.shippingRates.list.isLoading = true;
		},
		[GET_SHIPPING_RATE_LIST_SUCCESS](state, { shippingRate }) {
			state.shippingRates.list.data = shippingRate;
			state.shippingRates.list.isLoading = false;
		},
		[GET_SHIPPING_RATE_LIST_FAILURE](state) {
			state.shippingRates.list.isLoading = false;
		},
		[UPDATE_SHIPPING_METHOD_REQUEST](state) {
			state.shippingMethods.edit.isUpdating = true;
		},
		[UPDATE_SHIPPING_METHOD_SUCCESS](state) {
			state.shippingMethods.edit.isUpdating = false;
		},
		[UPDATE_SHIPPING_METHOD_FAILURE](state) {
			state.shippingMethods.edit.isUpdating = false;
		},
		[CREATE_SHIPPING_RATE_REQUEST](state) {
			state.shippingRates.create.isCreating = true;
		},
		[CREATE_SHIPPING_RATE_SUCCESS](state) {
			state.shippingRates.create.isCreating = false;
		},
		[CREATE_SHIPPING_RATE_FAILURE](state) {
			state.shippingRates.create.isCreating = false;
		},
		[GET_SHIPPING_RATE_REQUEST](state) {
			state.shippingRates.edit.isLoading = true;
		},
		[GET_SHIPPING_RATE_SUCCESS](state, data) {
			state.shippingRates.edit.isLoading = false;
			state.shippingRates.edit.data = data;
		},
		[GET_SHIPPING_RATE_FAILURE](state) {
			state.shippingRates.edit.isLoading = false;
		},
		[UPDATE_SHIPPING_RATE_REQUEST](state) {
			state.shippingRates.edit.isUpdating = true;
		},
		[UPDATE_SHIPPING_RATE_SUCCESS](state) {
			state.shippingRates.edit.isUpdating = false;
		},
		[UPDATE_SHIPPING_RATE_FAILURE](state) {
			state.shippingRates.edit.isUpdating = false;
		},
		[DELETE_SHIPPING_RATE_REQUEST]() {},
		[DELETE_SHIPPING_RATE_SUCCESS]() {},
		[DELETE_SHIPPING_RATE_FAILURE]() {},
		[SET_SHIPPING_ACTIVE_TAB](state, tabIndex) {
			state.activeTab = tabIndex;
		},
		[GET_SHIPPING_PAYMENT_REQUEST](state) {
			state.shippingPaymentMethods.isLoading = true;
		},
		[GET_SHIPPING_PAYMENT_SUCCESS](state) {
			state.shippingPaymentMethods.isLoading = false;
		},
		[SET_SHIPPING_PAYMENT_LIST](state, list) {
			state.shippingPaymentMethods.list = list;
		},
	},
	actions: {
		async getShippingMethodList({ commit }) {
			try {
				commit(GET_SHIPPING_METHOD_LIST_REQUEST);
				const { data } = await getShippingMethodListAPI();
				const shippingMethods = transformedShippingMethodList(data.data);

				commit(GET_SHIPPING_METHOD_LIST_SUCCESS, { shippingMethods });
			} catch (e) {
				commit(GET_SHIPPING_METHOD_LIST_FAILURE);
			}
		},
		async getShippingRateList({ state, commit }) {
			try {
				commit(GET_SHIPPING_RATE_LIST_REQUEST);
				const shipmentId = state.shippingMethods.list.data[state.activeTab].id;
				const { data } = await getShippingRatesAPI(shipmentId);
				const shippingRate = transfromedShippingRateList(data.data);

				commit(GET_SHIPPING_RATE_LIST_SUCCESS, { shippingRate });
			} catch (e) {
				commit(GET_SHIPPING_RATE_LIST_FAILURE);
			}
		},
		async updateShippingMethod({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_SHIPPING_METHOD_REQUEST);

				await updateShippingMethodAPI(id, params);

				commit(UPDATE_SHIPPING_METHOD_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_SHIPPING_METHOD_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });

				throw error;
			}
		},
		async postShippingRate({ commit }, { id, params }) {
			try {
				commit(CREATE_SHIPPING_RATE_REQUEST);
				await postShippingRateAPI(id, params);
				commit(CREATE_SHIPPING_RATE_SUCCESS);
			} catch (error) {
				commit(CREATE_SHIPPING_RATE_FAILURE, error);

				throw error;
			}
		},
		async getShippingRate({ commit }, rateId) {
			try {
				commit(GET_SHIPPING_RATE_REQUEST);
				const { data } = await getShippingRateAPI(rateId);
				commit(GET_SHIPPING_RATE_SUCCESS, transfromedShippingRate(data.data));
			} catch (e) {
				commit(GET_SHIPPING_RATE_FAILURE);
			}
		},
		async updateShippingRate({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_SHIPPING_RATE_REQUEST);

				const { data } = await updateShippingRateAPI(id, params);

				commit(UPDATE_SHIPPING_RATE_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				await dispatch('getShippingRate', id);
			} catch (error) {
				commit(UPDATE_SHIPPING_RATE_FAILURE);

				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
		async deleteShippingRate({ commit, dispatch }, id) {
			try {
				commit(DELETE_SHIPPING_RATE_REQUEST);

				await deleteShippingRateAPI(id);

				commit(DELETE_SHIPPING_RATE_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Rate' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_SHIPPING_RATE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		setShippingActiveTab({ commit }, tabIndex) {
			commit(SET_SHIPPING_ACTIVE_TAB, tabIndex);
		},
		setShippingPaymentMethodList({ commit }, list) {
			commit(SET_SHIPPING_PAYMENT_LIST, list);
		},
		loadingShippingPaymentMethodEdit({ commit }) {
			commit(GET_SHIPPING_PAYMENT_REQUEST);
		},
		loadedShippingPaymentMethodEdit({ commit }) {
			commit(GET_SHIPPING_PAYMENT_SUCCESS);
		},
	},
};
