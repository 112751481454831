<template>
	<div class="typo-body-2 d-flex justify-content-between">
		<span class="color-black-70">{{ count }} {{ appendText }}</span>
		<a class="reset-filter" href="#" @click.prevent="handleReset">{{ resetFilterBtnText }}</a>
	</div>
</template>

<script>
export default {
	name: 'BaseSearchFormFooter',

	props: {
		count: {
			type: [Number, String],
			default: 0,
		},
		appendText: {
			type: String,
			default: '',
		},
		resetFilterBtnText: {
			type: String,
			default: 'Reset search and filters',
		},
	},

	methods: {
		handleReset() {
			this.$emit('onReset');
		},
	},
};
</script>
