<template>
	<div :class="['media-list-item', { 'is-active': data.isActive }]">
		<img :src="data.imageUrls.thumbnail" :alt="data.name">
		<div class="media-list-item-checkbox" data-id="media-list-item-checkbox">
			<CInputCheckbox
				:checked="data.isSelected"
				class="checkbox-color-banana"
				custom
				@input="$emit('onSelect', data)"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MediaLibraryListItem',
	props: {
		data: {
			type: Object,
			default: () => ({
				imageUrls: {},
			}),
		},
	},
};
</script>

<style lang="scss" scoped>
.media-list-item {
	position: relative;
	z-index: 1;
	width: rem(96);
	height: rem(96);
	background-color: $color-white;
	border: 1px solid $color-gray-300;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;

	// overlay
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background-color: $color-black;
		opacity: 0;
		transition: opacity $transition-duration;
	}

	&:not(.is-active):hover::before {
		opacity: 0.08;
	}

	// active
	&.is-active {
		border-color: $color-primary;

		// overlay with active
		&::before {
			background-color: $color-primary;
			opacity: 0.15;
		}
	}

	// image
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	// .media-list-item-checkbox
	&-checkbox {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 3;

		// overwrite
		::v-deep {
			.custom-control-label {
				margin-left: 0;
			}
		}
	}
}
</style>
