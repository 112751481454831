import {
	GET_FEED_REQUEST,
	GET_FEED_SUCCESS,
	GET_FEED_FAILURE,
	GET_FEED_LIST_REQUEST,
	GET_FEED_LIST_SUCCESS,
	GET_FEED_LIST_FAILURE,
	CREATE_FEED_REQUEST,
	CREATE_FEED_SUCCESS,
	CREATE_FEED_FAILURE,
	UPDATE_FEED_REQUEST,
	UPDATE_FEED_SUCCESS,
	UPDATE_FEED_FAILURE,
	DELETE_FEED_REQUEST,
	DELETE_FEED_SUCCESS,
	DELETE_FEED_FAILURE,
	CREATE_FEED_REMARK_REQUEST,
	CREATE_FEED_REMARK_SUCCESS,
	CREATE_FEED_REMARK_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import {
	getFeedListAPI,
	getFeedAPI,
	createFeedAPI,
	updateFeedAPI,
	postFeedRemarkAPI,
	deleteFeedAPI,
} from '../../services/api/feeds.api';
import {
	transformedFeedList,
	transformedFeedDetails,
} from '../../assets/js/transform/feed';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr, covertTimeFromAPI } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		list: {
			data: [],
			isLoading: false,
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
	},
	getters: {
		feedTableData(state) {
			const getTableData = (feed = {}) => {
				const id = pathOr(0, ['id'])(feed);
				const duplicateId = id;
				const partner = pathOr('-', ['partner'])(feed);
				const status = pathOr(false, ['status'])(feed);
				const regenerateTimes = pathOr([], ['regenerateTimes'])(feed);
				const baseUrl = pathOr(null, ['baseUrl'])(feed);
				const slug = pathOr(null, ['slug'])(feed);
				const fileName = pathOr(null, ['fileName'])(feed);
				const lastUpdated = pathOr('-', ['lastUpdated'])(feed);
				const regenerateTimeText = regenerateTimes
					.map((time) => covertTimeFromAPI(time))
					.join(', ');
				const fullUrl = Array.prototype.concat(baseUrl, slug, fileName).join('/');

				return {
					id,
					partner,
					status,
					regenerateTimeText,
					fullUrl,
					lastUpdated,
					duplicateId,
				};
			};

			return state.list.data.map(getTableData);
		},
	},
	mutations: {
		[GET_FEED_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_FEED_SUCCESS](state, data) {
			state.edit.isLoading = false;
			state.edit.data = data;
		},
		[GET_FEED_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[CREATE_FEED_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_FEED_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_FEED_FAILURE](state) {
			state.create.isCreating = false;
		},
		[GET_FEED_LIST_REQUEST](state) {
			state.list.isLoading = true;
		},
		[GET_FEED_LIST_SUCCESS](state, { feeds, meta }) {
			state.list.isLoading = false;
			state.list.data = feeds;
			state.list.meta = meta;
		},
		[GET_FEED_LIST_FAILURE](state) {
			state.list.isLoading = false;
		},
		[UPDATE_FEED_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_FEED_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_FEED_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_FEED_REQUEST]() { },
		[DELETE_FEED_SUCCESS]() { },
		[DELETE_FEED_FAILURE]() { },
		[CREATE_FEED_REMARK_REQUEST]() { },
		[CREATE_FEED_REMARK_SUCCESS]() { },
		[CREATE_FEED_REMARK_FAILURE]() { },
	},
	actions: {
		async getFeedList({ commit }, params) {
			try {
				commit(GET_FEED_LIST_REQUEST);
				const { data } = await getFeedListAPI(params);
				commit(GET_FEED_LIST_SUCCESS, {
					feeds: transformedFeedList(data.data),
					meta: transformedMeta(data.meta),
				});
			} catch (e) {
				commit(GET_FEED_LIST_FAILURE);
			}
		},
		async getFeed({ commit }, id) {
			try {
				commit(GET_FEED_REQUEST);
				const { data } = await getFeedAPI(id);
				commit(GET_FEED_SUCCESS, transformedFeedDetails(data.data));
			} catch (e) {
				commit(GET_FEED_FAILURE);
			}
		},
		async createFeed({ commit, dispatch }, params) {
			try {
				commit(CREATE_FEED_REQUEST, params);
				await createFeedAPI(params);
				commit(CREATE_FEED_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_FEED_FAILURE);
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				throw error;
			}
		},
		async updateFeed({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_FEED_REQUEST);
				await updateFeedAPI(id, params);
				commit(UPDATE_FEED_SUCCESS);
				dispatch('getFeed', id);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_FEED_FAILURE);
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				throw error;
			}
		},
		async deleteFeed({ commit, dispatch }, id) {
			try {
				commit(DELETE_FEED_REQUEST);

				await deleteFeedAPI(id);

				commit(DELETE_FEED_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Feed' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_FEED_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async createFeedRemark({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(CREATE_FEED_REMARK_REQUEST);
				const { data } = await postFeedRemarkAPI(id, params);
				commit(CREATE_FEED_REMARK_SUCCESS);
				dispatch(
					'toast/showCreateSuccessToast',
					'remark',
					{ root: true },
				);

				return data;
			} catch (error) {
				commit(CREATE_FEED_REMARK_FAILURE);
				dispatch(
					'toast/showCreateErrorToast',
					null,
					{ root: true },
				);

				throw error;
			}
		},
	},
};
