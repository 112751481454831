import httpClient from './httpClient';

export const NOTIFICATION_ENDPOINT = '/backoffice/mobile-notification-schedules';

export const getNotificationsAPI = (params = {}) => httpClient.get(NOTIFICATION_ENDPOINT, { params });
export const getNotificationAPI = (id) => httpClient.get(`${NOTIFICATION_ENDPOINT}/${id}`);
export const postNotificationAPI = (params) => httpClient.post(`${NOTIFICATION_ENDPOINT}`, params);
export const updateNotificationAPI = (id, params) => httpClient.put(`${NOTIFICATION_ENDPOINT}/${id}`, params);
export const deleteNotificationAPI = (id) => httpClient.delete(`${NOTIFICATION_ENDPOINT}/${id}`);
export const postNotificationRemarkAPI = (id, params) => httpClient.post(`${NOTIFICATION_ENDPOINT}/${id}/remarks`, params);
