export const PAYMENT_ZERO_CHECKOUT_SLUG = 'zero-amount';
export const PAYMENT_CREDIT_CARD_SLUG = 'credit-debit-card';
export const PAYMENT_INSTALLMENT_SLUG = 'installment';
export const PAYMENT_QR_SLUG = 'qr';
export const PAYMENT_BILL_PAYMENT_SLUG = 'bill-payment';
export const PAYMENT_COUNTER_PAYMENT_SLUG = 'counter-payment';
export const PAYMENT_COD_SLUG = 'cod';
export const PAYMENT_PAY_AT_STORE_SLUG = 'pay-at-store';
export const PAYMENT_PAY_AT_STORE_UFUND_SLUG = 'pay-at-store-ufund';
export const PAYMENT_PAY_ON_PICK_UP_SLUG = 'pay-at-event';
export const PAYMENT_SCB_EASY_SLUG = 'scb-easy';
export const PAYMENT_AGENT_OTHER_BANK = 'unspecified';

// payment status, following from backend
export const PAYMENT_STATUS = Object.freeze({
	UNPAID: 'unpaid',
	PENDING: 'pending',
	FAILED: 'failed',
	EXPIRED: 'expired',
	PAID: 'paid',
	PARTIALLY_PAID: 'partially_paid',
	COD: 'cod',
	PAY_AT_STORE: 'pay_at_store',
	PAY_ON_PICK_UP: 'pay_at_event',
});

// payment staus option, use with dropdown ui
export const PAYMENT_STATUS_OPTIONS = [
	{
		name: 'All payment status',
		value: null,
	},
	{
		name: 'Unpaid',
		value: PAYMENT_STATUS.UNPAID,
	},
	{
		name: 'Pending',
		value: PAYMENT_STATUS.PENDING,
	},
	{
		name: 'Failed',
		value: PAYMENT_STATUS.FAILED,
	},
	{
		name: 'Expired',
		value: PAYMENT_STATUS.EXPIRED,
	},
	{
		name: 'Paid',
		value: PAYMENT_STATUS.PAID,
	},
];

export const mappingSlugToPaymentMethod = {
	PAYMENT_CREDIT_CARD_SLUG: 'Credit card',
	PAYMENT_INSTALLMENT_SLUG: 'Installment',
	PAYMENT_BILL_PAYMENT_SLUG: 'Bill payment',
	PAYMENT_QR_SLUG: 'QR',
	PAYMENT_COUNTER_PAYMENT_SLUG: 'Counter payment',
	PAYMENT_COD_SLUG: 'COD',
	PAYMENT_PAY_AT_STORE_SLUG: 'Pay at store',
	PAYMENT_PAY_ON_PICK_UP_SLUG: 'Pay on pick-up',
	PAYMENT_ZERO_CHECKOUT_SLUG: 'Zero subtotal',
	PAYMENT_SCB_EASY_SLUG: 'SCB Easy',
};

export const PAYMENT_GATEWAY = {
	KBANK: 'kbank',
	BAY: 'bay',
	FIRST_CHOICE: 'first_choice',
	TWO_C_TWO_P: 'two_c_two_p',
	KTC_AI: 'ktc_ai',
	KTC_FLEXI: 'ktc_flexi',
	KTC_FOREVER: 'ktc_forever',
	KTC_CC_FOREVER: 'ktc_cc_forever',
	KTC: 'ktc',
};

export const PAYMENT_GATEWAY_OPTIONS = [
	{
		name: 'Kbank gateway',
		value: PAYMENT_GATEWAY.KBANK,
	},
	{
		name: 'Krungsri gateway',
		value: PAYMENT_GATEWAY.BAY,
	},
	{
		name: '2C2P gateway',
		value: PAYMENT_GATEWAY.TWO_C_TWO_P,
	},
	{
		name: 'KTC gateway',
		value: PAYMENT_GATEWAY.KTC,
	},
];

export const INSTEREST_RATE_KBANK_OPTIONS = [
	{
		name: '0%',
		value: 0,
	},
	{
		name: '0.8%',
		value: 0.8,
	},
];

export const INSTEREST_RATE_DEFAULT_OPTIONS = [
	{
		name: '0%',
		value: 0,
	},
];

export const PAYMENT_BANK_CHANNEL_STATUS = {
	active: 'Active',
	inactive: 'Inactive',
};

// type of allowed product for installment plan
export const INSTALLMENT_PLAN_PRODUCT_BY_TYPE = Object.freeze({
	SKU: 'product',
	BRAND: 'brand',
});

export const INSTALLMENT_PLAN_PRODUCT_BY_TYPE_OPTIONS = [
	{ value: INSTALLMENT_PLAN_PRODUCT_BY_TYPE.SKU, label: 'SKU' },
	{ value: INSTALLMENT_PLAN_PRODUCT_BY_TYPE.BRAND, label: 'Brand' },
];

export const OFFLINE_PAYMENTS = [
	PAYMENT_COD_SLUG,
	PAYMENT_PAY_AT_STORE_SLUG,
	PAYMENT_PAY_ON_PICK_UP_SLUG,
];

export const SHOW_ON_TOP_PAYMENTS_STATUS = {
	true: 1,
	false: null, // if have null because will not sent params in API
};
