export const transformedOrderAutoCompletePayloadToAPI = (data = {}) => {
	return {
		// return in array form like this ['sku_string', 'sku_string_2']
		skus: data.excludeSku,
	};
};
export const transformedOrderAutoComplete = (data = []) => {
	return {
		// exclude sku
		excludeSku: data,
	};
};
