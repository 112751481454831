import dayjs from 'dayjs';
import { pathOr, dateFormat } from '../helpers';
import { PRODUCT_LABEL_STATUSES } from '../../../enums/productLabels';

export const transformedProductLabel = (label = {}) => {
	const isActive = label.is_active || 0;
	const status = isActive === 1
		? PRODUCT_LABEL_STATUSES.active
		: PRODUCT_LABEL_STATUSES.inactive;

	return {
		id: label.id || null,
		name: label.name || null,
		titleEN: label.title_en || null,
		titleTH: label.title_th || null,
		fontColor: label.font_color || null,
		type: label.type || null,
		subType: label.sub_type || null,
		order: label.order || null,
		isActive: label.is_active || 0,
		status,
		skus: label.skus || [],
		thumbnail: pathOr(null, ['medium', 'urls', 'thumbnail'])(label),
		lobVisibility: Boolean(label.is_lob_visible),
		plpVisibility: Boolean(label.is_plp_visible),
		productFamilyVisibility: Boolean(label.is_product_family_visible),
		isSpecialVisible: Boolean(label.is_special_visible),
		expiredStartDate: label.start_at ? dayjs(label.start_at).toDate() : null,
		expiredStartTime: label.start_at ? dateFormat(label.start_at, 'HH:mm') : null,
		expiredEndDate: label.end_at ? dayjs(label.end_at).toDate() : null,
		expiredEndTime: label.end_at ? dateFormat(label.end_at, 'HH:mm') : null,
		isLabelVisible: label.is_label_visible != null ? Boolean(label.is_label_visible) : null,
	};
};

export const transformedProductLabels = (labels = []) => {
	const data = labels.map((label) => transformedProductLabel(label));

	return data;
};
