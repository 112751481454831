import httpClient from './httpClient';

export const CONFIGURATION_PURGE_CACHE_ENDPOINT = '/backoffice/configurations/cache-purge';
export const PURGES_CACHE_ENDPOINT = '/backoffice/cache-purges';
export const PURGE_TAGS_CACHE_ENDPOINT = '/backoffice/cache-purge-tags';

export const configPurgeCache = async () => httpClient.get(`${CONFIGURATION_PURGE_CACHE_ENDPOINT}`);
export const updateConfigPurgeCache = async (payload) => httpClient.put(`${CONFIGURATION_PURGE_CACHE_ENDPOINT}`, payload);
export const purgesCache = async (payload) => httpClient.post(`${PURGES_CACHE_ENDPOINT}`, payload);
export const purgeTagsCache = async () => httpClient.get(`${PURGE_TAGS_CACHE_ENDPOINT}`);
