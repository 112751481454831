import router from '../../router';
import {
	getCustomerGroupListAPI,
	createCustomerGroupListAPI,
	getCustomerGroupAPI,
	updateCustomerGroupAPI,
	getCustomerGroupUsersAPI,
	removeUserFromGroupAPI,
	addUserToGroupAPI,
	deleteCustomerGroupAPI,
} from '../../services/api/customerGroups.api';
import { transformedMeta } from '../../assets/js/transform/meta';
import { transformedCustomerGroup } from '../../assets/js/transform/customerGroups';
import {
	GET_CUSTOMER_GROUP_REQUEST,
	GET_CUSTOMER_GROUP_SUCCESS,
	GET_CUSTOMER_GROUP_FAILURE,
	GET_CUSTOMER_GROUP_LIST_REQUEST,
	GET_CUSTOMER_GROUP_LIST_SUCCESS,
	GET_CUSTOMER_GROUP_LIST_FAILURE,
	CREATE_CUSTOMER_GROUP_REQUEST,
	CREATE_CUSTOMER_GROUP_SUCCESS,
	CREATE_CUSTOMER_GROUP_FAILURE,
	UPDATE_CUSTOMER_GROUP_REQUEST,
	UPDATE_CUSTOMER_GROUP_SUCCESS,
	UPDATE_CUSTOMER_GROUP_FAILURE,
	DELETE_CUSTOMER_GROUP_REQUEST,
	DELETE_CUSTOMER_GROUP_SUCCESS,
	DELETE_CUSTOMER_GROUP_FAILURE,
	GET_CUSTOMER_GROUP_USERS_REQUEST,
	GET_CUSTOMER_GROUP_USERS_SUCCESS,
	GET_CUSTOMER_GROUP_USERS_FAILURE,
	ADD_USER_TO_GROUP_REQUEST,
	ADD_USER_TO_GROUP_SUCCESS,
	ADD_USER_TO_GROUP_FAILURE,
	REMOVE_USER_FROM_GROUP_REQUEST,
	REMOVE_USER_FROM_GROUP_SUCCESS,
	REMOVE_USER_FROM_GROUP_FAILURE,
} from '../types';
import { transformedCustomer } from '../../assets/js/transform/customers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			data: {},
		},
		create: {
			isCreating: false,
		},
		users: {
			isLoading: false,
			isSubmitting: false,
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
			data: [],
		},
	},
	getters: {
		customerGroupList(state) {
			return state.list.data;
		},
		customerGroupListMeta(state) {
			return state.list.meta;
		},
		customerGroupOptions(state) {
			return state.list.data.map((customerGroup) => ({ name: customerGroup.name, value: customerGroup.id }));
		},
	},
	mutations: {
		[GET_CUSTOMER_GROUP_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_CUSTOMER_GROUP_SUCCESS](state, data) {
			state.edit = {
				...state.edit,
				isLoading: false,
				data,
			};
		},
		[GET_CUSTOMER_GROUP_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[GET_CUSTOMER_GROUP_LIST_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_CUSTOMER_GROUP_LIST_SUCCESS](state, { data, meta }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
				meta: {
					...state.list.meta,
					...meta,
				},
			};
		},
		[GET_CUSTOMER_GROUP_LIST_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_CUSTOMER_GROUP_REQUEST](state) {
			state.create = {
				isCreating: true,
			};
		},
		[CREATE_CUSTOMER_GROUP_SUCCESS](state) {
			state.create = {
				isCreating: false,
			};
		},
		[CREATE_CUSTOMER_GROUP_FAILURE](state) {
			state.create = {
				isCreating: false,
			};
		},
		[UPDATE_CUSTOMER_GROUP_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
			};
		},
		[UPDATE_CUSTOMER_GROUP_SUCCESS](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
		[UPDATE_CUSTOMER_GROUP_FAILURE](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
		[DELETE_CUSTOMER_GROUP_REQUEST]() {},
		[DELETE_CUSTOMER_GROUP_SUCCESS]() {},
		[DELETE_CUSTOMER_GROUP_FAILURE]() {},
		[GET_CUSTOMER_GROUP_USERS_REQUEST](state) {
			state.users = {
				...state.users,
				isLoading: true,
			};
		},
		[GET_CUSTOMER_GROUP_USERS_SUCCESS](state, { data, meta }) {
			state.users = {
				...state.users,
				isLoading: false,
				data,
				meta: {
					...state.users.meta,
					...meta,
				},
			};
		},
		[GET_CUSTOMER_GROUP_USERS_FAILURE](state) {
			state.users = {
				...state.users,
				isLoading: false,
			};
		},
		[ADD_USER_TO_GROUP_REQUEST](state) {
			state.users = {
				...state.users,
				isSubmitting: true,
			};
		},
		[ADD_USER_TO_GROUP_SUCCESS](state) {
			state.users = {
				...state.users,
				isSubmitting: false,
			};
		},
		[ADD_USER_TO_GROUP_FAILURE](state) {
			state.users = {
				...state.users,
				isSubmitting: false,
			};
		},
		[REMOVE_USER_FROM_GROUP_REQUEST]() {},
		[REMOVE_USER_FROM_GROUP_SUCCESS]() {},
		[REMOVE_USER_FROM_GROUP_FAILURE]() {},
	},
	actions: {
		async getCustomerGroup({ commit }, id) {
			try {
				commit(GET_CUSTOMER_GROUP_REQUEST);
				const { data } = await getCustomerGroupAPI(id);
				const customerGroup = transformedCustomerGroup(data?.data);
				commit(GET_CUSTOMER_GROUP_SUCCESS, customerGroup);
			} catch (e) {
				commit(GET_CUSTOMER_GROUP_FAILURE);
			}
		},
		async getCustomerGroupList({ commit }, query) {
			try {
				commit(GET_CUSTOMER_GROUP_LIST_REQUEST);
				const response = await getCustomerGroupListAPI(query);
				const groups = (response?.data?.data || []).map(transformedCustomerGroup);
				const meta = transformedMeta(response?.data?.meta || {});
				commit(GET_CUSTOMER_GROUP_LIST_SUCCESS, { data: groups, meta });
			} catch (e) {
				commit(GET_CUSTOMER_GROUP_LIST_FAILURE);
			}
		},
		async getCustomerGroupUsers({ commit }, { id, query }) {
			try {
				commit(GET_CUSTOMER_GROUP_USERS_REQUEST);
				const response = await getCustomerGroupUsersAPI(id, query);
				const users = (response?.data?.data || []).map(transformedCustomer);
				const meta = transformedMeta(response?.data?.meta || {});
				commit(GET_CUSTOMER_GROUP_USERS_SUCCESS, { data: users, meta });
			} catch (e) {
				commit(GET_CUSTOMER_GROUP_USERS_FAILURE);
			}
		},
		async createCustomerGroup({ commit, dispatch }, params) {
			try {
				commit(CREATE_CUSTOMER_GROUP_REQUEST);
				await createCustomerGroupListAPI(params);
				commit(CREATE_CUSTOMER_GROUP_SUCCESS);

				dispatch('toast/showCreateSuccessToast', 'customer group', { root: true });
				router.push({ name: 'CustomerGroupList' });
			} catch (e) {
				commit(CREATE_CUSTOMER_GROUP_FAILURE);

				dispatch('toast/showMultipleErrorsToast', e, { root: true });
			}
		},
		async updateCustomerGroup({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_CUSTOMER_GROUP_REQUEST);
				await updateCustomerGroupAPI(id, params);
				commit(UPDATE_CUSTOMER_GROUP_SUCCESS);

				dispatch('toast/showUpdateSuccessToast', null, { root: true });
			} catch (e) {
				commit(UPDATE_CUSTOMER_GROUP_FAILURE);

				dispatch('toast/showMultipleErrorsToast', e, { root: true });
			}
		},
		async deleteCustomerGroup({ commit, dispatch }, id) {
			try {
				commit(DELETE_CUSTOMER_GROUP_REQUEST);
				await deleteCustomerGroupAPI(id);
				commit(DELETE_CUSTOMER_GROUP_SUCCESS);

				dispatch('toast/showDeleteSuccessToast', 'Customer group', { root: true });
				router.push({ name: 'CustomerGroupList' });
			} catch (e) {
				commit(DELETE_CUSTOMER_GROUP_FAILURE);

				dispatch('toast/showDeleteErrorToast', e, { root: true });
			}
		},
		async addUserToGroup({ commit, dispatch }, { customerGroupId, user }) {
			try {
				commit(ADD_USER_TO_GROUP_REQUEST);
				await addUserToGroupAPI(customerGroupId, user);
				commit(ADD_USER_TO_GROUP_SUCCESS);

				dispatch('toast/showAddUserSuccessToast', null, { root: true });
				await dispatch('getCustomerGroupUsers', { id: customerGroupId });
			} catch (e) {
				commit(ADD_USER_TO_GROUP_FAILURE);
				dispatch('toast/showAddUserErrorToast', e, { root: true });
			}
		},
		async removeUserFromGroup({ commit, dispatch }, { customerGroupId, userId }) {
			try {
				commit(REMOVE_USER_FROM_GROUP_REQUEST);
				await removeUserFromGroupAPI(customerGroupId, userId);
				commit(REMOVE_USER_FROM_GROUP_SUCCESS);

				dispatch('toast/showDeleteSuccessToast', 'Customer', { root: true });
				await dispatch('getCustomerGroupUsers', { id: customerGroupId });
			} catch (e) {
				commit(REMOVE_USER_FROM_GROUP_FAILURE);

				dispatch('toast/showMultipleErrorsToast', e, { root: true });
			}
		},
	},
};
