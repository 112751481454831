import dayjs from 'dayjs';
import { pathOr, datetimeFormat } from '../helpers';
import {
	BANNER_STATUSES,
} from '../../../enums/banners';

export const transformedSubHeader = (subHeader = {}) => {
	const id = pathOr(-1, ['id'])(subHeader);

	const name = pathOr('', ['name'])(subHeader);
	const priority = pathOr(0, ['priority'])(subHeader);

	// This 2 fields for display at List
	const startAt = pathOr(null, ['start_at'])(subHeader);
	const endAt = pathOr(null, ['end_at'])(subHeader);
	const startDateDisplay = startAt ? datetimeFormat(subHeader.start_at) : '-';
	const endDateDisplay = endAt ? datetimeFormat(subHeader.end_at) : '-';

	// This 4 fields for Edit
	const startDate = startAt ? dayjs(subHeader.start_at).toDate() : null;
	const startTime = startAt ? datetimeFormat(subHeader.start_at, 'HH:mm') : '00:00';
	const endDate = endAt ? dayjs(subHeader.end_at).toDate() : null;
	const endTime = endAt ? datetimeFormat(subHeader.end_at, 'HH:mm') : '23:59';

	const contentTh = pathOr(null, ['content_th'])(subHeader);
	const contentEn = pathOr(null, ['content_en'])(subHeader);
	const status = BANNER_STATUSES[!!pathOr(false, ['is_content_visible'])(subHeader)];
	const isContentVisible = pathOr(false, ['is_content_visible'])(subHeader);
	const backgroundColorInitial = pathOr('#000000', ['background_colors', 'initial'])(subHeader);
	const backgroundColorFinal = pathOr('#ffffff', ['background_colors', 'final'])(subHeader);
	const desktopBannerContentTh = pathOr(null, ['desktop_banner_content_th'])(subHeader);
	const desktopBannerContentEn = pathOr(null, ['desktop_banner_content_en'])(subHeader);
	const mobileBannerContentTh = pathOr(null, ['mobile_banner_content_th'])(subHeader);
	const mobileBannerContentEn = pathOr(null, ['mobile_banner_content_en'])(subHeader);
	const isBannerContentVisible = pathOr(false, ['is_banner_content_visible'])(subHeader);
	const type = pathOr(null, ['type'])(subHeader);
	const link = pathOr(null, ['link'])(subHeader);

	return {
		id,
		name,
		priority,
		startDateDisplay,
		endDateDisplay,
		startDate,
		startTime,
		endDate,
		endTime,
		contentTh,
		contentEn,
		status,
		isContentVisible,
		backgroundColorInitial,
		backgroundColorFinal,
		desktopBannerContentTh,
		desktopBannerContentEn,
		mobileBannerContentTh,
		mobileBannerContentEn,
		isBannerContentVisible,
		type,
		link,
	};
};

export const transformedSubHeaderToAPI = (subHeader = {}) => {
	return {
		// Top general
		name: subHeader?.name || null,
		priority: subHeader?.priority || 0,

		// Date
		start_at: subHeader?.startAt || null,
		end_at: subHeader?.endAt || null,

		// Content
		content_th: subHeader?.contentTh || null,
		content_en: subHeader?.contentEn || null,
		link: subHeader?.link || null,
		is_content_visible: subHeader?.isContentVisible || false,
		background_color_initial: subHeader?.backgroundColorInitial || null,
		background_color_final: subHeader?.backgroundColorFinal || null,
		desktop_banner_content_th: subHeader?.desktopBannerContentTh || null,
		desktop_banner_content_en: subHeader?.desktopBannerContentEn || null,
		mobile_banner_content_th: subHeader?.mobileBannerContentTh || null,
		mobile_banner_content_en: subHeader?.mobileBannerContentEn || null,
		is_banner_content_visible: subHeader?.isBannerVisible || false,
	};
};

