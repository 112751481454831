import { pathOr } from '../helpers';

export const transformedUser = (user) => {
	const firstName = pathOr(null, ['profile', 'first_name'])(user);
	const lastName = pathOr(null, ['profile', 'last_name'])(user);
	const storeName = pathOr('-', ['profile', 'branch', 'name'])(user);
	const storeId = pathOr('-', ['profile', 'branch', 'id'])(user);
	const storeItecId = pathOr('-', ['profile', 'branch', 'itec_id'])(user);

	const name = (!firstName && !lastName)
		? '-'
		: `${firstName}${lastName ? ` ${lastName}` : ''}`;

	return {
		id: pathOr(0, ['id'])(user),
		username: pathOr('-', ['username'])(user),
		name,
		storeName,
		storeId,
		storeItecId,
		group: pathOr(null, ['role', 'name'])(user),
		status: pathOr(0, ['is_active'])(user),
	};
};

export const transformedUserList = (users = []) => {
	const data = users.map((user) => transformedUser(user));

	return data;
};

export const transformedUserInfo = (user = {}) => {
	const status = pathOr(0, ['is_active'])(user);

	return {
		username: pathOr(null, ['username'])(user),
		firstName: pathOr(null, ['profile', 'first_name'])(user),
		lastName: pathOr(null, ['profile', 'last_name'])(user),
		note: pathOr(null, ['profile', 'note'])(user),
		storeName: pathOr(null, ['profile', 'branch', 'name'])(user),
		storeId: pathOr(null, ['profile', 'branch', 'id'])(user),
		storeItecId: pathOr(null, ['profile', 'branch', 'itec_id'])(user),
		phoneNumber: pathOr(null, ['profile', 'phone_number'])(user),
		email: pathOr(null, ['email'])(user),
		status: status === 1,
		group: pathOr(null, ['role', 'name'])(user),
	};
};
