var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"position-relative"},[_c('table',{staticClass:"table table-fixed",class:{
				clickable: _vm.clickableRows,
				'table-hover': _vm.clickableRows,
				'table-striped': _vm.striped,
				'table-draggable': _vm.isDraggable,
			}},[_c('thead',[_c('tr',_vm._l((_vm.tableFields),function(head){return _c('th',{key:head.key,class:head.class || ''},[_vm._v(" "+_vm._s(head.label)+" "),(head.sortKey)?_c('CButton',{staticClass:"btn-sort ml-1 p-0",on:{"click":function($event){$event.preventDefault();return _vm.handleSort(head.sortKey)}}},[_c('CIcon',{attrs:{"name":head.sortIcon}})],1):_vm._e()],1)}),0)]),(!_vm.isLoading)?_c('draggable',{class:[{ draggable: _vm.isDraggable }],attrs:{"value":_vm.items,"tag":"tbody","ghost-class":"is-placeholder-item","draggable":".draggable"},on:{"input":function (sortedList) { return _vm.$emit('drag', sortedList); }}},_vm._l((_vm.items),function(itemData){return _c('tr',{key:itemData.key,class:[{ draggable: itemData.draggable }, itemData.rowClass],on:{"click":function($event){return _vm.handleClickRow(itemData.id)}}},[_vm._l((_vm.fields),function(field){return [(_vm.linkTo)?_c('router-link',{key:field.key,staticClass:"td-hyperlink",class:[field.cellClass, ("align-" + _vm.verticalAlign)],attrs:{"to":{
								name: _vm.linkTo,
								params: { id: itemData.id }
							}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[(_vm.$scopedSlots[field.key])?_vm._t(field.key,null,{"item":itemData[field.key]}):[_vm._v(" "+_vm._s(itemData[field.key])+" ")]],2):_c('td',{key:field.key,class:field.cellClass},[_c('span',[(_vm.$scopedSlots[field.key])?_vm._t(field.key,null,{"item":itemData[field.key]}):[_vm._v(" "+_vm._s(itemData[field.key])+" ")]],2)])]})],2)}),0):_vm._e()],1)]),(_vm.isLoading)?_c('BaseLoading'):(!_vm.items.length)?_vm._t("no-items-view"):_vm._e(),(_vm.pagination.pages > 1)?_c('CPagination',{staticClass:"sticky-container mr-4",attrs:{"active-page":_vm.pagination.activePage,"pages":_vm.pagination.pages,"align":"end","show-dots":""},on:{"update:activePage":_vm.handlePaginationClick}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }