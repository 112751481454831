import {
	pathOr,
} from '../helpers';

export const transformedProductGroupOption = (option = {}) => {
	const id = pathOr(null, ['id'])(option) ?? null;
	const value = pathOr(null, ['value'])(option) ?? null;
	const text = pathOr(null, ['text'])(option) ?? null;
	const image = pathOr(null, ['image'])(option) ?? null;
	return {
		id,
		value,
		text,
		image,
	};
};

export const transformedProductGroup = (group = {}) => {
	const id = pathOr(null, ['id'])(group) ?? null;
	const name = pathOr(null, ['name'])(group) ?? null;
	const type = pathOr(null, ['type'])(group) ?? null;
	const options = group.options && group.options.length > 0
		? group.options.map((option) => transformedProductGroupOption(option))
		: [];
	return {
		id,
		name,
		type,
		options,
	};
};

export const transformedProductGroups = (groups = []) => {
	return groups.map((group) => transformedProductGroup(group));
};
