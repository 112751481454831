
export const MARKETING_CAMPAIGN_PERIOD_VALUE = {
	SCHEDULE: 'scheduled',
	ONGOING: 'ongoing',
	EXPIRED: 'expired',
};
export const MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE = {
	SKU: 'sku',
	BRAND: 'brand',
	PROMOTION: 'promotion',
};

export const MARKETING_CAMPAIGN_PRODUCT_TYPE_NAME = {
	[MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU]: 'SKU',
	[MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND]: 'Brand',
	[MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION]: 'Promotion',
};

export const MARKETING_CAMPAIGN_STATUS_OPTIONS = [
	{ name: 'All status', value: null },
	{ name: 'Active', value: 1 },
	{ name: 'Inactive', value: 0 },
];

export const MARKETING_CAMPAIGN_PERIOD_OPTIONS = [
	{ name: 'All periods', value: null },
	{ name: 'Schedule', value: MARKETING_CAMPAIGN_PERIOD_VALUE.SCHEDULE },
	{ name: 'Ongoing', value: MARKETING_CAMPAIGN_PERIOD_VALUE.ONGOING },
	{ name: 'Expired', value: MARKETING_CAMPAIGN_PERIOD_VALUE.EXPIRED },
];

export const MARKETING_CAMPAIGN_PRODUCT_TYPE_OPTIONS = [
	{ name: 'All types', value: null },
	{ name: 'SKU', value: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU },
	{ name: 'Brand', value: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND },
	{ name: 'Promotion', value: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION },
];

export const MARKETING_CAMPAIGN_PERIOD_COLOR = {
	[MARKETING_CAMPAIGN_PERIOD_VALUE.SCHEDULE]: 'info',
	[MARKETING_CAMPAIGN_PERIOD_VALUE.ONGOING]: 'orange',
	[MARKETING_CAMPAIGN_PERIOD_VALUE.EXPIRED]: 'secondary',
};

export const MARKETING_CAMPAIGN_TABLE_FIELDS = [
	{ key: 'id', label: 'ID' },
	{ key: 'name', label: 'Marketing campaign name' },
	{ key: 'status', label: 'Status' },
	{ key: 'type', label: 'Add product by' },
	{ key: 'totalSKU', label: 'Total SKUs' },
	{ key: 'startDate', label: 'Start date' },
	{ key: 'endDate', label: 'End date' },
	{ key: 'period', label: 'Period' },
	{ key: 'isVisible', label: 'Store front visibility' },
];

export const PRODUCT_TYPE_OPTIONS = [
	{ value: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU, label: 'SKU' },
	{ value: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND, label: 'Brands' },
	{ value: MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION, label: 'Promotion' },
];

export const MARKETING_CAMPAIGN_STATUS = {
	INACTIVE: 'inactive',
	ACTIVE: 'active',
};

export const MARKETING_CAMPAIGN_SYNC_STATUS = {
	EMPTY: 'empty',
	SYNC_ERROR: 'failed',
	SYNCING: 'in_progress',
	COMPLETED: 'completed',
};

export const MARKETING_CAMPAIGN_SYNC_OPTIONS = [
	{ value: null, name: 'All status' },
	{ value: MARKETING_CAMPAIGN_SYNC_STATUS.EMPTY, name: '-' },
	{ value: MARKETING_CAMPAIGN_SYNC_STATUS.SYNC_ERROR, name: 'Sync error' },
	{ value: MARKETING_CAMPAIGN_SYNC_STATUS.SYNCING, name: 'Syncing' },
	{ value: MARKETING_CAMPAIGN_SYNC_STATUS.COMPLETED, name: 'Completed' },
];

export const ATTRIBUTE_STATUS = {
	ON: 'on',
	OFF: 'off',
};

export const ATTRIBUTE_STATUS_OPTIONS = [
	{ value: null, name: 'All status' },
	{ value: ATTRIBUTE_STATUS.ON, name: 'On' },
	{ value: ATTRIBUTE_STATUS.OFF, name: 'Off' },
];

export const MAPPING_STATUS_TO_SWITCH_VALUE = {
	[ATTRIBUTE_STATUS.ON]: true,
	[ATTRIBUTE_STATUS.OFF]: false,
};

export const MAPPING_SWITCH_VALUE_TO_STATUS = {
	[true]: ATTRIBUTE_STATUS.ON,
	[false]: ATTRIBUTE_STATUS.OFF,
};
