export default {
	error: {
		uploadValidationHeader: 'Cannot add image to the media library',
		uploadValidationMaxFileSizeContent: 'Allow only 20 MB for each image.',
		uploadValidationTotalFileContent: 'Allow only 20 images',
		uploadValidationImageFileName: 'Image name is invalid (Allow only A-Z, a-z, 0-9, _, -)',
		uploadFailedHeader: 'Upload failed',
		uploadFailedContent: 'An unexpected error occurred while uploading. Please try to re-upload images again.',
		deleteFailedHeader: 'Delete failed',
		deleteFailedContent: 'An unexpected error occurred while uploading. Please try to re-upload images again.',
	},
};
