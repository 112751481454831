import { priceFromAPI } from '../helpers';

/**
 * Transformed rate object
 * @param {Object} Rate input
 * @return {Object} new rate object format
 */
export const transfromedShippingRate = (rate = {}) => {
	return {
		id: rate.id || null,
		fee: priceFromAPI(rate.fee) || null,
		isDefault: !!(rate.is_default || 0),
		minPrice: priceFromAPI(rate.min_price) || null,
		maxPrice: priceFromAPI(rate.max_price) || null,
		minQuantity: rate.min_quantity || 0,
		maxQuantity: rate.max_quantity || 0,
		minWeight: rate.min_weight || 0,
		maxWeight: rate.max_weight || 0,
		shippingMethodId: rate.shipping_method_id || null,
	};
};

/**
 * Transformed rate array
 * @param {Array} Rate list
 * @return {Array} new rate array format
 */
export const transfromedShippingRateList = (rates = []) => {
	const data = rates.map((label) => transfromedShippingRate(label));

	return data;
};
