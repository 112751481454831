import { normalize } from 'normalizr';
import { transformProvinceList } from '../../assets/js/transform/addresses';
import { getProvincesAPI } from '../../services/api/addresses.api';
import { provinceEntity } from '../normalizrSchemas/addresses';
import {
	FETCH_THAILAND_ADDRESS_REQUEST,
	FETCH_THAILAND_ADDRESS_SUCCESS,
	FETCH_THAILAND_ADDRESS_FAILURE,
} from '../types';

export default {
	namespaced: true,
	state: {
		isLoading: false,
		provinces: {
			byId: {},
			allIds: [],
		},
		districts: {
			byId: {},
			allIds: [],
		},
		subdistricts: {
			byId: {},
			allIds: [],
		},
	},
	getters: {
		// #region Get data from entities
		getSubdistrictById: (state) => (id) => {
			return state.subdistricts.byId[id] || null;
		},
		getDistrictById: (state) => (id) => {
			return state.districts.byId[id] || null;
		},
		getProvinceById: (state) => (id) => {
			return state.provinces.byId[id] || null;
		},
		// #endregion
		/**
		 * @param {String} postcode
		 * @returns {Object} First matched subdistrict by given postcode
		 */
		getFirstSubdistrictByPostcode: (state, getters) => (postcode) => {
			const { byId } = state.subdistricts;
			const id = Object.keys(byId).find((subdistrictId) => (
				byId[subdistrictId].postcode === postcode
			));
			return getters.getSubdistrictById(id);
		},
		getSubdistrictsByPartialPostcode: (state) => (postcode) => {
			const { byId } = state.subdistricts;
			return Object.values(byId).filter((subdistrict) => subdistrict.postcode.startsWith(postcode));
		},
		/**
		 * @param {Number} districtId
		 * @returns {Array}
		 */
		getSubdistrictsByDistrictId: (state, getters) => (districtId) => {
			const district = getters.getDistrictById(districtId);
			if (!district || !Array.isArray(district.subdistricts)) {
				return [];
			}
			return district.subdistricts.map((id) => getters.getSubdistrictById(id));
		},
		/**
		 * @param {Number} provinceId
		 * @returns {Array}
		 */
		getDistrictsByProviceId: (state, getters) => (provinceId) => {
			const province = getters.getProvinceById(provinceId);
			if (!province || !Array.isArray(province.districts)) {
				return [];
			}
			return province.districts.map((id) => getters.getDistrictById(id));
		},
		getProvinceOptions: (state, getters) => {
			return [
				{ name: 'All provinces', value: null },
				...state.provinces.allIds.map((id) => {
					const province = getters.getProvinceById(id);
					return {
						name: province.name,
						value: province.id,
					};
				}),
			];
		},
		/**
		 * Get list of provinces in Thailand
		 * @returns {Array}
		 */
		getProvinces: (state, getters) => {
			return state.provinces.allIds.map((id) => getters.getProvinceById(id));
		},
		/**
		 * Get provinces, district, subdistrict from matching postcode
		 * @param {String} postcode
		 * @returns {Object} { province, district, subdistrict }
		 */
		getAddressInfoByPostcode: (state, getters) => (postcode) => {
			const subdistrict = getters.getFirstSubdistrictByPostcode(postcode);
			if (!subdistrict) {
				return null;
			}
			const district = getters.getDistrictById(subdistrict.district);
			if (!district) {
				return null;
			}
			const province = getters.getProvinceById(district.province);
			if (!province) {
				return null;
			}

			return {
				province,
				district,
				subdistrict,
			};
		},

		getAllAddressInfo(state, getters) {
			const addressInfoMap = new Map();
			const districtMap = new Map();
			const provinceMap = new Map();
			Object.values(state.subdistricts.byId)
				.forEach((subdistrict) => {
					const postcode = subdistrict.postcode;
					const districtId = subdistrict.district;
					const district = getters.getDistrictById(districtId);
					const province = getters.getProvinceById(district.province);
					const districtSet = districtMap.has(postcode)
						? districtMap.get(postcode)
						: new Set();
					const provinceSet = provinceMap.has(postcode)
						? provinceMap.get(postcode)
						: new Set();

					districtSet.add(district.name);
					provinceSet.add(province.name);
					districtMap.set(postcode, districtSet);
					provinceMap.set(postcode, provinceSet);

					const info = {
						postcode,
						provinces: [...provinceSet].join(', '),
						districts: [...districtSet].join(', '),
					};

					addressInfoMap.set(postcode, info);
				});

			return [...addressInfoMap.values()].sort((a, b) => a.postcode - b.postcode);
		},
	},
	mutations: {
		[FETCH_THAILAND_ADDRESS_REQUEST](state) {
			state.isLoading = true;
		},
		[FETCH_THAILAND_ADDRESS_SUCCESS](state, normalizedData) {
			state.provinces.byId = normalizedData.entities.provinces;
			state.provinces.allIds = Object.keys(normalizedData.entities.provinces);
			state.districts.byId = normalizedData.entities.districts;
			state.districts.allIds = Object.keys(normalizedData.entities.districts);
			state.subdistricts.byId = normalizedData.entities.subdistricts;
			state.subdistricts.allIds = Object.keys(normalizedData.entities.subdistricts);
			state.isLoading = false;
		},
		[FETCH_THAILAND_ADDRESS_FAILURE](state) {
			state.isLoading = false;
		},
	},
	actions: {
		async fetchProvinces({ state, commit }) {
			// If Provinces data existed, do nothing
			if (state.provinces.allIds.length) {
				return;
			}

			try {
				commit(FETCH_THAILAND_ADDRESS_REQUEST);
				const { data } = await getProvincesAPI();
				const normalizeData = normalize(transformProvinceList(data.data), [provinceEntity]);
				commit(FETCH_THAILAND_ADDRESS_SUCCESS, normalizeData);
			} catch (error) {
				commit(FETCH_THAILAND_ADDRESS_FAILURE);
			}
		},
	},
};
