export const PRODUCT_FAMILY_STATUSES = [
	{ title: 'Inactive', class: 'inactive' },
	{ title: 'Active', class: 'success' },
];

export const PRODUCT_FAMILY_STATUS_VALUES = {
	ACTIVE: 1,
	INACTIVE: 0,
};

export const PRODUCT_FAMILY_STATUS_OPTIONS = [
	{ name: 'All Status', value: null },
	{ name: 'Active', value: PRODUCT_FAMILY_STATUS_VALUES.ACTIVE },
	{ name: 'Inactive', value: PRODUCT_FAMILY_STATUS_VALUES.INACTIVE },
];

export const PRODUCT_FAMILY_ITEM_TYPES = {
	PRODUCT: 'product',
	CATEGORY: 'category',
};

export const PRODUCT_FAMILY_STATUS_FORM = [
	{ label: 'Active', value: PRODUCT_FAMILY_STATUS_VALUES.ACTIVE },
	{ label: 'Inactive', value: PRODUCT_FAMILY_STATUS_VALUES.INACTIVE },
];

export const PRODUCT_FAMILY_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id' },
	{ key: 'name', label: 'Family name', class: 'w-120' },
	{ key: 'startDateDisplay', label: 'Start date', class: 'col-date' },
	{ key: 'priority', label: 'Priority', class: 'col-priority' },
	{ key: 'status', label: 'Status' },
];

export const PRODUCT_FAMILY_TABS = [
	{ title: 'Default', label: 'ID', class: 'col-id' },
	{ title: 'Custom', label: 'Family name', class: 'w-120' },
];

export const PRODUCT_FAMILY_TYPES = {
	DEFAULT: 'default',
	CUSTOM: 'custom',
};
