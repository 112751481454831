export const transformedBrand = (brand = {}) => {
	return {
		id: brand.id || null,
		slug: brand.slug || null,
		name: brand.name || null,
	};
};

export const transformedBrandDropdownOption = (brand = {}) => {
	return {
		value: brand.id || null,
		name: brand.name || null,
	};
};

export const transformedBrandList = (brands = []) => {
	return brands.map(transformedBrand);
};
