import { i18n } from '@/i18n';
import { createOrderAddressAPI } from '../../services/api/orders.api';
import { updateOrderAddressAPI } from '../../services/api/orderAddresses.api';
import {
	UPDATE_ORDER_ADDRESS_REQUEST,
	UPDATE_ORDER_ADDRESS_SUCCESS,
	UPDATE_ORDER_ADDRESS_FAILURE,
	CREATE_ORDER_ADDRESS_REQUEST,
	CREATE_ORDER_ADDRESS_SUCCESS,
	CREATE_ORDER_ADDRESS_FAILURE,
} from '../types';

export default {
	namespaced: true,

	state: {
		create: {
			isCreating: false,
		},
		edit: {
			isUpdating: false,
		},
	},
	mutations: {
		[CREATE_ORDER_ADDRESS_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_ORDER_ADDRESS_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_ORDER_ADDRESS_FAILURE](state) {
			state.create.isCreating = false;
		},
		[UPDATE_ORDER_ADDRESS_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_ORDER_ADDRESS_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_ORDER_ADDRESS_FAILURE](state) {
			state.edit.isUpdating = false;
		},
	},
	actions: {
		async createOrderAddress({ commit, dispatch }, { orderId, params = {} }) {
			try {
				commit(CREATE_ORDER_ADDRESS_REQUEST);
				await createOrderAddressAPI(orderId, params);
				commit(CREATE_ORDER_ADDRESS_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				// Fetch order info
				dispatch('orders/getOrder', orderId, { root: true });
			} catch (e) {
				commit(CREATE_ORDER_ADDRESS_FAILURE);
			}
		},
		async updateOrderAddress({ commit, dispatch }, { id, orderId, params = {} }) {
			try {
				commit(UPDATE_ORDER_ADDRESS_REQUEST);

				const { data } = await updateOrderAddressAPI(id, params);

				commit(UPDATE_ORDER_ADDRESS_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				// Fetch order info
				dispatch('orders/getOrder', orderId, { root: true });
			} catch (error) {
				commit(UPDATE_ORDER_ADDRESS_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });

				throw error;
			}
		},
	},
};
