export default {
	create: {
		successMessage: 'New attribute key has been created.',
		error: {
			required: 'Field is required',
			maxLengthName: 'Name must not exceed {length} letters.',
			duplicateName: 'Duplicate attribute key name: {name}',
		},
	},
	delete: {
		successMessage: 'Remove attribute key success',
	},
};
