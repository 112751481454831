import dayjs from 'dayjs';
import { PRODUCT_FAMILY_ITEM_TYPES } from '../../../enums/productFamilies';
import { transformedProductLabels } from './productLabels';
import {
	pathOr,
	datetimeFormat,
} from '../helpers';

export const transformedProductItem = (item = {}) => {
	const badgeLabels = transformedProductLabels(pathOr([], ['labels'])(item));
	if (item.type === PRODUCT_FAMILY_ITEM_TYPES.PRODUCT) {
		return {
			type: item.type,
			itemType: item.item_type,
			name: item.data.name,
			id: item.data.id,
			sku: item.data.sku,
			thumbnail: item.data.files?.[0]?.urls?.thumbnail,
			customImage: item.media.thumbnail?.[0],
			titleEn: item.title_en,
			titleTh: item.title_th,
			badgeLabels,
			isLowestPriceVisible: item.is_lowest_price_visible ?? true,
		};
	} else if (item.type === PRODUCT_FAMILY_ITEM_TYPES.CATEGORY) {
		return {
			type: item.type,
			itemType: item.item_type,
			id: item.data.id,
			name: item.data.name,
			pathName: [],
			customImage: item.media.thumbnail?.[0],
			titleEn: item.title_en,
			titleTh: item.title_th,
			badgeLabels,
			isLowestPriceVisible: item.is_lowest_price_visible ?? true,
		};
	}
	return item;
};

export const transformedProductFamilies = (product = {}) => {
	const id = pathOr(null, ['id'])(product);
	const name = pathOr(null, ['name'])(product);
	const status = pathOr(null, ['is_visible'])(product);
	const priority = pathOr(null, ['priority'])(product);
	const startAt = pathOr(null, ['start_at'])(product);
	const startDate = startAt ? dayjs(startAt).toDate() : null;
	const startTime = startAt ? datetimeFormat(startAt, 'HH:mm') : '00:00';
	const startDateDisplay = startAt ? datetimeFormat(startAt) : '-';
	const items = pathOr([], ['items'])(product).sort((a, b) => a.order - b.order)
		.map((item) => transformedProductItem(item));

	return {
		id,
		name,
		status,
		priority,
		items,
		startDate,
		startTime,
		startDateDisplay,
	};
};

export const transformedProductFamiliesList = (productFamilies = []) => {
	return productFamilies.map(transformedProductFamilies);
};
