<template>
	<div class="color-picker-input-container">
		<div class="color-picker-input-wrapper">
			<div ref="colorpicker" class="custom-color-picker">
				<CInput
					v-model="localValue"
					class="custom-color-picker-input"
					type="text"
					data-test-id="input-color-picker"
					:is-valid="!$v.localValue.$invalid && null"
					:invalid-feedback="invalidFeedback"
					@focus="showPicker"
					@input="handleInput"
					@change="handleChange"
				/>
				<div class="custom-preview-color-box" :style="dynamicStyle" @focus="showPicker()"></div>

				<!-- color picker popup -->
				<ColorPicker
					v-if="displayPicker"
					:color="color"
					class="custom-color-picker-menu"
					:on-start-change="color => onChange(color)"
					:on-change="color => onChange(color)"
					:on-end-change="color => onChange(color)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
// ref:: https://github.com/arthay/vue-color-gradient-picker
import { ColorPicker } from 'vue-color-gradient-picker';
import 'vue-color-gradient-picker/dist/index.css';

import { hexColorFormat } from '../assets/js/validators';
import { hexColor } from '../regex/general';

export default {
	components: {
		ColorPicker,
	},

	props: {
		value: {
			type: String,
			default: null,
		},
	},

	validations() {
		return {
			localValue: {
				required,
				format: hexColorFormat,
			},
		};
	},

	data() {
		return {
			localValue: '#FF0000',
			displayPicker: false,
			color: {
				red: 255,
				green: 0,
				blue: 0,
				alpha: 1,
				style: 'rgba(255, 0, 0, 1)',
			},
		};
	},

	computed: {
		isHexColor() {
			return hexColor.test(this.localValue);
		},

		invalidFeedback() {
			if (!this.$v.localValue.format) {
				return this.$t('global.error.invalid');
			}

			if (!this.$v.localValue.required) {
				return this.$t('global.error.required');
			}

			return null;
		},

		getRgbToHex() {
			let r16 = this.color.red.toString(16);
			let g16 = this.color.green.toString(16);
			let b16 = this.color.blue.toString(16);

			if (this.color.red < 16) r16 = `0${r16}`;
			if (this.color.green < 16) g16 = `0${g16}`;
			if (this.color.blue < 16) b16 = `0${b16}`;

			return `#${r16 + g16 + b16}`;
		},

		dynamicStyle() {
			return {
				'background-color': this.color.style,
			};
		},
	},

	mounted() {
		if (this.value) {
			this.localValue = this.value;

			this.handleChange();
		}
	},

	methods: {
		onChange(attrs) {
			this.color = { ...attrs };

			// update value
			this.localValue = this.getRgbToHex;
			this.$emit('input', this.getRgbToHex);
		},

		showPicker() {
			document.addEventListener('click', this.documentClick);
			this.displayPicker = true;
		},

		hidePicker() {
			document.removeEventListener('click', this.documentClick);
			this.displayPicker = false;
		},

		documentClick(event) {
			// get element from refs color picker
			const element = this.$refs.colorpicker;

			// get target whatever mouse clicked on
			const target = event.target;

			// check if target that mouse clicked not element or color picker
			if (element !== target && !element.contains(target)) {
				this.hidePicker();
			}
		},

		handleInput(value) {
			this.$emit('input', value);
		},

		handleChange() {
			const regexp = /([0-9A-F])([0-9A-F])([0-9A-F])/i;
			let tempLocalValue = this.localValue;

			if (this.isHexColor) {
				if (tempLocalValue[0] === '#') tempLocalValue = tempLocalValue.slice(1, this.localValue.length);

				if (tempLocalValue.length === 3) tempLocalValue = tempLocalValue.replace(regexp, '$1$1$2$2$3$3');

				this.color.red = parseInt(tempLocalValue.substr(0, 2), 16);
				this.color.green = parseInt(tempLocalValue.substr(2, 2), 16);
				this.color.blue = parseInt(tempLocalValue.substr(4, 2), 16);
				this.color.alpha = (parseInt(tempLocalValue.substr(6, 2), 16) / 255) || 1;

				this.color.style = `rgba(${this.color.red}, ${this.color.green}, ${this.color.blue}, ${this.color.alpha})`;

				this.$emit('input', this.getRgbToHex);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.color-picker-input-container {
	.color-picker-input-wrapper {
		.custom-color-picker {
			display: flex;
			gap: rem(8);

			position: relative;

			.custom-color-picker-input {
				width: 100%;
			}

			.custom-preview-color-box {
				width: rem(35);
				height: rem(35);
				padding: rem(2);

				border: 2px solid $color-gray-300;
				border-radius: rem(4);
			}

			&-menu {
				position: absolute;
				top: rem(45);
				left: 0;
				z-index: 2;
			}
		}
	}
}
</style>
