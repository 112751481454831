import {
	getPromotionBannerKeysAPI,
	getPromotionBannerKeyAPI,
	createPromotionBannerKeyAPI,
	updatePromotionBannerKeyAPI,
	deletePromotionBannerKeyAPI,
} from '@/services/api/promotionBannerKeys.api';
import {
	GET_PROMOTION_BANNER_KEYS_REQUEST,
	GET_PROMOTION_BANNER_KEYS_SUCCESS,
	GET_PROMOTION_BANNER_KEYS_FAILURE,
	GET_PROMOTION_BANNER_KEY_REQUEST,
	GET_PROMOTION_BANNER_KEY_SUCCESS,
	GET_PROMOTION_BANNER_KEY_FAILURE,
	CREATE_PROMOTION_BANNER_KEY_REQUEST,
	CREATE_PROMOTION_BANNER_KEY_SUCCESS,
	CREATE_PROMOTION_BANNER_KEY_FAILURE,
	UPDATE_PROMOTION_BANNER_KEY_REQUEST,
	UPDATE_PROMOTION_BANNER_KEY_SUCCESS,
	UPDATE_PROMOTION_BANNER_KEY_FAILURE,
	DELETE_PROMOTION_BANNER_KEY_REQUEST,
	DELETE_PROMOTION_BANNER_KEY_SUCCESS,
	DELETE_PROMOTION_BANNER_KEY_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import { transformedPromotionBannerKey, transformedPromotionBannerKeyList } from '../../assets/js/transform/promotionBannerKeys';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
				from: null,
				perPage: null,
				to: null,
				total: '0',
				path: '',
			},
		},
		create: {
			isSubmitting: false,
			isErrorDuplicateName: false,
			isSuccess: false,
		},
		edit: {
			data: {},
			isLoading: true,
			isSubmitting: false,
			isSuccess: false,
		},
	},
	getters: {
		promotionBannerKeyList(state) {
			return state.list.data;
		},
		isCreateSuccess(state) {
			return state.create.isSuccess;
		},
	},
	mutations: {
		// GET ALL
		[GET_PROMOTION_BANNER_KEYS_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...transformedMeta(state.list.meta),
					currentPage: page,
				},
			};
		},
		[GET_PROMOTION_BANNER_KEYS_SUCCESS](state, { data, meta }) {
			state.list = {
				data,
				meta: transformedMeta(meta),
				isLoading: false,
			};
		},
		[GET_PROMOTION_BANNER_KEYS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},

		// GET SHOW
		[GET_PROMOTION_BANNER_KEY_REQUEST](state) {
			state.edit = {
				isLoading: true,
			};
		},
		[GET_PROMOTION_BANNER_KEY_SUCCESS](state, data) {
			state.edit = {
				data,
				isLoading: false,
			};
		},
		[GET_PROMOTION_BANNER_KEY_FAILURE](state) {
			state.edit = {
				isLoading: false,
			};
		},

		// CREATE
		[CREATE_PROMOTION_BANNER_KEY_REQUEST](state) {
			state.create = {
				...state.create,
				isSubmitting: true,
				isSuccess: false,
			};
		},
		[CREATE_PROMOTION_BANNER_KEY_SUCCESS](state) {
			state.create = {
				...state.create,
				isSubmitting: false,
				isSuccess: true,
			};
		},
		[CREATE_PROMOTION_BANNER_KEY_FAILURE](state, isErrorDuplicateName = false) {
			state.create = {
				...state.create,
				isSubmitting: false,
				isErrorDuplicateName,
				isSuccess: false,
			};
		},

		// UPDATE
		[UPDATE_PROMOTION_BANNER_KEY_REQUEST](state) {
			state.edit = {
				...state.edit,
				isSubmitting: true,
				isSuccess: false,
			};
		},
		[UPDATE_PROMOTION_BANNER_KEY_SUCCESS](state) {
			state.edit = {
				...state.edit,
				isSubmitting: false,
				isSuccess: true,
			};
		},
		[UPDATE_PROMOTION_BANNER_KEY_FAILURE](state) {
			state.edit = {
				...state.edit,
				isSubmitting: false,
				isSuccess: false,
			};
		},

		// DELETE
		[DELETE_PROMOTION_BANNER_KEY_REQUEST]() {},
		[DELETE_PROMOTION_BANNER_KEY_SUCCESS]() {},
		[DELETE_PROMOTION_BANNER_KEY_FAILURE]() {},
	},
	actions: {
		async getPromotionBannerKeys({ commit }, query) {
			try {
				commit(GET_PROMOTION_BANNER_KEYS_REQUEST, query);
				const response = await getPromotionBannerKeysAPI(query);
				const data = transformedPromotionBannerKeyList(pathOr([], ['data', 'data'])(response));
				const meta = pathOr({}, ['data', 'meta'])(response);
				commit(GET_PROMOTION_BANNER_KEYS_SUCCESS, { data, meta });
			} catch (error) {
				commit(GET_PROMOTION_BANNER_KEYS_FAILURE);
			}
		},
		async getPromotionBannerKey({ commit }, query) {
			try {
				commit(GET_PROMOTION_BANNER_KEY_REQUEST);
				const response = await getPromotionBannerKeyAPI(query);
				const data = transformedPromotionBannerKey(pathOr([], ['data', 'data'])(response));
				commit(GET_PROMOTION_BANNER_KEY_SUCCESS, data);
			} catch (error) {
				commit(GET_PROMOTION_BANNER_KEY_FAILURE);
			}
		},
		async createPromotionBannerKey({ commit, dispatch }, data) {
			try {
				commit(CREATE_PROMOTION_BANNER_KEY_REQUEST);
				await createPromotionBannerKeyAPI(data);
				commit(CREATE_PROMOTION_BANNER_KEY_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.create.successMessage', { type: 'promotion banner key' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_PROMOTION_BANNER_KEY_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleCreate'),
					type: 'danger',
				}, { root: true });
			}
		},
		async updatePromotionBannerKey({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_PROMOTION_BANNER_KEY_REQUEST);
				await updatePromotionBannerKeyAPI(id, params);
				commit(UPDATE_PROMOTION_BANNER_KEY_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.edit.successMessage', { type: 'Promotion banner key' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_PROMOTION_BANNER_KEY_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleCreate'),
					type: 'danger',
				}, { root: true });
			}
		},
		async deletePromotionBannerKey({ commit, dispatch }, keyId) {
			try {
				commit(DELETE_PROMOTION_BANNER_KEY_REQUEST);
				await deletePromotionBannerKeyAPI(keyId);
				commit(DELETE_PROMOTION_BANNER_KEY_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.general.delete.remove', { type: 'promotion banner key' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(DELETE_PROMOTION_BANNER_KEY_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
