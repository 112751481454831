import Vue from 'vue';
import Vuex from 'vuex';

import abandonedCarts from './modules/abandonedCarts';
import additionalFees from './modules/additionalFees';
import addresses from './modules/addresses';
import auditLogs from './modules/auditLogs';
import attributeKeys from './modules/attributeKeys';
import attributeSets from './modules/attributeSets';
import auth from './modules/auth';
import banners from './modules/banners';
import brands from './modules/brands';
import breadcrumb from './modules/breadcrumb';
import categories from './modules/categories';
import categorySelector from './modules/categorySelector';
import contentBlocks from './modules/contentBlocks';
import customers from './modules/customers';
import customerGroups from './modules/customerGroups';
import marketingCampaigns from './modules/marketingCampaigns';
import mediaLibrary from './modules/mediaLibrary';
import orderAddresses from './modules/orderAddresses';
import orders from './modules/orders';
import pages from './modules/pages';
import pageTitle from './modules/pageTitle';
import payments from './modules/payments';
import preorders from './modules/preorders';
import profile from './modules/profile';
import productLabels from './modules/productLabels';
import products from './modules/products';
import promotions from './modules/promotions';
import promotionBannerKeys from './modules/promotionBannerKeys';
import promotionBannerSets from './modules/promotionBannerSets';
import roles from './modules/roles';
import searchSynonyms from './modules/searchSynonyms';
import searchWeights from './modules/searchWeights';
import shipments from './modules/shipments';
import shippings from './modules/shippings';
import sidebar from './modules/sidebar';
import toast from './modules/toast';
import users from './modules/users';
import warehouses from './modules/warehouses';
import widgets from './modules/widgets';
import feeds from './modules/feeds';
import flashSales from './modules/flashSales';
import bundleSet from './modules/bundleSet';
import syncBranch from './modules/syncBranch';
import backInStock from './modules/backInStock';
import limitProduct from './modules/limitProduct';
import mobileNotification from './modules/mobileNotification';
import subHeaders from './modules/subHeaders';
import tagFilters from './modules/tagFilters';
import searchResultCaches from './modules/searchResultCaches';
import productFamilies from './modules/productFamilies';
import accordionKeys from './modules/accordionKeys';
import accordionSets from './modules/accordionSets';
import footers from './modules/footers';
import variantInfo from './modules/variantInfo';
import productGroups from './modules/productGroups';
import productCompares from './modules/productCompares';
import orderAutoComplete from './modules/orderAutoComplete';
import dateTimeCaches from './modules/dateTimeCaches';

Vue.use(Vuex);
export default new Vuex.Store({
	modules: {
		abandonedCarts,
		additionalFees,
		addresses,
		auditLogs,
		attributeKeys,
		attributeSets,
		auth,
		banners,
		brands,
		breadcrumb,
		categories,
		categorySelector,
		contentBlocks,
		customers,
		customerGroups,
		marketingCampaigns,
		mediaLibrary,
		orderAddresses,
		orders,
		pages,
		pageTitle,
		payments,
		preorders,
		productLabels,
		products,
		profile,
		promotions,
		promotionBannerKeys,
		promotionBannerSets,
		roles,
		searchSynonyms,
		searchWeights,
		shipments,
		shippings,
		sidebar,
		toast,
		users,
		warehouses,
		widgets,
		feeds,
		flashSales,
		bundleSet,
		syncBranch,
		backInStock,
		limitProduct,
		mobileNotification,
		subHeaders,
		tagFilters,
		searchResultCaches,
		productFamilies,
		accordionKeys,
		accordionSets,
		footers,
		variantInfo,
		productGroups,
		productCompares,
		orderAutoComplete,
		dateTimeCaches,
	},
});
