export const PREORDER_TABLE_FIELDS = Object.freeze([
	{ key: 'id', label: 'ID', class: 'col-id th-mw-50' },
	{ key: 'name', label: 'Campaign name', class: 'col-name th-mw-250' },
	{ key: 'status', label: 'Status', class: 'col-status th-mw-80' },
	{ key: 'skus', label: 'Total SKUs', class: 'col-skus th-mw-80' },
	{ key: 'payment', label: 'Payment option', class: 'col-payment th-mw-170' },
	{ key: 'startAt', label: 'Pre-order start date', class: 'col-date th-mw-100' },
	{ key: 'endAt', label: 'Pre-order end date', class: 'col-date th-mw-150' },
	{ key: 'periodStatus', label: 'Pre-order period', class: 'col-period th-mw-80' },
	{ key: 'paymentStartAt', label: 'Remaining payment start date', class: 'col-date th-mw-100' },
	{ key: 'paymentEndAt', label: 'Remaining payment end date', class: 'col-date th-mw-100' },
	{ key: 'remainingStatus', label: 'Remaining payment period', class: 'col-period th-mw-80' },
	{ key: 'releaseDate', label: 'Product release date', class: 'col-releaseDate th-mw-100' },
	{ key: 'releaseStatus', label: 'Product release status', class: 'col-period th-mw-80' },
	{ key: 'duplicateId', label: '', class: 'col-duplicate-id th-mw-100' },
]);

export const PREORDER_PAYMENT = Object.freeze({
	RESERVE_ONLY: 'reserve_only',
	FULL_PRICE_ONLY: 'full_price_only',
	FULL_PRICE_AND_RESERVE: 'full_price_and_reserve',
});

export const PREORDER_PAYMENT_METHOD_KEY = Object.freeze({
	FULL_PRICE: 'full_price',
	RESERVE_PRICE: 'reserve',
	REMAINING_PRICE: 'remaining',
});

export const PREORDER_PAYMENT_OPTIONS = [
	{
		name: 'All options',
		value: null,
	},
	{
		name: 'Reserve price only',
		value: PREORDER_PAYMENT.RESERVE_ONLY,
	},
	{
		name: 'Full price only',
		value: PREORDER_PAYMENT.FULL_PRICE_ONLY,
	},
	{
		name: 'Reserve and full price',
		value: PREORDER_PAYMENT.FULL_PRICE_AND_RESERVE,
	},
];

export const PREORDER_STATUSES = {
	ACTIVE: 1,
	INACTIVE: 0,
};

export const PREORDER_STATUS_OPTIONS = [
	{
		name: 'All statuses',
		value: null,
	},
	{
		name: 'Active',
		value: PREORDER_STATUSES.ACTIVE,
	},
	{
		name: 'Inactive',
		value: PREORDER_STATUSES.INACTIVE,
	},
];

export const PREORDER_RESTRICT_ALL_PRODUCTS_STATUSES = {
	ALL: 1,
	SPECIFIC_SKU: 0,
};

export const PREORDER_RESTRICT_ALL_PRODUCTS_OPTIONS = [
	{
		label: 'All normal SKUs',
		value: PREORDER_RESTRICT_ALL_PRODUCTS_STATUSES.ALL,
	},
	{
		label: 'Specific SKUs',
		value: PREORDER_RESTRICT_ALL_PRODUCTS_STATUSES.SPECIFIC_SKU,
	},
];

export const PREORDER_REFS = {
	GENERAL_INFO: ['pre-order-general-info'],
	PRE_ORDER_SKU: ['pre-oder-sku'],
	PRODUCT_PRICE_DISPLAY: ['pre-oder-product-price-display'],
	SCHEDULE: ['pre-oder-schedule'],
	PRE_ORDER_LIMIT: ['pre-order-limit'],
	DELIVERY_SHIPPING: ['pre-order-delivery-shipping'],
	PICKUP_AT_STORE: ['pre-order-pickup-at-store'],
	GRAB_AND_GO: ['pre-order-grab-and-go'],
	PRE_ORDER_LABEL: ['pre-order-label'],
	CAMPAIGN_INFORMATION: ['campaign-information'],
	TERM_AND_CONDITION: ['term-and-condition'],
	CUSTOM_BUTTON: ['pre-order-custom-button'],
};

export const PREORDER_REFS_TITLE = {
	[PREORDER_REFS.GENERAL_INFO]: 'General info',
	[PREORDER_REFS.PRE_ORDER_SKU]: 'Pre-order SKU',
	[PREORDER_REFS.PRODUCT_PRICE_DISPLAY]: 'Product price displaying',
	[PREORDER_REFS.SCHEDULE]: 'Schedule',
	[PREORDER_REFS.PRE_ORDER_LIMIT]: 'Pre-order limit',
	[PREORDER_REFS.DELIVERY_SHIPPING]: 'Delivery shipping',
	[PREORDER_REFS.PICKUP_AT_STORE]: 'Pickup at stores',
	[PREORDER_REFS.GRAB_AND_GO]: 'Grab and go',
	[PREORDER_REFS.PRE_ORDER_LABEL]: 'Pre-order label',
	[PREORDER_REFS.CAMPAIGN_INFORMATION]: 'Campaign information',
	[PREORDER_REFS.TERM_AND_CONDITION]: 'Term and condition',
	[PREORDER_REFS.CUSTOM_BUTTON]: 'Custom button',
};

export const PREORDER_PERIODS_STATUSES = {
	ON_GOING: 'ON_GOING',
	SCHEDULE: 'SCHEDULE',
	SCHEDULED: 'SCHEDULED',
	EXPIRED: 'EXPIRED',
	RELEASED: 'RELEASED',
	NO_SCHEDULED: 'NO_SCHEDULED',
};

export const PREORDER_PERIODS_OPTIONS = [
	{ name: 'All statuses', value: null },
	{ name: 'Ongoing', value: PREORDER_PERIODS_STATUSES.ON_GOING },
	{ name: 'Scheduled', value: PREORDER_PERIODS_STATUSES.SCHEDULED },
	{ name: 'Ended', value: PREORDER_PERIODS_STATUSES.EXPIRED },
];

export const PREORDER_RELEASED_OPTIONS = [
	{ name: 'All statuses', value: null },
	{ name: 'Scheduled', value: PREORDER_PERIODS_STATUSES.SCHEDULED },
	{ name: 'Released', value: PREORDER_PERIODS_STATUSES.RELEASED },
	{ name: 'No scheduled', value: PREORDER_PERIODS_STATUSES.NO_SCHEDULED },
];

// mapping pre order title, use with CBadge component
export const PREORDER_TITLE = {
	STATUS: {
		[PREORDER_STATUSES.ACTIVE]: 'Active',
		[PREORDER_STATUSES.INACTIVE]: 'Inactive',
	},
	PAYMENT: {
		[PREORDER_PAYMENT.RESERVE_ONLY]: 'Reserve price',
		[PREORDER_PAYMENT.FULL_PRICE_ONLY]: 'Full price',
		[PREORDER_PAYMENT.FULL_PRICE_AND_RESERVE]: 'Full price, Reserve price',
	},
	PERIODS_STATUSES: {
		[PREORDER_PERIODS_STATUSES.ON_GOING]: 'Ongoing',
		[PREORDER_PERIODS_STATUSES.SCHEDULE]: 'Scheduled',
		[PREORDER_PERIODS_STATUSES.SCHEDULED]: 'Scheduled',
		[PREORDER_PERIODS_STATUSES.EXPIRED]: 'Ended',
		[PREORDER_PERIODS_STATUSES.RELEASED]: 'Released',
	},
};

// mapping pre order color, use with CBadge property components
export const PREORDER_COLOR = {
	STATUS: {
		[PREORDER_STATUSES.ACTIVE]: 'success',
		[PREORDER_STATUSES.INACTIVE]: 'alto',
	},
	PERIODS_STATUSES: {
		[PREORDER_PERIODS_STATUSES.ON_GOING]: 'orange',
		[PREORDER_PERIODS_STATUSES.SCHEDULE]: 'info',
		[PREORDER_PERIODS_STATUSES.SCHEDULED]: 'info',
		[PREORDER_PERIODS_STATUSES.EXPIRED]: 'alto',
		[PREORDER_PERIODS_STATUSES.RELEASED]: 'success',
	},
};
