import { pathOr, getProductStatusValue, getThumbnailFromProduct } from '../helpers';
import { PRODUCT_STATUS_OPTIONS } from '../../../enums/products';

export const transformedProductRelated = (productRelated = {}) => {
	const id = pathOr(0, ['id'])(productRelated);
	const thumbnail = getThumbnailFromProduct(productRelated);
	const sku = pathOr('', ['sku'])(productRelated);
	const isPrimary = !!pathOr(false, ['is_primary'])(productRelated);
	const name = pathOr('', ['name'])(productRelated);
	const isEOL = pathOr(0, ['is_eol'])(productRelated);
	const isActive = pathOr(0, ['is_active'])(productRelated);
	const statusValue = getProductStatusValue(isEOL, isActive);
	const status = PRODUCT_STATUS_OPTIONS.find((option) => option.value === statusValue);

	return {
		id,
		thumbnail,
		sku,
		name,
		status,
		isPrimary,
	};
};

export const transformedProductRelatedList = (productRelatedList = []) => {
	const data = productRelatedList.map((productRelated) => transformedProductRelated(productRelated));
	return data;
};
