import { datetimeFormat } from '../../../assets/js/helpers';

export const transformLimitProduct = (item = {}) => {
	return {
		id: item.id,
		name: item.name,
		isActive: item.is_active,
		quantity: item.quantity,
		startDate: item.start_date ? datetimeFormat(item.start_date, 'DD MMM YYYY') : '-',
		endDate: item.end_date ? datetimeFormat(item.end_date, 'DD MMM YYYY') : '-',
		durationTime: item.duration_time,
		period: item.period,
		// using in detail page / edit page
		skus: item.skus || [],
		customerGroups: item.customer_groups || [],
	};
};
export const transformLimitProductList = (items = []) => {
	return items.map((item) => transformLimitProduct(item));
};
