import { getShipmentsAPI, getShipmentAPI, createShipmentAPI, cancelShipmentAPI, bookCourierAPI, updateShipmentAPI } from '../../services/api/shipments.api';
import { transformedShipmentList, transformedShipmentDetail } from '../../assets/js/transform/shipments';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';
import { i18n } from '../../i18n';
import {
	GET_SHIPMENTS_REQUEST,
	GET_SHIPMENTS_SUCCESS,
	GET_SHIPMENTS_FAILURE,
	GET_SHIPMENT_REQUEST,
	GET_SHIPMENT_SUCCESS,
	GET_SHIPMENT_FAILURE,
	UPDATE_SHIPMENT_REQUEST,
	UPDATE_SHIPMENT_SUCCESS,
	UPDATE_SHIPMENT_FAILURE,
	CREATE_SHIPMENT_REQUEST,
	CREATE_SHIPMENT_SUCCESS,
	CREATE_SHIPMENT_FAILURE,
	CANCEL_SHIPMENT_REQUEST,
	CANCEL_SHIPMENT_SUCCESS,
	CANCEL_SHIPMENT_FAILURE,
	BOOK_COURIER_REQUEST,
	BOOK_COURIER_SUCCESS,
	BOOK_COURIER_FAILURE,
} from '../types';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: transformedShipmentList(),
			meta: transformedMeta(),
			error: false,
		},
		detail: {
			isLoading: false,
			isBooking: false,
			isCanceling: false,
			data: {},
		},
		edit: {
			isUpdating: false,
		},
		shipment: {
			isCreating: false,
		},
	},
	getters: {
		shipmentDetail(state) {
			return transformedShipmentDetail(state.detail.data);
		},
	},
	mutations: {
		// Get Shipment List
		[GET_SHIPMENTS_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformedShipmentList();
			state.list.meta = transformedMeta();
			state.list.error = false;
		},
		[GET_SHIPMENTS_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedShipmentList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_SHIPMENTS_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		[GET_SHIPMENT_REQUEST](state) {
			state.detail = {
				data: {},
				isLoading: true,
			};
		},
		[GET_SHIPMENT_SUCCESS](state, { data }) {
			state.detail = {
				data,
				isLoading: false,
			};
		},
		[GET_SHIPMENT_FAILURE](state) {
			state.detail = {
				data: {},
				isLoading: false,
			};
		},
		[UPDATE_SHIPMENT_REQUEST](state) {
			state.edit = {
				isUpdating: true,
			};
		},
		[UPDATE_SHIPMENT_SUCCESS](state) {
			state.edit = {
				isUpdating: false,
			};
		},
		[UPDATE_SHIPMENT_FAILURE](state) {
			state.edit = {
				isUpdating: false,
			};
		},
		[CREATE_SHIPMENT_REQUEST](state) {
			state.shipment = {
				...state.shipment,
				isCreating: true,
			};
		},
		[CREATE_SHIPMENT_SUCCESS](state) {
			state.shipment = {
				...state.shipment,
				isCreating: false,
			};
		},
		[CREATE_SHIPMENT_FAILURE](state) {
			state.shipment = {
				...state.shipment,
				isCreating: false,
			};
		},
		[CANCEL_SHIPMENT_REQUEST](state) {
			state.detail = {
				...state.detail,
				isCanceling: true,
			};
		},
		[CANCEL_SHIPMENT_SUCCESS](state) {
			state.detail = {
				...state.detail,
				isCanceling: false,
			};
		},
		[CANCEL_SHIPMENT_FAILURE](state) {
			state.detail = {
				...state.detail,
				isCanceling: false,
			};
		},
		[BOOK_COURIER_REQUEST](state) {
			state.detail = {
				...state.detail,
				isBooking: true,
			};
		},
		[BOOK_COURIER_SUCCESS](state) {
			state.detail = {
				...state.detail,
				isBooking: false,
			};
		},
		[BOOK_COURIER_FAILURE](state) {
			state.detail = {
				...state.detail,
				isBooking: false,
			};
		},
	},
	actions: {
		async getShipments({ commit }, params = {}) {
			try {
				commit(GET_SHIPMENTS_REQUEST);
				const { data } = await getShipmentsAPI({
					q: params.q,
					status: params.status,
					shipping_method: params.shipping_method,
					courier: params.courier,
					created_from: params.created_from,
					created_to: params.created_to,
					page: params.page,
					branch_id: params.branch_id,
				});
				commit(GET_SHIPMENTS_SUCCESS, data);
			} catch (error) {
				commit(GET_SHIPMENTS_FAILURE);
			}
		},
		async getShipment({ commit }, id) {
			try {
				commit(GET_SHIPMENT_REQUEST);

				const { data } = await getShipmentAPI(id);
				commit(GET_SHIPMENT_SUCCESS, data);
			} catch (error) {
				commit(GET_SHIPMENT_FAILURE);
			}
		},
		async updateShipment({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_SHIPMENT_REQUEST);

				await updateShipmentAPI(id, params);

				commit(UPDATE_SHIPMENT_SUCCESS);

				await dispatch('getShipment', id);
			} catch (error) {
				commit(UPDATE_SHIPMENT_FAILURE);
			}
		},
		async createShipment({ commit, dispatch }, params) {
			try {
				commit(CREATE_SHIPMENT_REQUEST);
				await createShipmentAPI(params);
				commit(CREATE_SHIPMENT_SUCCESS);
			} catch (error) {
				commit(CREATE_SHIPMENT_FAILURE);
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleCreate'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
		async cancelShipment({ commit, dispatch }, id) {
			try {
				commit(CANCEL_SHIPMENT_REQUEST);
				await cancelShipmentAPI(id);
				commit(CANCEL_SHIPMENT_SUCCESS);

				dispatch('toast/showToast', {
					header: 'Success',
					content: 'Shipment has been canceled',
					type: 'success',
				}, { root: true });

				await dispatch('getShipment', id);
			} catch (e) {
				commit(CANCEL_SHIPMENT_FAILURE);
				dispatch('toast/showToast', {
					header: 'Unable to cancel',
					content: 'An unexpected error occurred. Please try again.',
					type: 'danger',
				}, { root: true });
			}
		},
		async bookCourier({ commit, dispatch }, { id, params = {}, courierName }) {
			try {
				commit(BOOK_COURIER_REQUEST);
				await bookCourierAPI(id, params);
				commit(BOOK_COURIER_SUCCESS);

				dispatch('toast/showToast', {
					header: 'Courier has been booked',
					content: `The shipment booking with ${courierName} has been succeeded.`,
					type: 'success',
				}, { root: true });

				await dispatch('getShipment', id);
			} catch (error) {
				const header = error.response?.data?.error ?? 'Unable to book courier';
				const content = error.response?.data?.message ?? `The shipment booking with ${courierName} has been failed.`;

				commit(BOOK_COURIER_FAILURE);

				dispatch('toast/showToast', {
					header,
					content,
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
