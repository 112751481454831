<template>
	<div :class="['loading-container', { fullpage: isFullPage }]">
		<div class="spinner-wrapper d-flex align-items-center justify-content-center">
			<div class="spinner">
				<div class="dot1"></div>
				<div class="dot2"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseLoading',

	props: {
		isFullPage: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
<style lang="scss" scoped>
.loading-container {
	display: flex;

	&.fullpage {
		position: absolute; // relative with .c-body
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		align-items: center;
	}
}

.spinner-wrapper {
	width: rem(80);
	height: rem(80);
	margin: rem(20) auto;
}

.spinner {
	width: 75%;
	height: 75%;
	position: relative;
	text-align: center;

	-webkit-animation: sk-rotate 2s infinite linear;
	animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	top: 0;
	background-color: $color-primary;
	border-radius: 100%;

	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
	top: auto;
	bottom: 0;
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes sk-rotate {
	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes sk-bounce {
	0%, 100% {
		-webkit-transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%, 100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}
</style>
