import { i18n } from '@/i18n';
import {
	getPreOrdersAPI,
	getPreOrderAPI,
	postPreOrderAPI,
	updatePreOrderAPI,
	deletePreOrderAPI,
	postPreOrderPickupRuleAPI,
	getPreOrderPickupRuleAPI,
	updatePreOrderPickupRuleAPI,
	deletePreOrderPickupAtStoreRuleAPI,
} from '../../services/api/preorders.api';
import {
	GET_PREORDERS_REQUEST,
	GET_PREORDERS_SUCCESS,
	GET_PREORDERS_FAILURE,
	GET_PREORDER_REQUEST,
	GET_PREORDER_SUCCESS,
	GET_PREORDER_FAILURE,
	CREATE_PREORDER_REQUEST,
	CREATE_PREORDER_SUCCESS,
	CREATE_PREORDER_FAILURE,
	DELETE_PREORDER_REQUEST,
	DELETE_PREORDER_SUCCESS,
	DELETE_PREORDER_FAILURE,
	UPDATE_PREORDER_REQUEST,
	UPDATE_PREORDER_SUCCESS,
	UPDATE_PREORDER_FAILURE,
	GET_PREORDER_PICKUP_AT_STORE_RULE_REQUEST,
	GET_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS,
	GET_PREORDER_PICKUP_AT_STORE_RULE_FAILURE,
	CREATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST,
	CREATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS,
	CREATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE,
	UPDATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST,
	UPDATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS,
	UPDATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE,
	DELETE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST,
	DELETE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS,
	DELETE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE,
} from '../types';

import { transformedPreOrderList, transformedPreOrderInfo } from '../../assets/js/transform/preorders';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		list: {
			isLoading: false,
			data: [],
			meta: [],
			error: false,
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			data: {},
		},
		pickupAtStore: {
			create: {
				isCreating: false,
			},
			delete: {
				isDeleting: false,
			},
			edit: {
				data: {},
				isLoading: false,
				isUpdating: false,
			},
		},
	},
	getters: {
		preOrderInfo(state) {
			return transformedPreOrderInfo(state.edit.data);
		},
	},
	mutations: {
		[GET_PREORDERS_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformedPreOrderList();
			state.list.meta = transformedMeta();
			state.list.error = false;
		},
		[GET_PREORDERS_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedPreOrderList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_PREORDERS_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		[GET_PREORDER_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_PREORDER_SUCCESS](state, { data = {} }) {
			state.edit = {
				data,
				isLoading: false,
			};
		},
		[GET_PREORDER_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[CREATE_PREORDER_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_PREORDER_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_PREORDER_FAILURE](state) {
			state.create.isCreating = false;
		},
		[UPDATE_PREORDER_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_PREORDER_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_PREORDER_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[GET_PREORDERS_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedPreOrderList(data);
			state.list.meta = transformedMeta(meta);
		},

		// Pre-order pickup at store
		[GET_PREORDER_PICKUP_AT_STORE_RULE_REQUEST](state) {
			state.pickupAtStore.edit.isLoading = true;
		},
		[GET_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS](state, { data = {} }) {
			state.pickupAtStore.edit = {
				data,
				isLoading: false,
			};
		},
		[GET_PREORDER_PICKUP_AT_STORE_RULE_FAILURE](state) {
			state.pickupAtStore.edit.isLoading = false;
		},
		[CREATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST](state) {
			state.pickupAtStore.create.isCreating = true;
		},
		[CREATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS](state) {
			state.pickupAtStore.create.isCreating = false;
		},
		[CREATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE](state) {
			state.pickupAtStore.create.isCreating = false;
		},
		[UPDATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST](state) {
			state.pickupAtStore.edit.isUpdating = true;
		},
		[UPDATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS](state) {
			state.pickupAtStore.edit.isUpdating = false;
		},
		[UPDATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE](state) {
			state.pickupAtStore.edit.isUpdating = false;
		},
		[DELETE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST](state) {
			state.pickupAtStore.delete.isDeleting = true;
		},
		[DELETE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS](state) {
			state.pickupAtStore.delete.isDeleting = false;
		},
		[DELETE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE](state) {
			state.pickupAtStore.delete.isDeleting = false;
		},
	},
	actions: {
		async getPreOrders({ commit }, query) {
			try {
				commit(GET_PREORDERS_REQUEST, query);
				const { data } = await getPreOrdersAPI(query);
				commit(GET_PREORDERS_SUCCESS, data);
			} catch (error) {
				commit(GET_PREORDERS_FAILURE);
			}
		},
		async getPreOrder({ commit }, id) {
			try {
				commit(GET_PREORDER_REQUEST);
				const { data } = await getPreOrderAPI(id);
				commit(GET_PREORDER_SUCCESS, data);
			} catch (error) {
				commit(GET_PREORDER_FAILURE);
			}
		},
		async createPreOrder({ commit, dispatch }, params) {
			try {
				commit(CREATE_PREORDER_REQUEST);
				await postPreOrderAPI(params);
				commit(CREATE_PREORDER_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_PREORDER_FAILURE);
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				throw error;
			}
		},
		async updatePreOrder({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_PREORDER_REQUEST);

				await updatePreOrderAPI(id, params);

				commit(UPDATE_PREORDER_SUCCESS);
				dispatch('getPreOrder', id);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_PREORDER_FAILURE);
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				throw error;
			}
		},
		async deletePreOrder({ commit, dispatch }, id) {
			try {
				commit(DELETE_PREORDER_REQUEST);

				await deletePreOrderAPI(id);

				commit(DELETE_PREORDER_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Pre-Order campaign' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_PREORDER_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},

		// Pre-order pickup at store
		async getPreOrderPickupRule({ commit }, { preOrderId, preOrderPickupId }) {
			try {
				commit(GET_PREORDER_PICKUP_AT_STORE_RULE_REQUEST);
				const { data } = await getPreOrderPickupRuleAPI(preOrderId, preOrderPickupId);
				commit(GET_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS, data);
			} catch (error) {
				commit(GET_PREORDER_PICKUP_AT_STORE_RULE_FAILURE);
			}
		},
		async createPreOrderPickupRule({ commit, dispatch }, { id, params }) {
			try {
				commit(CREATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST);
				await postPreOrderPickupRuleAPI(id, params);
				commit(CREATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE);
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				throw error;
			}
		},
		async updatePreOrderPickupRule({ commit, dispatch }, { preOrderId, preOrderPickupId, params = {} }) {
			try {
				commit(UPDATE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST);

				await updatePreOrderPickupRuleAPI(preOrderId, preOrderPickupId, params);

				commit(UPDATE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE);
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				throw error;
			}
		},
		async deletePreOrderPickupAtStoreRule({ commit, dispatch }, { preOrderId, preOrderPickupId }) {
			try {
				commit(DELETE_PREORDER_PICKUP_AT_STORE_RULE_REQUEST);

				await deletePreOrderPickupAtStoreRuleAPI(preOrderId, preOrderPickupId);

				commit(DELETE_PREORDER_PICKUP_AT_STORE_RULE_SUCCESS);

				dispatch('toast/showDeleteSuccessToast', 'Rule', { root: true });
			} catch (error) {
				commit(DELETE_PREORDER_PICKUP_AT_STORE_RULE_FAILURE);
				dispatch('toast/showDeleteErrorToast', { root: true });
				throw error;
			}
		},
	},
};
