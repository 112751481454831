<template>
	<div>
		<label
			v-if="label"
			data-test-id="label"
		>
			{{ label }}
		</label>
		<div
			v-if="upperHint"
			data-test-id="upper-hint"
			class="typo-helper-text color-black-45 mb-0"
		>
			{{ upperHint }}
		</div>
		<div class="input-group" :class="{ 'is-invalid': !isValid }">
			<div
				v-if="prependText"
				class="input-group-prepend"
			>
				<div
					class="input-group-text"
					data-test-id="prepend-text"
				>
					{{ prependText }}
				</div>
			</div>
			<Cleave
				:id="id"
				:options="options"
				:value="value"
				:class="`text-${textAlign}`"
				:placeholder="placeholder"
				:disabled="disabled"
				class="form-control form-control-number"
				@input="handleInput"
				@blur="handleBlur"
			/>
			<div
				v-if="appendText"
				class="input-group-append"
			>
				<div
					class="input-group-text"
					data-test-id="append-text"
				>
					{{ appendText }}
				</div>
			</div>
		</div>
		<div
			v-if="invalidFeedback"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
		<div
			v-if="lowerHint"
			class="typo-helper-text color-black-45 mb-0"
			data-test-id="lower-hint"
		>
			{{ lowerHint }}
		</div>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import Cleave from 'vue-cleave-component';
import { numberDecimalFormat } from '../assets/js/helpers';

export default {
	components: {
		Cleave,
	},
	props: {
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		upperHint: {
			type: String,
			default: null,
		},
		lowerHint: {
			type: String,
			default: null,
		},
		prependText: {
			type: String,
			default: null,
		},
		appendText: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		decimalScale: {
			type: Number,
			default: 0,
		},
		allowNullValue: {
			type: Boolean,
			default: false,
		},
		allowNegativeValue: {
			type: Boolean,
			default: false,
		},
		textAlign: {
			type: String,
			default: 'right',
			validator: (val) => ['left', 'center', 'right'].includes(val),
		},
		value: {
			type: [String, Number],
			default: null,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			options: {
				numeral: true,
				numeralPositiveOnly: !this.allowNegativeValue,
				rawValueTrimPrefix: true,
				numeralDecimalScale: this.decimalScale,
			},
		};
	},
	methods: {
		handleInput(number) {
			this.$emit('input', number);
		},
		handleBlur() {
			if (this.decimalScale > 0) {
				if (this.allowNullValue && isEmpty(this.value)) {
					this.$emit('input', null);
					return;
				}

				this.$emit('input', numberDecimalFormat(this.value, this.decimalScale));
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.input-group {
		// .input-group-append
		&-append,
		// .input-group-prepend
		&-prepend {
			pointer-events: none;
		}

		&.is-invalid {
			.form-control {
				border: 1px solid $color-alert;

				&:focus {
					box-shadow: none;
				}
			}
		}
	}
</style>
