import { i18n } from '../../i18n';
import {
	GET_PRODUCTS_REQUEST,
	GET_PRODUCTS_SUCCESS,
	GET_PRODUCTS_FAILURE,
	GET_PRODUCT_REQUEST,
	GET_PRODUCT_SUCCESS,
	GET_PRODUCT_FAILURE,
	UPDATE_PRODUCT_REQUEST,
	UPDATE_PRODUCT_SUCCESS,
	UPDATE_PRODUCT_FAILURE,
	GET_PRODUCT_IMPORT_HISTORIES_REQUEST,
	GET_PRODUCT_IMPORT_HISTORIES_SUCCESS,
	GET_PRODUCT_IMPORT_HISTORIES_FAILURE,
	CREATE_PRODUCT_REMARK_REQUEST,
	CREATE_PRODUCT_REMARK_SUCCESS,
	CREATE_PRODUCT_REMARK_FAILURE,
} from '../types';

import { getProductsAPI, getProductAPI, updateProductAPI, createProductRemarkAPI } from '../../services/api/products.api';
import { getProductImportHistoriesAPI } from '../../services/api/productImportHistories.api';
import { transformedProductList, transformedProductInfo } from '../../assets/js/transform/products';
import { transformedProductImportHistoryList } from '../../assets/js/transform/productImportHistory';
import { numberFormat, pathOr, scrollToTop } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			data: {},
		},
		import: {
			isLoading: false,
			data: [],
		},
		remark: {
			isCreating: false,
		},
	},
	getters: {
		productList(state) {
			return transformedProductList(state.list.data);
		},
		productInfo(state) {
			return transformedProductInfo(state.edit.data);
		},
		productImportHistoryList(state) {
			return transformedProductImportHistoryList(state.import.data);
		},
	},
	mutations: {
		[GET_PRODUCTS_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...state.list.meta,
					currentPage: page,
				},
			};
		},
		[GET_PRODUCTS_SUCCESS](state, { data, meta, ...rest }) {
			state.list = {
				rest,
				data,
				meta: {
					...meta,
					currentPage: meta.current_page,
					lastPage: meta.last_page,
					total: numberFormat(meta.total),
				},
				isLoading: false,
			};
		},
		[GET_PRODUCTS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[GET_PRODUCT_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_PRODUCT_SUCCESS](state, { data }) {
			state.edit = {
				data,
				isLoading: false,
			};
		},
		[GET_PRODUCT_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[UPDATE_PRODUCT_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
			};
		},
		[UPDATE_PRODUCT_SUCCESS](state, { data }) {
			state.edit = {
				...state.edit,
				data,
				isUpdating: false,
			};
		},
		[UPDATE_PRODUCT_FAILURE](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
		[GET_PRODUCT_IMPORT_HISTORIES_REQUEST](state) {
			state.import = {
				...state.import,
				isLoading: true,
			};
		},
		[GET_PRODUCT_IMPORT_HISTORIES_SUCCESS](state, { data }) {
			state.import = {
				data,
				isLoading: false,
			};
		},
		[GET_PRODUCT_IMPORT_HISTORIES_FAILURE](state) {
			state.import = {
				...state.import,
				isLoading: false,
			};
		},
		[CREATE_PRODUCT_REMARK_REQUEST](state) {
			state.remark.isCreating = true;
		},
		[CREATE_PRODUCT_REMARK_SUCCESS](state) {
			state.remark.isCreating = false;
		},
		[CREATE_PRODUCT_REMARK_FAILURE](state) {
			state.remark.isCreating = false;
		},
	},
	actions: {
		async getProducts({ commit }, query) {
			try {
				commit(GET_PRODUCTS_REQUEST, query);

				const { data } = await getProductsAPI(query);

				commit(GET_PRODUCTS_SUCCESS, data);
			} catch (error) {
				commit(GET_PRODUCTS_FAILURE);
			}
		},
		async getProduct({ commit }, id) {
			try {
				commit(GET_PRODUCT_REQUEST);

				const { data } = await getProductAPI(id);
				commit(GET_PRODUCT_SUCCESS, data);
			} catch (error) {
				commit(GET_PRODUCT_FAILURE);
			}
		},
		async getProductImportHistories({ commit }) {
			try {
				commit(GET_PRODUCT_IMPORT_HISTORIES_REQUEST);

				const { data } = await getProductImportHistoriesAPI();

				commit(GET_PRODUCT_IMPORT_HISTORIES_SUCCESS, data);
			} catch (error) {
				commit(GET_PRODUCT_IMPORT_HISTORIES_FAILURE);
			}
		},
		async updateProduct({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_PRODUCT_REQUEST);

				const { data } = await updateProductAPI(id, params);

				commit(UPDATE_PRODUCT_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);

				commit(UPDATE_PRODUCT_FAILURE);
				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}

				scrollToTop();
			}
		},
		async createProductRemark({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(CREATE_PRODUCT_REMARK_REQUEST);
				await createProductRemarkAPI(id, params);
				commit(CREATE_PRODUCT_REMARK_SUCCESS);

				await dispatch('getProduct', id);
			} catch (e) {
				commit(CREATE_PRODUCT_REMARK_FAILURE);
			}
		},
	},
};
