import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import {
	pathOr,
	datetimeFormat,
} from '../helpers';
import { PRODUCT_LABEL_STATUSES } from '../../../enums/productLabels';
import { CATEGORY_LEVEL, SHOW_LOWEST_PRICE_TYPE } from '../../../enums/categories';
import { transformedMediaBanner, transformedMedia } from '../transform/mediaLibrary';
import { transformedAttributeSetList } from './attributeSets';
import { transformedProductLabels } from './productLabels';

export const normalizeCategoriesWithLevel = (categories, parent = null, level = 1) => categories
	.reduce(
		(acc, category) => {
			let node = {
				result: [
					...new Set([
						...acc.result,
						category.id,
					]),
				],
				entities: {
					...acc.entities,
					[category.id]: category,
				},
			};
			if (category.children) {
				category.pathName = parent
					? [...parent.pathName, category.name]
					: [category.name];

				const { entities, result } = normalizeCategoriesWithLevel(category.children, category, level + 1);
				node = {
					result: [
						...new Set([
							...node.result,
							...result,
						]),
					],
					entities: {
						...node.entities,
						...entities,
					},
				};
				category.level = level;
				category.parentId = parent ? parent.id : null;
				category.children = category.children.map((categoryChild) => categoryChild.id);
				category.checked = false;
				category.childrenCount = 0;
			}
			return node;
		},
		{ result: [], entities: {} },
	);

export const getCategoryLevel = (categoryParent = null) => {
	if (categoryParent) {
		if (categoryParent.parent) {
			return {
				level: CATEGORY_LEVEL.THREE,
				parentLevelOneId: categoryParent.parent.id || null,
				parentLevelTwoId: categoryParent.id || null,
			};
		}
		return {
			level: CATEGORY_LEVEL.TWO,
			parentLevelOneId: categoryParent.id || null,
			parentLevelTwoId: null,
		};
	}
	return {
		level: CATEGORY_LEVEL.ONE,
		parentLevelOneId: null,
		parentLevelTwoId: null,
	};
};

export const transfromedHtmlContent = (content) => {
	const isActive = !!(content.is_active || false);
	const status = isActive
		? PRODUCT_LABEL_STATUSES.active
		: PRODUCT_LABEL_STATUSES.inactive;

	return {
		id: content.id,
		titleEN: content.title_en,
		titleTH: content.title_th,
		contentEN: content.content_en,
		contentTH: content.content_th,
		priority: content.priority,
		status,
		isActive,
	};
};

export const transformCategoryContent = (categoryContents) => {
	return categoryContents.map((categoryContent) => {
		const htmlContents = categoryContent.html_contents?.[0] || {};
		const badgeLabels = transformedProductLabels(pathOr([], ['labels'])(categoryContent));
		return {
			id: uuidv4(),
			titleEN: categoryContent.title_en,
			titleTH: categoryContent.title_th,
			descriptionEN: categoryContent.description_en,
			descriptionTH: categoryContent.description_th,
			shortDescriptionEN: categoryContent.short_description_en,
			shortDescriptionTH: categoryContent.short_description_th,
			destinationType: categoryContent.destination_type, // enum CATEGORY_CONTENT_DESTINATION
			searchKeyword: categoryContent.search_keyword || null,
			type: categoryContent.type, // enum CATEGORY_CONTENT_TYPE
			typeId: categoryContent.type_id,
			isHighlight: categoryContent.highlight, // true/false
			isActive: categoryContent.is_active, // true/false
			priority: categoryContent.priority, // priority for drag&drop
			imageDesktop: categoryContent?.media?.desktop?.length ? transformedMedia(categoryContent?.media?.desktop?.[0]) : null,
			imageMobile: categoryContent?.media?.mobile?.length ? transformedMedia(categoryContent?.media?.mobile?.[0]) : null,
			btnTitleEN: htmlContents.title_en || '',
			btnTitleTH: htmlContents.title_th || '',
			contentEN: htmlContents.content_en || '',
			contentTH: htmlContents.content_th || '',
			isContentHTMLActive: htmlContents.is_active || false,
			subTitleEN: categoryContent.sub_title_en,
			subTitleTH: categoryContent.sub_title_th,
			displayLowestPriceType: categoryContent.display_lowest_price_type || SHOW_LOWEST_PRICE_TYPE.APPEND_DESCRIPTION,
			isLowestPriceVisible: categoryContent.is_lowest_price_visible || false,
			startDate: categoryContent.start_at ? dayjs(categoryContent.start_at).toDate() : null,
			startTime: categoryContent.start_at ? datetimeFormat(categoryContent.start_at, 'HH:mm') : '00:00',
			endDate: categoryContent.end_at ? dayjs(categoryContent.end_at).toDate() : null,
			endTime: categoryContent.end_at ? datetimeFormat(categoryContent.end_at, 'HH:mm') : '23:59',
			period: categoryContent.period,
			isActiveButton: Boolean(categoryContent.use_default_button),
			buttonNameTh: categoryContent.custom_button_title_th || null,
			buttonNameEn: categoryContent.custom_button_title_en || null,
			customLink: categoryContent.custom_url || null,
			badgeLabels,
		};
	});
};

export const transformedCategory = (category) => {
	const categoryData = category || {};
	const children = categoryData.children || [];
	const seo = categoryData.seo || {};
	const attributeKeys = categoryData.attribute_keys || [];
	const levelData = getCategoryLevel(categoryData.parent);
	const banners = categoryData?.banners ?? [];
	const icon = categoryData?.media?.icons ?? null;

	return {
		id: categoryData.id || null,
		slug: categoryData.slug || null,
		name: categoryData.name || categoryData.name_en || null,
		nameTH: categoryData.name_th || null,
		customUrl: categoryData.custom_url || null,
		commission: categoryData.commission || null,
		status: !!(categoryData.is_active || false),
		new: !!(categoryData.is_new || false),
		isVisibleViewAll: !!(categoryData.is_visible_view_all || false),
		visibility: !!(categoryData.is_visible || false),
		navbarVisibility: !!(categoryData.is_navbar_visible || false),
		tradeInTruemoveVisibility: !!(categoryData.is_trade_in_truemove_visible || false),
		descriptionTop: categoryData.description_top || null,
		descriptionBottom: categoryData.description_bottom || null,
		banners: banners.map((banner) => ({
			link: banner.link,
			desktopFile: transformedMediaBanner(banner?.files?.[0]),
			desktopENFile: transformedMediaBanner(banner?.files?.[1]),
			mobileFile: transformedMediaBanner(banner?.files?.[2]),
			mobileENFile: transformedMediaBanner(banner?.files?.[3]),
			startDate: banner.start_at ? dayjs(banner.start_at).toDate() : null,
			startTime: banner.start_at ? datetimeFormat(banner.start_at, 'HH:mm') : '00:00',
			endDate: banner.end_at ? dayjs(banner.end_at).toDate() : null,
			endTime: banner.end_at ? datetimeFormat(banner.end_at, 'HH:mm') : '23:59',
			isActive: Boolean(banner.is_active),
			period: banner.period,
		})),
		icon: {
			desktopFile: icon?.desktop.length ? transformedMediaBanner(icon?.desktop?.[0]) : null,
			mobileFile: icon?.mobile.length ? transformedMediaBanner(icon?.mobile?.[0]) : null,
		},
		seo: {
			title: seo.title_en || null,
			titleTH: seo.title_th || null,
			keyword: seo.keyword_en || null,
			keywordTH: seo.keyword_th || null,
			description: seo.description_en || null,
			descriptionTH: seo.description_th || null,
		},
		attributeKeys: transformedAttributeSetList(attributeKeys),
		hasChild: children.length > 0,
		children: children.map(transformedCategory),
		level: levelData.level,
		parentLevelOneId: levelData.parentLevelOneId,
		parentLevelTwoId: levelData.parentLevelTwoId,
		isExpand: categoryData.is_description_top_expanded || false,
		isBannerVisibility: !!(categoryData.is_banner_visible || false),
		isLowestPriceVisibility: !!(categoryData.is_lowest_price_visible || false),
		productFamiliesGroup: categoryData.product_families || [],
		labels: transformedProductLabels(pathOr([], ['labels'])(categoryData)),
		htmlContents: categoryData.html_contents?.map(transfromedHtmlContent) || [],
		link: categoryData.compare_link,
		compareEN: categoryData.compare_text_en,
		compareTH: categoryData.compare_text_th,
		compareDescriptionEN: categoryData.compare_description_en,
		compareDescriptionTH: categoryData.compare_description_th,
		categoryDisplayType: categoryData.display_type,
		categoryContents: transformCategoryContent(pathOr([], ['category_contents'])(categoryData)),
		lowestPriceCategoryIds: categoryData.lowest_price_category_ids || [],
		searchKeyword: categoryData.search_keyword || null,
	};
};

export const transformedCategoryDropdownOption = (category) => {
	const categoryData = category || {};
	return {
		value: categoryData.id || null,
		name: categoryData.name || null,
	};
};

export const transformedManageFilterItem = (item = {}) => ({
	name: item.name || null,
	data: {
		id: item.id || null,
		status: item.status || false,
	},
});

export const transformedManageFilterList = (list = []) => list.map(transformedManageFilterItem);
