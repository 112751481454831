import httpClient from './httpClient';

export const SEARCH_SETTING_ENDPOINT = '/backoffice/search_weights';

export const getSearchWeightsAPI = () => httpClient.get(`${SEARCH_SETTING_ENDPOINT}`);
export const getSearchWeightAPI = (id) => httpClient.get(`${SEARCH_SETTING_ENDPOINT}/${id}`);
export const postSearchWeightAPI = (params) => httpClient.post(`${SEARCH_SETTING_ENDPOINT}`, params);
export const updateSearchWeightAPI = (id, params) => httpClient.put(`${SEARCH_SETTING_ENDPOINT}/${id}`, params);
export const deleteSearchWeightAPI = (id) => httpClient.delete(`${SEARCH_SETTING_ENDPOINT}/${id}`);
export const reSyncSearchWeightAPI = (id) => httpClient.post(`${SEARCH_SETTING_ENDPOINT}/${id}/resync`);
