<template>
	<form
		class="flex-fill"
		@submit.prevent="$emit('onSubmit')"
	>
		<CInput
			:value="value"
			type="text"
			placeholder="Search by file name"
			@input="(value) => $emit('input', value)"
		>
			<template #prepend-content>
				<button class="btn-search" type="submit">
					<FontAwesomeIcon :icon="['far', 'search']" />
				</button>
			</template>
		</CInput>
	</form>
</template>

<script>
export default {
	name: 'MediaLibrarySearch',
	props: {
		value: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-search {
	display: inline-flex;
	background: transparent;
	box-shadow: none;
	border: none;
	padding: 0;

	&:focus {
		outline: none;
	}
}
</style>
