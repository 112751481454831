import {
	getCategoriesAPI,
	getCategoryAPI,
	postCategoryAPI,
	putCategoryAPI,
	deleteCategoryAPI,
	updateAttributeStatusAPI,
	postSortCategoriesAPI,
} from '../../services/api/categories.api';

import { postCategoryBannerAPI, getCategoryBannerAPI } from '../../services/api/banners.api';

import {
	GET_CATEGORIES_REQUEST,
	GET_CATEGORIES_SUCCESS,
	GET_CATEGORIES_FAILURE,
	SELECT_CATEGORY,

	GET_CATEGORY_REQUEST,
	GET_CATEGORY_SUCCESS,
	GET_CATEGORY_FAILURE,
	RESET_CATEGORY,

	CREATE_CATEGORY_REQUEST,
	CREATE_CATEGORY_SUCCESS,
	CREATE_CATEGORY_FAILURE,
	CREATE_CATEGORY_CLEAR_ERROR,

	UPDATE_CATEGORY_REQUEST,
	UPDATE_CATEGORY_SUCCESS,
	UPDATE_CATEGORY_FAILURE,
	UPDATE_CATEGORY_CLEAR_ERROR,

	DELETE_CATEGORY_REQUEST,
	DELETE_CATEGORY_SUCCESS,
	DELETE_CATEGORY_FAILURE,
	DELETE_CATEGORY_CLEAR_ERROR,

	PUT_ATTRIBUTE_STATUS_REQUEST,
	PUT_ATTRIBUTE_STATUS_SUCCESS,
	PUT_ATTRIBUTE_STATUS_FAILURE,

	SORT_CATEGORIES_REQUEST,
	SORT_CATEGORIES_SUCCESS,
	SORT_CATEGORIES_FAILURE,

	TOGGLE_ATTRIBUTE_STATUS,

	GET_SORT_CATEGORY_BANNER_REQUEST,
	GET_SORT_CATEGORY_BANNER_SUCCESS,
	GET_SORT_CATEGORY_BANNER_FAILURE,
	UPDATE_SORT_CATEGORY_BANNER_REQUEST,
	UPDATE_SORT_CATEGORY_BANNER_SUCCESS,
	UPDATE_SORT_CATEGORY_BANNER_FAILURE,
} from '../types';
import { CATEGORY_LEVEL } from '../../enums/categories';
import { transformedCategory } from '../../assets/js/transform/category';
import { transformedAttributeStatus } from '../../assets/js/transform/attributeSets';
import { pathOr, mappingAttributeStatus } from '../../assets/js/helpers';
import { i18n } from '../../i18n';

const getActiveIdsNested = (id = null, level = null) => {
	switch (level) {
		case CATEGORY_LEVEL.ONE:
			return {
				[CATEGORY_LEVEL.ONE]: id,
				[CATEGORY_LEVEL.TWO]: null,
			};

		case CATEGORY_LEVEL.TWO:
			return {
				[CATEGORY_LEVEL.TWO]: id,
			};

		default:
			return {};
	}
};

const defaultErrors = {
	slug: false,
};

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: [],
			activeIds: {
				[CATEGORY_LEVEL.ONE]: null,
				[CATEGORY_LEVEL.TWO]: null,
				// Impossible to active level three
			},
		},
		create: {
			isLoading: false,
			error: false,
			fieldErrors: defaultErrors,
			isSuccess: false, // for display toast after created
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			error: false,
			isSuccess: false,
			fieldErrors: defaultErrors,
			data: {},
		},
		delete: {
			isLoading: false,
			isSuccess: false,
			error: false,
		},
		sort: {
			isLoading: false,
			error: false,
		},
		// update attribute key status: on, off
		editAttrStatus: {
			isLoading: false,
			isSuccess: false,
			error: false,
		},

		// sort category banner
		banner: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
	},

	getters: {
		categoryLists(state) {
			return state.list.data.map(transformedCategory);
		},
		categoryInfo(state) {
			return transformedCategory(state.edit.data);
		},
		// attribute key ids of category: [18, 19, 20]
		attributeIds(state, getters) {
			const { categoryInfo: { attributeKeys } } = getters;
			return attributeKeys.map((item) => (item.id));
		},
		attributeList: (stateLocal, gettersLocal, stateGlobal, gettersGlobal) => {
			// use getters from `this store`
			const attributeIds = gettersLocal.attributeIds;
			// use getters from `attributeKeys stores`
			const attributeList = gettersGlobal['attributeKeys/attributeList'];
			// mapping attribute keys with this category
			const results = mappingAttributeStatus(attributeList, attributeIds);

			return transformedAttributeStatus(results);
		},
	},

	mutations: {
		// Get categories
		[GET_CATEGORIES_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_CATEGORIES_SUCCESS](state, { data = [] }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
			};
		},
		[GET_CATEGORIES_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[SELECT_CATEGORY](state, { categoryId = null, categoryLevel = null }) {
			if (categoryLevel) {
				state.list = {
					...state.list,
					activeIds: {
						...state.list.activeIds,
						...getActiveIdsNested(categoryId, categoryLevel),
					},
				};
			}
		},

		// Get category
		[GET_CATEGORY_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_CATEGORY_SUCCESS](state, { data = {} }) {
			state.edit = {
				...state.edit,
				isLoading: false,
				data,
			};
		},
		[GET_CATEGORY_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},

		// Create category
		[CREATE_CATEGORY_REQUEST](state) {
			state.create = {
				...state.create,
				isLoading: true,
			};
		},
		[CREATE_CATEGORY_SUCCESS](state) {
			state.create = {
				...state.create,
				isLoading: false,
				error: false,
				fieldErrors: defaultErrors,
				isSuccess: true,
			};
		},
		[CREATE_CATEGORY_FAILURE](state, error) {
			const isErrorSlug = !!(pathOr(false, ['response', 'data', 'errors', 'slug', 0])(error));

			state.create = {
				...state.create,
				isLoading: false,
				error: true,
				fieldErrors: {
					...defaultErrors,
					slug: isErrorSlug,
				},
				isSuccess: false,
			};
		},
		[CREATE_CATEGORY_CLEAR_ERROR](state) {
			state.create = {
				...state.create,
				error: false,
				fieldErrors: defaultErrors,
				isSuccess: false,
			};
		},

		// Update category
		[UPDATE_CATEGORY_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
			};
		},
		[UPDATE_CATEGORY_SUCCESS](state, { data = {} }) {
			state.edit = {
				...state.edit,
				isUpdating: false,
				error: false,
				isSuccess: true,
				data,
			};
		},
		[UPDATE_CATEGORY_FAILURE](state, error) {
			const isErrorSlug = !!(pathOr(false, ['response', 'data', 'errors', 'slug', 0])(error));

			state.edit = {
				...state.edit,
				isUpdating: false,
				error: true,
				fieldErrors: {
					...defaultErrors,
					slug: isErrorSlug,
				},
				isSuccess: false,
			};
		},
		[UPDATE_CATEGORY_CLEAR_ERROR](state) {
			state.edit = {
				...state.edit,
				error: false,
				fieldErrors: defaultErrors,
				isSuccess: false,
			};
		},
		[RESET_CATEGORY](state) {
			state.edit = {
				...state.edit,
				data: {},
				isUpdating: false,
				error: false,
				fieldErrors: defaultErrors,
				isSuccess: false,
			};
		},

		// Delete categories
		[DELETE_CATEGORY_REQUEST](state) {
			state.delete = {
				...state.delete,
				isLoading: true,
				isSuccess: false,
			};
		},
		[DELETE_CATEGORY_SUCCESS](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
				isSuccess: true,
			};
		},
		[DELETE_CATEGORY_FAILURE](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
			};
		},
		[DELETE_CATEGORY_CLEAR_ERROR](state) {
			state.delete = {
				...state.delete,
				isSuccess: false,
			};
		},

		// Put attribute status
		[PUT_ATTRIBUTE_STATUS_REQUEST](state) {
			state.editAttrStatus.isLoading = true;
			state.editAttrStatus.isSuccess = false;
			state.editAttrStatus.error = false;
		},
		[PUT_ATTRIBUTE_STATUS_SUCCESS](state) {
			state.editAttrStatus.isLoading = false;
			state.editAttrStatus.isSuccess = true;
			state.editAttrStatus.error = false;
		},
		[PUT_ATTRIBUTE_STATUS_FAILURE](state) {
			state.editAttrStatus.isLoading = false;
			state.editAttrStatus.isSuccess = false;
			state.editAttrStatus.error = true;
		},

		// Sort categories
		[SORT_CATEGORIES_REQUEST](state) {
			state.sort = {
				...state.sort,
				isLoading: true,
				error: false,
			};
		},
		[SORT_CATEGORIES_SUCCESS](state, { data = [] }) {
			state.list = {
				...state.list,
				data,
			};
			state.sort = {
				...state.sort,
				isLoading: false,
			};
		},
		[SORT_CATEGORIES_FAILURE](state) {
			state.sort = {
				...state.sort,
				isLoading: false,
				error: true,
			};
		},
		[TOGGLE_ATTRIBUTE_STATUS](state, payload) {
			// TODO: get full attributeList object
			let attributeKeys = [];
			const attributeKeyId = state.edit.data.attribute_keys.find((item) => item.id === payload);
			if (attributeKeyId) {
				attributeKeys = state.edit.data.attribute_keys.filter((item) => item.id !== payload);
			} else {
				attributeKeys = [...state.edit.data.attribute_keys, { id: payload }];
			}
			state.edit = {
				...state.edit,
				data: {
					...state.edit.data,
					attribute_keys: [...attributeKeys],
				},
			};
		},

		// Sorting Category Banner
		[GET_SORT_CATEGORY_BANNER_REQUEST](state) {
			state.banner.isLoading = true;
		},
		[GET_SORT_CATEGORY_BANNER_SUCCESS](state, payload) {
			state.banner.data = payload;
			state.banner.isLoading = false;
		},
		[GET_SORT_CATEGORY_BANNER_FAILURE](state) {
			state.banner.isLoading = false;
		},
		[UPDATE_SORT_CATEGORY_BANNER_REQUEST](state) {
			state.banner.isUpdating = true;
		},
		[UPDATE_SORT_CATEGORY_BANNER_SUCCESS](state, payload) {
			state.banner.data = payload;
			state.banner.isUpdating = false;
		},
		[UPDATE_SORT_CATEGORY_BANNER_FAILURE](state) {
			state.banner.isUpdating = false;
		},
	},

	actions: {
		async getCategories({ commit }) {
			try {
				commit(GET_CATEGORIES_REQUEST);

				const response = await getCategoriesAPI();
				const data = pathOr([], ['data', 'data'])(response);
				commit(GET_CATEGORIES_SUCCESS, { data });
			} catch (error) {
				commit(GET_CATEGORIES_FAILURE);
			}
		},

		selectCategory({ commit, state }, { categoryId = null, categoryLevel = CATEGORY_LEVEL.ONE }) {
			if (categoryId && categoryId === state.list.activeIds[categoryLevel]) {
				// When select actived item, de-active item
				commit(SELECT_CATEGORY, { categoryId: null, categoryLevel });
			} else {
				commit(SELECT_CATEGORY, { categoryId, categoryLevel });
			}
		},

		async getCategory({ commit }, categoryId) {
			try {
				commit(GET_CATEGORY_REQUEST);

				const response = await getCategoryAPI(categoryId);
				const data = pathOr([], ['data', 'data'])(response);
				commit(GET_CATEGORY_SUCCESS, { data });
			} catch (error) {
				commit(GET_CATEGORY_FAILURE);
			}
		},

		async postCategory({ commit }, categoryData) {
			try {
				commit(CREATE_CATEGORY_REQUEST);

				await postCategoryAPI(categoryData);
				commit(CREATE_CATEGORY_SUCCESS);
			} catch (error) {
				commit(CREATE_CATEGORY_FAILURE, error);
			}
		},

		clearCreateError({ commit }) {
			commit(CREATE_CATEGORY_CLEAR_ERROR);
		},

		async updateCategory({ commit }, { categoryId, categoryData }) {
			try {
				commit(UPDATE_CATEGORY_REQUEST);

				const response = await putCategoryAPI(categoryId, categoryData);
				const data = pathOr({}, ['data', 'data'])(response);
				commit(UPDATE_CATEGORY_SUCCESS, { data });
			} catch (error) {
				commit(UPDATE_CATEGORY_FAILURE, error);
			}
		},

		clearEditError({ commit }) {
			commit(UPDATE_CATEGORY_CLEAR_ERROR);
		},

		async deleteCategory({ commit }, categoryId) {
			try {
				commit(DELETE_CATEGORY_REQUEST);

				await deleteCategoryAPI(categoryId);
				commit(DELETE_CATEGORY_SUCCESS);
			} catch (error) {
				commit(DELETE_CATEGORY_FAILURE);
			}
		},

		clearDeleteError({ commit }) {
			commit(DELETE_CATEGORY_CLEAR_ERROR);
		},

		async postUpdateOrderCategory({ commit }, categoryIds = []) {
			try {
				commit(SORT_CATEGORIES_REQUEST);

				const response = await postSortCategoriesAPI({
					category_ids: categoryIds,
				});
				const data = pathOr([], ['data', 'data'])(response);
				commit(SORT_CATEGORIES_SUCCESS, { data });
			} catch (error) {
				commit(SORT_CATEGORIES_FAILURE);
			}
		},

		resetCategoryInfo({ commit }) {
			commit(RESET_CATEGORY);
		},

		async updateAttributeStatus({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(PUT_ATTRIBUTE_STATUS_REQUEST);

				const _params = {
					attribute_key_id: params.attribute_key_id,
					action: params.action,
				};

				commit(TOGGLE_ATTRIBUTE_STATUS, params.attribute_key_id);
				await updateAttributeStatusAPI(id, _params);
				commit(PUT_ATTRIBUTE_STATUS_SUCCESS);
			} catch (error) {
				commit(PUT_ATTRIBUTE_STATUS_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},

		async getSortingCategoryBanner({ commit }) {
			try {
				commit(GET_SORT_CATEGORY_BANNER_REQUEST);
				const response = await getCategoryBannerAPI();
				const data = pathOr({}, ['data', 'data'])(response);

				commit(GET_SORT_CATEGORY_BANNER_SUCCESS, data);
			} catch (error) {
				commit(GET_SORT_CATEGORY_BANNER_FAILURE);
			}
		},

		async updateSortingCategoryBanner({ commit, dispatch }, { params = {} }) {
			try {
				commit(UPDATE_SORT_CATEGORY_BANNER_REQUEST);

				const { data } = await postCategoryBannerAPI(params);

				commit(UPDATE_SORT_CATEGORY_BANNER_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				await dispatch('getSortingCategoryBanner');
			} catch (error) {
				commit(UPDATE_SORT_CATEGORY_BANNER_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
