import { saveAs } from 'file-saver';
import httpClient from './httpClient';
import { getFilenameFromHeader } from '../../assets/js/helpers';

export const IMPORT_ENDPOINT = '/backoffice/imports';

export const getProductImportHistoriesAPI = () => {
	return httpClient.get(`${IMPORT_ENDPOINT}`);
};

export const downloadFileAPI = async (logUrl) => {
	const { data, headers } = await httpClient.get(logUrl, {
		responseType: 'blob',
	});

	const filename = getFilenameFromHeader(headers);
	saveAs(data, filename);
};
