import { i18n } from '@/i18n';
import {
	getVariantInfoListAPI,
	getVariantInfoAPI,
	postVariantInfoAPI,
	updateVariantInfoAPI,
	deleteVariantInfoAPI,
} from '../../services/api/variantInfo.api';
import {
	CREATE_VARIANT_INFO_REQUEST,
	CREATE_VARIANT_INFO_SUCCESS,
	CREATE_VARIANT_INFO_FAILURE,
	GET_VARIANT_INFO_LIST_REQUEST,
	GET_VARIANT_INFO_LIST_SUCCESS,
	GET_VARIANT_INFO_LIST_FAILURE,
	GET_VARIANT_INFO_REQUEST,
	GET_VARIANT_INFO_SUCCESS,
	GET_VARIANT_INFO_FAILURE,
	UPDATE_VARIANT_INFO_REQUEST,
	UPDATE_VARIANT_INFO_SUCCESS,
	UPDATE_VARIANT_INFO_FAILURE,
	DELETE_VARIANT_INFO_REQUEST,
	DELETE_VARIANT_INFO_SUCCESS,
	DELETE_VARIANT_INFO_FAILURE,
} from '../types';

import { transformedVariantInfoList, transformedVariantInfo } from '../../assets/js/transform/variantInfo';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		list: {
			isLoading: false,
			data: [],
			meta: [],
			error: false,
		},
	},
	getters: {
		variantInfoList(state) {
			return state.list.data;
		},
		variantInfoListMeta(state) {
			return state.list.meta;
		},
	},
	mutations: {
		[CREATE_VARIANT_INFO_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_VARIANT_INFO_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_VARIANT_INFO_FAILURE](state) {
			state.create.isCreating = false;
		},
		[GET_VARIANT_INFO_LIST_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = [];
			state.list.meta = [];
			state.list.error = false;
		},
		[GET_VARIANT_INFO_LIST_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedVariantInfoList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_VARIANT_INFO_LIST_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		[GET_VARIANT_INFO_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_VARIANT_INFO_SUCCESS](state, { data }) {
			state.edit.data = transformedVariantInfo(data);
			state.edit.isLoading = false;
		},
		[GET_VARIANT_INFO_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[UPDATE_VARIANT_INFO_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_VARIANT_INFO_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_VARIANT_INFO_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_VARIANT_INFO_REQUEST]() { },
		[DELETE_VARIANT_INFO_SUCCESS]() { },
		[DELETE_VARIANT_INFO_FAILURE]() { },
	},
	actions: {
		async getVariantInfoList({ commit }, query) {
			try {
				commit(GET_VARIANT_INFO_LIST_REQUEST, query);
				const { data } = await getVariantInfoListAPI(query);
				commit(GET_VARIANT_INFO_LIST_SUCCESS, data);
			} catch (error) {
				commit(GET_VARIANT_INFO_LIST_FAILURE);
			}
		},

		async getVariantInfo({ commit }, id) {
			try {
				commit(GET_VARIANT_INFO_REQUEST);
				const { data } = await getVariantInfoAPI(id);
				commit(GET_VARIANT_INFO_SUCCESS, data);
			} catch (error) {
				const { data } = error.response;
				commit(GET_VARIANT_INFO_FAILURE, data);
			}
		},

		async createVariantInfo({ commit, dispatch }, payload) {
			try {
				commit(CREATE_VARIANT_INFO_REQUEST);
				const { data } = await postVariantInfoAPI(payload);
				commit(CREATE_VARIANT_INFO_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				const { data } = error.response;
				commit(CREATE_VARIANT_INFO_FAILURE, data);
			}
		},

		async updateVariantInfo({ commit, dispatch }, { id, payload }) {
			try {
				commit(UPDATE_VARIANT_INFO_REQUEST);
				const { data } = await updateVariantInfoAPI(id, payload);
				commit(UPDATE_VARIANT_INFO_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const { data } = error.response;
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				commit(UPDATE_VARIANT_INFO_FAILURE, data);
			}
		},

		async deleteVariantInfo({ commit, dispatch }, id) {
			try {
				commit(DELETE_VARIANT_INFO_REQUEST);
				const { data } = await deleteVariantInfoAPI(id);
				commit(DELETE_VARIANT_INFO_SUCCESS, data);
				dispatch('toast/showDeleteSuccessToast', 'Variant info', { root: true });
			} catch (error) {
				const { data } = error.response;
				dispatch('toast/showDeleteErrorToast', { root: true });
				commit(DELETE_VARIANT_INFO_FAILURE, data);
			}
		},
	},
};
