export const ECOMMERCE_WAREHOUSE_ID = '77';
export const STATUS_OPTIONS = [
	{ name: 'All status', value: null },
	{ name: 'Active', value: 1 },
	{ name: 'Inactive', value: 0 },
];

export const WAREHOUSE_STATUSES = {
	true: { name: 'Active', value: 'active' },
	false: { name: 'Inactive', value: 'inactive' },
};
export const DAY_MAPPER = {
	MONDAY: 'จันทร์',
	TUESDAY: 'อังคาร',
	WEDNESDAY: 'พุธ',
	THURSDAY: 'พฤหัส',
	FRIDAY: 'ศุกร์',
	SATURDAY: 'เสาร์',
	SUNDAY: 'อาทิดย์',
};
