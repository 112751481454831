import httpClient from './httpClient';

export const BANNER_GROUP_ENDPOINT = '/backoffice/banner-groups';
export const CATEGORY_BANNER_ENDPOINT = 'backoffice/categories/sort/slide-category-banner';

export const getBannersGroupAPI = (params = {}) => httpClient.get(BANNER_GROUP_ENDPOINT, { params });
export const getBannerGroupAPI = (id, type) => httpClient.get(`${BANNER_GROUP_ENDPOINT}/${type}/${id}`);
export const createBannerGroupAPI = (params) => httpClient.post(BANNER_GROUP_ENDPOINT, params);
export const updateBannerGroupAPI = (id, params) => httpClient.put(`${BANNER_GROUP_ENDPOINT}/${id}`, params);
export const deleteBannerGroupAPI = (id) => httpClient.delete(`${BANNER_GROUP_ENDPOINT}/${id}`);

export const getCategoryBannerAPI = (params = {}) => httpClient.get(CATEGORY_BANNER_ENDPOINT, { params });
export const postCategoryBannerAPI = (params) => httpClient.post(CATEGORY_BANNER_ENDPOINT, params);
