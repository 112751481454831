<template>
	<CToaster
		:position="config.position"
		class="toast-wrapper"
	>
		<ToastBox
			v-for="toast in list"
			:key="toast.key"
			:toast="toast"
			data-test-id="toast"
		/>
	</CToaster>
</template>

<script>
import { mapState } from 'vuex';
import ToastBox from '@/components/ToastBox.vue';

export default {
	name: 'TheToastContainer',
	components: {
		ToastBox,
	},
	computed: {
		...mapState('toast', {
			list: 'list',
			config: 'config',
		}),
	},

};
</script>
