export default {
	searchNotFound: 'We didn\'t find any {field} matching your criteria.',
	successMessageTitle: 'Success!',
	successMessage: 'Your changes have been saved.',
	successMessageTitleDelete: 'Removed',
	successMessageClearCache: 'Search result is now updated.',
	successMessageClearDateTimeCache: 'Date time is now updated.',
	errorMessageTitleClearCache: 'Unable to clear cache',
	errorMessageTitleClearDateTimeCache: 'Unable to clear date time cache',
	successMessageDelete: '{type} has been removed.',
	successMessageCreate: 'New {type} has been created.',
	errorMessageTitleGetData: 'Unable to get data',
	errorMessageTitleEdit: 'Unable to save',
	errorMessageTitleEditWithCode: 'Unable to save ({status})',
	errorMessageTitleCreate: 'Unable to create',
	errorMessageTitleDelete: 'Unable to remove',
	errorMessageTitleSend: 'Unable to send',
	errorMessageExport: 'Unable to export',
	errorMessage: 'An unexpected error occurred. Please try again.',
	errorMessageWithMessage: '{message}. Please try again.',
	errorMessageDateTimePast: 'The selected date and time must be in the future',
	error: {
		required: 'Required field.',
		invalid: 'Invalid value',
		maximumPercentage: 'Please input the maximum number 100.',
		maxFileSize: 'Allow only {size} MB for image.',
		minimumNumber: 'At least {number}',
		maximumNumber: 'Maximum {number}',
		emailInvalidFormat: 'Invalid email',
		phoneInvalidFormat: 'Invalid phone number',
		timeInvalidFormat: 'Time format is invalid',
		timeAfter: 'Should be after {time}',
		url: 'Invalid Url',
		between: 'Must be between {min} and {max} {unit}',
		exist: 'This value already exist',
		datetimeAfterNow: 'The datetime is less than current time',
		maximumLength: 'Maximum {field} length is {number}',
		minimumLength: 'Must contain {number} digits',
		phoneNumberMustStartWithZero: 'Phone number should start with zero',
		lengthCharacters: 'Field Length: {minLength} - {maxLength} characters',
		maximumCharacters: 'Field Length: 1 - 255 characters',
		recommendCharacters: 'Recommend {number} characters',
		greaterThan: '{field} must be greater than {number}',
		lessThan: '{field} must be less than {number}',
		exceedCharacters: '{field} must not exceed {length} letters.',
		duplicate: 'Duplicate {type} name: {name}',
	},
	status: {
		active: 'Active',
		inActive: 'Inactive',
	},
	visibility: {
		on: 'On',
		off: 'Off',
	},
	slug: {
		slugFormat: 'Allow letters, numbers, and ( - )',
		duplicateSlug: 'Slug already exists',
		invalid: 'Allow letters, numbers, and ( - ) for slug name format',
	},
	separateBySemicolon: 'Use semicolon (;) to input multiple items',
	alternateProductUrl: 'This link will be a replacement of product detail link. System will redirect to this link only when product detail visibility is hidden (not apply on product group). Please leave it blank to use product slug',
	general: {
		create: 'Create {type}',
		searchPlaceholder: 'Search by {type} name or ID',
		found: '{count} {type}(s) found',
		delete: {
			remove: 'Remove {type}',
			title: 'Remove this {type}?',
			description: 'Are you sure you want to remove this {type}?',
			descriptionWithLinked: 'By removing this, all {type} info will be disappeared from the list and linked widgets.',
		},
	},
	create: {
		successMessage: 'New {type} has been added.',
	},
	edit: {
		successMessage: '{type} has been updated.',
	},
};
