import { i18n } from '@/i18n';
import {
	deleteAdditionalFeeAPI,
	getAdditionalFeeAPI,
	getShippingAdditionalFeeAPI,
	postAdditionalFeeAPI,
	updateAdditionalFeeAPI,
} from '@/services/api/shippings.api';
import {
	GET_ADDITIONAL_FEE_REQUEST,
	GET_ADDITIONAL_FEE_SUCCESS,
	GET_ADDITIONAL_FEE_FAILURE,
	GET_SHIPPING_ADDITIONAL_FEE_REQUEST,
	GET_SHIPPING_ADDITIONAL_FEE_SUCCESS,
	GET_SHIPPING_ADDITIONAL_FEE_FAILURE,
	CREATE_ADDITIONAL_FEE_REQUEST,
	CREATE_ADDITIONAL_FEE_SUCCESS,
	CREATE_ADDITIONAL_FEE_FAILURE,
	UPDATE_ADDITIONAL_FEE_REQUEST,
	UPDATE_ADDITIONAL_FEE_SUCCESS,
	UPDATE_ADDITIONAL_FEE_FAILURE,
} from '../types';
import { transformedAdditionalFee, transformedAdditionalFeeList } from '../../assets/js/transform/additionalFees';
import { SHIPPING_ADDITIONAL_FEE_TYPE } from '../../enums/shippings';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		additionalFees: {
			[SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT]: {
				list: {
					data: [],
					isLoading: false,
				},
				create: {
					isCreating: false,
				},
				edit: {
					data: {},
					isLoading: false,
					isUpdating: false,
				},
			},
			[SHIPPING_ADDITIONAL_FEE_TYPE.CATEGORY]: {
				list: {
					data: [],
					isLoading: false,
				},
				create: {
					isCreating: false,
				},
				edit: {
					data: {},
					isLoading: false,
					isUpdating: false,
				},
			},
			[SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE]: {
				list: {
					data: [],
					isLoading: false,
				},
				create: {
					isCreating: false,
				},
				edit: {
					data: {},
					isLoading: false,
					isUpdating: false,
				},
			},
		},
	},
	getters: {
		additionalFeesByShippingMethodId: (state) => (shippingMethodId, type) => {
			// Filter for make sure that get only related to shipping method id
			const data = state.additionalFees[type].list.data
				.filter((categoryAdditionalFee) => categoryAdditionalFee.shippingMethodId === shippingMethodId);

			return {
				isLoading: state.additionalFees[type].list.isLoading,
				data,
			};
		},
	},
	mutations: {
		[GET_ADDITIONAL_FEE_REQUEST](state, type) {
			state.additionalFees[type].edit.isLoading = true;
		},
		[GET_ADDITIONAL_FEE_SUCCESS](state, { additionalFee, type }) {
			state.additionalFees[type].edit.isLoading = false;
			state.additionalFees[type].edit.data = additionalFee;
		},
		[GET_ADDITIONAL_FEE_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[GET_SHIPPING_ADDITIONAL_FEE_REQUEST](state, { type }) {
			state.additionalFees[type].list.isLoading = true;
		},
		[GET_SHIPPING_ADDITIONAL_FEE_SUCCESS](state, { type, data }) {
			state.additionalFees[type].list.data = data;
			state.additionalFees[type].list.isLoading = false;
		},
		[GET_SHIPPING_ADDITIONAL_FEE_FAILURE](state, { type }) {
			state.additionalFees[type].list.isLoading = false;
		},
		[CREATE_ADDITIONAL_FEE_REQUEST](state, type) {
			state.additionalFees[type].create.isCreating = true;
		},
		[CREATE_ADDITIONAL_FEE_SUCCESS](state, type) {
			state.additionalFees[type].create.isCreating = false;
		},
		[CREATE_ADDITIONAL_FEE_FAILURE](state, type) {
			state.additionalFees[type].create.isCreating = false;
		},
		[UPDATE_ADDITIONAL_FEE_REQUEST](state, type) {
			state.additionalFees[type].edit.isUpdating = true;
		},
		[UPDATE_ADDITIONAL_FEE_SUCCESS](state, type) {
			state.additionalFees[type].edit.isUpdating = false;
		},
		[UPDATE_ADDITIONAL_FEE_FAILURE](state, type) {
			state.additionalFees[type].edit.isUpdating = false;
		},
	},
	actions: {
		async getAdditionalFee({ commit }, { id, type }) {
			try {
				commit(GET_ADDITIONAL_FEE_REQUEST, type);
				const { data } = await getAdditionalFeeAPI(id);
				commit(GET_ADDITIONAL_FEE_SUCCESS, {
					additionalFee: transformedAdditionalFee(data.data),
					type,
				});
			} catch (e) {
				commit(GET_ADDITIONAL_FEE_FAILURE);
			}
		},
		async postAdditionalFee({ commit, dispatch }, { id, type, params }) {
			try {
				commit(CREATE_ADDITIONAL_FEE_REQUEST, type);
				await postAdditionalFeeAPI(id, params);
				commit(CREATE_ADDITIONAL_FEE_SUCCESS, type);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_ADDITIONAL_FEE_FAILURE, type);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async getShippingAdditionalByType({ commit }, { id, type }) {
			try {
				commit('GET_SHIPPING_ADDITIONAL_FEE_REQUEST', { type });

				const { data } = await getShippingAdditionalFeeAPI(id, type);
				const additionalFees = transformedAdditionalFeeList(data.data);

				commit('GET_SHIPPING_ADDITIONAL_FEE_SUCCESS', { type, data: additionalFees });
			} catch (e) {
				commit('GET_SHIPPING_ADDITIONAL_FEE_FAILURE', { type });
			}
		},
		async updateAdditionalFee({ commit, dispatch }, { id, type, params }) {
			try {
				commit(UPDATE_ADDITIONAL_FEE_REQUEST, type);
				await updateAdditionalFeeAPI(id, params);
				commit(UPDATE_ADDITIONAL_FEE_SUCCESS, type);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_ADDITIONAL_FEE_FAILURE, type);

				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
		async deleteAdditionalFee({ dispatch }, id) {
			try {
				await deleteAdditionalFeeAPI(id);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Rule' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
