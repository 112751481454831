<template>
	<div class="modal-header">
		<h5 class="mb-0" data-test-id="modal-title">
			{{ title }}
		</h5>
		<CButton
			v-if="!hideCloseButton"
			ref="button-close-icon"
			class="transparent p-0"
			@click="handleClick"
		>
			<CIcon
				class="icon-close text-muted"
				size="lg"
				name="cil-x"
			/>
		</CButton>
	</div>
</template>

<script>
export default {
	name: 'ModalHeaderWrapper',
	props: {
		title: {
			type: String,
			default: null,
		},
		hideCloseButton: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		handleClick() {
			this.$emit('onClose');
		},
	},
};
</script>
