import {
	getSearchSynonymsAPI,
	createSearchSynonymAPI,
	deleteSearchSynonymAPI,
} from '@/services/api/searchSynonyms.api';
import {
	GET_SEARCH_SYNONYMS_REQUEST,
	GET_SEARCH_SYNONYMS_SUCCESS,
	GET_SEARCH_SYNONYMS_FAILURE,
	CREATE_SEARCH_SYNONYM_REQUEST,
	CREATE_SEARCH_SYNONYM_SUCCESS,
	CREATE_SEARCH_SYNONYM_FAILURE,
	DELETE_SEARCH_SYNONYM_REQUEST,
	DELETE_SEARCH_SYNONYM_SUCCESS,
	DELETE_SEARCH_SYNONYM_FAILURE,
} from '../types';

import { i18n } from '../../i18n';
import { numberFormat } from '../../assets/js/helpers';
import { transformedSearchSynonyms } from '../../assets/js/transform/searchSynonyms';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		create: {
			isSubmitting: false,
		},
		delete: {
			isSubmitting: false,
		},
	},
	getters: {
		synonymList(state) {
			return transformedSearchSynonyms(state.list.data);
		},
	},
	mutations: {
		[GET_SEARCH_SYNONYMS_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...state.list.meta,
					currentPage: page,
				},
			};
		},
		[GET_SEARCH_SYNONYMS_SUCCESS](state, { data, meta = {}, ...rest }) {
			state.list = {
				rest,
				data,
				meta: {
					...meta,
					currentPage: meta.current_page,
					lastPage: meta.last_page,
					total: numberFormat(meta.total),
				},
				isLoading: false,
			};
		},
		[GET_SEARCH_SYNONYMS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_SEARCH_SYNONYM_REQUEST](state) {
			state.create.isSubmitting = true;
		},
		[CREATE_SEARCH_SYNONYM_SUCCESS](state) {
			state.create.isSubmitting = false;
		},
		[CREATE_SEARCH_SYNONYM_FAILURE](state) {
			state.create.isSubmitting = false;
		},
		[DELETE_SEARCH_SYNONYM_REQUEST](state) {
			state.delete.isSubmitting = true;
		},
		[DELETE_SEARCH_SYNONYM_SUCCESS](state) {
			state.delete.isSubmitting = false;
		},
		[DELETE_SEARCH_SYNONYM_FAILURE](state) {
			state.delete.isSubmitting = false;
		},
	},
	actions: {
		async getSearchSynonyms({ commit }, query) {
			try {
				commit(GET_SEARCH_SYNONYMS_REQUEST, query);
				const { data } = await getSearchSynonymsAPI(query);

				commit(GET_SEARCH_SYNONYMS_SUCCESS, data);
			} catch (error) {
				commit(GET_SEARCH_SYNONYMS_FAILURE);
			}
		},
		async createSearchSynonym({ commit, dispatch }, keywords) {
			try {
				commit(CREATE_SEARCH_SYNONYM_REQUEST);
				await createSearchSynonymAPI(keywords);
				commit(CREATE_SEARCH_SYNONYM_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('page.searchSynonims.create.successMessage'),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_SEARCH_SYNONYM_FAILURE);
				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async deleteSearchSynonym({ commit, dispatch }, id) {
			try {
				commit(DELETE_SEARCH_SYNONYM_REQUEST);
				await deleteSearchSynonymAPI(id);
				commit(DELETE_SEARCH_SYNONYM_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageDelete', { type: 'Search synonyms' }),
						header: i18n.t('global.successMessageTitleDelete'),
					},
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_SEARCH_SYNONYM_FAILURE);
				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);
			}
		},
	},
};
