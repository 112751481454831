import {
	getAttributeKeysAPI,
	createAttributeKeyAPI,
	deleteAttributeKeyAPI,
} from '@/services/api/attributeKeys.api';
import {
	GET_ATTRIBUTE_KEYS_REQUEST,
	GET_ATTRIBUTE_KEYS_SUCCESS,
	GET_ATTRIBUTE_KEYS_FAILURE,
	CREATE_ATTRIBUTE_KEY_REQUEST,
	CREATE_ATTRIBUTE_KEY_SUCCESS,
	CREATE_ATTRIBUTE_KEY_FAILURE,
	RESET_ERROR_CREATE_ATTRIBUTE_KEY,
	DELETE_ATTRIBUTE_KEY_REQUEST,
	DELETE_ATTRIBUTE_KEY_SUCCESS,
	DELETE_ATTRIBUTE_KEY_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import { transformedAttributeKeyList } from '../../assets/js/transform/attributeKeys';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
				from: null,
				perPage: null,
				to: null,
				total: '0',
				path: '',
			},
		},
		create: {
			isSubmitting: false,
			isErrorDuplicateName: false,
		},
	},
	getters: {
		attributeList(state) {
			return state.list.data;
		},
	},
	mutations: {
		[GET_ATTRIBUTE_KEYS_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...transformedMeta(state.list.meta),
					currentPage: page,
				},
			};
		},
		[GET_ATTRIBUTE_KEYS_SUCCESS](state, { data, meta }) {
			state.list = {
				data,
				meta: transformedMeta(meta),
				isLoading: false,
			};
		},
		[GET_ATTRIBUTE_KEYS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_ATTRIBUTE_KEY_REQUEST](state) {
			state.create = {
				...state.create,
				isSubmitting: true,
			};
		},
		[CREATE_ATTRIBUTE_KEY_SUCCESS](state) {
			state.create = {
				...state.create,
				isSubmitting: false,
			};
		},
		[CREATE_ATTRIBUTE_KEY_FAILURE](state, isErrorDuplicateName = false) {
			state.create = {
				...state.create,
				isSubmitting: false,
				isErrorDuplicateName,
			};
		},
		[RESET_ERROR_CREATE_ATTRIBUTE_KEY](state) {
			state.create = {
				...state.create,
				isErrorDuplicateName: false,
			};
		},
		[DELETE_ATTRIBUTE_KEY_REQUEST]() {},
		[DELETE_ATTRIBUTE_KEY_SUCCESS]() {},
		[DELETE_ATTRIBUTE_KEY_FAILURE]() {},
	},
	actions: {
		async getAttributeKeys({ commit }, query) {
			try {
				commit(GET_ATTRIBUTE_KEYS_REQUEST, query);
				const response = await getAttributeKeysAPI(query);
				const data = transformedAttributeKeyList(pathOr([], ['data', 'data'])(response));
				const meta = pathOr({}, ['data', 'meta'])(response);
				commit(GET_ATTRIBUTE_KEYS_SUCCESS, { data, meta });
			} catch (error) {
				commit(GET_ATTRIBUTE_KEYS_FAILURE);
			}
		},
		async createAttributeKey({ commit, dispatch }, name) {
			try {
				commit(CREATE_ATTRIBUTE_KEY_REQUEST);
				await createAttributeKeyAPI(name);
				commit(CREATE_ATTRIBUTE_KEY_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('page.attributeKeys.create.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const errorDuplicateKey = 'duplicate_attribute_key_name';
				const isErrorDuplicateName = pathOr(false, ['response', 'data', 'error'])(error) === errorDuplicateKey;

				commit(CREATE_ATTRIBUTE_KEY_FAILURE, isErrorDuplicateName);

				if (!isErrorDuplicateName) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					}, { root: true });
				}
			}
		},
		resetErrorCreateAttributeKey({ commit }) {
			commit(RESET_ERROR_CREATE_ATTRIBUTE_KEY);
		},
		async deleteAttributeKey({ commit, dispatch }, attributeKeyId) {
			try {
				commit(DELETE_ATTRIBUTE_KEY_REQUEST);
				await deleteAttributeKeyAPI(attributeKeyId);
				commit(DELETE_ATTRIBUTE_KEY_SUCCESS);
			} catch (error) {
				commit(DELETE_ATTRIBUTE_KEY_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
