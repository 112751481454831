import {
	getAbandonedCartsAPI,
} from '../../services/api/abandonedCarts.api';
import {
	GET_ABANDONED_CART_REQUEST,
	GET_ABANDONED_CART_SUCCESS,
	GET_ABANDONED_CART_FAILURE,
} from '../types';

import { transformedAbandonedCarts } from '../../assets/js/transform/abandonedCarts';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: [],
		},
	},
	mutations: {
		[GET_ABANDONED_CART_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = [];
			state.list.meta = [];
			state.list.error = false;
		},
		[GET_ABANDONED_CART_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedAbandonedCarts(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_ABANDONED_CART_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
	},

	actions: {
		async getAbandonedCarts({ commit }, query) {
			try {
				commit(GET_ABANDONED_CART_REQUEST, query);
				const { data } = await getAbandonedCartsAPI(query);
				commit(GET_ABANDONED_CART_SUCCESS, data);
			} catch (error) {
				commit(GET_ABANDONED_CART_FAILURE);
				throw error;
			}
		},
	},
};
