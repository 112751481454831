import { transformedProductList } from './products';
import { transformedPromotion } from './promotions';
import { SEARCH_WEIGHT_TYPES } from '../../../enums/searchWeights';
import { pathOr, priceFromAPI } from '../helpers';

/**
 * transformedSearchWeight
 * @param {Object} search score object
 * @returns {Object} new object with transformed
 */
export const transformedSearchWeight = (data = {}) => {
	let items = data.items || [];
	const type = data.type || null;

	if (type === SEARCH_WEIGHT_TYPES.product) {
		const products = transformedProductList(items);
		// No need to get Array of Product, but simple as sku string array
		items = (products || []).map(({ sku }) => sku);
	} else if (type === SEARCH_WEIGHT_TYPES.promotion) {
		items = items.map(transformedPromotion);
	} else if (type === SEARCH_WEIGHT_TYPES.price) {
		items = {
			condition: pathOr(null, ['items', 'condition'])(data),
			price: priceFromAPI(pathOr(0, ['items', 'price'])(data)),
		};
	}

	return {
		id: data.id || null,
		name: data.name || null,
		type,
		score: data.score || 0,
		status: data.sync_status,
		items,
	};
};

/**
 * transformedSearchWeightList
 * @param {Array} data
 * @returns {Array} new array with transformed
 */
export const transformedSearchWeightList = (data = []) => {
	const item = data.map((searchItem = {}) => transformedSearchWeight(searchItem));

	return item;
};
