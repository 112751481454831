import { i18n } from '@/i18n';

import {
	getSubHeadersAPI,
	getSubHeaderAPI,
	createSubHeaderAPI,
	putSubHeaderAPI,
	deleteSubHeaderAPI,
} from '../../services/api/subHeader.api';

import { transformedMeta } from '../../assets/js/transform/meta';

import {
	// GET List
	GET_SUB_HEADERS_REQUEST,
	GET_SUB_HEADERS_SUCCESS,
	GET_SUB_HEADERS_FAILURE,

	// GET
	GET_SUB_HEADER_REQUEST,
	GET_SUB_HEADER_SUCCESS,
	GET_SUB_HEADER_FAILURE,

	// CREATE
	CREATE_SUB_HEADERS_REQUEST,
	CREATE_SUB_HEADERS_SUCCESS,
	CREATE_SUB_HEADERS_FAILURE,

	// UPDATE
	UPDATE_SUB_HEADERS_REQUEST,
	UPDATE_SUB_HEADERS_SUCCESS,
	UPDATE_SUB_HEADERS_FAILURE,

	// DELETE
	DELETE_SUB_HEADER_REQUEST,
	DELETE_SUB_HEADER_SUCCESS,
	DELETE_SUB_HEADER_FAILURE,
} from '../types';

import { transformedSubHeader } from '../../assets/js/transform/subHeaders';

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		create: {
			isLoading: false,
			isSuccess: false,
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			isSuccess: false,
			data: {},
		},
		delete: {
			isLoading: false,
			isSuccess: false,
		},
	},

	getters: {
		getSubHeaderList(state) {
			return state.list.data;
		},
		getSubHeaderMeta(state) {
			return state.list.meta;
		},
		getSubHeaderDetail(state) {
			return state.edit.data;
		},
	},

	mutations: {
		// GET List
		[GET_SUB_HEADERS_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_SUB_HEADERS_SUCCESS](state, { data = [], meta }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
				meta,
			};
		},
		[GET_SUB_HEADERS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},

		// GET
		[GET_SUB_HEADER_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_SUB_HEADER_SUCCESS](state, { data }) {
			state.edit = {
				...state.edit,
				isLoading: false,
				data,
			};
		},
		[GET_SUB_HEADER_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},

		// CREATE
		[CREATE_SUB_HEADERS_REQUEST](state) {
			state.create = {
				...state.create,
				isLoading: true,
				isSuccess: false,
			};
		},
		[CREATE_SUB_HEADERS_SUCCESS](state) {
			state.create = {
				...state.create,
				isLoading: false,
				isSuccess: true,
			};
		},
		[CREATE_SUB_HEADERS_FAILURE](state) {
			state.create = {
				...state.create,
				isLoading: false,
				isSuccess: false,
			};
		},

		// UPDATE
		[UPDATE_SUB_HEADERS_REQUEST](state) {
			state.edit = {
				isSuccess: false,
				isUpdating: true,
			};
		},
		[UPDATE_SUB_HEADERS_SUCCESS](state) {
			state.edit = {
				isSuccess: true,
				isUpdating: false,
			};
		},
		[UPDATE_SUB_HEADERS_FAILURE](state) {
			state.edit = {
				isSuccess: false,
				isUpdating: false,
			};
		},

		// DELETE
		[DELETE_SUB_HEADER_REQUEST](state) {
			state.delete = {
				...state.delete,
				isLoading: true,
				isSuccess: false,
			};
		},
		[DELETE_SUB_HEADER_SUCCESS](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
				isSuccess: true,
			};
		},
		[DELETE_SUB_HEADER_FAILURE](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
				isSuccess: false,
			};
		},
	},
	actions: {
		async getSubHeaders({ commit }, params = {}) {
			try {
				commit(GET_SUB_HEADERS_REQUEST);
				const { data } = await getSubHeadersAPI(params);
				commit(GET_SUB_HEADERS_SUCCESS, {
					data: data.data.map(transformedSubHeader),
					meta: transformedMeta(data.meta),
				});
			} catch (error) {
				commit(GET_SUB_HEADERS_FAILURE);
			}
		},
		async getSubHeader({ commit }, { id }) {
			try {
				commit(GET_SUB_HEADER_REQUEST);
				const { data } = await getSubHeaderAPI(id);
				commit(GET_SUB_HEADER_SUCCESS, {
					data: transformedSubHeader(data.data),
				});
			} catch (error) {
				commit(GET_SUB_HEADER_FAILURE);
			}
		},
		async createSubHeader({ commit, dispatch }, { params }) {
			try {
				commit(CREATE_SUB_HEADERS_REQUEST);
				await createSubHeaderAPI(params);
				commit(CREATE_SUB_HEADERS_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageCreate', { type: 'SubHeader' }),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_SUB_HEADERS_FAILURE, error);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},
		async updateSubHeader({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_SUB_HEADERS_REQUEST);
				const { data } = await putSubHeaderAPI(id, params);
				commit(UPDATE_SUB_HEADERS_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_SUB_HEADERS_FAILURE, error);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });

				throw error;
			}
		},
		async deleteSubHeader({ commit, dispatch }, id) {
			try {
				commit(DELETE_SUB_HEADER_REQUEST);

				await deleteSubHeaderAPI(id);
				commit(DELETE_SUB_HEADER_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageDelete', { type: 'SubHeader' }),
						header: i18n.t('global.successMessageTitleDelete'),
					},
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_SUB_HEADER_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleDelete'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
