import {
	getBundleSetsAPI,
	getBundleSetAPI,
	postBundleSetAPI,
	updateBundleSetAPI,
	deleteBundleSetAPI,
} from '../../services/api/bundleSet.api';
import {
	GET_BUNDLE_SETS_REQUEST,
	GET_BUNDLE_SETS_SUCCESS,
	GET_BUNDLE_SETS_FAILURE,
	CREATE_BUNDLE_SET_REQUEST,
	CREATE_BUNDLE_SET_SUCCESS,
	CREATE_BUNDLE_SET_FAILURE,
	GET_BUNDLE_SET_REQUEST,
	GET_BUNDLE_SET_SUCCESS,
	GET_BUNDLE_SET_FAILURE,
	UPDATE_BUNDLE_SET_REQUEST,
	UPDATE_BUNDLE_SET_SUCCESS,
	UPDATE_BUNDLE_SET_FAILURE,
	DELETE_BUNDLE_SET_REQUEST,
	DELETE_BUNDLE_SET_SUCCESS,
	DELETE_BUNDLE_SET_FAILURE,
} from '../types';
import { i18n } from '../../i18n';

import { transformedBundleSetList, transformedBundleSet } from '../../assets/js/transform/bundleSet';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		list: {
			isLoading: false,
			data: [],
			meta: [],
			error: false,
		},
	},
	getters: {
		getBundleGeneralInfo(state) {
			return transformedBundleSet(state.edit.data);
		},
	},
	mutations: {
		[GET_BUNDLE_SETS_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformedBundleSetList();
			state.list.meta = transformedMeta();
			state.list.error = false;
		},
		[GET_BUNDLE_SETS_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedBundleSetList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_BUNDLE_SETS_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		[CREATE_BUNDLE_SET_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_BUNDLE_SET_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_BUNDLE_SET_FAILURE](state) {
			state.create.isCreating = false;
		},
		[GET_BUNDLE_SET_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_BUNDLE_SET_SUCCESS](state, { data }) {
			state.edit.data = data;
			state.edit.isLoading = false;
		},
		[GET_BUNDLE_SET_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[UPDATE_BUNDLE_SET_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_BUNDLE_SET_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_BUNDLE_SET_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_BUNDLE_SET_REQUEST]() { },
		[DELETE_BUNDLE_SET_SUCCESS]() { },
		[DELETE_BUNDLE_SET_FAILURE]() { },
	},

	actions: {
		async getBundleSets({ commit }, query) {
			try {
				commit(GET_BUNDLE_SETS_REQUEST, query);
				const { data } = await getBundleSetsAPI(query);
				commit(GET_BUNDLE_SETS_SUCCESS, data);
			} catch (error) {
				commit(GET_BUNDLE_SETS_FAILURE);
			}
		},
		async getBundleSet({ commit }, id) {
			try {
				commit(GET_BUNDLE_SET_REQUEST);
				const { data } = await getBundleSetAPI(id);
				commit(GET_BUNDLE_SET_SUCCESS, data);
			} catch (error) {
				commit(GET_BUNDLE_SET_FAILURE);
			}
		},
		async createBundleSet({ commit, dispatch }, params) {
			try {
				commit(CREATE_BUNDLE_SET_REQUEST, params);
				await postBundleSetAPI(params);
				commit(CREATE_BUNDLE_SET_SUCCESS);

				dispatch('toast/showCreateSuccessToast', 'bundle set', { root: true });
			} catch (error) {
				commit(CREATE_BUNDLE_SET_FAILURE);
				if (error?.response?.status === 400) {
					// display specific error message toast
					dispatch(
						'toast/showToast',
						{
							header: i18n.t('global.errorMessageTitleCreate'),
							content: error?.response?.data?.message ?? '-',
							type: 'danger',
						},
						{ root: true },
					);
				} else {
					dispatch('toast/showCreateErrorToast', null, { root: true });
				}
				throw error;
			}
		},
		async updateBundleSet({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_BUNDLE_SET_REQUEST);
				const { data } = await updateBundleSetAPI(id, params);
				commit(UPDATE_BUNDLE_SET_SUCCESS, data);
				dispatch('getBundleSet', id);
				dispatch('toast/showUpdateSuccessToast', null, { root: true });
			} catch (error) {
				commit(UPDATE_BUNDLE_SET_FAILURE);
				if (error?.response?.status === 400) {
					// display specific error message toast
					dispatch(
						'toast/showToast',
						{
							header: i18n.t('global.errorMessageTitleEdit'),
							content: error?.response?.data?.message ?? '-',
							type: 'danger',
						},
						{ root: true },
					);
				} else {
					dispatch('toast/showUpdateErrorToast', null, { root: true });
				}
				throw error;
			}
		},
		async deleteBundleSet({ commit, dispatch }, id) {
			try {
				commit(DELETE_BUNDLE_SET_REQUEST);
				await deleteBundleSetAPI(id);
				commit(DELETE_BUNDLE_SET_SUCCESS);
				dispatch('toast/showDeleteSuccessToast', 'Bundle set', { root: true });
			} catch (error) {
				commit(DELETE_BUNDLE_SET_FAILURE);
				dispatch('toast/showDeleteErrorToast', null, { root: true });
				throw error;
			}
		},
	},
};
