import httpClient from './httpClient';

export const SHIPPING_METHOD_ENDPOINT = '/backoffice/shipping-methods';
export const SHIPPING_RATE_ENDPOINT = '/backoffice/rates';
export const ADDITIONAL_FEES_ENDPOINT = '/backoffice/additional-fees';

export const getShippingMethodListAPI = () => httpClient.get(SHIPPING_METHOD_ENDPOINT);
export const getShippingMethodAPI = (id) => httpClient.get(`${SHIPPING_METHOD_ENDPOINT}/${id}`);
export const updateShippingMethodAPI = (id, params) => httpClient.put(`${SHIPPING_METHOD_ENDPOINT}/${id}`, params);

// Shipping Rate
export const getShippingRatesAPI = (id) => httpClient.get(`${SHIPPING_METHOD_ENDPOINT}/${id}/rates`);
export const getShippingRateAPI = (rateId) => httpClient.get(`${SHIPPING_RATE_ENDPOINT}/${rateId}`);
export const postShippingRateAPI = (id, params) => httpClient.post(`${SHIPPING_METHOD_ENDPOINT}/${id}/rates`, params);
export const updateShippingRateAPI = (id, params) => httpClient.put(`${SHIPPING_RATE_ENDPOINT}/${id}`, params);
export const deleteShippingRateAPI = async (id) => httpClient.delete(`${SHIPPING_RATE_ENDPOINT}/${id}`);

// Additional Fee
export const getShippingAdditionalFeeAPI = (id, type) => httpClient.get(`${SHIPPING_METHOD_ENDPOINT}/${id}/additional-fees?type=${type}`);
export const postAdditionalFeeAPI = (id, params) => httpClient.post(`${SHIPPING_METHOD_ENDPOINT}/${id}/additional-fees`, params);
export const getAdditionalFeeAPI = (id) => httpClient.get(`${ADDITIONAL_FEES_ENDPOINT}/${id}`);
export const updateAdditionalFeeAPI = (id, params) => httpClient.put(`${ADDITIONAL_FEES_ENDPOINT}/${id}`, params);
export const deleteAdditionalFeeAPI = (id) => httpClient.delete(`${ADDITIONAL_FEES_ENDPOINT}/${id}`);
