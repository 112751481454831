export const CATEGORY_LEVEL = Object.freeze({
	ONE: 'ONE',
	TWO: 'TWO',
	THREE: 'THREE',
});

export const CATEGORY_ATTRIBUTE_STATUS_OPTIONS = [
	{ value: null, name: 'All status' },
	{ value: '1', name: 'On' },
	{ value: '0', name: 'Off' },
];

export const CATEGORY_LEVEL_OPTIONS = [
	{ value: CATEGORY_LEVEL.ONE, label: 'Level 1' },
	{ value: CATEGORY_LEVEL.TWO, label: 'Level 2' },
	{ value: CATEGORY_LEVEL.THREE, label: 'Level 3' },
];

export const CONFIRM_DELETE_TEXT = 'REMOVE';

export const TABLE_ATTRIBUTE_KEY_FIELDS = [
	{ key: 'name', label: 'Attribute', class: 'col-name' },
	{ key: 'data', label: 'Filter visibility', class: 'col-id text-right w-200' },
];

export const MAPPING_SWITCH_VALUE_TO_STATUS = {
	[true]: 'on',
	[false]: 'off',
};

export const COMPARE_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id th-mw-30' },
	{ key: 'name', label: 'Name', class: 'col-name th-mw-250' },
	{ key: 'period', label: 'Period', class: 'col-period th-mw-80' },
	{ key: 'delete', label: 'Delete', class: 'col-delete th-mw-80' },
];

export const CATEGORY_CONTENT_TYPE = Object.freeze({
	CATEGORY: 'category',
	PRODUCT: 'product',
});

export const CATEGORY_DISPLAY_TYPE = Object.freeze({
	LOB: 'lob',
	PLP: 'plp',
	SEARCH_LIST: 'search_list',
	SEARCH_CATEGORY: 'search_category',
});

export const CATEGORY_DISPLAY_TYPE_OPTIONS = [
	{ value: CATEGORY_DISPLAY_TYPE.SEARCH_LIST, label: 'Search Page' },
	{ value: CATEGORY_DISPLAY_TYPE.SEARCH_CATEGORY, label: 'Search Category' },
	{ value: CATEGORY_DISPLAY_TYPE.LOB, label: 'LOB' },
	{ value: CATEGORY_DISPLAY_TYPE.PLP, label: 'PLP' },
];

export const CATEGORY_CONTENT_DESTINATION_TYPE = Object.freeze({
	PDP: 'pdp',
	LOB: 'lob',
	PLP: 'plp',
	SEARCH_PAGE: 'search',
	SEARCH_CATEGORY: 'search_category',
	CUSTOM_LINK: 'custom_link',
});

export const CATEGORY_CONTENT_PRODUCT_DESTINATION_OPTIONS = [
	{ value: CATEGORY_CONTENT_DESTINATION_TYPE.PDP, label: 'PDP' },
	{ value: CATEGORY_CONTENT_DESTINATION_TYPE.SEARCH_PAGE, label: 'Search Page' },
	{ value: CATEGORY_CONTENT_DESTINATION_TYPE.CUSTOM_LINK, label: 'Custom Link' },
];

export const CATEGORY_CONTENT_CATEGORY_DESTINATION_OPTIONS = [
	{ value: CATEGORY_CONTENT_DESTINATION_TYPE.LOB, label: 'LOB' },
	{ value: CATEGORY_CONTENT_DESTINATION_TYPE.PLP, label: 'PLP' },
	{ value: CATEGORY_CONTENT_DESTINATION_TYPE.SEARCH_PAGE, label: 'Search Page' },
	{ value: CATEGORY_CONTENT_DESTINATION_TYPE.SEARCH_CATEGORY, label: 'Search Category' },
	{ value: CATEGORY_CONTENT_DESTINATION_TYPE.CUSTOM_LINK, label: 'Custom Link' },
];

/**
 * Enum for show lowest price type
 * @readonly
 * @enum {String}
 */
export const SHOW_LOWEST_PRICE_TYPE = Object.freeze({
	ABOVE_CONTENT: 'above_content',
	PREPEND_SHORT_DESCRIPTION: 'prepend_short_description',
	APPEND_SHORT_DESCRIPTION: 'append_short_description',
	PREPEND_DESCRIPTION: 'prepend_description',
	APPEND_DESCRIPTION: 'append_description',
	BELOW_CONTENT: 'below_content',
});

export const SHOW_LOWEST_PRICE_TYPE_OPTIONS = [
	{ value: SHOW_LOWEST_PRICE_TYPE.ABOVE_CONTENT, label: 'Show Lowest Price above short description' },
	{ value: SHOW_LOWEST_PRICE_TYPE.PREPEND_SHORT_DESCRIPTION, label: 'Show Lowest Price prepend short description' },
	{ value: SHOW_LOWEST_PRICE_TYPE.APPEND_SHORT_DESCRIPTION, label: 'Show Lowest Price append short description' },
	{ value: SHOW_LOWEST_PRICE_TYPE.PREPEND_DESCRIPTION, label: 'Show Lowest Price prepend description' },
	{ value: SHOW_LOWEST_PRICE_TYPE.APPEND_DESCRIPTION, label: 'Show Lowest Price append description' },
	{ value: SHOW_LOWEST_PRICE_TYPE.BELOW_CONTENT, label: 'Show Lowest Price below description' },
];

export const CATEGORY_REFS = {
	LEVEL: ['category-level'],
	GENERAL_INFO: ['category-general-info'],
	ICON: ['category-icon'],
	PRODUCT_COMPARE: ['category-product-compare'],
	SLIDE_BANNER: ['category-slide-banner'],
	PRODUCT_RANK: ['category-product-rank'],
	FILTER: ['category-filter'],
	PRODUCT_FAMILY: ['category-product-family'],
	// BADGE_LABEL: ['category-badge-label'],
	CATEGORY_REDIRECTION: ['category-category-redirection'],
	CATEGORY_CONTENT: ['category-category-content'],
	CONTENT: ['category-content'],
	SEO: ['category-seo'],
};

export const CATEGORY_REFS_VISIBLE = {
	[CATEGORY_REFS.LEVEL]: null,
	[CATEGORY_REFS.GENERAL_INFO]: null,
	[CATEGORY_REFS.ICON]: null,
	[CATEGORY_REFS.PRODUCT_COMPARE]: null,
	[CATEGORY_REFS.SLIDE_BANNER]: CATEGORY_LEVEL.ONE, // show only level 1
	[CATEGORY_REFS.PRODUCT_RANK]: null,
	[CATEGORY_REFS.FILTER]: null,
	[CATEGORY_REFS.PRODUCT_FAMILY]: null,
	// [CATEGORY_REFS.BADGE_LABEL]: null,
	[CATEGORY_REFS.CATEGORY_REDIRECTION]: null,
	[CATEGORY_REFS.CATEGORY_CONTENT]: null,
	[CATEGORY_REFS.CONTENT]: null,
	[CATEGORY_REFS.SEO]: null,
};

export const CATEGORY_REFS_TITLE = {
	[CATEGORY_REFS.LEVEL]: 'Category Level',
	[CATEGORY_REFS.GENERAL_INFO]: 'General Info',
	[CATEGORY_REFS.ICON]: 'Category Icon',
	[CATEGORY_REFS.PRODUCT_COMPARE]: 'Product Compare',
	[CATEGORY_REFS.SLIDE_BANNER]: 'Category Slide Banner',
	[CATEGORY_REFS.PRODUCT_RANK]: 'Product Ranking',
	[CATEGORY_REFS.FILTER]: 'Filters',
	[CATEGORY_REFS.PRODUCT_FAMILY]: 'Product Families',
	// [CATEGORY_REFS.BADGE_LABEL]: 'Badge Labels',
	[CATEGORY_REFS.CATEGORY_REDIRECTION]: 'Category Redirection',
	[CATEGORY_REFS.CATEGORY_CONTENT]: 'Category Content',
	[CATEGORY_REFS.CONTENT]: 'Contents',
	[CATEGORY_REFS.SEO]: 'SEO',
};

