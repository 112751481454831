export const BUNDLE_SET_TABLE_FIELDS = Object.freeze([
	{ key: 'id', label: 'ID', class: 'col-id th-mw-50' },
	{ key: 'name', label: 'Bundle name', class: 'col-name th-mw-250' },
	{ key: 'status', label: 'Status', class: 'col-status th-mw-80' },
	// TODO: un-comment when has to use total skus
	// { key: 'skus', label: 'Total SKUs', class: 'col-skus th-mw-80' },
	{ key: 'startAt', label: 'Start date', class: 'col-date th-mw-150' },
	{ key: 'endAt', label: 'End date', class: 'col-date th-mw-150' },
	{ key: 'period', label: 'Period', class: 'col-period th-mw-80' },
	{ key: 'duplicateId', label: '', class: 'col-duplicate-id th-mw-100' },
]);

export const BUNDLE_SET_STATUSES = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const BUNDLE_SET_STATUS_LABELS = {
	[BUNDLE_SET_STATUSES.ACTIVE]: 'Active',
	[BUNDLE_SET_STATUSES.INACTIVE]: 'Inactive',
};

// bundle sets status option, use with radio groups
export const BUNDLE_SET_STATUS_OPTIONS = [
	{
		value: BUNDLE_SET_STATUSES.ACTIVE,
		label: BUNDLE_SET_STATUS_LABELS[BUNDLE_SET_STATUSES.ACTIVE],
	},
	{
		value: BUNDLE_SET_STATUSES.INACTIVE,
		label: BUNDLE_SET_STATUS_LABELS[BUNDLE_SET_STATUSES.INACTIVE],
	},
];

export const BUNDLE_SET_LABEL_TITLE_TABS = {
	GENERAL_INFO: 'General info',
	CONDITION: 'Condition',
};

export const BUNDLE_SET_PERIODS_STATUSES = {
	ON_GOING: 'ON_GOING',
	SCHEDULED: 'SCHEDULE',
	EXPIRED: 'EXPIRED',
};

export const BUNDLE_SET_PERIODS_OPTIONS = [
	{ name: 'All statuses', value: null },
	{ name: 'Ongoing', value: BUNDLE_SET_PERIODS_STATUSES.ON_GOING },
	{ name: 'Scheduled', value: BUNDLE_SET_PERIODS_STATUSES.SCHEDULED },
	{ name: 'Ended', value: BUNDLE_SET_PERIODS_STATUSES.EXPIRED },
];

// mapping bundle title, use with CBadge component
export const BUNDLE_SET_TITLE = {
	STATUS: {
		[BUNDLE_SET_STATUSES.ACTIVE]: BUNDLE_SET_STATUS_LABELS[BUNDLE_SET_STATUSES.ACTIVE],
		[BUNDLE_SET_STATUSES.INACTIVE]: BUNDLE_SET_STATUS_LABELS[BUNDLE_SET_STATUSES.INACTIVE],
	},
	PERIODS_STATUSES: {
		[BUNDLE_SET_PERIODS_STATUSES.ON_GOING]: 'Ongoing',
		[BUNDLE_SET_PERIODS_STATUSES.SCHEDULED]: 'Scheduled',
		[BUNDLE_SET_PERIODS_STATUSES.EXPIRED]: 'Ended',
	},
};

// mapping bundle color, use with CBadge property components
export const BUNDLE_SET_COLOR = {
	STATUS: {
		[BUNDLE_SET_STATUSES.ACTIVE]: 'active',
		[BUNDLE_SET_STATUSES.INACTIVE]: 'inactive',
	},
	PERIODS_STATUSES: {
		[BUNDLE_SET_PERIODS_STATUSES.ON_GOING]: 'orange',
		[BUNDLE_SET_PERIODS_STATUSES.SCHEDULED]: 'info',
		[BUNDLE_SET_PERIODS_STATUSES.EXPIRED]: 'alto',
	},
};

// Bundle set condition rule key
export const BUNDLE_SET_RULE_KEY = Object.freeze({
	TOTAL_GROUP_QUANTITY: 'quantity',
});

export const BUNDLE_SET_RULE_KEY_LABEL = Object.freeze({
	[BUNDLE_SET_RULE_KEY.TOTAL_GROUP_QUANTITY]: 'Total group quantity',
});

export const BUNDLE_SET_RULE_KEY_OPTIONS = Object.freeze([
	{
		value: BUNDLE_SET_RULE_KEY.TOTAL_GROUP_QUANTITY,
		label: BUNDLE_SET_RULE_KEY_LABEL[BUNDLE_SET_RULE_KEY.TOTAL_GROUP_QUANTITY],
	},
]);

// Bundle set condition rule rule
export const BUNDLE_SET_RULE_RULE = Object.freeze({
	IS: 'is',
});

export const BUNDLE_SET_RULE_RULE_LABEL = Object.freeze({
	[BUNDLE_SET_RULE_RULE.IS]: 'is',
});

export const BUNDLE_SET_RULE_RULE_OPTIONS = Object.freeze([
	{
		value: BUNDLE_SET_RULE_RULE.IS,
		label: BUNDLE_SET_RULE_RULE_LABEL[BUNDLE_SET_RULE_RULE.IS],
	},
]);

// Bundle set display type
export const BUNDLE_SET_DISPLAY_TYPE = Object.freeze({
	PRIMARY_SKU: 'primary',
	SPECIFIC_DISPLAY: 'specific',
});

export const BUNDLE_SET_DISPLAY_TYPE_LABEL = Object.freeze({
	[BUNDLE_SET_DISPLAY_TYPE.PRIMARY_SKU]: 'Primary SKU',
	[BUNDLE_SET_DISPLAY_TYPE.SPECIFIC_DISPLAY]: 'Specific display',
});

export const BUNDLE_SET_DISPLAY_TYPE_OPTIONS = Object.freeze([
	{
		value: BUNDLE_SET_DISPLAY_TYPE.PRIMARY_SKU,
		label: BUNDLE_SET_DISPLAY_TYPE_LABEL[BUNDLE_SET_DISPLAY_TYPE.PRIMARY_SKU],
	},
	{
		value: BUNDLE_SET_DISPLAY_TYPE.SPECIFIC_DISPLAY,
		label: BUNDLE_SET_DISPLAY_TYPE_LABEL[BUNDLE_SET_DISPLAY_TYPE.SPECIFIC_DISPLAY],
		props: { disabled: true }, // TODO: Remove this line when ready to use specific display
	},
]);

// Condition key
export const CONDITION_KEY = Object.freeze({
	SKU: 'SKU',
});

export const CONDITION_KEY_LABEL = Object.freeze({
	[CONDITION_KEY.SKU]: 'SKU',
});

export const CONDITION_KEY_OPTIONS = Object.freeze([
	{
		value: CONDITION_KEY.SKU,
		label: CONDITION_KEY_LABEL[CONDITION_KEY.SKU],
	},
]);

// Condition type
export const CONDITION_TYPE = Object.freeze({
	IS_ONE_OF: 'IS_ONE_OF',
});

export const CONDITION_TYPE_LABEL = Object.freeze({
	[CONDITION_TYPE.IS_ONE_OF]: 'is one of',
});

export const CONDITION_TYPE_OPTIONS = Object.freeze({
	[CONDITION_KEY.SKU]: [
		{
			value: CONDITION_TYPE.IS_ONE_OF,
			label: CONDITION_TYPE_LABEL[CONDITION_TYPE.IS_ONE_OF],
		},
	],
});

// Bundle set condition rule key
export const BUNDLE_SETTING_BADGE_OPTIONS = Object.freeze([
	{
		label: 'Special (All sku product group)',
		value: true,
	},
	{
		label: 'Not Special (Some sku)',
		value: false,
	},
]);
