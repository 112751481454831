import httpClient from './httpClient';

export const FLASHSALE_ENDPOINT = '/backoffice/flash-sales';

export const getFlashSalesAPI = (params = {}) => httpClient.get(FLASHSALE_ENDPOINT, { params });
export const getFlashSaleAPI = (id) => httpClient.get(`${FLASHSALE_ENDPOINT}/${id}`);
export const postFlashSaleAPI = (params) => httpClient.post(`${FLASHSALE_ENDPOINT}`, params);
export const updateFlashSaleAPI = (id, params) => httpClient.put(`${FLASHSALE_ENDPOINT}/${id}`, params);
export const deleteFlashSaleAPI = (id) => httpClient.delete(`${FLASHSALE_ENDPOINT}/${id}`);
export const postFlashSaleRemarkAPI = (id, params) => httpClient.post(`${FLASHSALE_ENDPOINT}/${id}/remarks`, params);
