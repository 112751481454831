export default {
	create: {
		formLabel: {
			username: 'Username*',
			password: 'Password*',
			firstName: 'First name*',
			lastName: 'Last name',
			status: 'Status',
			userGroup: 'User group',
			email: 'Email address',
			store: 'Store',
			note: 'Note',
		},
		button: {
			submit: 'Create',
			cancel: 'Cancel',
		},
		description: {
			username: 'Allow letters, numbers, and ( - ), ( _ )',
			password: 'Minimum 8 characters',
		},
		validationMessage: {
			username: {
				required: 'Required field',
				exists: 'Username already exists',
				lengthLimit: 'Field Length: 4 - 255 characters',
			},
			password: {
				required: 'Required field',
				lengthLimit: 'Field Length: 8 - 255 characters',
			},
			email: {
				required: 'Required field',
				exists: 'Email already exists',
				lengthLimit: 'Field Length: Maximum 255 characters',
				invalidFormat: 'Invalid email',
			},
			firstName: {
				required: 'Required field',
				lengthLimit: 'Field Length: 1 - 35 characters',
			},
			lastName: {
				lengthLimit: 'Field Length: 0 - 35 characters',
			},
			store: {
				lengthLimit: 'Field Length: 0 - 50 characters',
			},
			note: {
				lengthLimit: 'Field Length: 0 - 150 characters',
			},

		},
	},
};
