import { ROUTE_NAME } from '../enums/route';

export const COMPONENT_LIST = {
	BANNER_GROUP_LIST: 'BannerGroupList',
	BANNER_PRODUCT_CATEGORY: 'BannerProductCategory',
	CONTENT_BLOCK_LIST: 'ContentBlockList',
};

export const HOMEPAGE_TABS = Object.freeze([
	'ALL_WIDGETS',
	'BANNER_HERO',
	'BANNER_CATEGORY_PRODUCT',
	'BANNER_HIGHLIGHT',
	'BANNER_SMALL',
	'BANNER_SHOP_BY_BRAND',
	'BANNER_SHORTCUT',
	'CONTENT_BLOCK_HIGHLIGHT',
	'CONTENT_BLOCK_TEXT_WITH_IMAGE',
	'CONTENT_BLOCK_STRIPE',
]);

export const HOMEPAGE_GROUP_TYPES = {
	BANNER: 'banner',
	CATEGORY_PRODUCT: 'category_product',
	CONTENT_BLOCK: 'content_block',
};

export const HOMEPAGE_COMPONENTS = Object.freeze({
	ALL_WIDGETS: {
		title: 'All widgets',
		component: 'WidgetList',
		routerTo: ROUTE_NAME.HOMEPAGE,
	},
	BANNER_HERO: {
		title: 'Hero banner',
		component: COMPONENT_LIST.BANNER_GROUP_LIST,
		routerTo: ROUTE_NAME.BANNER_HERO_LIST,
		routerToCreate: ROUTE_NAME.BANNER_HERO_CREATE,
		routerToEdit: ROUTE_NAME.BANNER_HERO_EDIT,
	},
	BANNER_CATEGORY_PRODUCT: {
		title: 'Category product banner',
		component: COMPONENT_LIST.BANNER_PRODUCT_CATEGORY,
		routerTo: ROUTE_NAME.BANNER_PRODUCT_CATEGORY,
	},
	BANNER_HIGHLIGHT: {
		title: 'Highlight banner',
		component: COMPONENT_LIST.BANNER_GROUP_LIST,
		routerTo: ROUTE_NAME.BANNER_HIGHLIGHT_LIST,
		routerToCreate: ROUTE_NAME.BANNER_HIGHLIGHT_CREATE,
		routerToEdit: ROUTE_NAME.BANNER_HIGHLIGHT_EDIT,
	},
	BANNER_SMALL: {
		title: 'Small banner',
		component: COMPONENT_LIST.BANNER_GROUP_LIST,
		routerTo: ROUTE_NAME.BANNER_SMALL_LIST,
		routerToCreate: ROUTE_NAME.BANNER_SMALL_CREATE,
		routerToEdit: ROUTE_NAME.BANNER_SMALL_EDIT,
	},
	BANNER_SHOP_BY_BRAND: {
		title: 'Shop by brand banner',
		component: COMPONENT_LIST.BANNER_GROUP_LIST,
		routerTo: ROUTE_NAME.BANNER_SHOP_BY_BRAND_LIST,
		routerToCreate: ROUTE_NAME.BANNER_SHOP_BY_BRAND_CREATE,
		routerToEdit: ROUTE_NAME.BANNER_SHOP_BY_BRAND_EDIT,
	},
	BANNER_SHORTCUT: {
		title: 'Shortcut banner',
		component: COMPONENT_LIST.BANNER_GROUP_LIST,
		routerTo: ROUTE_NAME.BANNER_SHORTCUT_LIST,
		routerToCreate: ROUTE_NAME.BANNER_SHORTCUT_CREATE,
		routerToEdit: ROUTE_NAME.BANNER_SHORTCUT_EDIT,
	},
	CONTENT_BLOCK_HIGHLIGHT: {
		title: 'Pop content block',
		component: COMPONENT_LIST.CONTENT_BLOCK_LIST,
		routerTo: ROUTE_NAME.CONTENT_BLOCK_HIGHLIGHT_LIST,
		routerToCreate: ROUTE_NAME.CONTENT_BLOCK_HIGHLIGHT_CREATE,
		routerToEdit: ROUTE_NAME.CONTENT_BLOCK_HIGHLIGHT_EDIT,
	},
	CONTENT_BLOCK_TEXT_WITH_IMAGE: {
		title: 'Text with image content block',
		component: COMPONENT_LIST.CONTENT_BLOCK_LIST,
		routerTo: ROUTE_NAME.CONTENT_BLOCK_TEXT_WITH_IMAGE_LIST,
		routerToCreate: ROUTE_NAME.CONTENT_BLOCK_TEXT_WITH_IMAGE_CREATE,
		routerToEdit: ROUTE_NAME.CONTENT_BLOCK_TEXT_WITH_IMAGE_EDIT,
	},
	CONTENT_BLOCK_STRIPE: {
		title: 'Stripe content block',
		component: COMPONENT_LIST.CONTENT_BLOCK_LIST,
		routerTo: ROUTE_NAME.CONTENT_BLOCK_STRIPE_LIST,
		routerToCreate: ROUTE_NAME.CONTENT_BLOCK_STRIPE_CREATE,
		routerToEdit: ROUTE_NAME.CONTENT_BLOCK_STRIPE_EDIT,
	},
});
