import httpClient from './httpClient';

export const WAREHOUSE_ENDPOINT = '/backoffice/branches';
export const WAREHOUSE_TYPES_ENDPOINT = '/backoffice/branch-brand-types';

export const getWarehouseListAPI = (params = {}) => httpClient.get(WAREHOUSE_ENDPOINT, { params });
export const postWarehouseAPI = (params) => httpClient.post(`${WAREHOUSE_ENDPOINT}`, params);
export const getWarehouseAPI = (id) => httpClient.get(`${WAREHOUSE_ENDPOINT}/${id}`);
export const putWarehouseAPI = (id, params) => httpClient.put(`${WAREHOUSE_ENDPOINT}/${id}`, params);
export const getWarehouseTypesAPI = (params = {}) => httpClient.get(WAREHOUSE_TYPES_ENDPOINT, { params });
