<template>
	<div class="">
		<a
			:class="{ 'is-invalid': !isValid }"
			href=""
			class="image-link"
			@click.prevent="handleUploadImageClick"
		>
			<div v-if="!previewImage && !thumbnail" :class="{ 'image-placeholder': !previewImage }">
				<img src="/assets/images/product-placeholder.svg" alt="Add image" class="mb-1">
				{{ title }}
			</div>
			<img
				v-else
				:src="thumbnail || previewImage"
				class="image-preview"
				alt=""
			>
			<CButton
				v-if="thumbnail"
				class="btn-delete-image"
				@click.stop.prevent="handleDelete"
			>
				<CIcon name="cil-trash" />
			</CButton>
		</a>
		<div class="invalid-feedback mb-3">
			{{ $t('global.error.required') }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseInputImage',
	props: {
		isValid: {
			type: Boolean,
			default: true,
		},
		thumbnail: {
			type: String,
			default: null,
		},
		previewImage: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			default: 'Add Logo',
		},
	},
	methods: {
		handleUploadImageClick() {
			this.$emit('onClick');
		},
		handleDelete() {
			this.$emit('onDelete');
		},
	},
};
</script>

<style lang="scss" scoped>
	.image-link {
		position: relative;
		display: block;
		text-decoration: none;
		width: rem(80);
		height: rem(80);

		&.is-invalid {
			.image-placeholder,
			.image-preview {
				border-color: $color-alert;
			}
		}
	}

	.image-placeholder {
		@include typo-helper-text;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: rem(80);
		height: rem(80);
		background-color: $color-gray-100;
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		color: $color-black-45;

		img {
			width: rem(32);
			height: rem(32);
		}
	}

	.image-preview {
		width: rem(80);
		height: rem(80);
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		object-fit: contain;
	}

	.btn-delete-image {
		position: absolute;
		right: rem(4);
		bottom: rem(4);
		width: rem(20);
		height: rem(20);
		box-shadow: none;
		padding: 0;
		border-radius: 2px;
		background-color: $color-black-45;

		svg {
			width: rem(10) !important;
			color: $color-white;
		}
	}
</style>
