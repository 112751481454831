import {
	clearSearchResultCache,
} from '@/services/api/searchResultCaches.api';
import {
	CLEAR_SEARCH_RESULT_CACHE_REQUEST,
	CLEAR_SEARCH_RESULT_CACHE_SUCCESS,
	CLEAR_SEARCH_RESULT_CACHE_FAILURE,
} from '../types';
import { i18n } from '../../i18n';

export default {
	namespaced: true,
	state: {
		isLoading: false,
	},
	mutations: {
		[CLEAR_SEARCH_RESULT_CACHE_REQUEST](state) {
			state.isLoading = true;
		},
		[CLEAR_SEARCH_RESULT_CACHE_SUCCESS](state) {
			state.isLoading = false;
		},
		[CLEAR_SEARCH_RESULT_CACHE_FAILURE](state) {
			state.isLoading = false;
		},
	},
	actions: {
		async clearCacheSearchResult({ commit, dispatch }) {
			try {
				commit(CLEAR_SEARCH_RESULT_CACHE_REQUEST);
				await clearSearchResultCache();
				commit(CLEAR_SEARCH_RESULT_CACHE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageClearCache'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CLEAR_SEARCH_RESULT_CACHE_FAILURE);
				dispatch('toast/showToast', {
					type: 'danger',
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleClearCache'),
				}, { root: true });
			}
		},
	},
};
