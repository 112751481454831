import dayjs from 'dayjs';
import {
	pathOr,
	datetimeFormat,
} from '../helpers';
import { transformedMedia } from '../transform/mediaLibrary';

export const transformedPromotionBannerKey = (promotionBannerKey = {}) => {
	const id = pathOr(0, ['id'])(promotionBannerKey);
	const name = pathOr('', ['name'])(promotionBannerKey);
	const link = pathOr('', ['url'])(promotionBannerKey);
	const pDesktopFile = pathOr([], ['media', 'desktop'])(promotionBannerKey);
	const desktopImageFirst = pDesktopFile && pDesktopFile.length > 0 ? pDesktopFile[0] : null;
	const desktopBanner = desktopImageFirst ? transformedMedia(desktopImageFirst) : null;
	const pMobileFile = pathOr([], ['media', 'mobile'])(promotionBannerKey);
	const mobileImageFirst = pMobileFile && pMobileFile.length > 0 ? pMobileFile[0] : null;
	const mobileBanner = mobileImageFirst ? transformedMedia(mobileImageFirst) : null;
	const isActive = pathOr(false, ['is_active'])(promotionBannerKey);
	const period = pathOr('', ['period'])(promotionBannerKey);
	const startAt = pathOr(null, ['start_at'])(promotionBannerKey);
	const startDate = startAt ? dayjs(startAt).toDate() : null;
	const startTime = startAt ? datetimeFormat(startAt, 'HH:mm') : '00:00';
	const endAt = pathOr(null, ['end_at'])(promotionBannerKey);
	const endDate = endAt ? dayjs(endAt).toDate() : null;
	const endTime = endAt ? datetimeFormat(endAt, 'HH:mm') : '23:59';
	const startDateDisplay = startAt ? datetimeFormat(startAt) : '-';
	const endDateDisplay = endAt ? datetimeFormat(endAt) : '-';

	// for column delete
	const deleteId = id;
	return {
		name,
		id,
		desktopBanner,
		mobileBanner,
		link,
		deleteId,
		isActive,
		period,
		startDate,
		startTime,
		endDate,
		endTime,
		startDateDisplay,
		endDateDisplay,
	};
};

export const transformedPromotionBannerKeyList = (promotionBannerKeys = []) => {
	return promotionBannerKeys.map(transformedPromotionBannerKey);
};
