import {
	priceFormat,
	priceFromAPI,
	numberFormat,
	datetimeFormat,
} from '../helpers';

export const transformedAbandonedCart = (item = {}) => ({
	customerId: item.customer_id || '-',
	customerName: item.customer_first_name
		? `${item.customer_first_name} ${item.customer_last_name}`
		: '-',
	customerGroup: item.customer_group_name || '-',
	customerType: item.customer_type || '-',
	product: numberFormat(item.total_product || 0),
	cartItems: numberFormat(item.total_quantity || 0),
	subtotal: priceFormat(priceFromAPI(item.subtotal || 0)),
	createdAt: item.created_at ? datetimeFormat(item.created_at) : '-',
	customerLastLoginAt: item.customer_last_login_at ? datetimeFormat(item.customer_last_login_at) : '-',
	updatedAt: item.created_at ? datetimeFormat(item.updated_at) : '-',
});

export const transformedAbandonedCarts = (abandonedCarts = []) => abandonedCarts.map(transformedAbandonedCart);