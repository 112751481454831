import router from '../../router';
import {
	GET_NOTIFICATION_REQUEST,
	GET_NOTIFICATION_SUCCESS,
	GET_NOTIFICATION_FAILURE,
	GET_NOTIFICATIONS_REQUEST,
	GET_NOTIFICATIONS_SUCCESS,
	GET_NOTIFICATIONS_FAILURE,
	CREATE_NOTIFICATION_REQUEST,
	CREATE_NOTIFICATION_SUCCESS,
	CREATE_NOTIFICATION_FAILURE,
	EDIT_NOTIFICATION_REQUEST,
	EDIT_NOTIFICATION_SUCCESS,
	EDIT_NOTIFICATION_FAILURE,
} from '../types';
import {
	getNotificationsAPI,
	postNotificationAPI,
	getNotificationAPI,
	deleteNotificationAPI,
	updateNotificationAPI,
} from '../../services/api/mobileNotification.api';
import {
	transformedMobileNotificationList,
	transformedMobileNotification,
	transformedNotificationToFormData,
} from '../../assets/js/transform/mobileNotification';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		list: {
			isLoading: false,
			data: [],
			meta: {},
			isError: false,
		},
	},
	getters: {},
	mutations: {
		[GET_NOTIFICATION_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_NOTIFICATION_SUCCESS](state, { data }) {
			state.edit.data = transformedNotificationToFormData(data);
			state.edit.isLoading = false;
		},
		[GET_NOTIFICATION_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[GET_NOTIFICATIONS_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = [];
			state.list.meta = {};
			state.list.isError = false;
		},
		[GET_NOTIFICATIONS_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedMobileNotificationList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_NOTIFICATIONS_FAILURE](state) {
			state.list.isLoading = false;
			state.list.isError = true;
		},
		[CREATE_NOTIFICATION_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_NOTIFICATION_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_NOTIFICATION_FAILURE](state) {
			state.create.isCreating = false;
		},
		[EDIT_NOTIFICATION_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[EDIT_NOTIFICATION_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[EDIT_NOTIFICATION_FAILURE](state) {
			state.edit.isUpdating = false;
		},
	},

	actions: {
		async getNotifications({ commit }, params) {
			try {
				commit(GET_NOTIFICATIONS_REQUEST);
				const { data } = await getNotificationsAPI(params);
				commit(GET_NOTIFICATIONS_SUCCESS, data);
			} catch (error) {
				commit(GET_NOTIFICATIONS_FAILURE);
			}
		},
		async getNotification({ commit }, params) {
			try {
				commit(GET_NOTIFICATION_REQUEST);
				const { data } = await getNotificationAPI(params);
				commit(GET_NOTIFICATION_SUCCESS, data);
			} catch (error) {
				commit(GET_NOTIFICATION_FAILURE);
			}
		},
		async createNotification({ commit, dispatch }, params) {
			try {
				commit(CREATE_NOTIFICATION_REQUEST, params);
				await postNotificationAPI(transformedMobileNotification(params));
				commit(CREATE_NOTIFICATION_SUCCESS);
				dispatch('toast/showCreateSuccessToast', 'notification', { root: true });
				router.push({ name: 'MobileNotification' });
			} catch (error) {
				commit(CREATE_NOTIFICATION_FAILURE);
				const statusCode = error?.response?.status ?? null;

				if (statusCode !== 422) {
					dispatch('toast/showCreateErrorToast', null, { root: true });
				}
				throw error;
			}
		},
		async UpdateNotification({ commit, dispatch }, params) {
			try {
				commit(EDIT_NOTIFICATION_REQUEST, params);
				const { data } = await updateNotificationAPI(params.id, transformedMobileNotification(params));
				commit(EDIT_NOTIFICATION_SUCCESS);
				dispatch('toast/showUpdateSuccessToast', null, { root: true });
				commit(GET_NOTIFICATION_SUCCESS, data);
			} catch (error) {
				commit(EDIT_NOTIFICATION_FAILURE);
				const statusCode = error?.response?.status ?? null;

				if (statusCode !== 422) {
					dispatch('toast/showUpdateErrorToast', null, { root: true });
				}
				throw error;
			}
		},
		async deleteNotification({ dispatch }, id) {
			try {
				await deleteNotificationAPI(id);
				dispatch('toast/showDeleteSuccessToast', 'Mobile notification', { root: true });
			} catch (error) {
				dispatch('toast/showDeleteErrorToast', null, { root: true });
				throw error;
			}
		},
	},

};
