export const PROMOTION_TYPES = {
	GENERAL: 'GENERAL',
	TIER: 'TIER',
	BUNDLE: 'BUNDLE',
	BUNDLE_GROUP: 'BUNDLE2',
	COUPON: 'COUPON',
};

export const PROMOTION_TYPE_LABELS = {
	[PROMOTION_TYPES.GENERAL]: 'General',
	[PROMOTION_TYPES.TIER]: 'Tier Spending ',
	[PROMOTION_TYPES.BUNDLE]: 'Bundle',
	[PROMOTION_TYPES.BUNDLE_GROUP]: 'Bundle 2.0', // TODO: Rename to be `Bundle`
	[PROMOTION_TYPES.COUPON]: 'e-Coupon',
};

// promotion status option, use with radio groups
export const PROMOTION_TYPE_TABS = [
	{
		value: PROMOTION_TYPES.GENERAL,
		label: PROMOTION_TYPE_LABELS[PROMOTION_TYPES.GENERAL],
	},
	{
		value: PROMOTION_TYPES.TIER,
		label: PROMOTION_TYPE_LABELS[PROMOTION_TYPES.TIER],
	},
	{
		value: PROMOTION_TYPES.BUNDLE, // TODO: Hide after complete enchanment for PROMOTION BUNDLE v.2
		label: PROMOTION_TYPE_LABELS[PROMOTION_TYPES.BUNDLE],
	},
	{
		value: PROMOTION_TYPES.BUNDLE_GROUP,
		label: PROMOTION_TYPE_LABELS[PROMOTION_TYPES.BUNDLE_GROUP],
	},
	{
		value: PROMOTION_TYPES.COUPON,
		label: PROMOTION_TYPE_LABELS[PROMOTION_TYPES.COUPON],
	},
];

export const PROMOTION_STATUSES = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
	TESTING: 'TESTING',
};

export const PROMOTION_STATUS_LABELS = {
	[PROMOTION_STATUSES.ACTIVE]: 'Active',
	[PROMOTION_STATUSES.INACTIVE]: 'Inactive',
	[PROMOTION_STATUSES.TESTING]: 'Testing',
};

// promotion status option, use with radio groups
export const PROMOTION_STATUS_OPTIONS = [
	{
		value: PROMOTION_STATUSES.ACTIVE,
		label: PROMOTION_STATUS_LABELS[PROMOTION_STATUSES.ACTIVE],
	},
	{
		value: PROMOTION_STATUSES.INACTIVE,
		label: PROMOTION_STATUS_LABELS[PROMOTION_STATUSES.INACTIVE],
	},
	{
		value: PROMOTION_STATUSES.TESTING,
		label: PROMOTION_STATUS_LABELS[PROMOTION_STATUSES.TESTING],
	},
];

// Condition type
export const CONDITION_TYPES = {
	ALL: 'ALL',
	ANY: 'ANY',
};

export const CONDITION_TYPE_LABELS = {
	[CONDITION_TYPES.ALL]: 'All',
	[CONDITION_TYPES.ANY]: 'Any',
};

// Condition type option, use with radio groups
export const CONDITION_TYPE_OPTIONS = [
	{
		value: CONDITION_TYPES.ALL,
		label: CONDITION_TYPE_LABELS[CONDITION_TYPES.ALL],
	},
	{
		value: CONDITION_TYPES.ANY,
		label: CONDITION_TYPE_LABELS[CONDITION_TYPES.ANY],
	},
];

// e-Coupon code creation mode
export const COUPON_MODES = {
	MANUAL: 'MANUAL',
	AUTO: 'AUTO',
};

export const MAXIMUM_COUPON_QUANTITY = 50000;

export const COUPON_MODE_LABELS = {
	[COUPON_MODES.MANUAL]: 'Manual',
	[COUPON_MODES.AUTO]: 'Auto',
};

// Coupon creation mode
export const COUPON_MODE_OPTIONS = [
	{
		value: COUPON_MODES.MANUAL,
		label: COUPON_MODE_LABELS[COUPON_MODES.MANUAL],
	},
	{
		value: COUPON_MODES.AUTO,
		label: COUPON_MODE_LABELS[COUPON_MODES.AUTO],
	},
];

// Suffix code format
export const SUFFIX_CODE_FORMATS = {
	NUMBER: 'NUMBER',
	ALPHABET: 'ALPHABET',
	ALPHA_NUMERIC: 'ALPHA_NUMERIC',
};

export const SUFFIX_CODE_FORMAT_LABELS = {
	[SUFFIX_CODE_FORMATS.NUMBER]: 'Number',
	[SUFFIX_CODE_FORMATS.ALPHABET]: 'Alphabet',
	[SUFFIX_CODE_FORMATS.ALPHA_NUMERIC]: 'Alpha numeric',
};

// Suffix code format option
export const SUFFIX_CODE_FORMAT_OPTIONS = [
	{
		value: SUFFIX_CODE_FORMATS.NUMBER,
		label: SUFFIX_CODE_FORMAT_LABELS[SUFFIX_CODE_FORMATS.NUMBER],
	},
	{
		value: SUFFIX_CODE_FORMATS.ALPHABET,
		label: SUFFIX_CODE_FORMAT_LABELS[SUFFIX_CODE_FORMATS.ALPHABET],
	},
	{
		value: SUFFIX_CODE_FORMATS.ALPHA_NUMERIC,
		label: SUFFIX_CODE_FORMAT_LABELS[SUFFIX_CODE_FORMATS.ALPHA_NUMERIC],
	},
];

// Condition key
export const CONDITION_KEYS = {
	SKU: 'SKU',
	CATEGORY: 'CATEGORY',
	MAIN_CATEGORY: 'MAIN_CATEGORY',
	BRAND: 'BRAND',
	ATTRIBUTE: 'ATTRIBUTE',
	PURCHASE_AMOUNT: 'PURCHASE_AMOUNT',
	QUANTITY: 'QUANTITY',
	TOTAL_PURCHASE_AMOUNT: 'TOTAL_PURCHASE_AMOUNT',
	TOTAL_QUANTITY: 'TOTAL_QUANTITY',
};

export const CONDITION_KEY_LABELS = {
	[CONDITION_KEYS.SKU]: 'SKU',
	[CONDITION_KEYS.CATEGORY]: 'Category',
	[CONDITION_KEYS.MAIN_CATEGORY]: 'Main category',
	[CONDITION_KEYS.BRAND]: 'Brand',
	[CONDITION_KEYS.ATTRIBUTE]: 'Attribute',
	[CONDITION_KEYS.PURCHASE_AMOUNT]: 'Per item purchase amount',
	[CONDITION_KEYS.QUANTITY]: 'Per item quantity',
	[CONDITION_KEYS.TOTAL_PURCHASE_AMOUNT]: 'All item purchase amount',
	[CONDITION_KEYS.TOTAL_QUANTITY]: 'All item quantity',
};

export const CART_CONDITION_TYPES = {
	PURCHASE_AMOUNT: 'PURCHASE_AMOUNT',
	QUANTITY: 'QUANTITY',
};

export const CONDITION_KEY_OPTIONS = [
	{
		value: CONDITION_KEYS.SKU,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.SKU],
	},
	{
		value: CONDITION_KEYS.CATEGORY,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.CATEGORY],
	},
	{
		value: CONDITION_KEYS.MAIN_CATEGORY,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.MAIN_CATEGORY],
	},
	{
		value: CONDITION_KEYS.BRAND,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.BRAND],
	},
];

export const SUB_CONDITION_KEY_OPTIONS = [
	{
		value: CONDITION_KEYS.ATTRIBUTE,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.ATTRIBUTE],
	},
	{
		value: CONDITION_KEYS.QUANTITY,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.QUANTITY],
	},
	{
		value: CONDITION_KEYS.PURCHASE_AMOUNT,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.PURCHASE_AMOUNT],
	},
	{
		value: CONDITION_KEYS.TOTAL_QUANTITY,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.TOTAL_QUANTITY],
	},
	{
		value: CONDITION_KEYS.TOTAL_PURCHASE_AMOUNT,
		label: CONDITION_KEY_LABELS[CONDITION_KEYS.TOTAL_PURCHASE_AMOUNT],
	},
];

export const CONDITION_RULE_KEYS = {
	IS: 'IS',
	IS_IN: 'IS_IN',
	IS_NOT: 'IS_NOT',
	IS_ONE_OF: 'IS_ONE_OF',
	IS_NOT_ONE_OF: 'IS_NOT_ONE_OF',
	IS_GREATER_THAN: 'IS_GREATER_THAN',
	IS_GREATER_THAN_EQUAL: 'IS_GREATER_THAN_EQUAL',
	IS_LESS_THAN: 'IS_LESS_THAN',
	IS_LESS_THAN_EQUAL: 'IS_LESS_THAN_EQUAL',
	CONTAINS: 'CONTAINS', // For customer email
	IS_CONTAIN: 'IS_CONTAIN', // For attribute
	IS_ALL_OF: 'IS_ALL_OF',
	IS_NOT_ALL_OF: 'IS_NOT_ALL_OF',
};

export const CONDITION_RULE_LABELS = {
	[CONDITION_RULE_KEYS.IS]: 'is',
	[CONDITION_RULE_KEYS.IS_IN]: 'is in',
	[CONDITION_RULE_KEYS.IS_NOT]: 'is not',
	[CONDITION_RULE_KEYS.IS_ONE_OF]: 'is one of',
	[CONDITION_RULE_KEYS.IS_NOT_ONE_OF]: 'is not one of',
	[CONDITION_RULE_KEYS.IS_GREATER_THAN]: 'is greater than',
	[CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL]: 'is greater than or equal',
	[CONDITION_RULE_KEYS.IS_LESS_THAN]: 'is less than',
	[CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL]: 'is less than or equal',
	[CONDITION_RULE_KEYS.CONTAINS]: 'contains',
	[CONDITION_RULE_KEYS.IS_CONTAIN]: 'contains',
	[CONDITION_RULE_KEYS.IS_ALL_OF]: 'all of',
	[CONDITION_RULE_KEYS.IS_NOT_ALL_OF]: 'is not all of',
};

export const CONDITION_RULE_OPTIONS = {
	[CONDITION_KEYS.SKU]: [
		{
			value: CONDITION_RULE_KEYS.IS_ONE_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_ONE_OF],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT_ONE_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT_ONE_OF],
		},
	],
	[CONDITION_KEYS.CATEGORY]: [
		{
			value: CONDITION_RULE_KEYS.IS_ONE_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_ONE_OF],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT_ONE_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT_ONE_OF],
		},
	],
	[CONDITION_KEYS.MAIN_CATEGORY]: [
		{
			value: CONDITION_RULE_KEYS.IS_ONE_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_ONE_OF],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT_ONE_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT_ONE_OF],
		},
	],
	[CONDITION_KEYS.BRAND]: [
		{
			value: CONDITION_RULE_KEYS.IS_ONE_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_ONE_OF],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT_ONE_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT_ONE_OF],
		},
	],
	[CONDITION_KEYS.ATTRIBUTE]: [
		{
			value: CONDITION_RULE_KEYS.IS_CONTAIN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_CONTAIN],
		},
	],
	[CONDITION_KEYS.PURCHASE_AMOUNT]: [
		{
			value: CONDITION_RULE_KEYS.IS,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT],
		},
		{
			value: CONDITION_RULE_KEYS.IS_LESS_THAN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_LESS_THAN],
		},
		{
			value: CONDITION_RULE_KEYS.IS_GREATER_THAN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_GREATER_THAN],
		},
		{
			value: CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL],
		},
		{
			value: CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL],
		},
	],
	[CONDITION_KEYS.QUANTITY]: [
		{
			value: CONDITION_RULE_KEYS.IS,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT],
		},
		{
			value: CONDITION_RULE_KEYS.IS_LESS_THAN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_LESS_THAN],
		},
		{
			value: CONDITION_RULE_KEYS.IS_GREATER_THAN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_GREATER_THAN],
		},
		{
			value: CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL],
		},
		{
			value: CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL],
		},
	],
	[CONDITION_KEYS.TOTAL_PURCHASE_AMOUNT]: [
		{
			value: CONDITION_RULE_KEYS.IS,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT],
		},
		{
			value: CONDITION_RULE_KEYS.IS_LESS_THAN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_LESS_THAN],
		},
		{
			value: CONDITION_RULE_KEYS.IS_GREATER_THAN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_GREATER_THAN],
		},
		{
			value: CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL],
		},
		{
			value: CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL],
		},
	],
	[CONDITION_KEYS.TOTAL_QUANTITY]: [
		{
			value: CONDITION_RULE_KEYS.IS,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT],
		},
		{
			value: CONDITION_RULE_KEYS.IS_LESS_THAN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_LESS_THAN],
		},
		{
			value: CONDITION_RULE_KEYS.IS_GREATER_THAN,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_GREATER_THAN],
		},
		{
			value: CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_LESS_THAN_EQUAL],
		},
		{
			value: CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_GREATER_THAN_EQUAL],
		},
	],
};

export const CONDITION_RULE_TYPE_ALL_OPTIONS = {
	[CONDITION_KEYS.SKU]: [
		{
			value: CONDITION_RULE_KEYS.IS_ALL_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_ALL_OF],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT_ALL_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT_ALL_OF],
		},
	],
	[CONDITION_KEYS.CATEGORY]: [
		{
			value: CONDITION_RULE_KEYS.IS_ALL_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_ALL_OF],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT_ALL_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT_ALL_OF],
		},
	],
	[CONDITION_KEYS.MAIN_CATEGORY]: [
		{
			value: CONDITION_RULE_KEYS.IS_ALL_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_ALL_OF],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT_ALL_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT_ALL_OF],
		},
	],
	[CONDITION_KEYS.BRAND]: [
		{
			value: CONDITION_RULE_KEYS.IS_ALL_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_ALL_OF],
		},
		{
			value: CONDITION_RULE_KEYS.IS_NOT_ALL_OF,
			label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS_NOT_ALL_OF],
		},
	],
};

export const CUSTOMER_CONDITION_RULE_OPTIONS = [
	{
		value: CONDITION_RULE_KEYS.IS,
		label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.IS],
	},
	{
		value: CONDITION_RULE_KEYS.CONTAINS,
		label: CONDITION_RULE_LABELS[CONDITION_RULE_KEYS.CONTAINS],
	},
];

export const CART_MININUM_TYPE_LABELS = {
	[CART_CONDITION_TYPES.QUANTITY]: 'Item quantity',
	[CART_CONDITION_TYPES.PURCHASE_AMOUNT]: 'Purchase amount',
};

export const CART_MININUM_TYPE_OPTIONS = [
	{
		value: CART_CONDITION_TYPES.QUANTITY,
		label: CART_MININUM_TYPE_LABELS[CART_CONDITION_TYPES.QUANTITY],
	},
	{
		value: CART_CONDITION_TYPES.PURCHASE_AMOUNT,
		label: CART_MININUM_TYPE_LABELS[CART_CONDITION_TYPES.PURCHASE_AMOUNT],
	},
];

// Customer get reward from
export const CUSTOMER_REWARD_TYPES = {
	ALL: 'ALL',
	HIGHEST_TIER: 'HIGHEST_TIER',
};

export const CUSTOMER_REWARD_TYPE_LABELS = {
	[CUSTOMER_REWARD_TYPES.ALL]: 'All',
	[CUSTOMER_REWARD_TYPES.HIGHEST_TIER]: 'Highest tier ',
};

export const CUSTOMER_REWARD_TYPE_OPTIONS = [
	{
		value: CUSTOMER_REWARD_TYPES.ALL,
		label: CUSTOMER_REWARD_TYPE_LABELS[CUSTOMER_REWARD_TYPES.ALL],
	},
	{
		value: CUSTOMER_REWARD_TYPES.HIGHEST_TIER,
		label: CUSTOMER_REWARD_TYPE_LABELS[CUSTOMER_REWARD_TYPES.HIGHEST_TIER],
	},
];

// Customer Condition type
export const CUSTOMER_CONDITION_TYPES = {
	CUSTOMER_ID: 'CUSTOMER_ID',
	EMAIL: 'EMAIL',
	MOBILE_NUMBER: 'MOBILE_NUMBER',
	REGISTER_DATE: 'REGISTER_DATE',
};

// Purchase history Condition type
export const PURCHASE_HISTORY_CONDITION_TYPES = {
	COMPLETE_ORDER: 'COMPLETE_ORDER',
	LAST_ORDER_DATE: 'LAST_ORDER_DATE',
	TOTAL_SALE: 'TOTAL_SALE',
};

export const APPLY_TO_KEYS = {
	ENTIRE_ORDER: 'ENTIRE_ORDER',
	PRODUCT_SELECTED_IN_CONDITION: 'PRODUCT_SELECTED_IN_CONDITION',
	PRODUCT_BUNDLE_SELECTED_IN_CONDITION: 'PRODUCT_BUNDLE_SELECTED_IN_CONDITION',
	PRODUCT_BUNDLE2_SELECTED_IN_CONDITION: 'PRODUCT_BUNDLE2_SELECTED_IN_CONDITION',
	SPECIFIC_CATEGORY: 'SPECIFIC_CATEGORY',
	SPECIFIC_SKU: 'SPECIFIC_SKU',
	BUNDLE2_SPECIFIC_SKU: 'BUNDLE2_SPECIFIC_SKU',
	SHIPPING_FEE: 'SHIPPING_FEE',
	BUNDLE2_SET_FREEBIE: 'BUNDLE2_SET_FREEBIE',
};

export const APPLY_TO_LABELS = {
	[APPLY_TO_KEYS.ENTIRE_ORDER]: 'Entire order',
	[APPLY_TO_KEYS.PRODUCT_SELECTED_IN_CONDITION]: 'Product selected in Condition',
	[APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION]: 'Product selected in Condition',
	[APPLY_TO_KEYS.PRODUCT_BUNDLE2_SELECTED_IN_CONDITION]: 'Product selected in Condition',
	[APPLY_TO_KEYS.SPECIFIC_CATEGORY]: 'Specific category',
	[APPLY_TO_KEYS.SPECIFIC_SKU]: 'Specific SKU',
	[APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU]: 'Specific SKU',
	[APPLY_TO_KEYS.SHIPPING_FEE]: 'Shipping fee',
	[APPLY_TO_KEYS.BUNDLE2_SET_FREEBIE]: 'Bundle set in condition',
};

export const APPLY_TO_OPTIONS = [
	{
		value: APPLY_TO_KEYS.ENTIRE_ORDER,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.ENTIRE_ORDER],
	},
	{
		value: APPLY_TO_KEYS.PRODUCT_SELECTED_IN_CONDITION,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.PRODUCT_SELECTED_IN_CONDITION],
	},
	{
		value: APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION],
	},
	{
		value: APPLY_TO_KEYS.PRODUCT_BUNDLE2_SELECTED_IN_CONDITION,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.PRODUCT_BUNDLE2_SELECTED_IN_CONDITION],
	},
	{
		value: APPLY_TO_KEYS.SPECIFIC_CATEGORY,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.SPECIFIC_CATEGORY],
	},
	{
		value: APPLY_TO_KEYS.SPECIFIC_SKU,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.SPECIFIC_SKU],
	},
	{
		value: APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU],
	},
	{
		value: APPLY_TO_KEYS.SHIPPING_FEE,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.SHIPPING_FEE],
	},
	{
		value: APPLY_TO_KEYS.BUNDLE2_SET_FREEBIE,
		label: APPLY_TO_LABELS[APPLY_TO_KEYS.BUNDLE2_SET_FREEBIE],
	},
];

export const APPLY_TO_ITEM_LABELS = {
	[APPLY_TO_KEYS.SPECIFIC_CATEGORY]: 'Category',
	[APPLY_TO_KEYS.SPECIFIC_SKU]: 'SKU',
	[APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU]: 'SKU',
};

export const DISCOUNT_TYPE_KEYS = {
	PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
	FIXED_AMOUNT_DISCOUNT: 'FIXED_AMOUNT_DISCOUNT',
	FIXED_PRICE: 'FIXED_PRICE',
	FREEBIE: 'FREEBIE',
	COUPON: 'COUPON',
	FREE_SHIPPING: 'FREE_SHIPPING',
	BUNDLE2_SET_FREEBIE: 'BUNDLE2_SET_FREEBIE',
	PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT: 'PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT',
	FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT: 'FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT',
};

export const DISCOUNT_TYPE_LABELS = {
	[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: 'Percentage discount',
	[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: 'Fixed amount discount',
	[DISCOUNT_TYPE_KEYS.FIXED_PRICE]: 'Fixed price',
	[DISCOUNT_TYPE_KEYS.FREEBIE]: 'Freebie',
	[DISCOUNT_TYPE_KEYS.COUPON]: 'e-Coupon',
	[DISCOUNT_TYPE_KEYS.FREE_SHIPPING]: 'Free shipping',
	[DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE]: 'Freebie',
	[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: 'Percentage discount on top payment',
	[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: 'Fixed amount discount on top payment',
};

export const DISCOUNT_TYPE_OPTIONS = [
	{
		value: DISCOUNT_TYPE_KEYS.FREE_SHIPPING,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.FREE_SHIPPING],
	},
	{
		value: DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT],
	},
	{
		value: DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT],
	},
	{
		value: DISCOUNT_TYPE_KEYS.FIXED_PRICE,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.FIXED_PRICE],
	},
	{
		value: DISCOUNT_TYPE_KEYS.FREEBIE,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.FREEBIE],
	},
	{
		value: DISCOUNT_TYPE_KEYS.COUPON,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.COUPON],
	},
	{
		value: DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE],
	},
	{
		value: DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT],
	},
	{
		value: DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT,
		label: DISCOUNT_TYPE_LABELS[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT],
	},
];

export const DISCOUNT_TYPE_FILTER_KEYS = {
	PERCENTAGE_DISCOUNT: 'PERCENTAGE',
	FIXED_AMOUNT_DISCOUNT: 'FIXED_AMOUNT',
	FIXED_PRICE: 'FIXED_PRICE',
	FREEBIE: 'FREEBIE',
	COUPON: 'E_COUPON',
	PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT: 'PERCENTAGE_ON_TOP_PAYMENT',
	FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT: 'FIXED_AMOUNT_ON_TOP_PAYMENT',
};

export const DISCOUNT_TYPE_FILTER_LABELS = {
	[DISCOUNT_TYPE_FILTER_KEYS.PERCENTAGE_DISCOUNT]: 'Percentage discount',
	[DISCOUNT_TYPE_FILTER_KEYS.FIXED_AMOUNT_DISCOUNT]: 'Fixed amount discount',
	[DISCOUNT_TYPE_FILTER_KEYS.FIXED_PRICE]: 'Fixed price',
	[DISCOUNT_TYPE_FILTER_KEYS.FREEBIE]: 'Freebie',
	[DISCOUNT_TYPE_FILTER_KEYS.COUPON]: 'e-Coupon',
	[DISCOUNT_TYPE_FILTER_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: 'Percentage discount on top payment',
	[DISCOUNT_TYPE_FILTER_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: 'Fixed amount discount on top payment',
};

export const DISCOUNT_TYPE_FILTER_OPTIONS = [
	{
		value: null,
		label: 'All types',
	},
	{
		value: DISCOUNT_TYPE_FILTER_KEYS.PERCENTAGE_DISCOUNT,
		label: DISCOUNT_TYPE_FILTER_LABELS[DISCOUNT_TYPE_FILTER_KEYS.PERCENTAGE_DISCOUNT],
	},
	{
		value: DISCOUNT_TYPE_FILTER_KEYS.FIXED_AMOUNT_DISCOUNT,
		label: DISCOUNT_TYPE_FILTER_LABELS[DISCOUNT_TYPE_FILTER_KEYS.FIXED_AMOUNT_DISCOUNT],
	},
	{
		value: DISCOUNT_TYPE_FILTER_KEYS.FIXED_PRICE,
		label: DISCOUNT_TYPE_FILTER_LABELS[DISCOUNT_TYPE_FILTER_KEYS.FIXED_PRICE],
	},
	{
		value: DISCOUNT_TYPE_FILTER_KEYS.FREEBIE,
		label: DISCOUNT_TYPE_FILTER_LABELS[DISCOUNT_TYPE_FILTER_KEYS.FREEBIE],
	},
	{
		value: DISCOUNT_TYPE_FILTER_KEYS.COUPON,
		label: DISCOUNT_TYPE_FILTER_LABELS[DISCOUNT_TYPE_FILTER_KEYS.COUPON],
	},
	{
		value: DISCOUNT_TYPE_FILTER_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT,
		label: DISCOUNT_TYPE_FILTER_LABELS[DISCOUNT_TYPE_FILTER_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT],
	},
	{
		value: DISCOUNT_TYPE_FILTER_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT,
		label: DISCOUNT_TYPE_FILTER_LABELS[DISCOUNT_TYPE_FILTER_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT],
	},
];

// Reward
export const REWARD_TYPE_KEYS = {
	// Cart
	CART_PERCENT_DISCOUNT: 'CART_PERCENT_DISCOUNT',
	CART_FIXED_DISCOUNT: 'CART_FIXED_DISCOUNT',
	CART_FIXED_PRICE: 'CART_FIXED_PRICE',
	CART_FREEBIE: 'CART_FREEBIE',
	CART_PERCENT_DISCOUNT_ON_TOP_PAYMENT: 'CART_PERCENT_DISCOUNT_ON_TOP_PAYMENT',
	CART_FIXED_DISCOUNT_ON_TOP_PAYMENT: 'CART_FIXED_DISCOUNT_ON_TOP_PAYMENT',
	// Item, Product selected in condition
	ITEM_FIXED_DISCOUNT: 'ITEM_FIXED_DISCOUNT',
	ITEM_PERCENT_DISCOUNT: 'ITEM_PERCENT_DISCOUNT',
	ITEM_FIXED_PRICE: 'ITEM_FIXED_PRICE',
	ITEM_FREEBIE: 'ITEM_FREEBIE',
	ITEM_PERCENT_DISCOUNT_ON_TOP_PAYMENT: 'ITEM_PERCENT_DISCOUNT_ON_TOP_PAYMENT',
	ITEM_FIXED_DISCOUNT_ON_TOP_PAYMENT: 'ITEM_FIXED_DISCOUNT_ON_TOP_PAYMENT',
	// BUNDLE Item, Product selected in condition
	BUNDLE_FIXED_DISCOUNT: 'BUNDLE_FIXED_DISCOUNT',
	BUNDLE_PERCENT_DISCOUNT: 'BUNDLE_PERCENT_DISCOUNT',
	BUNDLE_FIXED_PRICE: 'BUNDLE_FIXED_PRICE',
	BUNDLE_FREEBIE: 'BUNDLE_FREEBIE',
	BUNDLE_PERCENT_DISCOUNT_ON_TOP_PAYMENT: 'BUNDLE_PERCENT_DISCOUNT_ON_TOP_PAYMENT',
	BUNDLE_FIXED_DISCOUNT_ON_TOP_PAYMENT: 'BUNDLE_FIXED_DISCOUNT_ON_TOP_PAYMENT',
	// BUNDLE2 Item, Product selected in condition
	BUNDLE2_ITEM_FIXED_DISCOUNT: 'BUNDLE2_ITEM_FIXED_DISCOUNT',
	BUNDLE2_ITEM_PERCENT_DISCOUNT: 'BUNDLE2_ITEM_PERCENT_DISCOUNT',
	BUNDLE2_ITEM_FIXED_PRICE: 'BUNDLE2_ITEM_FIXED_PRICE',
	BUNDLE2_PERCENT_DISCOUNT_ON_TOP_PAYMENT: 'BUNDLE2_PERCENT_DISCOUNT_ON_TOP_PAYMENT',
	BUNDLE2_FIXED_DISCOUNT_ON_TOP_PAYMENT: 'BUNDLE2_FIXED_DISCOUNT_ON_TOP_PAYMENT',
	// Category
	CATEGORY_FIXED_DISCOUNT: 'CATEGORY_FIXED_DISCOUNT',
	CATEGORY_PERCENT_DISCOUNT: 'CATEGORY_PERCENT_DISCOUNT',
	CATEGORY_FIXED_PRICE: 'CATEGORY_FIXED_PRICE',
	CATEGORY_FREEBIE: 'CATEGORY_FREEBIE',
	CATEGORY_PERCENT_DISCOUNT_ON_TOP_PAYMENT: 'CATEGORY_PERCENT_DISCOUNT_ON_TOP_PAYMENT',
	CATEGORY_FIXED_DISCOUNT_ON_TOP_PAYMENT: 'CATEGORY_FIXED_DISCOUNT_ON_TOP_PAYMENT',
	// Sku
	SKU_PERCENT_DISCOUNT: 'SKU_PERCENT_DISCOUNT',
	SKU_FIXED_DISCOUNT: 'SKU_FIXED_DISCOUNT',
	SKU_FIXED_PRICE: 'SKU_FIXED_PRICE',
	SKU_FREEBIE: 'SKU_FREEBIE',
	SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT: 'SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT',
	SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT: 'SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT',
	// BUNDLE2 Sku
	BUNDLE2_SKU_PERCENT_DISCOUNT: 'BUNDLE2_SKU_PERCENT_DISCOUNT',
	BUNDLE2_SKU_FIXED_DISCOUNT: 'BUNDLE2_SKU_FIXED_DISCOUNT',
	BUNDLE2_SKU_FIXED_PRICE: 'BUNDLE2_SKU_FIXED_PRICE',
	BUNDLE2_SET_FREEBIE: 'BUNDLE2_SET_FREEBIE',
	BUNDLE2_SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT: 'BUNDLE2_SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT',
	BUNDLE2_SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT: 'BUNDLE2_SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT',
	// Shipping fee
	FREE_SHIPPING: 'FREE_SHIPPING',
	SHIPPING_PERCENT_DISCOUNT: 'SHIPPING_PERCENT_DISCOUNT',
	SHIPPING_FIXED_DISCOUNT: 'SHIPPING_FIXED_DISCOUNT',
	// e-Coupon
	COUPON: 'COUPON',
};

// Reward for Bundle only, Item, Product selected in condition
export const REWARD_TYPE_BUNDLE_MAPPING_KEYS = {
	ITEM_FIXED_DISCOUNT: 'BUNDLE_FIXED_DISCOUNT',
	ITEM_PERCENT_DISCOUNT: 'BUNDLE_PERCENT_DISCOUNT',
	ITEM_FIXED_PRICE: 'BUNDLE_FIXED_PRICE',
	ITEM_FREEBIE: 'BUNDLE_FREEBIE',
};

export const REWARD_KEYS = {
	[APPLY_TO_KEYS.ENTIRE_ORDER]: {
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: REWARD_TYPE_KEYS.CART_PERCENT_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: REWARD_TYPE_KEYS.CART_FIXED_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_PRICE]: REWARD_TYPE_KEYS.CART_FIXED_PRICE,
		[DISCOUNT_TYPE_KEYS.FREEBIE]: REWARD_TYPE_KEYS.CART_FREEBIE,
		[DISCOUNT_TYPE_KEYS.COUPON]: REWARD_TYPE_KEYS.COUPON,
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.CART_PERCENT_DISCOUNT_ON_TOP_PAYMENT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.CART_FIXED_DISCOUNT_ON_TOP_PAYMENT,
	},
	[APPLY_TO_KEYS.PRODUCT_SELECTED_IN_CONDITION]: {
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: REWARD_TYPE_KEYS.ITEM_PERCENT_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: REWARD_TYPE_KEYS.ITEM_FIXED_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_PRICE]: REWARD_TYPE_KEYS.ITEM_FIXED_PRICE,
		[DISCOUNT_TYPE_KEYS.FREEBIE]: REWARD_TYPE_KEYS.ITEM_FREEBIE,
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.ITEM_PERCENT_DISCOUNT_ON_TOP_PAYMENT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.ITEM_FIXED_DISCOUNT_ON_TOP_PAYMENT,
	},
	[APPLY_TO_KEYS.PRODUCT_BUNDLE_SELECTED_IN_CONDITION]: {
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: REWARD_TYPE_KEYS.BUNDLE_PERCENT_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: REWARD_TYPE_KEYS.BUNDLE_FIXED_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_PRICE]: REWARD_TYPE_KEYS.BUNDLE_FIXED_PRICE,
		[DISCOUNT_TYPE_KEYS.FREEBIE]: REWARD_TYPE_KEYS.BUNDLE_FREEBIE,
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.BUNDLE_PERCENT_DISCOUNT_ON_TOP_PAYMENT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.BUNDLE_FIXED_DISCOUNT_ON_TOP_PAYMENT,
	},
	[APPLY_TO_KEYS.PRODUCT_BUNDLE2_SELECTED_IN_CONDITION]: {
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: REWARD_TYPE_KEYS.BUNDLE2_ITEM_PERCENT_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: REWARD_TYPE_KEYS.BUNDLE2_ITEM_FIXED_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_PRICE]: REWARD_TYPE_KEYS.BUNDLE2_ITEM_FIXED_PRICE,
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.BUNDLE2_PERCENT_DISCOUNT_ON_TOP_PAYMENT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.BUNDLE2_FIXED_DISCOUNT_ON_TOP_PAYMENT,
	},
	[APPLY_TO_KEYS.SPECIFIC_CATEGORY]: {
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: REWARD_TYPE_KEYS.CATEGORY_PERCENT_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: REWARD_TYPE_KEYS.CATEGORY_FIXED_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_PRICE]: REWARD_TYPE_KEYS.CATEGORY_FIXED_PRICE,
		[DISCOUNT_TYPE_KEYS.FREEBIE]: REWARD_TYPE_KEYS.CATEGORY_FREEBIE,
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.CATEGORY_PERCENT_DISCOUNT_ON_TOP_PAYMENT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.CATEGORY_FIXED_DISCOUNT_ON_TOP_PAYMENT,
	},
	[APPLY_TO_KEYS.SPECIFIC_SKU]: {
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: REWARD_TYPE_KEYS.SKU_PERCENT_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: REWARD_TYPE_KEYS.SKU_FIXED_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_PRICE]: REWARD_TYPE_KEYS.SKU_FIXED_PRICE,
		[DISCOUNT_TYPE_KEYS.FREEBIE]: REWARD_TYPE_KEYS.SKU_FREEBIE,
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT,
	},
	[APPLY_TO_KEYS.BUNDLE2_SPECIFIC_SKU]: {
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: REWARD_TYPE_KEYS.BUNDLE2_SKU_PERCENT_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: REWARD_TYPE_KEYS.BUNDLE2_SKU_FIXED_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_PRICE]: REWARD_TYPE_KEYS.BUNDLE2_SKU_FIXED_PRICE,
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.BUNDLE2_SKU_PERCENT_DISCOUNT_ON_TOP_PAYMENT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT_ON_TOP_PAYMENT]: REWARD_TYPE_KEYS.BUNDLE2_SKU_FIXED_DISCOUNT_ON_TOP_PAYMENT,
	},
	[APPLY_TO_KEYS.SHIPPING_FEE]: {
		[DISCOUNT_TYPE_KEYS.FREE_SHIPPING]: REWARD_TYPE_KEYS.FREE_SHIPPING,
		[DISCOUNT_TYPE_KEYS.PERCENTAGE_DISCOUNT]: REWARD_TYPE_KEYS.SHIPPING_PERCENT_DISCOUNT,
		[DISCOUNT_TYPE_KEYS.FIXED_AMOUNT_DISCOUNT]: REWARD_TYPE_KEYS.SHIPPING_FIXED_DISCOUNT,
	},
	[APPLY_TO_KEYS.BUNDLE2_SET_FREEBIE]: {
		[DISCOUNT_TYPE_KEYS.BUNDLE2_SET_FREEBIE]: REWARD_TYPE_KEYS.BUNDLE2_SET_FREEBIE,
	},
};

// Apply Every DiscountType
export const APPLY_DISCOUNT_EVERY_TYPE_KEYS = {
	PURCHASE_AMOUNT: 'PURCHASE_AMOUNT',
	QUANTITY: 'QUANTITY',
};

export const APPLY_DISCOUNT_EVERY_TYPE_LABELS = {
	[APPLY_DISCOUNT_EVERY_TYPE_KEYS.QUANTITY]: 'Quantity',
	[APPLY_DISCOUNT_EVERY_TYPE_KEYS.PURCHASE_AMOUNT]: 'Purchase amount',
};

export const APPLY_DISCOUNT_EVERY_TYPE_OPTIONS = [
	{
		value: APPLY_DISCOUNT_EVERY_TYPE_KEYS.QUANTITY,
		label: APPLY_DISCOUNT_EVERY_TYPE_LABELS[APPLY_DISCOUNT_EVERY_TYPE_KEYS.QUANTITY],
	},
	{
		value: APPLY_DISCOUNT_EVERY_TYPE_KEYS.PURCHASE_AMOUNT,
		label: APPLY_DISCOUNT_EVERY_TYPE_LABELS[APPLY_DISCOUNT_EVERY_TYPE_KEYS.PURCHASE_AMOUNT],
	},
];

export const PERIOD_STATUSES = {
	ON_GOING: 'ON_GOING',
	SCHEDULE: 'SCHEDULE',
	EXPIRED: 'EXPIRED',
};

export const PERIOD_STATUS_LABELS = {
	[PERIOD_STATUSES.ON_GOING]: 'Ongoing',
	[PERIOD_STATUSES.SCHEDULE]: 'Scheduled',
	[PERIOD_STATUSES.EXPIRED]: 'Expired',
};

export const PERIOD_STATUS_OPTIONS = [
	{
		value: null,
		label: 'All period status',
	},
	{
		value: PERIOD_STATUSES.ON_GOING,
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.ON_GOING],
	},
	{
		value: PERIOD_STATUSES.SCHEDULE,
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.SCHEDULE],
	},
	{
		value: PERIOD_STATUSES.EXPIRED,
		label: PERIOD_STATUS_LABELS[PERIOD_STATUSES.EXPIRED],
	},
];

// mapping period status color,
export const PERIOD_STATUS_COLOR = {
	[PERIOD_STATUSES.ON_GOING]: 'orange',
	[PERIOD_STATUSES.SCHEDULE]: 'info',
	[PERIOD_STATUSES.EXPIRED]: 'alto',
};

export const PROMOTIONS_TABLE_FIELDS = Object.freeze([
	{ key: 'id', label: 'ID', class: 'col-id' },
	{ key: 'title', label: 'Promotion name', class: 'col-name' },
	{ key: 'status', label: 'Status', class: 'col-status' },
	{ key: 'type', label: 'Type', class: 'col-type' },
	{ key: 'priority', label: 'Priority', class: 'col-priority' },
	{ key: 'startAt', label: 'Start date', class: 'col-date' },
	{ key: 'endAt', label: 'End date', class: 'col-date' },
	{ key: 'periodStatus', label: 'Period', class: 'col-period' },
	{ key: 'duplicateId', label: '', class: 'col-duplicate-id' },
]);

// Calculate purchase amount from option
export const PURCHASE_SUBTRACT_DISCOUNT_KEYS = {
	PRICE_BEFORE_DISCOUNT: false,
	TOTAL_PRICE: true,
};

export const PURCHASE_SUBTRACT_DISCOUNT_LABELS = {
	[PURCHASE_SUBTRACT_DISCOUNT_KEYS.PRICE_BEFORE_DISCOUNT]: 'Price before discount',
	[PURCHASE_SUBTRACT_DISCOUNT_KEYS.TOTAL_PRICE]: 'Total price',
};

export const PURCHASE_SUBTRACT_DISCOUNT_OPTIONS = [
	{
		value: PURCHASE_SUBTRACT_DISCOUNT_KEYS.PRICE_BEFORE_DISCOUNT,
		label: PURCHASE_SUBTRACT_DISCOUNT_LABELS[PURCHASE_SUBTRACT_DISCOUNT_KEYS.PRICE_BEFORE_DISCOUNT],
	},
	{
		value: PURCHASE_SUBTRACT_DISCOUNT_KEYS.TOTAL_PRICE,
		label: PURCHASE_SUBTRACT_DISCOUNT_LABELS[PURCHASE_SUBTRACT_DISCOUNT_KEYS.TOTAL_PRICE],
	},
];

// Freebie stock option
export const FREEBIE_STOCK_OPTION_KEYS = {
	DEFAULT: null,
	IGNORE_INSUFFICIENT_STOCK: 'IGNORE_INSUFFICIENT_STOCK',
	SUBSTITUTE_WITH_OTHER_SKU: 'SUBSTITUTE_WITH_OTHER_SKU',
	SUBSTITUTE_WITH_DISCOUNT: 'SUBSTITUTE_WITH_DISCOUNT',
};

export const FREEBIE_STOCK_OPTION_LABELS = {
	[FREEBIE_STOCK_OPTION_KEYS.DEFAULT]: 'No option',
	[FREEBIE_STOCK_OPTION_KEYS.IGNORE_INSUFFICIENT_STOCK]: 'Ignore insufficient stock',
	[FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU]: 'Substitute with another freebie',
	[FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT]: 'Substitute with discount',
};

export const FREEBIE_STOCK_OPTIONS = [
	{
		key: FREEBIE_STOCK_OPTION_KEYS.DEFAULT,
		label: FREEBIE_STOCK_OPTION_LABELS[FREEBIE_STOCK_OPTION_KEYS.DEFAULT],
	},
	{
		key: FREEBIE_STOCK_OPTION_KEYS.IGNORE_INSUFFICIENT_STOCK,
		label: FREEBIE_STOCK_OPTION_LABELS[FREEBIE_STOCK_OPTION_KEYS.IGNORE_INSUFFICIENT_STOCK],
	},
	{
		key: FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU,
		label: FREEBIE_STOCK_OPTION_LABELS[FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_OTHER_SKU],
	},
	{
		key: FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT,
		label: FREEBIE_STOCK_OPTION_LABELS[FREEBIE_STOCK_OPTION_KEYS.SUBSTITUTE_WITH_DISCOUNT],
	},
];
