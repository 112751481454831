import httpClient from './httpClient';

export const SHIPMENTS_ENDPOINT = '/backoffice/shipments';

export const getShipmentsAPI = (params = {}) => httpClient.get(`${SHIPMENTS_ENDPOINT}`, { params });
export const getShipmentAPI = (id) => httpClient.get(`${SHIPMENTS_ENDPOINT}/${id}`);
export const updateShipmentAPI = (id, params = {}) => httpClient.put(`${SHIPMENTS_ENDPOINT}/${id}`, params);
export const createShipmentAPI = (params = {}) => httpClient.post(`${SHIPMENTS_ENDPOINT}`, params);
export const cancelShipmentAPI = (id) => httpClient.post(`${SHIPMENTS_ENDPOINT}/${id}/cancel`);
export const bookCourierAPI = (id, params) => httpClient.post(`${SHIPMENTS_ENDPOINT}/${id}/book`, params);
export const printAirWaybillAPI = async (id) => httpClient.get(`${SHIPMENTS_ENDPOINT}/${id}/awb`, { responseType: 'blob' });
export const postShipmentReportExport = (params = {}) => httpClient.post(`${SHIPMENTS_ENDPOINT}/reports/export`, params);

// Remove this when Sokochan is able to create tracking number and automatic pass to website
export const updateTrackingNumberAPI = (id, params = {}) => httpClient.put(`${SHIPMENTS_ENDPOINT}/${id}/update-tracking-number`, params);
