import { ROUTE_NAME } from '../enums/route';

export const CONTENT_ALIGN = {
	LEFT: 'left',
	RIGHT: 'right',
};

export const CONTENT_ALIGN_OPTIONS = [
	{ label: 'Left', value: CONTENT_ALIGN.LEFT },
	{ label: 'Right', value: CONTENT_ALIGN.RIGHT },
];

// TODO: [APP-XXXX] remove after all content blocks are converted
export const CONTENT_BLOCK_TYPE_API = {
	HIGHLIGHT: 'highlight',
	TEXT_WITH_IMAGE: 'text_with_image',
	STRIPE: 'stripe',
};

export const CONTENT_BLOCK_WIDGET_TYPE = {
	HIGHLIGHT: 'highlightContentBlock',
	TEXT_WITH_IMAGE: 'textWithImageContentBlock',
	STRIPE: 'stripeContentBlock',
};

export const CONTENT_BLOCK_TYPE = {
	HIGHLIGHT: 'HIGHLIGHT',
	TEXT_WITH_IMAGE: 'TEXT_WITH_IMAGE',
	STRIPE: 'STRIPE',
};

export const CONTENT_BLOCK_FORM_COMPONENTS = Object.freeze({
	HIGHLIGHT: {
		title: 'Highlight content block',
		component: 'ContentBlockHighlightForm',
		routerTo: ROUTE_NAME.CONTENT_BLOCK_HIGHLIGHT_LIST,
	},
	STRIPE: {
		title: 'Stripe content block',
		component: 'ContentBlockStripeForm',
		routerTo: ROUTE_NAME.CONTENT_BLOCK_STRIPE_LIST,
	},
	TEXT_WITH_IMAGE: {
		title: 'Text with image content block',
		component: 'ContentBlockTextWithImageForm',
		routerTo: ROUTE_NAME.CONTENT_BLOCK_TEXT_WITH_IMAGE_LIST,
	},
});

export const CONTENT_BLOCK_IMAGE_TYPE = {
	HIGHLIGHT: 'content_block_highlight_image',
	TEXT_WITH_IMAGE: 'text_with_image',
};

export const CONTENT_BLOCK_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id th-mw-30' },
	{ key: 'name', label: 'Content Block Name', class: 'col-name th-mw-250' },
	{ key: 'period', label: 'Period', class: 'col-period th-mw-80' },
	{ key: 'startDate', label: 'Start date', class: 'col-date th-mw-100', cellClass: 'td-nowrap' },
	{ key: 'endDate', label: 'End date', class: 'col-date th-mw-100', cellClass: 'td-nowrap' },
	{ key: 'priority', label: 'Priority', class: 'col-priority th-mw-10' },
	{ key: 'widgets', label: 'Link to', class: 'col-widget th-mw-100' },
	{ key: 'status', label: 'Status', class: 'col-status' },
];
