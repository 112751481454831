import {
	pathOr,
	isEmptyObject,
} from '../helpers';
import { transfromedHtmlContent } from './category';
import { transformedProductRelated } from './productRelated';

export const transformedProductCompareList = (product) => {
	return {
		id: pathOr(null, ['id'])(product),
		name: pathOr(null, ['name'])(product),
		compareTextEn: pathOr(null, ['compare_text_en'])(product),
		compareTextTh: pathOr(null, ['compare_text_th'])(product),
		compareDescriptionEn: pathOr(null, ['compare_description_en'])(product),
		compareDescriptionTh: pathOr(null, ['compare_description_th'])(product),
		status: pathOr(null, ['is_active'])(product),
	};
};
export const transformedProductCompareData = (product) => {
	const htmlContents = product.html_contents.map((htmlContent) => transfromedHtmlContent(htmlContent));
	const products = product.items.map((item) => transformedProductRelated(item.data));
	return {
		id: product.id,
		name: product.name,
		status: !!product.is_active,
		compareTextEn: product.compare_text_en,
		compareTextTh: product.compare_text_th,
		compareDescriptionEn: product.compare_description_en,
		compareDescriptionTh: product.compare_description_th,
		htmlContents,
		products,
	};
};
export const transformedPayloadProductCompare = (data) => {
	const params = {
		name: data.name ?? '',
		is_active: data.status ?? false,
		compare_text_en: data.compareTextEn ?? '',
		compare_text_th: data.compareTextTh ?? '',
		compare_description_en: data.compareDescriptionEn ?? '',
		compare_description_th: data.compareDescriptionTh ?? '',
	};
	const htmlContents = data.htmlContents.map((item, index) => ({
		title_en: item.titleEN ?? '',
		title_th: item.titleTH ?? '',
		content_en: item.contentEN ?? '',
		content_th: item.contentTH ?? '',
		priority: !isEmptyObject(item.priority) ? item.priority : index,
		is_active: item.isActive ?? false,
	}));
	const productitem = data.products.map((item) => ({
		id: item.id,
		type: 'product_group', // category
	}));
	return {
		...params,
		html_contents: htmlContents,
		items: productitem,
	};
};
