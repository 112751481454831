<template>
	<CModal
		:show.sync="isShow"
		:title="modalTitle"
		class="modal-delete-image"
		centered
	>
		<template #default>
			<p>Deleting the image will also detach the image from all locations that are using this image such as product image and product description.</p>
			<div class="image-previews">
				<div
					v-for="(image, key) in selectedDeleteImages"
					:key="key"
					class="image-preview"
				>
					<img :src="image.imageUrls.thumbnail" alt="">
				</div>
			</div>
		</template>
		<template #footer>
			<CButton
				color="tertiary"
				class="mr-3"
				@click="isShow = false"
			>
				Cancel
			</CButton>
			<CButton
				color="danger"
				@click="handleRemoveClick"
			>
				Remove
			</CButton>
		</template>
	</CModal>
</template>

<script>
export default {
	name: 'ModalDeleteImage',

	props: {
		selectedDeleteImages: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	computed: {
		modalTitle() {
			return this.selectedDeleteImages.length === 1
				? 'Delete this image?'
				: `Delete ${this.selectedDeleteImages.length} selected images?`;
		},
	},
	methods: {
		open() {
			this.isShow = true;
		},
		handleRemoveClick() {
			this.$emit('onConfirmDeleteImage', this.selectedDeleteImages);
			this.isShow = false;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
	z-index: 10000;
}

::v-deep .modal-backdrop {
	z-index: 9999;
}

.image-preview {
	display: inline-block;
	margin: 0 rem(12) rem(12) 0;

	img {
		width: rem(68);
		height: rem(68);
		border-radius: 4px;
		border: 1px solid $color-gray-300;
	}

	&:nth-child(6n) {
		margin-right: 0;
	}
}
</style>
