import { pathOr } from '../../assets/js/helpers';
import { i18n } from '../../i18n';
import { transformedUserList } from '../../assets/js/transform/user';
import router from '../../router';
import {
	addUserToRoleAPI,
	getRoleAPI,
	updateRoleAPI,
	getRoleUsersAPI,
	getPermissionsAPI,
	getRoleListAPI,
	createRoleAPI,
	removeUserFromRoleAPI,
	deleteRoleAPI,
} from '../../services/api/roles.api';
import {
	GET_ROLE_REQUEST,
	GET_ROLE_SUCCESS,
	GET_ROLE_FAILURE,
	GET_ROLE_LIST_REQUEST,
	GET_ROLE_LIST_SUCCESS,
	GET_ROLE_LIST_FAILURE,
	CREATE_ROLE_REQUEST,
	CREATE_ROLE_SUCCESS,
	CREATE_ROLE_FAILURE,
	UPDATE_ROLE_REQUEST,
	UPDATE_ROLE_SUCCESS,
	UPDATE_ROLE_FAILURE,
	DELETE_ROLE_REQUEST,
	DELETE_ROLE_SUCCESS,
	DELETE_ROLE_FAILURE,
	GET_ROLE_USERS_REQUEST,
	GET_ROLE_USERS_SUCCESS,
	GET_ROLE_USERS_FAILURE,
	GET_PERMISSIONS_REQUEST,
	GET_PERMISSIONS_SUCCESS,
	GET_PERMISSIONS_FAILURE,
	ADD_USER_TO_ROLE_REQUEST,
	ADD_USER_TO_ROLE_SUCCESS,
	ADD_USER_TO_ROLE_FAILURE,
	REMOVE_USER_FROM_ROLE_REQUEST,
	REMOVE_USER_FROM_ROLE_SUCCESS,
	REMOVE_USER_FROM_ROLE_FAILURE,
} from '../types';
import { HTTP_STATUS_CODE } from '../../enums/http';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
		},
		create: {
			isCreating: false,
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			data: {},
		},
		users: {
			isLoading: false,
			data: [],
		},
		permissions: {
			isLoading: false,
			data: [],
		},
	},
	getters: {
		getRoles(state) {
			return state.list.data;
		},
	},
	mutations: {
		[GET_ROLE_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_ROLE_SUCCESS](state, data) {
			state.edit.isLoading = false;
			state.edit.data = data;
		},
		[GET_ROLE_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[GET_ROLE_LIST_REQUEST](state) {
			state.list.isLoading = true;
		},
		[GET_ROLE_LIST_SUCCESS](state, data) {
			state.list = {
				isLoading: false,
				data,
			};
		},
		[GET_ROLE_LIST_FAILURE](state) {
			state.list.isLoading = false;
		},
		[CREATE_ROLE_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_ROLE_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_ROLE_FAILURE](state) {
			state.create.isCreating = false;
		},
		[UPDATE_ROLE_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_ROLE_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_ROLE_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_ROLE_REQUEST]() {},
		[DELETE_ROLE_SUCCESS]() {},
		[DELETE_ROLE_FAILURE]() {},
		[GET_ROLE_USERS_REQUEST](state) {
			state.users.isLoading = true;
		},
		[GET_ROLE_USERS_SUCCESS](state, data) {
			state.users.isLoading = false;
			state.users.data = data;
		},
		[GET_ROLE_USERS_FAILURE](state) {
			state.users.isLoading = false;
		},
		[GET_PERMISSIONS_REQUEST](state) {
			state.permissions.isLoading = true;
		},
		[GET_PERMISSIONS_SUCCESS](state, data) {
			state.permissions = {
				isLoading: false,
				data,
			};
		},
		[GET_PERMISSIONS_FAILURE](state) {
			state.permissions.isLoading = false;
		},
		[ADD_USER_TO_ROLE_REQUEST](state) {
			state.users = {
				...state.users,
				isSubmitting: true,
			};
		},
		[ADD_USER_TO_ROLE_SUCCESS](state) {
			state.users = {
				...state.users,
				isSubmitting: false,
			};
		},
		[ADD_USER_TO_ROLE_FAILURE](state) {
			state.users = {
				...state.users,
				isSubmitting: false,
			};
		},
		[REMOVE_USER_FROM_ROLE_REQUEST]() {},
		[REMOVE_USER_FROM_ROLE_SUCCESS]() {},
		[REMOVE_USER_FROM_ROLE_FAILURE]() {},
	},
	actions: {
		async getPermissions({ commit }) {
			try {
				commit(GET_PERMISSIONS_REQUEST);
				const { data } = await getPermissionsAPI();
				commit(GET_PERMISSIONS_SUCCESS, data.data);
			} catch (e) {
				commit(GET_PERMISSIONS_FAILURE);
			}
		},
		async getRoleList({ commit }, query) {
			try {
				commit(GET_ROLE_LIST_REQUEST);
				const { data } = await getRoleListAPI(query);
				commit(GET_ROLE_LIST_SUCCESS, data.data);
			} catch (e) {
				commit(GET_ROLE_LIST_FAILURE);
			}
		},
		async getRole({ commit }, id) {
			try {
				commit(GET_ROLE_REQUEST);
				const { data } = await getRoleAPI(id);
				commit(GET_ROLE_SUCCESS, data.data);
			} catch (e) {
				commit(GET_ROLE_FAILURE);
			}
		},
		async createRole({ commit, dispatch }, params) {
			try {
				commit(CREATE_ROLE_REQUEST);
				await createRoleAPI(params);
				commit(CREATE_ROLE_SUCCESS);
				router.push({ name: 'UserRole' });

				dispatch('toast/showToast', {
					content: `${params.name} group create successfully`,
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (e) {
				commit(CREATE_ROLE_FAILURE);
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(e);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
		async updateRole({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_ROLE_REQUEST);
				await updateRoleAPI(id, params);
				commit(UPDATE_ROLE_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (e) {
				commit(UPDATE_ROLE_FAILURE);
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(e);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
		async deleteRole({ commit, dispatch }, id) {
			try {
				commit(DELETE_ROLE_REQUEST);
				await deleteRoleAPI(id);
				commit(DELETE_ROLE_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Role' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });

				router.push({ name: 'UserRole' });
			} catch (e) {
				commit(DELETE_ROLE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		async getRoleUsers({ commit }, { id, query }) {
			try {
				commit(GET_ROLE_USERS_REQUEST);
				const { data } = await getRoleUsersAPI(id, query);
				commit(GET_ROLE_USERS_SUCCESS, transformedUserList(data.data));
			} catch (e) {
				commit(GET_ROLE_USERS_FAILURE);
			}
		},
		async addUserToRole({ commit, dispatch }, { roleId, user }) {
			try {
				commit(ADD_USER_TO_ROLE_REQUEST);
				await addUserToRoleAPI(roleId, user);
				commit(ADD_USER_TO_ROLE_SUCCESS);

				dispatch('toast/showToast', {
					content: 'New user has been added',
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				await dispatch('getRoleUsers', { id: roleId });
			} catch (e) {
				commit(ADD_USER_TO_ROLE_FAILURE);

				const errorStatus = pathOr(null, ['response', 'status'])(e);
				const userNotFound = errorStatus === HTTP_STATUS_CODE.NOT_FOUND;
				const userAlreadyExists = errorStatus === HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY;

				if (userNotFound || userAlreadyExists) {
					dispatch('toast/showToast', {
						type: 'danger',
						header: 'Unable to add',
						content: userNotFound ? 'User not found' : 'User is already in this group',
					}, { root: true });
				}
			}
		},
		async removeUserFromRole({ commit, dispatch }, { roleId, userId }) {
			try {
				commit(REMOVE_USER_FROM_ROLE_REQUEST);
				await removeUserFromRoleAPI(roleId, userId);
				commit(REMOVE_USER_FROM_ROLE_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'User' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });

				await dispatch('getRoleUsers', { id: roleId });
			} catch (e) {
				commit(REMOVE_USER_FROM_ROLE_FAILURE);
			}
		},
	},
};
