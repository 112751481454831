import dayjs from 'dayjs';
import {
	TAG_FILTER_PERIOD_STATUS,
	TAG_FILTER_PERIOD_STATUS_LABEL,
	TAG_FILTER_BADGE_COLOR,
	TAG_FILTER_STATUS,
	TAG_FILTER_STATUS_OPTIONS,
} from '../../../enums/tagFilters';
import {
	datetimeFormat,
	pathOr,
	convertDateTimeToUTC,
} from '../helpers';

/**
 * Transform tag filter status
 * @param {String} status
 * @returns {Object} tag filter status
 */
export const transformedTagFilterStatus = (status) => {
	const statusValue = TAG_FILTER_STATUS[status];
	return TAG_FILTER_STATUS_OPTIONS.find((option) => option.value === statusValue);
};

/**
 * Transform tag filter period status
 * @param {String} periodStatus
 * @returns {Object} period status
 */
export const transformedTagFilterPeriodStatus = (periodStatus) => {
	const periodStatusValue = TAG_FILTER_PERIOD_STATUS[periodStatus];
	return {
		title: TAG_FILTER_PERIOD_STATUS_LABEL[periodStatusValue],
		color: TAG_FILTER_BADGE_COLOR.PERIOD_STATUS[periodStatusValue],
	};
};

/**
 * Transform tag filter
 * @param {Object} tagFilter
 * @returns {Object} transformed tag filter
 */
export const transformedTagFilterGeneralInfo = (tagFilter = {}) => {
	return {
		id: tagFilter?.id,
		name: tagFilter?.name,
		startAt: pathOr(null, ['start_at'])(tagFilter) ? datetimeFormat(tagFilter.start_at) : '-',
		endAt: pathOr(null, ['end_at'])(tagFilter) ? datetimeFormat(tagFilter.end_at) : '-',
		priority: tagFilter?.priority,
		status: transformedTagFilterStatus(pathOr(null, ['status'])(tagFilter)),
		periodStatus: transformedTagFilterPeriodStatus(pathOr(null, ['period_status'])(tagFilter)),
	};
};

/**
 * Transform tag filter list
 * @param {Array} tagFilters
 * @returns {Array} transformed tag filters list
 */
export const transformedTagFilterList = (tagFilters = []) => {
	return tagFilters.map(transformedTagFilterGeneralInfo);
};

/**
 *	Transform tag filter (edit)
 * @param {Object} tagFilter
 * @returns transformed tag filter
 */
export const transformedTagFilter = (tagFilter = {}) => {
	const startDate = tagFilter.start_at ? dayjs(tagFilter.start_at).toDate() : null;
	const startTime = tagFilter.start_at ? datetimeFormat(tagFilter.start_at, 'HH:mm') : '00:00';
	const endDate = tagFilter.end_at ? dayjs(tagFilter.end_at).toDate() : null;
	const endTime = tagFilter.end_at ? datetimeFormat(tagFilter.end_at, 'HH:mm') : '23:59';

	return {
		id: tagFilter?.id || null,
		nameEn: pathOr(null, ['name_en'])(tagFilter),
		nameTh: pathOr(null, ['name_th'])(tagFilter),
		slug: tagFilter?.slug || null,
		startDate,
		startTime,
		endDate,
		endTime,
		priority: pathOr(0, ['priority'])(tagFilter),
		status: tagFilter?.status === TAG_FILTER_STATUS.ACTIVE,
		periodStatus: transformedTagFilterPeriodStatus(pathOr(null, ['period_status'])(tagFilter)),
		configBy: pathOr(null, ['config_by'])(tagFilter),
		configData: pathOr([], ['config_data'])(tagFilter),
		createdAt: tagFilter.created_at ? datetimeFormat(tagFilter.created_at) : '-',
		updatedAt: tagFilter.updated_at ? datetimeFormat(tagFilter.updated_at) : '-',
	};
};

/**
 * Transform tag filter form data to API
 * @param {Object} formData
 * @returns Transformed form data
 */
export const transformedTagFilterForm = (formData) => {
	return {
		name_th: formData.nameTh,
		name_en: formData.nameEn,
		slug: formData.slug,
		priority: Number(formData.priority, 10),
		status: formData.status ? TAG_FILTER_STATUS.ACTIVE : TAG_FILTER_STATUS.INACTIVE,
		start_at: convertDateTimeToUTC(formData.startDate, formData.startTime),
		end_at: convertDateTimeToUTC(formData.endDate, formData.endTime),
		config_by: formData.configBy,
		config_data: formData.configData,
	};
};
