<template>
	<div>
		<div class="position-relative d-flex justify-content-between">
			<BaseInputImage
				:thumbnail="showPreviewImage(previewImage)"
				title="Add cover"
				class="mr-4"
				@onClick="handleClickPreviewImage"
				@onDelete="handleDeletePreviewImage"
			/>

			<div class="d-flex w-100 input-container">
				<CInput
					v-model.trim="$v.localLink.$model"
					:is-valid="!$v.localLink.$error && null"
					:invalid-feedback="$t('global.error.url')"
					:label="label"
					:placeholder="placeholder"
					class="w-100 mb-0"
					type="text"
					@input="handleLinkChange"
				/>

				<CButton
					class="btn-delete-item"
					@click="handleDeleteItem"
				>
					<CIcon name="cil-trash" />
				</CButton>
			</div>
		</div>

		<ModalMediaLibrary
			ref="media-library"
			type="single"
			@onSubmit="handleSubmitMedia"
		/>
	</div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';

import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';
import { getThumbnailFromMediaObject } from '../assets/js/helpers';

export default {
	name: 'BaseInputMedia',
	components: {
		ModalMediaLibrary,
	},
	validations: {
		localLink: {
			required,
			url,
		},
	},
	props: {
		link: {
			type: String,
			default: null,
		},
		file: {
			type: Object,
			default: () => ({}),
		},
		index: {
			type: Number,
			default: 0,
		},
		label: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			localLink: null,
			previewImage: null,
		};
	},
	created() {
		this.localLink = this.link;
		this.previewImage = this.file;
	},
	methods: {
		showPreviewImage(image) {
			return getThumbnailFromMediaObject(image);
		},

		handleClickPreviewImage() {
			this.$refs['media-library'].open();
		},

		handleDeletePreviewImage() {
			this.previewImage = null;

			this.$emit('onInputImage', null, this.index);
		},

		handleDeleteItem() {
			this.$emit('onDelete', this.index);
		},

		handleSubmitMedia(images) {
			this.previewImage = {
				...images[0],
				urls: images[0].imageUrls,
			};

			this.$emit('onInputImage', images[0], this.index);
		},

		handleLinkChange(value) {
			this.localLink = value;

			this.$emit('onLinkChange', this.localLink, this.index);
		},
	},
};
</script>

<style lang="scss" scoped>
	.input-container {
		margin-right: rem(54);
	}

	.btn-delete-item {
		position: absolute;
		top: 30px;
		right: 0;
		color: $color-black-25;
	}
</style>
