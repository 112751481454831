import dayjs from 'dayjs';
import { transformedMedia } from '../transform/mediaLibrary';
import {
	BANNER_STATUSES,
	BANNER_PERIODS,
} from '../../../enums/banners';
import { IMAGE_PLACEHOLDER_URL } from '../../../enums/general';
import { pathOr, datetimeFormat } from '../helpers';

import { transformedProductLabels } from './productLabels';

export const transformedWidgetList = (widget = {}) => {
	return pathOr(null, ['name'])(widget);
};

export const transformedContentBlockList = (contentBlock = {}) => {
	let period = null;
	const id = pathOr(0, ['id'])(contentBlock);

	const thumbnail = pathOr(IMAGE_PLACEHOLDER_URL, ['file', 'urls', 'original'])(contentBlock);
	const name = pathOr(null, ['name'])(contentBlock);
	const startDate = contentBlock.start_at ? datetimeFormat(contentBlock.start_at) : '-';
	const endDate = contentBlock.end_at ? datetimeFormat(contentBlock.end_at) : '-';
	const priority = pathOr(0, ['priority'])(contentBlock);
	const status = BANNER_STATUSES[!!pathOr(false, ['is_visible'])(contentBlock)];
	const currentTimeFormat = new Date().toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
	const currentTimestamp = new Date(currentTimeFormat).getTime();
	const startAtTimestamp = startDate ? new Date(startDate).getTime() : null;
	const endAtTimestamp = startDate ? new Date(endDate).getTime() : null;
	const widgetList = pathOr([], ['widgets'])(contentBlock);
	const widgets = widgetList.map(transformedWidgetList);

	/**
	 * Comapre current time with start at and end at
	 */
	if (currentTimestamp < startAtTimestamp) {
		period = BANNER_PERIODS.scheduled;
	} else if (
		currentTimestamp > startAtTimestamp
		&& (currentTimestamp < endAtTimestamp || !endAtTimestamp)
	) {
		period = BANNER_PERIODS.ongoing;
	} else if (currentTimestamp > endAtTimestamp) {
		period = BANNER_PERIODS.expired;
	} else {
		period = { name: '-', value: '-' };
	}

	return {
		id,
		thumbnail,
		name,
		period,
		startDate,
		endDate,
		priority,
		widgets,
		status,
	};
};

export const transformedContentBlockDetail = (contentBlock = {}) => {
	// general and date
	const id = pathOr(0, ['id'])(contentBlock);
	const name = pathOr(null, ['name'])(contentBlock);
	const priority = pathOr(0, ['priority'])(contentBlock);
	const status = !!pathOr(false, ['is_visible'])(contentBlock);
	const startDate = contentBlock.start_at ? dayjs(contentBlock.start_at).toDate() : null;
	const startTime = contentBlock.start_at ? datetimeFormat(contentBlock.start_at, 'HH:mm') : '00:00';
	const endDate = contentBlock.end_at ? dayjs(contentBlock.end_at).toDate() : null;
	const endTime = contentBlock.end_at ? datetimeFormat(contentBlock.end_at, 'HH:mm') : '23:59';
	const widgets = pathOr(null, ['widgets'])(contentBlock);
	// content
	const titleEn = pathOr('', ['title_en'])(contentBlock);
	const titleTh = pathOr('', ['title_th'])(contentBlock);
	const descriptionEn = pathOr('', ['description_en'])(contentBlock);
	const descriptionTh = pathOr('', ['description_th'])(contentBlock);
	const linkTextEn = pathOr('', ['link_text_en'])(contentBlock);
	const linkTextTh = pathOr('', ['link_text_th'])(contentBlock);
	const link = pathOr('', ['link'])(contentBlock);
	const originFile = pathOr(null, ['file'])(contentBlock);
	const file = originFile ? transformedMedia(originFile) : null;
	const labels = transformedProductLabels(pathOr([], ['labels'])(contentBlock));

	return {
		id,
		name,
		priority,
		status,
		startDate,
		startTime,
		endDate,
		endTime,
		widgets,
		titleEn,
		titleTh,
		descriptionEn,
		descriptionTh,
		linkTextEn,
		linkTextTh,
		link,
		file,
		labels,
	};
};

