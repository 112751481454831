import { pathOr, priceFromAPI } from '../helpers';
import { transformedProductList } from './products';

export const transformedMap = (transformedFunc) => (data) => transformedFunc(data);

export const transformedAdditionalFee = (additionalFee = {}) => {
	const id = pathOr(0, ['id'])(additionalFee);
	const fee = priceFromAPI(pathOr('', ['fee'])(additionalFee));
	const type = pathOr('', ['type'])(additionalFee);
	const shippingMethodId = pathOr(null, ['shipping_method_id'])(additionalFee);
	const name = pathOr(0, ['name'])(additionalFee);
	let items = pathOr([], ['items'])(additionalFee);

	switch (type) {
		case 'product':
			items = transformedMap(transformedProductList)(items);
			break;

		default:
			break;
	}

	return {
		id,
		shippingMethodId,
		name,
		fee,
		type,
		items,
	};
};

export const transformedAdditionalFeeList = (additionalFees = []) => additionalFees.map(transformedAdditionalFee);
