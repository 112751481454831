import httpClient from './httpClient';

export const FOOTER_COLUMNS_ENDPOINT = '/backoffice/footer-columns';
export const FOOTERS_ENDPOINT = '/backoffice/footers';
export const FOOTERS_SORT_ENDPOINT = '/backoffice/footers/sort';

export const getFooterColumnsAPI = (params = {}) => httpClient.get(FOOTER_COLUMNS_ENDPOINT, { params });

export const getFootersAPI = (params = {}) => httpClient.get(FOOTERS_ENDPOINT, { params });
export const postFooterAPI = (params) => httpClient.post(FOOTERS_ENDPOINT, params);
export const updateFooterAPI = (id, params) => httpClient.put(`${FOOTERS_ENDPOINT}/${id}`, params);
export const deleteFooterAPI = (id) => httpClient.delete(`${FOOTERS_ENDPOINT}/${id}`);
export const postFooterSortAPI = (params) => httpClient.post(FOOTERS_SORT_ENDPOINT, params);
