import { pathOr, priceFromAPI, covertTimeFromAPI } from '../helpers';
import { transformedProductList } from './products';

export const transformedBusinessHour = (businessHour = {}) => {
	const day = pathOr(null, ['day'])(businessHour);
	const isActive = pathOr(false, ['is_active'])(businessHour);
	const from = pathOr(null, ['hour', 'from'])(businessHour);
	const to = pathOr(null, ['hour', 'to'])(businessHour);
	return {
		day,
		isActive,
		from: from ? covertTimeFromAPI(from) : null,
		to: to ? covertTimeFromAPI(to) : null,
	};
};

export const transformedShippingMethod = (shippingMethod = {}) => {
	const id = pathOr(0, ['id'])(shippingMethod);
	let branches = pathOr([], ['branches'])(shippingMethod);
	const categories = pathOr([], ['categories'])(shippingMethod);
	const paymentMethodSettings = pathOr([], ['shipping_payment_method_setting', 'payment_method_setting'])(shippingMethod);
	const estimatedDeliveryHoursFrom = pathOr('0', ['estimated_delivery_hours_from'])(shippingMethod);
	const estimatedDeliveryHoursTo = pathOr('0', ['estimated_delivery_hours_to'])(shippingMethod);
	const isActive = !!pathOr(false, ['is_active'])(shippingMethod);
	const isSpecifyDeliveryTimeActive = !!pathOr(false, ['is_specify_delivery_time_active'])(shippingMethod);
	const businessHours = pathOr([], ['business_hours'])(shippingMethod).map(transformedBusinessHour);
	const maxHeight = pathOr('', ['max_height'])(shippingMethod);
	const maxLength = pathOr('', ['max_length'])(shippingMethod);
	const maxQuantity = pathOr('', ['max_quantity'])(shippingMethod);
	const maxWeight = pathOr('', ['max_weight'])(shippingMethod);
	const maxWidth = pathOr('', ['max_width'])(shippingMethod);
	const minOrder = priceFromAPI(pathOr('0.00', ['min_order'])(shippingMethod));
	const maxOrder = priceFromAPI(pathOr('0.00', ['max_order'])(shippingMethod));
	const name = pathOr('', ['name'])(shippingMethod);
	const postcodes = pathOr([], ['postcodes'])(shippingMethod).map((item) => item.id);
	const excludedProducts = transformedProductList(pathOr([], ['excluded_products'])(shippingMethod));
	const excludedCustomerGroups = transformedProductList(pathOr([], ['excluded_customer_groups'])(shippingMethod));
	const termsAndConditionsEN = pathOr('', ['terms_and_conditions_en'])(shippingMethod);
	const termsAndConditionsTH = pathOr('', ['terms_and_conditions_th'])(shippingMethod);

	if (branches.length) {
		branches = branches.map((item) => ({
			id: item.id,
			isStandard: item.is_standard,
			isExpress: item.is_express,
		}));
	}

	return {
		id,
		branches,
		categories,
		paymentMethodSettings,
		estimatedDeliveryHoursFrom,
		estimatedDeliveryHoursTo,
		isActive,
		isSpecifyDeliveryTimeActive,
		businessHours,
		maxHeight,
		maxLength,
		maxQuantity,
		maxWeight,
		maxWidth,
		minOrder,
		maxOrder,
		name,
		postcodes,
		excludedProducts,
		excludedCustomerGroups,
		termsAndConditionsEN,
		termsAndConditionsTH,
	};
};

export const transformedShippingMethodList = (shippingMethods = []) => {
	return shippingMethods.map((shippingMethod) => transformedShippingMethod(shippingMethod));
};

export const transformedShippingMethodDropdownOption = (shippingMethod = {}) => {
	return {
		value: shippingMethod.id || null,
		name: shippingMethod.name || null,
	};
};
