<template>
	<div>
		<div
			:class="{ 'is-invalid': !isValid }"
			class="list-empty color-black-45 d-flex align-items-center justify-content-center rounded-sm"
		>
			{{ text }}
		</div>
		<div
			v-show="!isValid"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseNoItemBanner',
	props: {
		isValid: {
			type: Boolean,
			default: true,
		},
		text: {
			type: String,
			default: null,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
	.list-empty {
		height: rem(140);
		background-color: $color-gray-100;
	}

	.is-invalid {
		border: 1px solid $color-alert;
		border-radius: rem(4);
	}

	.invalid-feedback {
		display: block;
	}
</style>
