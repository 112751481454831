export const PRODUCT_IMPORT_HISTORY_FIELDS = [
	{ key: 'file', label: 'Filename', class: 'col-filename' },
	{ key: 'type', label: 'Type', class: 'col-type' },
	{ key: 'logfile', label: 'Log file', class: 'col-logfile' },
	{ key: 'uploader', label: 'Uploaded by', class: 'col-uploader' },
	{ key: 'createdAt', label: 'Created At', class: 'col-created-at' },
	{ key: 'status', label: 'Status', class: 'col-status' },
];

export const PRODUCT_IMPORT_HISTORY_STATUSES = {
	'in-progress': { name: 'Started', value: 'secondary' },
	success: { name: 'Success', value: 'success' },
	failed: { name: 'Failed', value: 'danger' },
};
