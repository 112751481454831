import httpClient from './httpClient';

export const FEED_ENDPOINT = '/backoffice/product-feeds';

export const getFeedListAPI = (params = {}) => httpClient.get(FEED_ENDPOINT, { params });
export const getFeedAPI = (id) => httpClient.get(`${FEED_ENDPOINT}/${id}`);
export const createFeedAPI = (params = {}) => httpClient.post(FEED_ENDPOINT, params);
export const updateFeedAPI = (id, params) => httpClient.put(`${FEED_ENDPOINT}/${id}`, params);
export const deleteFeedAPI = (id) => httpClient.delete(`${FEED_ENDPOINT}/${id}`);
export const postFeedRemarkAPI = (id, params) => httpClient.post(`${FEED_ENDPOINT}/${id}/remarks`, params);
