import { schema } from 'normalizr';

export const subdistrictEntity = new schema.Entity(
	'subdistricts',
	{},
	{
		processStrategy(value, parent) {
		// Add district id to subdistrict
			return {
				...value,
				district: parent[this.idAttribute],
			};
		},
	},
);

export const districtEntity = new schema.Entity(
	'districts',
	{
		subdistricts: [subdistrictEntity],
	},
	{
		processStrategy(value, parent) {
			// Add province id to district
			return {
				...value,
				province: parent[this.idAttribute],
			};
		},
	},
);

export const provinceEntity = new schema.Entity('provinces', {
	districts: [districtEntity],
});
