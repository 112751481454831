import httpClient from './httpClient';

export const CATEGORIES_ENDPOINT = '/backoffice/categories';
export const SORT_CATEGORIES_ENDPOINT = `${CATEGORIES_ENDPOINT}/sort`;
export const IMPORT_CATEGORY_ENDPOINT = '/backoffice/imports/categories';

export const getCategoryAPI = (id) => httpClient.get(`${CATEGORIES_ENDPOINT}/${id}`);
export const getCategoriesAPI = () => httpClient.get(CATEGORIES_ENDPOINT);
export const postCategoryAPI = (params) => httpClient.post(CATEGORIES_ENDPOINT, params);
export const postSortCategoriesAPI = (params) => httpClient.post(SORT_CATEGORIES_ENDPOINT, params);
export const putCategoryAPI = (id, params) => httpClient.put(`${CATEGORIES_ENDPOINT}/${id}`, params);
export const deleteCategoryAPI = (id) => httpClient.delete(`${CATEGORIES_ENDPOINT}/${id}`);
export const updateAttributeStatusAPI = (id, params) => httpClient.put(`${CATEGORIES_ENDPOINT}/${id}/attribute-key`, params);
export const importProductRankingAPI = (file, categoryId, params = {}) => {
	const formData = new FormData();
	formData.append('file', file);
	return httpClient.post(
		`${IMPORT_CATEGORY_ENDPOINT}/${categoryId}`,
		formData,
		params,
	);
};
