import dayjs from 'dayjs';
import { pathOr, mergeText } from '../helpers';
import { transformedWarehouse } from './warehouses';

export const transformedCustomerAddressInfo = (address = {}) => {
	const id = pathOr(null, ['id'])(address);
	const addressName = pathOr(null, ['name'])(address);
	const customerAddressOne = pathOr(null, ['address_1'])(address);
	const customerAddressTwo = pathOr(null, ['address_2'])(address);
	const customerAddressSubdistrict = pathOr(null, ['subdistrict'])(address);
	const customerAddressDistrict = pathOr(null, ['district'])(address);
	const customerAddressProvince = pathOr(null, ['province'])(address);
	const customerAddressPostcode = pathOr(null, ['postcode'])(address);
	const customerFirstName = pathOr(null, ['first_name'])(address);
	const customerLastName = pathOr(null, ['last_name'])(address);
	const customerPhone = pathOr(null, ['phone_number'])(address);
	const customerDetail = pathOr(null, ['detail'])(address);
	const isDefault = pathOr(null, ['is_default'])(address);
	const isJuristic = pathOr(null, ['is_juristic'])(address);

	const customerInfo = mergeText([
		`${customerFirstName} ${customerLastName} (${customerPhone})`,

	]);
	const customerAddress = mergeText([
		`${customerAddressOne} ${customerAddressTwo}`,
		customerAddressSubdistrict.name,
		customerAddressDistrict.name,
		customerAddressProvince.name,
		customerAddressPostcode,
	]);

	return {
		id,
		addressName,
		customerAddress,
		customerInfo,
		customerDetail,
		isDefault,
		isJuristic,
	};
};

export const transformedCustomer = (customer = {}) => {
	const id = pathOr(null, ['id'])(customer);
	const firstName = pathOr(null, ['first_name'])(customer);
	const lastName = pathOr(null, ['last_name'])(customer);
	const fullname = `${firstName ?? ''} ${lastName ?? ''}`;
	const email = pathOr(null, ['email'])(customer);
	const emailVerifiedAt = pathOr(null, ['email_verified_at'])(customer);
	const phone = pathOr('-', ['phone_number'])(customer);
	const customerGroup = pathOr(null, ['customer_group'])(customer);
	const customerStatus = pathOr(null, ['customer_status'])(customer);
	const branch = transformedWarehouse(pathOr({}, ['branch'])(customer));
	const store = {
		name: branch?.nameTH || null,
		value: branch?.id || null, // transformer return 0 as default
	};
	const staffId = pathOr(null, ['staff_id'])(customer);
	const isActive = !!pathOr(false, ['is_active'])(customer);
	const shippingAddresses = pathOr([], ['shipping_address'])(customer).map(transformedCustomerAddressInfo);
	const billingAddresses = pathOr([], ['billing_address'])(customer).map(transformedCustomerAddressInfo);
	const taxAddresses = pathOr([], ['tax_address'])(customer).map(transformedCustomerAddressInfo);
	const provider = pathOr('-', ['provider'])(customer);
	const birthdate = customer?.birthdate ? dayjs(customer.birthdate).toDate() : null;

	return {
		id,
		firstName,
		lastName,
		fullname,
		name,
		email,
		emailVerifiedAt,
		store,
		isActive,
		phone,
		staffId,
		customerGroup,
		customerStatus,
		shippingAddresses,
		billingAddresses,
		taxAddresses,
		provider,
		birthdate,
	};
};
