import {
	getContentBlocksAPI,
	getContentBlockAPI,
	createContentBlockAPI,
	updateContentBlockAPI,
	deleteContentBlockAPI,
} from '../../services/api/contentBlocks.api';
import {
	GET_CONTENT_BLOCKS_REQUEST,
	GET_CONTENT_BLOCKS_SUCCESS,
	GET_CONTENT_BLOCKS_FAILURE,
	GET_CONTENT_BLOCK_REQUEST,
	GET_CONTENT_BLOCK_SUCCESS,
	GET_CONTENT_BLOCK_FAILURE,
	CREATE_CONTENT_BLOCK_REQUEST,
	CREATE_CONTENT_BLOCK_SUCCESS,
	CREATE_CONTENT_BLOCK_FAILURE,
	UPDATE_CONTENT_BLOCK_REQUEST,
	UPDATE_CONTENT_BLOCK_SUCCESS,
	UPDATE_CONTENT_BLOCK_FAILURE,
	DELETE_CONTENT_BLOCK_REQUEST,
	DELETE_CONTENT_BLOCK_SUCCESS,
	DELETE_CONTENT_BLOCK_FAILURE,
} from '../types';
import {
	transformedContentBlockList,
	transformedContentBlockDetail,
} from '../../assets/js/transform/contentBlocks';
import { pathOr } from '../../assets/js/helpers';
import { i18n } from '../../i18n';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: [],
			meta: transformedMeta(),
		},
		create: {
			isLoading: false,
			isSuccess: false,
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			isSuccess: false,
			data: {},
		},
		delete: {
			isLoading: false,
			isSuccess: false,
		},
	},

	getters: {
		getContentBlocksList(state) {
			return state.list.data.map(transformedContentBlockList);
		},

		getContentBlockDetail(state) {
			return transformedContentBlockDetail(state.edit.data);
		},
	},

	mutations: {
		// Get content blocks
		[GET_CONTENT_BLOCKS_REQUEST](state) {
			state.list = {
				...state.list,
				data: [],
				isLoading: true,
			};
		},
		[GET_CONTENT_BLOCKS_SUCCESS](state, { data = [], meta }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
				meta: transformedMeta(meta),
			};
		},
		[GET_CONTENT_BLOCKS_FAILURE](state) {
			state.list = {
				...state.list,
				data: [],
				isLoading: false,
			};
		},

		// Get content block
		[GET_CONTENT_BLOCK_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_CONTENT_BLOCK_SUCCESS](state, { data = {} }) {
			state.edit = {
				...state.edit,
				isLoading: false,
				data,
			};
		},
		[GET_CONTENT_BLOCK_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},

		// Create block content
		[CREATE_CONTENT_BLOCK_REQUEST](state) {
			state.create = {
				...state.create,
				isLoading: true,
				isSuccess: false,
			};
		},
		[CREATE_CONTENT_BLOCK_SUCCESS](state) {
			state.create = {
				...state.create,
				isLoading: false,
				isSuccess: true,
			};
		},
		[CREATE_CONTENT_BLOCK_FAILURE](state) {
			state.create = {
				...state.create,
				isLoading: false,
				isSuccess: false,
			};
		},

		// Update block content
		[UPDATE_CONTENT_BLOCK_REQUEST](state) {
			state.edit.isUpdating = true;
			state.edit.isSuccess = false;
		},
		[UPDATE_CONTENT_BLOCK_SUCCESS](state) {
			state.edit.isUpdating = false;
			state.edit.isSuccess = true;
		},
		[UPDATE_CONTENT_BLOCK_FAILURE](state) {
			state.edit.isUpdating = false;
			state.edit.isSuccess = false;
		},

		// Delete content block
		[DELETE_CONTENT_BLOCK_REQUEST](state) {
			state.delete = {
				...state.delete,
				isLoading: true,
				isSuccess: false,
			};
		},
		[DELETE_CONTENT_BLOCK_SUCCESS](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
				isSuccess: true,
			};
		},
		[DELETE_CONTENT_BLOCK_FAILURE](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
				isSuccess: false,
			};
		},
	},

	actions: {
		async getContentBlocks({ commit }, params = {}) {
			try {
				commit(GET_CONTENT_BLOCKS_REQUEST);
				const { data } = await getContentBlocksAPI(params);

				commit(GET_CONTENT_BLOCKS_SUCCESS, data);
			} catch (error) {
				commit(GET_CONTENT_BLOCKS_FAILURE);
			}
		},

		async getContentBlock({ commit }, { id, type }) {
			try {
				commit(GET_CONTENT_BLOCK_REQUEST);
				const { data } = await getContentBlockAPI(id, type);

				commit(GET_CONTENT_BLOCK_SUCCESS, data);
			} catch (error) {
				commit(GET_CONTENT_BLOCK_FAILURE);
				window.location = '/404'; // Redirect to 404
			}
		},

		async createContentBlock({ commit, dispatch }, contentBlockData) {
			try {
				commit(CREATE_CONTENT_BLOCK_REQUEST);

				await createContentBlockAPI(contentBlockData);
				commit(CREATE_CONTENT_BLOCK_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageCreate', { type: 'page' }),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_CONTENT_BLOCK_FAILURE, error);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},

		async updateContentBlock({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_CONTENT_BLOCK_REQUEST);

				const response = await updateContentBlockAPI(id, params);
				const data = pathOr({}, ['data', 'data'])(response);

				commit(UPDATE_CONTENT_BLOCK_SUCCESS, { data });

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('page.contentBlock.edit.successMessage', { type: 'page' }),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_CONTENT_BLOCK_FAILURE);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},

		async deleteContentBlock({ commit, dispatch }, id) {
			try {
				commit(DELETE_CONTENT_BLOCK_REQUEST);

				await deleteContentBlockAPI(id);
				commit(DELETE_CONTENT_BLOCK_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageDelete', { type: 'Content block' }),
						header: i18n.t('global.successMessageTitleDelete'),
					},
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_CONTENT_BLOCK_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleDelete'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
