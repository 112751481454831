import {
	GET_TAG_FILTER_LIST_REQUEST,
	GET_TAG_FILTER_LIST_SUCCESS,
	GET_TAG_FILTER_LIST_FAILURE,
	GET_TAG_FILTER_REQUEST,
	GET_TAG_FILTER_SUCCESS,
	GET_TAG_FILTER_FAILURE,
	CREATE_TAG_FILTER_REQUEST,
	CREATE_TAG_FILTER_SUCCESS,
	CREATE_TAG_FILTER_FAILURE,
	UPDATE_TAG_FILTER_REQUEST,
	UPDATE_TAG_FILTER_SUCCESS,
	UPDATE_TAG_FILTER_FAILURE,
	DELETE_TAG_FILTER_REQUEST,
	DELETE_TAG_FILTER_SUCCESS,
	DELETE_TAG_FILTER_FAILURE,
	CLEAR_CREATE_TAG_FILTER_ERROR,
	CLEAR_EDIT_TAG_FILTER_ERROR,
} from '../types';

import {
	getTagFiltersAPI,
	getTagFilterAPI,
	createTagFilterAPI,
	updateTagFilterAPI,
	deleteTagFilterAPI,
} from '../../services/api/tagFilters.api';
import {
	transformedTagFilterList,
	transformedTagFilter,
	transformedTagFilterForm,
} from '../../assets/js/transform/tagFilters';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';

const defaultFieldErrors = {
	fieldErrors: {
		slug: false,
		availableProduct: false,
	},
};

export default {
	namespaced: true,
	state: {
		// Tag filters list
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		create: {
			isCreating: false,
			...defaultFieldErrors,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
			...defaultFieldErrors,
		},
	},
	getters: {
		tagFilterList(state) {
			return state.data;
		},
		getTagFilter(state) {
			return state.edit.data;
		},
	},
	mutations: {
		[GET_TAG_FILTER_LIST_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_TAG_FILTER_LIST_SUCCESS](state, { data = [], meta = {} }) {
			state.list.data = transformedTagFilterList(data);
			state.list.meta = transformedMeta(meta);
			state.list.isLoading = false;
		},
		[GET_TAG_FILTER_LIST_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[GET_TAG_FILTER_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_TAG_FILTER_SUCCESS](state, { data = {} }) {
			state.edit.data = transformedTagFilter(data);
			state.edit.isLoading = false;
		},
		[GET_TAG_FILTER_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[CREATE_TAG_FILTER_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_TAG_FILTER_SUCCESS](state) {
			state.create.fieldErrors.slug = false;
			state.create.fieldErrors.availableProduct = false;
			state.create.isCreating = false;
		},
		[CREATE_TAG_FILTER_FAILURE](state, error) {
			const isErrorSlug = !!(pathOr(false, ['response', 'data', 'errors', 'slug', 0])(error));
			const isErrorConfigData = !!(pathOr(false, ['response', 'data', 'errors', 'config_data', 0])(error));

			state.create.isCreating = false;
			state.create.fieldErrors.slug = isErrorSlug;
			state.create.fieldErrors.availableProduct = isErrorConfigData;
		},
		[UPDATE_TAG_FILTER_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_TAG_FILTER_SUCCESS](state) {
			state.edit.fieldErrors.slug = false;
			state.edit.fieldErrors.availableProduct = false;
			state.edit.isUpdating = false;
		},
		[UPDATE_TAG_FILTER_FAILURE](state, error) {
			const isErrorSlug = !!(pathOr(false, ['response', 'data', 'errors', 'slug', 0])(error));
			const isErrorConfigData = !!(pathOr(false, ['response', 'data', 'errors', 'config_data', 0])(error));

			state.edit.isUpdating = false;
			state.edit.fieldErrors.slug = isErrorSlug;
			state.edit.fieldErrors.availableProduct = isErrorConfigData;
		},
		[DELETE_TAG_FILTER_REQUEST]() {},
		[DELETE_TAG_FILTER_SUCCESS]() {},
		[DELETE_TAG_FILTER_FAILURE]() {},

		[CLEAR_CREATE_TAG_FILTER_ERROR](state) {
			state.create.fieldErrors.slug = false;
			state.create.fieldErrors.availableProduct = false;
		},

		[CLEAR_EDIT_TAG_FILTER_ERROR](state) {
			state.edit.fieldErrors.slug = false;
			state.edit.fieldErrors.availableProduct = false;
		},
	},
	actions: {
		async getTagFilters({ commit }, params = {}) {
			try {
				commit(GET_TAG_FILTER_LIST_REQUEST);
				const { data } = await getTagFiltersAPI(params);
				commit(GET_TAG_FILTER_LIST_SUCCESS, data);
			} catch (error) {
				commit(GET_TAG_FILTER_LIST_FAILURE);
			}
		},
		async getTagFilter({ commit }, id) {
			try {
				commit(GET_TAG_FILTER_REQUEST);
				const { data } = await getTagFilterAPI(id);
				commit(GET_TAG_FILTER_SUCCESS, data);
			} catch (error) {
				commit(GET_TAG_FILTER_FAILURE);
			}
		},
		async createTagFilter({ commit, dispatch }, params) {
			try {
				commit(CREATE_TAG_FILTER_REQUEST);
				await createTagFilterAPI(transformedTagFilterForm(params));
				commit(CREATE_TAG_FILTER_SUCCESS);
				dispatch('toast/showCreateSuccessToast', 'Tag filter', { root: true });
			} catch (error) {
				commit(CREATE_TAG_FILTER_FAILURE, error);
				dispatch('toast/showCreateErrorToast', null, { root: true });
				throw error;
			}
		},
		async updateTagFilter({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_TAG_FILTER_REQUEST);
				const { data } = await updateTagFilterAPI(id, transformedTagFilterForm(params));
				commit(UPDATE_TAG_FILTER_SUCCESS, data);
				dispatch('getTagFilter', id);
				dispatch('toast/showUpdateSuccessToast', null, { root: true });
			} catch (error) {
				commit(UPDATE_TAG_FILTER_FAILURE, error);
				dispatch('toast/showUpdateErrorToast', null, { root: true });
				throw error;
			}
		},
		async deleteTagFilter({ commit, dispatch }, id) {
			try {
				commit(DELETE_TAG_FILTER_REQUEST);
				await deleteTagFilterAPI(id);
				commit(DELETE_TAG_FILTER_SUCCESS);
				dispatch('toast/showDeleteSuccessToast', 'Bundle set', { root: true });
			} catch (error) {
				commit(DELETE_TAG_FILTER_FAILURE);
				dispatch('toast/showDeleteErrorToast', null, { root: true });
				throw error;
			}
		},
		clearTagFilterCreateError({ commit }) {
			commit(CLEAR_CREATE_TAG_FILTER_ERROR);
		},
		clearTagFilterEditError({ commit }) {
			commit(CLEAR_EDIT_TAG_FILTER_ERROR);
		},
	},
};
