import attributeSets from './page/attributeSets/en';
import attributeKeys from './page/attributeKeys/en';
import limitProduct from './page/limitProduct/en';
import banner from './page/banner/en';
import categories from './page/categories/en';
import contentBlock from './page/contentBlock/en';
import login from './page/login/en';
import products from './page/products/en';
import searchSynonims from './page/searchSynonims/en';
import users from './page/users/en';
import productLabels from './page/productLabels/en';
import modalMediaLibrary from './components/modalMediaLibrary/en';
import contentWrapper from './components/contentWrapper/en';
import manageFilter from './components/manageFilter/en';
import accordionKeys from './page/accordionKeys/en';
import global from './global/en';

export default {
	page: {
		attributeSets,
		attributeKeys,
		banner,
		categories,
		contentBlock,
		login,
		products,
		searchSynonims,
		users,
		productLabels,
		accordionKeys,
		limitProduct,
	},
	components: {
		modalMediaLibrary,
		manageFilter,
		contentWrapper,
	},
	global,
};
