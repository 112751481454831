import {
	getAccordionKeysAPI,
	createAccordionKeyAPI,
	deleteAccordionKeyAPI,
	putAccordionKeyAPI, getAccordionKeyAPI,
} from '@/services/api/accordionKeys.api';
import {
	GET_ACCORDION_KEYS_REQUEST,
	GET_ACCORDION_KEYS_SUCCESS,
	GET_ACCORDION_KEY_SUCCESS,
	GET_ACCORDION_KEYS_FAILURE,
	CREATE_ACCORDION_KEY_REQUEST,
	CREATE_ACCORDION_KEY_SUCCESS,
	CREATE_ACCORDION_KEY_FAILURE,
	RESET_ERROR_CREATE_ACCORDION_KEY,
	DELETE_ACCORDION_KEY_REQUEST,
	DELETE_ACCORDION_KEY_SUCCESS,
	DELETE_ACCORDION_KEY_FAILURE,
	GET_ACCORDION_KEY_FAILURE,
	UPDATE_ACCORDION_KEY_REQUEST,
	UPDATE_ACCORDION_KEY_SUCCESS,
	UPDATE_ACCORDION_KEY_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import { transformedAccordionKeyList, transformedAccordionKey, transformedFormAccordionKey } from '../../assets/js/transform/accordionKeys';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
				from: null,
				perPage: null,
				to: null,
				total: '0',
				path: '',
			},
		},
		create: {
			isSubmitting: false,
			isErrorDuplicateName: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
	},
	getters: {
		accordionList(state) {
			return state.list.data;
		},
	},
	mutations: {
		[GET_ACCORDION_KEYS_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...transformedMeta(state.list.meta),
					currentPage: page,
				},
			};
		},
		[GET_ACCORDION_KEYS_SUCCESS](state, { data, meta }) {
			state.list = {
				data,
				meta: transformedMeta(meta),
				isLoading: false,
			};
		},
		[GET_ACCORDION_KEY_FAILURE](state) {
			state.edit = {
				data: {},
				isLoading: false,
			};
		},
		[GET_ACCORDION_KEY_SUCCESS](state, { data }) {
			state.edit = {
				data,
				isLoading: false,
			};
		},
		[GET_ACCORDION_KEYS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_ACCORDION_KEY_REQUEST](state) {
			state.create = {
				...state.create,
				isSubmitting: true,
			};
		},
		[CREATE_ACCORDION_KEY_SUCCESS](state) {
			state.create = {
				...state.create,
				isSubmitting: false,
			};
		},
		[CREATE_ACCORDION_KEY_FAILURE](state, isErrorDuplicateName = false) {
			state.create = {
				...state.create,
				isSubmitting: false,
				isErrorDuplicateName,
			};
		},
		[RESET_ERROR_CREATE_ACCORDION_KEY](state) {
			state.create = {
				...state.create,
				isErrorDuplicateName: false,
			};
		},
		[UPDATE_ACCORDION_KEY_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_ACCORDION_KEY_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_ACCORDION_KEY_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_ACCORDION_KEY_REQUEST]() {},
		[DELETE_ACCORDION_KEY_SUCCESS]() {},
		[DELETE_ACCORDION_KEY_FAILURE]() {},
	},
	actions: {
		async getAccordionKey({ commit }, id) {
			try {
				const response = await getAccordionKeyAPI(id);
				const data = transformedAccordionKey(pathOr([], ['data', 'data'])(response));
				commit(GET_ACCORDION_KEY_SUCCESS, { data });
			} catch (error) {
				commit(GET_ACCORDION_KEY_FAILURE);
			}
		},
		async getAccordionKeys({ commit }, query) {
			try {
				commit(GET_ACCORDION_KEYS_REQUEST, query);
				const response = await getAccordionKeysAPI(query);
				const data = transformedAccordionKeyList(pathOr([], ['data', 'data'])(response));
				const meta = pathOr({}, ['data', 'meta'])(response);
				commit(GET_ACCORDION_KEYS_SUCCESS, { data, meta });
			} catch (error) {
				commit(GET_ACCORDION_KEYS_FAILURE);
			}
		},
		async createAccordionKey({ commit, dispatch }, params = {}) {
			try {
				commit(CREATE_ACCORDION_KEY_REQUEST);
				await createAccordionKeyAPI(transformedFormAccordionKey(params));
				commit(CREATE_ACCORDION_KEY_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('page.accordionKeys.create.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const errorDuplicateKey = 'duplicate_accordion_key_name';
				const isErrorDuplicateName = pathOr(false, ['response', 'data', 'error'])(error) === errorDuplicateKey;

				commit(CREATE_ACCORDION_KEY_FAILURE, isErrorDuplicateName);

				if (!isErrorDuplicateName) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					}, { root: true });
				}
			}
		},
		async updateAccordionKey({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_ACCORDION_KEY_REQUEST);

				const { data } = await putAccordionKeyAPI(id, transformedFormAccordionKey(params));

				commit(UPDATE_ACCORDION_KEY_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_ACCORDION_KEY_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		resetErrorCreateAccordionKey({ commit }) {
			commit(RESET_ERROR_CREATE_ACCORDION_KEY);
		},
		async deleteAccordionKey({ commit, dispatch }, accordionKeyId) {
			try {
				commit(DELETE_ACCORDION_KEY_REQUEST);
				await deleteAccordionKeyAPI(accordionKeyId);
				commit(DELETE_ACCORDION_KEY_SUCCESS);
			} catch (error) {
				commit(DELETE_ACCORDION_KEY_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
