import httpClient from './httpClient';

const PAGES_ENDPOINT = '/backoffice/pages';
const SORT_PAGES_ENDPOINT = `${PAGES_ENDPOINT}/sort`;

const getPageAPI = (id) => httpClient.get(`${PAGES_ENDPOINT}/${id}`);
const getPagesAPI = () => httpClient.get(PAGES_ENDPOINT);
const postPageAPI = (params) => httpClient.post(PAGES_ENDPOINT, params);
const postSortPagesAPI = (params) => httpClient.post(SORT_PAGES_ENDPOINT, params);
const putPageAPI = (id, params) => httpClient.put(`${PAGES_ENDPOINT}/${id}`, params);
const deletePageAPI = (id) => httpClient.delete(`${PAGES_ENDPOINT}/${id}`);

export {
	getPageAPI,
	getPagesAPI,
	postPageAPI,
	postSortPagesAPI,
	putPageAPI,
	deletePageAPI,
};
