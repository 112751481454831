<template>
	<div class="base-rich-text-editor">
		<template v-if="isCloseTextEditor">
			<div
				v-if="content"
				class="html-content-wrapper"
				@click="handleToggleTextEditor"
			>
				<!-- eslint-disable-next-line vue/no-v-html -->
				<div :class="['html-content rounded-sm', { 'is-invalid': !isValid }]" v-html="content"></div>
				<div :class="['html-content-overlay rounded-sm', { 'is-invalid': !isValid }]">
					<span class="click-here">Click here to edit.</span>
				</div>
			</div>
			<div
				v-else
				:class="['html-content-empty d-flex flex-column align-items-center justify-content-center rounded-sm', {'is-invalid': !isValid}]"
				@click="handleToggleTextEditor"
			>
				<span>{{ contentPlaceholder }}</span>
				<span class="click-here">Click here to edit.</span>
			</div>
		</template>
		<template v-else>
			<CRow>
				<CCol :class="editorClasses">
					<editor
						ref="editor"
						v-model="content"
						:api-key="apiKey"
						:init="editorOption"
						:placeholder="placeholder"
					/>
					<ModalMediaLibrary
						:ref="mediaLibraryRef"
						@onSubmit="handleSubmitMedia"
					/>
					<input
						:id="uploadContentInput"
						:ref="uploadContentRef"
						data-id="input-upload-content-upload"
						type="file"
						class="d-none"
						accept="zip, application/octet-stream, application/zip, application/x-zip, application/x-zip-compressed"
						@change="handleChangeFiles"
					>
					<CButton
						:id="mediaLibraryButton"
						class="btn-editor-media-library transparent"
						@click="$refs[mediaLibraryRef].open()"
					/>
					<CButton
						:id="productOverviewTemplateButton"
						class="btn-editor-media-library transparent"
						@click="onClickProductOverviewTemplate"
					/>

					<div v-if="isUploading" class="loading-container">
						<span class="text color-black-45">uploading...</span>
					</div>
				</CCol>
			</CRow>
		</template>
		<div
			v-if="invalidFeedback"
			class="invalid-feedback"
		>
			{{ invalidFeedback }}
		</div>
		<small v-if="description" class="description-text">{{ description }}</small>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import uniqueId from 'lodash/uniqueId';
import axios from 'axios';
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';
import Editor from '@tinymce/tinymce-vue';

import { uploadZipAPI } from '../services/api/files.api';
import { pathOr, validateImageFileName } from '../assets/js/helpers';

export default {
	name: 'BaseRichTextEditor',
	components: {
		Editor,
		ModalMediaLibrary,
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'medium',
			validator: (val) => ['small', 'medium', 'large'].includes(val),
		},
		name: {
			type: String,
			default: 'description',
		},
		description: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		contentPlaceholder: {
			type: String,
			default: 'There are no html content for any section.',
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		const mediaLibraryButton = uniqueId('btn-media-library-');
		const uploadContentInput = uniqueId('btn-upload-content-');
		const productOverviewTemplateButton = uniqueId('btn-product-overview-template-');

		return {
			mediaLibraryButton,
			uploadContentInput,
			productOverviewTemplateButton,
			apiKey: process.env.VUE_APP_TINYMCE_API_KEY,
			editorOption: {
				selector: 'textarea#full-featured',
				plugins: 'importcss searchreplace autolink save directionality visualblocks link media template codesample table charmap  nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap code',
				mobile: {
					plugins: 'importcss tinydrive searchreplace autolink save directionality visualblocks link media template codesample table charmap nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap code',
				},
				menubar: 'edit view insert format table',
				menu: {
					edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
					view: { title: 'View', items: 'code | visualaid' },
					insert: { title: 'Insert', items: 'image link media iframe' },
					format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
					table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
				},
				toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect | formatselect | insertfile image media link | mediaLibrary | uploadContent | productOverviewTemplate | alignleft aligncenter alignright alignjustify | numlist bullist | forecolor backcolor casechange removeformat',
				image_class_list: [
					{ title: 'None', value: '' },
					{ title: 'Some class', value: 'class-name' },
				],
				importcss_append: true,
				body_class: 'tinymce-container',
				content_css: '/assets/css/tinymce.css',
				height: 500,
				image_caption: true,
				quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote',
				noneditable_noneditable_class: 'mceNonEditable',
				toolbar_mode: 'wrap',
				content_style: '.mymention{ color: gray; }',
				setup(editor) {
					editor.ui.registry.addButton('mediaLibrary', {
						text: 'Media Library',
						onAction() {
							document.getElementById(mediaLibraryButton).click();
						},
					});

					editor.ui.registry.addButton('uploadContent', {
						text: 'Upload Content',
						onAction() {
							document.getElementById(uploadContentInput).click();
						},
					});

					editor.ui.registry.addButton('productOverviewTemplate', {
						text: 'Product overview template',
						onAction() {
							document.getElementById(productOverviewTemplateButton).click();
						},
					});
				},

				// enable to inject <style>
				valid_children: '+body[style]',
			},
			isUploading: false,
			isCloseTextEditor: true,
		};
	},
	computed: {
		mediaLibraryRef() {
			return `media-library`;
		},
		uploadContentRef() {
			return `upload-content`;
		},
		editorClasses() {
			return [`position-relative size-${this.size}`, { 'is-invalid': !this.isValid }];
		},
		content: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),
		handleSubmitMedia(files = []) {
			// this code to set image on your server to quill editor
			files.forEach((file) => {
				const image = file.imageUrls.original;
				this.$refs.editor.editor.insertContent(`<img src="${image}" alt="" />`);
			});
		},

		handleChangeFiles(event) {
			const imageFiles = pathOr([], ['target', 'files'])(event);
			this.contentData = null;

			if (imageFiles.length) {
				let error = null;
				const files = Array.from(imageFiles);
				const errorFileName = files.some((imageFile) => !validateImageFileName(imageFile.name));

				if (errorFileName) {
					const header = this.$t('components.modalMediaLibrary.error.uploadValidationHeader');
					let content = null;

					if (errorFileName) {
						content = this.$t('components.modalMediaLibrary.error.uploadValidationImageFileName');
					} else {
						content = this.$t('components.modalMediaLibrary.error.uploadValidationHeader');
					}

					error = { header, content };
				}

				this.handleUploadFiles({ files, error });
			}
		},

		async handleUploadFiles({ files = [], error = null }) {
			if (error) {
				this.showToast({
					type: 'danger',
					header: error.header,
					content: error.content,
				});
			} else {
				await this.uploadFiles({ files });
			}
		},

		async uploadFiles({ files }) {
			this.isUploading = true;
			this.uploadingSource = axios.CancelToken.source();

			for (const file of files) {
				try {
					const formData = new FormData();
					formData.append('zip', file);

					// eslint-disable-next-line
					const { data } = await uploadZipAPI(formData, this.uploadingSource);
					this.isCancel = false;
					this.isUploading = false;

					this.handleUpdateHtmlContent(data);
				} catch (error) {
					// Handle error code 'invalid_image_name'
					if (!this.isCancel) {
						this.showToast({
							type: 'danger',
							header: this.$t('components.modalMediaLibrary.error.uploadFailedHeader'),
							content: error?.response?.data?.message ?? this.$t('components.modalMediaLibrary.error.uploadFailedContent'),
						});
					}
				}
			}
		},

		handleUpdateHtmlContent(htmlContent) {
			// this code to set image on your server to quill editor
			this.$refs.editor.editor.insertContent(`<div style="height: 100%;"><iframe style="width: 100%; height: 100vh;" src="${htmlContent.data}" frameborder="0"></iframe></div>`);

			// reset input
			this.$refs[this.uploadContentRef].value = '';
		},

		onClickProductOverviewTemplate() {
			this.$refs.editor.editor.insertContent(`
				<div class="product-overview">
				<div class="product-overview-section">
				<div class="label">Title</div>
				<div class="detail">Detail</div>
				</div>
				<div class="product-overview-section">
				<div class="label">Title</div>
				<div class="detail">Detail</div>
				</div>
				<div class="product-overview-section">
				<div class="label">Title</div>
				<div class="detail">Detail 1<br />Detail 2<br />Detail 3</div>
				</div>
				<div class="product-overview-section">
				<div class="label">Title</div>
				<div class="detail">
				<div class="sub-detail">
				<div class="sub-detail-description">Detail 1</div>
				<div class="sub-detail-description">Detail 2</div>
				</div>
				<div class="sub-detail">
				<div class="sub-detail-description">Detail 3</div>
				<div class="sub-detail-description">Detail 4</div>
				</div>
				</div>
				</div>
				</div>
			`);
		},
		handleToggleTextEditor() {
			this.isCloseTextEditor = !this.isCloseTextEditor;
		},
	},
};
</script>

<style lang="scss" scoped>
.loading-container {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;

	width: calc(100% - #{rem(24)});
	height: calc(100% - #{rem(20)});
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: rem(4);
	margin-left: rem(12);
	margin-right: rem(12);
	margin-bottom: rem(16);

	background-color: $color-gray-100;
}

::v-deep .is-invalid {
	.tox-tinymce {
		border: 1px solid $color-alert;
	}

	.invalid-feedback {
		display: block;
	}
}

.btn-editor-media-library {
	&.-hide {
		position: absolute;
		z-index: -1;
	}
}

.description-text {
	color: $color-black-45;
	font-size: rem(12);
	font-weight: 500;
	line-height: 1.33;
}

.html-content-wrapper {
	position: relative;

	&:hover {
		.html-content-overlay {
			opacity: 0.7;
		}
	}
}

.html-content {
	border: 1px solid $color-gray-400;
	padding: rem(6) rem(12);
	height: rem(140);
	overflow: hidden;

	&.is-invalid {
		border-color: $color-alert;
	}
}

.html-content-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $color-gray-100;
	transition: 0.15s all ease-in-out;
	opacity: 0.2;

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	color: $color-black;
	text-align: center;
	font-size: rem(14);

	&.is-invalid {
		border-color: $color-alert;
	}
}

.html-content-empty {
	border: 1px solid $color-gray-400;
	height: rem(140);
	background-color: $color-gray-100;
	color: $color-black-45;
	text-align: center;
	font-size: rem(14);
	transition: 0.15s all ease-in-out;
	cursor: pointer;

	&:hover {
		color: $color-black-70;
	}

	&.is-invalid {
		border-color: $color-alert;
	}
}

.click-here {
	text-decoration: underline;
}
</style>
