import {
	pathOr,
} from '../helpers';

export const transformedVariantInfo = (variantInfo) => {
	return {
		id: pathOr(null, ['id'])(variantInfo),
		name: pathOr(null, ['name'])(variantInfo),
		contentEN: pathOr(null, ['content_en'])(variantInfo),
		contentTH: pathOr(null, ['content_th'])(variantInfo),
		isActive: pathOr(null, ['is_active'])(variantInfo),
	};
};

export const transformedVariantInfoList = (variantInfoList = []) => {
	return variantInfoList.map(transformedVariantInfo);
};
