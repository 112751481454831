<template>
	<CButton
		type="submit"
		:disabled="disabled"
		:color="isOpen ? 'secondary' : 'tertiary'"
		@click.prevent="handleClick"
	>
		<CIcon name="cil-filter" />
		<span class="ml-2">Filter</span>
		<span v-if="showNotify" class="notify"></span>
	</CButton>
</template>

<script>
export default {
	name: 'BaseFilterButton',
	props: {
		isOpen: {
			type: Boolean,
			default: false,
		},
		hasFilter: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showNotify() {
			return !this.isOpen && this.hasFilter;
		},
	},
	methods: {
		handleClick() {
			this.$emit('onClick');
		},
	},
};
</script>


<style lang="scss" scoped>
	.btn {
		position: relative;
	}

	.notify {
		width: rem(12);
		height: rem(12);
		position: absolute;
		top: -#{rem(6)};
		right: -#{rem(6)};
		border-radius: 50%;
		background-color: $color-alert;
	}
</style>

