<template>
	<div>
		<TheToastContainer />
		<router-view v-if="!profile.isLoading" />
		<BaseLoading v-else is-full-page />
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TheToastContainer from '@/components/TheToastContainer.vue';

export default {
	name: 'App',
	components: {
		TheToastContainer,
	},
	computed: {
		...mapState('profile', {
			profile: 'profile',
		}),

		...mapState({
			isIdle: (state) => state.idleVue.isIdle,
		}),
	},
	watch: {
		$route() {
			// Reset toast, page title when change page
			this.setPageTitle({
				title: '',
				isShow: true,
			});
		},
		isIdle(value) {
			// If user idle more than 1 hr then logout
			if (value) {
				this.$router.replace({ name: 'Logout' });
			}
		},
	},
	created() {
		this.setUsername();
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			hideToast: 'toast/hideToast',
			setUsername: 'auth/setUsername',
		}),
	},
};
</script>

<style lang="scss">
	// Import Main styles for this application
	@import "assets/scss/style";
</style>
