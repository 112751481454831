<template>
	<div class="base-input-date">
		<label v-if="label" data-test-id="label">
			{{ label }}
		</label>
		<div
			class="input-group input-group-date"
			:class="{ 'is-invalid': !isValid }"
		>
			<div
				v-if="prependText || hasPrependContentSlot"
				class="input-group-prepend"
			>
				<div class="input-group-text" data-test-id="prepend-text">
					<template v-if="hasPrependContentSlot">
						<slot name="prepend-content"></slot>
					</template>
					<template v-else>
						{{ prependText }}
					</template>
				</div>
			</div>
			<DatePicker
				:id="id"
				:value="value"
				:mode="mode"
				:min-date="minDate"
				:max-date="maxDate"
				:class="`text-${textAlign}`"
				:input-props="{
					class: 'form-control',
					disabled: disabled,
					placeholder: placeholder,
				}"
				:popover="{ placement: 'bottom', visibility: 'click' }"
				:masks="{ L: 'DD/MM/YYYY' }"
				:attributes="attributes"
				class="form-control-date"
				@input="handleInput"
			/>
			<div
				v-if="appendText || hasAppendContentSlot"
				class="input-group-append"
			>
				<div class="input-group-text" data-test-id="append-text">
					<template v-if="hasAppendContentSlot">
						<slot name="append-content"></slot>
					</template>
					<template v-else>
						{{ appendText }}
					</template>
				</div>
			</div>
		</div>
		<div v-if="invalidFeedback" class="invalid-feedback">
			{{ invalidFeedback }}
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

dayjs.extend(customParseFormat);

export default {
	components: {
		DatePicker,
	},
	props: {
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		prependText: {
			type: String,
			default: null,
		},
		appendText: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		textAlign: {
			type: String,
			default: 'right',
			validator: (val) => ['left', 'center', 'right'].includes(val),
		},
		mode: {
			type: String,
			default: 'single',
		},
		value: {
			type: [Date, String, Object],
			default: null,
		},
		minDate: {
			type: Date,
			default: null,
		},
		maxDate: {
			type: Date,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			attributes: [
				{
					bar: {
						backgroundColor: '#398fac',
					},
					dates: [
						new Date(), // Highlight today by show bar at bottom
					],
				},
			],
		};
	},
	computed: {
		hasPrependContentSlot() {
			return !!this.$slots['prepend-content'];
		},
		hasAppendContentSlot() {
			return !!this.$slots['append-content'];
		},
	},
	methods: {
		handleInput(value) {
			if (value !== this.value) {
				this.$emit('input', value);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.base-input-date {
		.input-group-date {
			.form-control-date {
				width: 100%;
				padding: 0;

				::v-deep input[type="input"] {
					font-size: rem(14);
				}
			}

			.form-control-date:not(:first-child) {
				::v-deep input[type="input"] {
					padding-left: rem(40);
				}
			}

			.form-control-date:not(:last-child) {
				::v-deep .form-control {
					padding-right: rem(40);
				}
			}

			.input-group-append,
			.input-group-prepend {
				pointer-events: none;
			}

			&.is-invalid {
				.form-control-date ::v-deep input[type="input"] {
					border: 1px solid $color-alert;

					&:focus {
						box-shadow: none;
					}
				}
			}
		}
	}
</style>
