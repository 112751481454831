import { pathOr } from '../../assets/js/helpers';
import { transformedMarketingCampaign } from '../../assets/js/transform/marketingCampaigns';
import { transformedMeta } from '../../assets/js/transform/meta';
import {
	addMarketingCampaignProductsAPI,
	createMarketingCampaignAPI,
	getMarketingCampaignAPI,
	getMarketingCampaignListAPI,
	removeMarketingCampaignAPI,
	updateMarketingCampaignAPI,
	getMarketingCampaignAttributeKeyListAPI,
	updateMarketingCampaignAttributeKeyAPI,
} from '../../services/api/marketingCampaigns.api';
import {
	GET_MARKETING_CAMPAIGN_REQUEST,
	GET_MARKETING_CAMPAIGN_SUCCESS,
	GET_MARKETING_CAMPAIGN_FAILURE,
	GET_MARKETING_CAMPAIGN_LIST_REQUEST,
	GET_MARKETING_CAMPAIGN_LIST_SUCCESS,
	GET_MARKETING_CAMPAIGN_LIST_FAILURE,
	CREATE_MARKETING_CAMPAIGN_REQUEST,
	CREATE_MARKETING_CAMPAIGN_SUCCESS,
	CREATE_MARKETING_CAMPAIGN_FAILURE,
	UPDATE_MARKETING_CAMPAIGN_REQUEST,
	UPDATE_MARKETING_CAMPAIGN_SUCCESS,
	UPDATE_MARKETING_CAMPAIGN_FAILURE,
	REMOVE_MARKETING_CAMPAIGN_REQUEST,
	REMOVE_MARKETING_CAMPAIGN_SUCCESS,
	REMOVE_MARKETING_CAMPAIGN_FAILURE,
	ADD_MARKETING_CAMPAIGN_PRODUCTS_REQUEST,
	ADD_MARKETING_CAMPAIGN_PRODUCTS_SUCCESS,
	ADD_MARKETING_CAMPAIGN_PRODUCTS_FAILURE,
	GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_REQUEST,
	GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_SUCCESS,
	GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_FAILURE,
	UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_REQUEST,
	UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_SUCCESS,
	UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_FAILURE,
} from '../types';
import router from '../../router';
import { i18n } from '../../i18n';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {},
		},
		create: {
			isCreating: false,
		},
		edit: {
			isLoading: false,
			data: {},
		},
		attributeKeys: {
			isLoading: false,
			data: [],
		},
		editAttributeKey: {
			isLoading: false,
		},
	},
	mutations: {
		[GET_MARKETING_CAMPAIGN_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_MARKETING_CAMPAIGN_SUCCESS](state, data) {
			state.edit = {
				...state.edit,
				isLoading: false,
				data,
			};
		},
		[GET_MARKETING_CAMPAIGN_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[GET_MARKETING_CAMPAIGN_LIST_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_MARKETING_CAMPAIGN_LIST_SUCCESS](state, { data, meta }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
				meta: {
					...state.list.meta,
					...meta,
				},
			};
		},
		[GET_MARKETING_CAMPAIGN_LIST_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_MARKETING_CAMPAIGN_REQUEST](state) {
			state.create = {
				isCreating: true,
			};
		},
		[CREATE_MARKETING_CAMPAIGN_SUCCESS](state) {
			state.create = {
				isCreating: false,
			};
		},
		[CREATE_MARKETING_CAMPAIGN_FAILURE](state) {
			state.create = {
				isCreating: false,
			};
		},
		[UPDATE_MARKETING_CAMPAIGN_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
			};
		},
		[UPDATE_MARKETING_CAMPAIGN_SUCCESS](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
		[UPDATE_MARKETING_CAMPAIGN_FAILURE](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
		[REMOVE_MARKETING_CAMPAIGN_REQUEST]() {},
		[REMOVE_MARKETING_CAMPAIGN_SUCCESS]() {},
		[REMOVE_MARKETING_CAMPAIGN_FAILURE]() {},
		[ADD_MARKETING_CAMPAIGN_PRODUCTS_REQUEST]() {},
		[ADD_MARKETING_CAMPAIGN_PRODUCTS_SUCCESS]() {},
		[ADD_MARKETING_CAMPAIGN_PRODUCTS_FAILURE]() {},
		[GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_REQUEST](state) {
			state.attributeKeys = {
				...state.attributeKeys,
				isLoading: true,
			};
		},
		[GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_SUCCESS](state, data) {
			state.attributeKeys = {
				...state.attributeKeys,
				isLoading: false,
				data,
			};
		},
		[GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_FAILURE](state) {
			state.attributeKeys = {
				...state.attributeKeys,
				isLoading: false,
			};
		},
		[UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_REQUEST](state) {
			state.editAttributeKey = {
				...state.editAttributeKey,
				isLoading: true,
			};
		},
		[UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_SUCCESS](state, attributeKey = null) {
			state.editAttributeKey = {
				...state.editAttributeKey,
				isLoading: false,
			};

			// Update action
			if (attributeKey) {
				const { id, action } = attributeKey;
				const data = state.attributeKeys.data.map((item) => {
					if (item.id === id) {
						return { ...item, action };
					}
					return item;
				});

				state.attributeKeys = {
					...state.attributeKeys,
					data,
				};
			}
		},
		[UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_FAILURE](state) {
			state.editAttributeKey = {
				...state.editAttributeKey,
				isLoading: false,
			};
		},
	},
	actions: {
		async getMarketingCampaign({ commit }, id) {
			try {
				commit(GET_MARKETING_CAMPAIGN_REQUEST);
				const { data } = await getMarketingCampaignAPI(id);
				const marketingCampaigns = transformedMarketingCampaign(pathOr(null, ['data'])(data));
				commit(GET_MARKETING_CAMPAIGN_SUCCESS, marketingCampaigns);
			} catch (error) {
				commit(GET_MARKETING_CAMPAIGN_FAILURE);
			}
		},
		async getMarketingCampaignList({ commit }, query) {
			try {
				commit(GET_MARKETING_CAMPAIGN_LIST_REQUEST);
				const response = await getMarketingCampaignListAPI(query);
				const data = pathOr([], ['data', 'data'])(response).map(transformedMarketingCampaign);
				const meta = transformedMeta(pathOr({}, ['data', 'meta'])(response));
				commit(GET_MARKETING_CAMPAIGN_LIST_SUCCESS, { data, meta });
			} catch (error) {
				commit(GET_MARKETING_CAMPAIGN_LIST_FAILURE);
			}
		},
		async createMarketingCampaign({ commit, dispatch }, params) {
			try {
				commit(CREATE_MARKETING_CAMPAIGN_REQUEST);
				await createMarketingCampaignAPI(params);
				commit(CREATE_MARKETING_CAMPAIGN_SUCCESS);

				dispatch('toast/showToast', {
					content: 'New markerting campaign has been created',
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				router.push({ name: 'MarketingCampaignList' });
			} catch (error) {
				commit(CREATE_MARKETING_CAMPAIGN_FAILURE);
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
		async updateMarketingCampaign({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_MARKETING_CAMPAIGN_REQUEST);
				await updateMarketingCampaignAPI(id, params);
				commit(UPDATE_MARKETING_CAMPAIGN_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_MARKETING_CAMPAIGN_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		async removeMarketingCampaign({ commit, dispatch }, id) {
			try {
				commit(REMOVE_MARKETING_CAMPAIGN_REQUEST);
				await removeMarketingCampaignAPI(id);
				commit(REMOVE_MARKETING_CAMPAIGN_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Marketing campaign' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });

				router.push({ name: 'MarketingCampaignList' });
			} catch (error) {
				commit(REMOVE_MARKETING_CAMPAIGN_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		async addMarketingCampaignProducts({ commit, dispatch }, { id, params }) {
			try {
				commit(ADD_MARKETING_CAMPAIGN_PRODUCTS_REQUEST);
				await addMarketingCampaignProductsAPI(id, params);
				commit(ADD_MARKETING_CAMPAIGN_PRODUCTS_SUCCESS);

				await dispatch('getMarketingCampaign', id);
			} catch (error) {
				commit(ADD_MARKETING_CAMPAIGN_PRODUCTS_FAILURE);
			}
		},
		async getMarketingCampaignAttributeKeyList({ commit }, { id, params = {} }) {
			try {
				commit(GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_REQUEST);
				const response = await getMarketingCampaignAttributeKeyListAPI(id, params);
				const data = pathOr([], ['data', 'data'])(response);
				commit(GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_SUCCESS, data);
			} catch (error) {
				commit(GET_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_LIST_FAILURE);
			}
		},
		async updateMarketingCampaignAttributeKey({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_REQUEST);

				await updateMarketingCampaignAttributeKeyAPI(id, params);
				commit(UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_SUCCESS, {
					id: params.attribute_key_id,
					action: params.action,
				});
			} catch (error) {
				commit(UPDATE_MARKETING_CAMPAIGN_ATTRIBUTE_KEY_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
