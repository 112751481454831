<template>
	<div>
		<CButton
			color="secondary"
			class="mr-3"
			@click="handleUploadClick"
		>
			<CIcon class="icon-left" name="cil-plus" />
			Upload image
		</CButton>
		<input
			:ref="uploadButtonRef"
			data-id="btn-media-library-upload"
			type="file"
			class="d-none"
			accept="image/png, image/jpeg, image/bmp, image/gif, image/svg+xml"
			multiple
			@change="handleChangeFiles"
		>
	</div>
</template>

<script>
import { pathOr, validateImageFileName } from '../assets/js/helpers';

const SIZE_IN_MB = 1024 * 1024 * 20;

export default {
	name: 'MediaLibraryButtonUpload',

	data() {
		return {
			maxFile: 20,
			maxFileSize: SIZE_IN_MB,
			uploadButtonRef: 'media-library-files',
		};
	},
	mounted() {
		this.$refs[this.uploadButtonRef].value = '';
	},
	methods: {
		isMatchedFileSize(imageFile = {}) {
			return imageFile.size <= this.maxFileSize;
		},
		handleChangeFiles(event) {
			const imageFiles = pathOr([], ['target', 'files'])(event);

			if (imageFiles.length) {
				let error = null;
				const files = Array.from(imageFiles);
				const errorMaxFileSize = files.some((imageFile) => !this.isMatchedFileSize(imageFile));
				const errorTotalFile = files.length > this.maxFile;
				const errorFileName = files.some((imageFile) => !validateImageFileName(imageFile.name));

				if (errorMaxFileSize || errorTotalFile || errorFileName) {
					const header = this.$t('components.modalMediaLibrary.error.uploadValidationHeader');
					let content = null;

					if (errorMaxFileSize) {
						content = this.$t('components.modalMediaLibrary.error.uploadValidationMaxFileSizeContent');
					} else if (errorTotalFile) {
						content = this.$t('components.modalMediaLibrary.error.uploadValidationTotalFileContent');
					} else if (errorFileName) {
						content = this.$t('components.modalMediaLibrary.error.uploadValidationImageFileName');
					} else {
						content = this.$t('components.modalMediaLibrary.error.uploadValidationHeader');
					}

					error = { header, content };
				}

				this.$emit('onChange', { files, error });
			}
		},
		handleUploadClick() {
			this.$refs[this.uploadButtonRef].value = '';
			this.$refs[this.uploadButtonRef].click();
		},
	},
};
</script>
