import { i18n } from '@/i18n';
import {
	getProductFamiliesAPI,
	getProductFamilyAPI,
	postProductFamilyAPI,
	updateProductFamilyAPI,
	deleteProductFamilyAPI,
} from '../../services/api/productFamilies.api';
import {
	CREATE_PRODUCT_FAMILY_REQUEST,
	CREATE_PRODUCT_FAMILY_SUCCESS,
	CREATE_PRODUCT_FAMILY_FAILURE,
	GET_PRODUCT_FAMILIES_REQUEST,
	GET_PRODUCT_FAMILIES_SUCCESS,
	GET_PRODUCT_FAMILIES_FAILURE,
	GET_PRODUCT_FAMILY_REQUEST,
	GET_PRODUCT_FAMILY_SUCCESS,
	GET_PRODUCT_FAMILY_FAILURE,
	UPDATE_PRODUCT_FAMILY_REQUEST,
	UPDATE_PRODUCT_FAMILY_SUCCESS,
	UPDATE_PRODUCT_FAMILY_FAILURE,
	DELETE_PRODUCT_FAMILY_REQUEST,
	DELETE_PRODUCT_FAMILY_SUCCESS,
	DELETE_PRODUCT_FAMILY_FAILURE,
} from '../types';

import { transformedProductFamiliesList, transformedProductFamilies } from '../../assets/js/transform/productFamilies';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		list: {
			isLoading: false,
			data: [],
			meta: [],
			error: false,
		},
	},
	getters: {
		productFamiliesList(state) {
			return state.list.data;
		},
		productFamiliesListMeta(state) {
			return state.list.meta;
		},
	},
	mutations: {
		[CREATE_PRODUCT_FAMILY_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_PRODUCT_FAMILY_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_PRODUCT_FAMILY_FAILURE](state) {
			state.create.isCreating = false;
		},
		[GET_PRODUCT_FAMILIES_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformedProductFamiliesList();
			state.list.meta = transformedMeta();
			state.list.error = false;
		},
		[GET_PRODUCT_FAMILIES_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedProductFamiliesList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_PRODUCT_FAMILIES_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		[GET_PRODUCT_FAMILY_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_PRODUCT_FAMILY_SUCCESS](state, { data }) {
			state.edit.data = transformedProductFamilies(data);
			state.edit.isLoading = false;
		},
		[GET_PRODUCT_FAMILY_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[UPDATE_PRODUCT_FAMILY_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_PRODUCT_FAMILY_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_PRODUCT_FAMILY_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_PRODUCT_FAMILY_REQUEST]() { },
		[DELETE_PRODUCT_FAMILY_SUCCESS]() { },
		[DELETE_PRODUCT_FAMILY_FAILURE]() { },
	},
	actions: {
		async getProductFamilies({ commit }, query) {
			try {
				commit(GET_PRODUCT_FAMILIES_REQUEST, query);
				const { data } = await getProductFamiliesAPI(query);
				commit(GET_PRODUCT_FAMILIES_SUCCESS, data);
			} catch (error) {
				commit(GET_PRODUCT_FAMILIES_FAILURE);
			}
		},

		async getProductFamily({ commit }, id) {
			try {
				commit(GET_PRODUCT_FAMILY_REQUEST);
				const { data } = await getProductFamilyAPI(id);
				commit(GET_PRODUCT_FAMILY_SUCCESS, data);
			} catch (error) {
				const { data } = error.response;
				commit(GET_PRODUCT_FAMILY_FAILURE, data);
			}
		},

		async postProductFamily({ commit, dispatch }, payload) {
			try {
				commit(CREATE_PRODUCT_FAMILY_REQUEST);
				const { data } = await postProductFamilyAPI(payload);
				commit(CREATE_PRODUCT_FAMILY_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				const { data } = error.response;
				commit(CREATE_PRODUCT_FAMILY_FAILURE, data);
			}
		},

		async updateProductFamily({ commit, dispatch }, { id, payload }) {
			try {
				commit(UPDATE_PRODUCT_FAMILY_REQUEST);
				const { data } = await updateProductFamilyAPI(id, payload);
				commit(UPDATE_PRODUCT_FAMILY_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const { data } = error.response;
				dispatch('toast/showMultipleErrorsToast', error, { root: true });
				commit(UPDATE_PRODUCT_FAMILY_FAILURE, data);
			}
		},

		async deleteProductFamily({ commit, dispatch }, id) {
			try {
				commit(DELETE_PRODUCT_FAMILY_REQUEST);
				const { data } = await deleteProductFamilyAPI(id);
				commit(DELETE_PRODUCT_FAMILY_SUCCESS, data);
				dispatch('toast/showDeleteSuccessToast', 'Product Family', { root: true });
			} catch (error) {
				const { data } = error.response;
				dispatch('toast/showDeleteErrorToast', { root: true });
				commit(DELETE_PRODUCT_FAMILY_FAILURE, data);
			}
		},
	},
};
