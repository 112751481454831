import httpClient from './httpClient';

export const MARKETING_CAMPAIGN_ENDPOINT = '/backoffice/marketing-categories';

export const getMarketingCampaignAPI = (id) => httpClient.get(`${MARKETING_CAMPAIGN_ENDPOINT}/${id}`);
export const getMarketingCampaignListAPI = (params = {}) => httpClient.get(`${MARKETING_CAMPAIGN_ENDPOINT}`, { params });
export const createMarketingCampaignAPI = (params) => httpClient.post(`${MARKETING_CAMPAIGN_ENDPOINT}`, params);
export const updateMarketingCampaignAPI = (id, params) => httpClient.put(`${MARKETING_CAMPAIGN_ENDPOINT}/${id}`, params);
export const removeMarketingCampaignAPI = (id) => httpClient.delete(`${MARKETING_CAMPAIGN_ENDPOINT}/${id}`);
export const addMarketingCampaignProductsAPI = (id, params) => httpClient.put(`${MARKETING_CAMPAIGN_ENDPOINT}/${id}/products`, params);
export const getMarketingCampaignAttributeKeyListAPI = (id, params) => httpClient.get(`${MARKETING_CAMPAIGN_ENDPOINT}/${id}/attribute-keys`, { params });
export const updateMarketingCampaignAttributeKeyAPI = (id, params) => httpClient.put(`${MARKETING_CAMPAIGN_ENDPOINT}/${id}/attribute-keys`, params);

// Re-sync product
export const reSyncAllCampaignAPI = () => httpClient.post(`${MARKETING_CAMPAIGN_ENDPOINT}/resync-all`);
export const reSyncCampaignAPI = (id) => httpClient.post(`${MARKETING_CAMPAIGN_ENDPOINT}/${id}/resync`);

// Clear Cache
export const clearCacheAPI = () => httpClient.post(`${MARKETING_CAMPAIGN_ENDPOINT}/clear-cache`);
