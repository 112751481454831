<template>
	<CModal
		ref="modal"
		:show.sync="isShow"
		centered
		class="modal-remove"
		data-test-id="modal"
		@update:show="close"
	>
		<template #header-wrapper>
			<div class="modal-header">
				<h5
					class="modal-title flex"
					data-test-id="modal-title"
				>
					{{ title }}
				</h5>
				<CButton
					class="transparent p-0"
					data-test-id="close-icon-button"
					@click="close"
				>
					<CIcon
						class="icon-close"
						size="lg"
						name="cil-x"
					/>
				</CButton>
			</div>
		</template>
		<template #default>
			<div data-test-id="modal-description">
				{{ description }}
			</div>
			<CForm v-if="isConfirmRequired" @submit.prevent="handleSubmit">
				<CInput
					ref="input-text"
					v-model="inputText"
					type="text"
					class="mt-3"
					placeholder="type REMOVE to confirm"
					data-test-id="input-text"
				/>
			</CForm>
		</template>
		<template #footer>
			<CButton
				:disabled="isSubmitting"
				class="modal-button transparent mr-3"
				color="tertiary"
				data-test-id="cancel-button"
				@click="close"
			>
				Cancel
			</CButton>
			<CButton
				:disabled="isSubmitting || (isConfirmRequired && inputText !== REMOVE)"
				class="modal-button transparent"
				color="danger"
				data-test-id="primary-button"
				@click="handleSubmit"
			>
				{{ isSubmitting ? "Removing" : deleteButtonText }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import { REMOVE } from '../enums/general';

export default {
	name: 'BaseModalConfirmDelete',
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		deleteButtonText: {
			type: String,
			default: 'Remove',
		},
		handleRemove: {
			type: Function,
			default: () => {},
		},
		isConfirmRequired: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			REMOVE,
			isShow: false,
			isSubmitting: false,
			inputText: '',
		};
	},
	methods: {
		async open() {
			this.isShow = true;
			await this.$nextTick();

			if (this.isConfirmRequired) {
				this.$refs['input-text'].$el.querySelector('input').focus();
			}
		},
		async handleSubmit() {
			if (this.isConfirmRequired && this.inputText !== REMOVE) return;

			this.isSubmitting = true;
			try {
				if (this.handleRemove) {
					await this.handleRemove();
				}

				this.$emit('onSuccess');
				this.close();
			} catch (error) {
				this.$emit('onFailure', error);
			}
			this.isSubmitting = false;
		},
		close() {
			this.isShow = false;
			this.inputText = '';
			this.$emit('onClose');
		},
	},
};
</script>
