export default {
	create: {
		slugFormat: 'Allow letters, numbers, and ( - )',
		duplicateSlug: 'Slug already exists',
		successMessage: 'New category has been created.',
		linkBannerInvalidFormat: 'Invalid url format',
		linkBannerHelperText: 'Leave it blank to dismiss the banner link',
		titleBannerHelperText: 'Leave it blank to dismiss the banner additional text',
	},
	edit: {
		categoryId: 'Category ID',
		filterDetail: 'Manage what attributes can be filters when users browse products through product list page.',
		addProductFamiles: 'Manage product families can be adds when users browse products.',
		categorySlideBanner: 'Manage what category slide banner can be shows when users browse homepage.',
		categoryIcon: 'Manage what category icon can be shows when users browse homepage/slide banner.',
		productCompare: 'Manage what product compare can be shows when users browse PDP/LOB/PLP.',
	},
	delete: {
		modalConfirm: {
			title: 'Remove this category?',
			description: 'Removing this category will also remove all its sub-categories underneath, detach from associated SKU, and detach from settings like payment method by category.',
			inputPlaceholder: 'type REMOVE to confirm',
		},
		successMessage: 'Category has been removed.',
	},
	manageFilter: {
		description: 'These are attributes from all the products within this category. Turning an attribute off, users won\'t be able to use that attribute to filter products in product list, but will still see the attribute in product detail specification.',
	},
	status: {
		active: 'Active',
		inActive: 'Inactive',
	},
	visibility: {
		on: 'On',
		off: 'Off',
	},
	expand: {
		on: 'Expand',
		off: 'Collapse',
	},
	displayLOBPLP: {
		search_page: 'Search Page',
		lob_plp: 'LOB/PLP',
		titleLvl1: 'LOB',
		titleLvl2And3: 'PLP',
		enableLOB: 'Enable LOB (for redirect link in SubMenu on Header)',
		enablePLP: 'Enable PLP (for redirect link in SubMenu on Header)',
	},
	tooltip: {
		isNew: 'Flag to attach new badge to this category.',
		visibleViewAll: 'Flag to show/hide the view all menu in navigation bar and sidebar menu.',
		navbarVisibility: 'Flag to show/hide the this category in navigation bar.',
		visibilityTradeIn: 'Flag to show/hide the trade in icon in serch result page.',
	},
};
