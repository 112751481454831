import isEmpty from 'lodash/isEmpty';
import {
	getAuditLogsAPI,
	getAuditLogAPI,
	getAuditLogOptionAPI,
} from '../../services/api/auditLogs.api';
import {
	GET_AUDIT_LOGS_REQUEST,
	GET_AUDIT_LOGS_SUCCESS,
	GET_AUDIT_LOGS_FAILURE,
	GET_AUDIT_LOG_REQUEST,
	GET_AUDIT_LOG_SUCCESS,
	GET_AUDIT_LOG_FAILURE,
	GET_AUDIT_LOG_OPTION_REQUEST,
	GET_AUDIT_LOG_OPTION_SUCCESS,
	GET_AUDIT_LOG_OPTION_FAILURE,
} from '../types';

import { transformedAuditLogList, transformedAuditLog } from '../../assets/js/transform/auditLogs';
import { transformedMeta } from '../../assets/js/transform/meta';

export default {
	namespaced: true,
	state: {
		detail: {
			data: {},
			isLoading: false,
		},
		list: {
			isLoading: false,
			data: [],
			meta: [],
			error: false,
			option: {},
		},
	},
	getters: {
		getAuditLogGeneralInfo(state) {
			return transformedAuditLog(state.detail.data);
		},
		getAuditLogEventOptions(state) {
			const allOption = { name: 'All events', value: null };
			if (isEmpty(state.list.option)) {
				return [allOption];
			}

			const list = Object.entries(state.list.option.events).map(([key, value]) => {
				return { value: key, name: value };
			});

			return [allOption, ...list];
		},
		getAuditLogEntityOptions(state) {
			const allOption = { name: 'All entities', value: null };
			if (isEmpty(state.list.option)) {
				return [allOption];
			}

			const list = Object.entries(state.list.option.entities).map(([key, value]) => {
				return { value: key, name: value };
			});

			return [allOption, ...list];
		},
	},
	mutations: {
		[GET_AUDIT_LOGS_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = [];
			state.list.meta = [];
			state.list.error = false;
		},
		[GET_AUDIT_LOGS_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedAuditLogList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_AUDIT_LOGS_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		[GET_AUDIT_LOG_REQUEST](state) {
			state.detail.isLoading = true;
		},
		[GET_AUDIT_LOG_SUCCESS](state, { data }) {
			state.detail.data = data;
			state.detail.isLoading = false;
		},
		[GET_AUDIT_LOG_FAILURE](state) {
			state.detail.isLoading = false;
		},
		[GET_AUDIT_LOG_OPTION_REQUEST](state) {
			state.list.isLoading = true;
			state.list.option = {};
			state.list.error = false;
		},
		[GET_AUDIT_LOG_OPTION_SUCCESS](state, { data }) {
			state.list.isLoading = false;
			state.list.option = data;
		},
		[GET_AUDIT_LOGS_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
	},

	actions: {
		async getAuditLogs({ commit }, query) {
			try {
				commit(GET_AUDIT_LOGS_REQUEST, query);
				const { data } = await getAuditLogsAPI(query);
				commit(GET_AUDIT_LOGS_SUCCESS, data);
			} catch (error) {
				commit(GET_AUDIT_LOGS_FAILURE);
				throw error;
			}
		},
		async getAuditLogOption({ commit }) {
			try {
				commit(GET_AUDIT_LOG_OPTION_REQUEST);
				const { data } = await getAuditLogOptionAPI();
				commit(GET_AUDIT_LOG_OPTION_SUCCESS, data);
			} catch (error) {
				commit(GET_AUDIT_LOG_OPTION_FAILURE);
				throw error;
			}
		},
		async getAuditLog({ commit }, id) {
			try {
				commit(GET_AUDIT_LOG_REQUEST);
				const { data } = await getAuditLogAPI(id);
				commit(GET_AUDIT_LOG_SUCCESS, data);
			} catch (error) {
				commit(GET_AUDIT_LOG_FAILURE);
				throw error;
			}
		},
	},
};
