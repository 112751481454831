import dayjs from 'dayjs';
import { transformedMedia } from '../transform/mediaLibrary';
import {
	BANNER_STATUSES,
	BANNER_PERIODS,
	BANNER_TYPE,
	BANNER_IMAGE_TYPE,
} from '../../../enums/banners';
import { IMAGE_PLACEHOLDER_URL } from '../../../enums/general';
import { pathOr, datetimeFormat, priceFromAPI } from '../helpers';

import { transformedProductLabels } from './productLabels';

export const transformedWidgetList = (widget = {}) => {
	return pathOr(null, ['name'])(widget);
};

export const transformedBannerGroupList = (banner = {}) => {
	let period = null;
	let thumbnail = null;
	const id = pathOr(0, ['id'])(banner);
	const banners = pathOr([], ['banners'])(banner);

	/**
	 * Get thumbnail from first banner
	 */
	if (banners.length) {
		thumbnail = transformedMedia(pathOr(null, ['0', 'file', '0'])(banners));
	}

	thumbnail = pathOr(IMAGE_PLACEHOLDER_URL, ['imageUrls', 'thumbnail'])(thumbnail);

	const name = pathOr(null, ['name'])(banner);
	const startDate = banner.start_at ? datetimeFormat(banner.start_at) : '-';
	const endDate = banner.end_at ? datetimeFormat(banner.end_at) : '-';
	const priority = pathOr(0, ['priority'])(banner);
	const status = BANNER_STATUSES[!!pathOr(false, ['is_visible'])(banner)];
	const currentTimeFormat = new Date().toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
	const currentTimestamp = new Date(currentTimeFormat).getTime();
	const startAtTimestamp = startDate ? new Date(startDate).getTime() : null;
	const endAtTimestamp = startDate ? new Date(endDate).getTime() : null;
	const widgetList = pathOr([], ['widgets'])(banner);
	const widgets = widgetList.map(transformedWidgetList);

	/**
	 * Comapre current time with start at and end at
	 */
	if (currentTimestamp < startAtTimestamp) {
		period = BANNER_PERIODS.scheduled;
	} else if (
		currentTimestamp > startAtTimestamp
		&& (currentTimestamp < endAtTimestamp || !endAtTimestamp)
	) {
		period = BANNER_PERIODS.ongoing;
	} else if (currentTimestamp > endAtTimestamp) {
		period = BANNER_PERIODS.expired;
	} else {
		period = { name: '-', value: '-' };
	}

	return {
		id,
		thumbnail,
		name,
		period,
		startDate,
		endDate,
		priority,
		widgets,
		status,
	};
};

export const getBannerFromType = (banners = [], type) => {
	const index = banners.findIndex((banner) => banner.type === type);

	let banner = null;
	if (banners.length) {
		banner = transformedMedia(pathOr(null, [index, 'file', '0'])(banners));
	}

	if (banner && banner.imageUrls && banner.imageUrls.thumbnail) {
		switch (type) {
			case BANNER_IMAGE_TYPE.homepage_desktop_banner_image:
			case BANNER_IMAGE_TYPE.homepage_desktop_banner_image_en:
				banner.desktopBackgroundColor = pathOr('#ffffff', [index, 'background_color'])(banners);
				break;
			case BANNER_IMAGE_TYPE.homepage_mobile_banner_image:
			case BANNER_IMAGE_TYPE.homepage_mobile_banner_image_en:
				banner.mobileBackgroundColor = pathOr('#ffffff', [index, 'background_color'])(banners);
				break;
			case BANNER_IMAGE_TYPE.homepage_highlight_1_banner_image:
			case BANNER_IMAGE_TYPE.homepage_highlight_1_mobile_banner_image:
			case BANNER_IMAGE_TYPE.homepage_highlight_2_banner_image:
			case BANNER_IMAGE_TYPE.homepage_highlight_2_mobile_banner_image:
			case BANNER_IMAGE_TYPE.homepage_highlight_3_banner_image:
			case BANNER_IMAGE_TYPE.homepage_highlight_3_mobile_banner_image:
				banner.titleTh = pathOr('', [index, 'title_th'])(banners);
				banner.titleEn = pathOr('', [index, 'title_en'])(banners);
				banner.descriptionTh = pathOr('', [index, 'description_th'])(banners);
				banner.descriptionEn = pathOr('', [index, 'description_en'])(banners);
				banner.buttonTextTh = pathOr('', [index, 'button_text_th'])(banners);
				banner.buttonTextEn = pathOr('', [index, 'button_text_en'])(banners);
				banner.lowestPrice = priceFromAPI(pathOr(0, [index, 'lowest_price'])(banners));
				break;

			case BANNER_IMAGE_TYPE.homepage_small_banner_image:
			case BANNER_IMAGE_TYPE.homepage_mobile_small_banner_image:
				banner.titleTh = pathOr('', [index, 'title_th'])(banners);
				banner.titleEn = pathOr('', [index, 'title_en'])(banners);
				banner.subTitleTh = pathOr('', [index, 'sub_title_th'])(banners);
				banner.subTitleEn = pathOr('', [index, 'sub_title_en'])(banners);
				banner.descriptionTh = pathOr('', [index, 'description_th'])(banners);
				banner.descriptionEn = pathOr('', [index, 'description_en'])(banners);
				break;

			default:
				// Do nothing
				break;
		}

		return banner;
	}

	return null;
};

export const getBannerLinkFromType = (banners = [], type) => {
	const index = banners.findIndex((banner) => banner.type === type);

	return pathOr(null, [index, 'link'])(banners);
};

export const getBannerTitleFromType = (banners = [], type, locale = 'th') => {
	const index = banners.findIndex((banner) => banner.type === type);

	return pathOr(null, [index, `title_${locale}`])(banners);
};

export const getBannerBackgroundColorFromType = (banners = [], type) => {
	const index = banners.findIndex((banner) => banner.type === type);

	return pathOr('#ffffff', [index, 'background_color'])(banners);
};

export const transformedBannerGroupDetailHero = (banners = []) => {
	return {
		desktopBannerEn: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_desktop_banner_image),
		desktopBannerTh: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_desktop_banner_image_en),
		mobileBannerEn: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_mobile_banner_image),
		mobileBannerTh: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_mobile_banner_image_en),
		bannerLink: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_desktop_banner_image),
	};
};

export const transformedBannerGroupDetailHighlight = (banners = []) => {
	return {
		leftBannerDesktop: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_1_banner_image),
		leftBannerMobile: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_1_mobile_banner_image),
		topRightBanner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_2_banner_image),
		topRightBannerMobile: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_2_mobile_banner_image),
		bottomRightBanner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_3_banner_image),
		bottomRightBannerMobile: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_3_mobile_banner_image),
		bannerLinkLeft: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_1_banner_image),
		bannerLinkTopRight: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_2_banner_image),
		bannerLinkBottomRight: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_highlight_3_banner_image),
		leftBannerLabels: transformedProductLabels(pathOr([], [banners.findIndex((banner) => banner.type === BANNER_IMAGE_TYPE.homepage_highlight_1_banner_image), 'labels'])(banners)),
		topRightBannerLabels: transformedProductLabels(pathOr([], [banners.findIndex((banner) => banner.type === BANNER_IMAGE_TYPE.homepage_highlight_2_banner_image), 'labels'])(banners)),
		bottomRightBannerLabels: transformedProductLabels(pathOr([], [banners.findIndex((banner) => banner.type === BANNER_IMAGE_TYPE.homepage_highlight_3_banner_image), 'labels'])(banners)),
	};
};

export const transformedBannerGroupDetailSmall = (banners = []) => {
	return {
		desktopBanner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_small_banner_image),
		mobileBanner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_mobile_small_banner_image),
		bannerLink: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_small_banner_image),
	};
};

export const transformedBannerGroupDetailShopByBrand = (banners = []) => {
	return {
		banner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_shop_by_brand_banner_image),
		bannerLink: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_shop_by_brand_banner_image),
	};
};

export const transformedBannerGroupDetailShortcut = (banners = []) => {
	return {
		banner: getBannerFromType(banners, BANNER_IMAGE_TYPE.homepage_shortcut_banner_image),
		bannerLink: getBannerLinkFromType(banners, BANNER_IMAGE_TYPE.homepage_shortcut_banner_image),
		bannerTitleTh: getBannerTitleFromType(banners, BANNER_IMAGE_TYPE.homepage_shortcut_banner_image, 'th'),
		bannerTitleEn: getBannerTitleFromType(banners, BANNER_IMAGE_TYPE.homepage_shortcut_banner_image, 'en'),
	};
};

export const transformedBannerGroupDetail = (banner = {}) => {
	// general and date
	const id = pathOr(0, ['id'])(banner);
	const name = pathOr(null, ['name'])(banner);
	const priority = pathOr(0, ['priority'])(banner);
	const status = !!pathOr(false, ['is_visible'])(banner);
	const startDate = banner.start_at ? dayjs(banner.start_at).toDate() : null;
	const startTime = banner.start_at ? datetimeFormat(banner.start_at, 'HH:mm') : '00:00';
	const endDate = banner.end_at ? dayjs(banner.end_at).toDate() : null;
	const endTime = banner.end_at ? datetimeFormat(banner.end_at, 'HH:mm') : '23:59';
	const widgets = pathOr(null, ['widgets'])(banner);
	const labels = transformedProductLabels(pathOr([], ['labels'])(banner));

	// banner images and links
	let banners = {};
	if (banner.type === BANNER_TYPE.hero) {
		banners = transformedBannerGroupDetailHero(banner.banners);
	} else if (banner.type === BANNER_TYPE.highlight) {
		banners = transformedBannerGroupDetailHighlight(banner.banners);
	} else if (banner.type === BANNER_TYPE.small) {
		banners = transformedBannerGroupDetailSmall(banner.banners);
	} else if (banner.type === BANNER_TYPE.shop_by_brand) {
		banners = transformedBannerGroupDetailShopByBrand(banner.banners);
	} else if (banner.type === BANNER_TYPE.shortcut) {
		banners = transformedBannerGroupDetailShortcut(banner.banners);
	}

	return {
		id,
		name,
		priority,
		status,
		startDate,
		startTime,
		endDate,
		endTime,
		banners,
		widgets,
		labels,
	};
};
