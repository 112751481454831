import { i18n } from '@/i18n';
import { pathOr } from '../../assets/js/helpers';

import {
	getFootersAPI,
	postFooterAPI,
	updateFooterAPI,
	deleteFooterAPI,
	postFooterSortAPI,
} from '../../services/api/footers.api';

import {
	GET_FOOTERS_REQUEST,
	GET_FOOTERS_SUCCESS,
	GET_FOOTERS_FAILURE,
	SORT_FOOTERS_REQUEST,
	SORT_FOOTERS_SUCCESS,
	SORT_FOOTERS_FAILURE,

	CREATE_FOOTERS_REQUEST,
	CREATE_FOOTERS_SUCCESS,
	CREATE_FOOTERS_FAILURE,
	UPDATE_FOOTERS_REQUEST,
	UPDATE_FOOTERS_SUCCESS,
	UPDATE_FOOTERS_FAILURE,
	DELETE_FOOTERS_REQUEST,
	DELETE_FOOTERS_SUCCESS,
	DELETE_FOOTERS_FAILURE,
} from '../types';

import { transformedFooters } from '../../assets/js/transform/footers';

import { FOOTER_NAMES } from '../../enums/footers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [
				{
					id: 1,
					name: FOOTER_NAMES.STUDIO_7,
					pages: [],
				},
			],
		},
	},
	getters: {
		getStudio7List(state) {
			return state.list.data.find((footer) => footer.name === FOOTER_NAMES.STUDIO_7);
		},

		getServiceList(state) {
			return state.list.data.find((footer) => footer.name === FOOTER_NAMES.SERVICES);
		},

		getInfomationList(state) {
			return state.list.data.find((footer) => footer.name === FOOTER_NAMES.INFORMATION);
		},
	},

	mutations: {
		[GET_FOOTERS_REQUEST](state) {
			state.list.data = transformedFooters();
			state.list.isLoading = true;
		},
		[GET_FOOTERS_SUCCESS](state, payload) {
			state.list.data = transformedFooters(payload);
			state.list.isLoading = false;
		},
		[GET_FOOTERS_FAILURE](state) {
			state.list.isLoading = false;
		},

		[SORT_FOOTERS_REQUEST]() {},
		[SORT_FOOTERS_SUCCESS]() {},
		[SORT_FOOTERS_FAILURE]() {},
		[CREATE_FOOTERS_REQUEST]() {},
		[CREATE_FOOTERS_SUCCESS]() {},
		[CREATE_FOOTERS_FAILURE]() {},
		[UPDATE_FOOTERS_REQUEST]() {},
		[UPDATE_FOOTERS_SUCCESS]() {},
		[UPDATE_FOOTERS_FAILURE]() {},
		[DELETE_FOOTERS_REQUEST]() {},
		[DELETE_FOOTERS_SUCCESS]() {},
		[DELETE_FOOTERS_FAILURE]() {},
	},

	actions: {
		async getFooters({ commit }) {
			try {
				commit(GET_FOOTERS_REQUEST);

				const { data: payload } = await getFootersAPI();
				commit(GET_FOOTERS_SUCCESS, payload.data);
			} catch (error) {
				commit(GET_FOOTERS_FAILURE);
			}
		},

		async createFooterItem({ commit, dispatch }, { data }) {
			try {
				commit(CREATE_FOOTERS_REQUEST);
				await postFooterAPI(data);

				commit(CREATE_FOOTERS_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_FOOTERS_FAILURE);
				const statusCode = pathOr(null, ['response', 'status'])(error);

				if (statusCode !== 422) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					}, { root: true });
				}
				throw error;
			}
		},

		async updateFooterItem({ commit, dispatch }, { id, data }) {
			try {
				commit(UPDATE_FOOTERS_REQUEST);
				await updateFooterAPI(id, data);

				commit(UPDATE_FOOTERS_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_FOOTERS_FAILURE);
				const statusCode = pathOr(null, ['response', 'status'])(error);

				if (statusCode !== 422) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					}, { root: true });
				}
				throw error;
			}
		},

		async deleteFooterItem({ commit, dispatch }, id) {
			try {
				commit(DELETE_FOOTERS_REQUEST);
				await deleteFooterAPI(id);

				commit(DELETE_FOOTERS_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(DELETE_FOOTERS_FAILURE);
				const statusCode = pathOr(null, ['response', 'status'])(error);

				if (statusCode !== 422) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleDelete'),
						type: 'danger',
					}, { root: true });
				}
				throw error;
			}
		},

		async sortFooters({ commit, dispatch }, params) {
			try {
				commit(SORT_FOOTERS_REQUEST);

				await postFooterSortAPI(params);
				commit(SORT_FOOTERS_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(SORT_FOOTERS_FAILURE);
				const statusCode = pathOr(null, ['response', 'status'])(error);

				if (statusCode !== 422) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					}, { root: true });
				}
				throw error;
			}
		},
	},
};
