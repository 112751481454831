import { pathOr } from '../helpers';
import { transformedPromotionBannerKeyList } from './promotionBannerKeys';

export const transformProductSkus = (products = []) => {
	return products.map((product) => product.itec_sku);
};

export const transformedPromotionBannerSet = (promotionBannerSet = {}) => {
	const id = pathOr(0, ['id'])(promotionBannerSet);
	const name = pathOr('', ['name'])(promotionBannerSet);
	const isActive = pathOr(false, ['is_active'])(promotionBannerSet);
	const promotionBannerKeys = transformedPromotionBannerKeyList(pathOr([], ['promotion_banner_keys'])(promotionBannerSet));
	const categories = pathOr([], ['categories'])(promotionBannerSet);
	const skus = transformProductSkus(pathOr([], ['products'])(promotionBannerSet));

	// for column delete
	const deleteId = id;
	return {
		name,
		id,
		isActive,
		promotionBannerKeys,
		categories,
		skus,
		deleteId,
	};
};

export const transformedPromotionBannerSetList = (promotionBannerSets = []) => {
	const data = promotionBannerSets.map((attr) => transformedPromotionBannerSet(attr));
	return data;
};
