import {
	GET_LIMIT_PRODUCTS_REQUEST,
	GET_LIMIT_PRODUCTS_SUCCESS,
	GET_LIMIT_PRODUCTS_FAILURE,
	GET_LIMIT_PRODUCT_REQUEST,
	GET_LIMIT_PRODUCT_SUCCESS,
	GET_LIMIT_PRODUCT_FAILURE,
	UPDATE_LIMIT_PRODUCT_REQUEST,
	UPDATE_LIMIT_PRODUCT_SUCCESS,
	UPDATE_LIMIT_PRODUCT_FAILURE,
	CREATE_LIMIT_PRODUCT_REQUEST,
	CREATE_LIMIT_PRODUCT_SUCCESS,
	CREATE_LIMIT_PRODUCT_FAILURE,
	DELETE_LIMIT_PRODUCT_REQUEST,
	DELETE_LIMIT_PRODUCT_SUCCESS,
	DELETE_LIMIT_PRODUCT_FAILURE,
} from '../types';
import {
	createLimitProductAPI,
	getLimitProductAPI,
	getLimitProductsAPI,
	updateLimitProductAPI,
	deleteLimitProductAPI,
} from '../../services/api/limitProduct.api';
import { transformLimitProductList, transformLimitProduct } from '../../assets/js/transform/limitProduct';
import { transformedMeta } from '../../assets/js/transform/meta';
import { i18n } from '../../i18n';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: transformLimitProductList(),
			meta: transformedMeta(),
			error: false,
		},

		detail: {
			isLoading: false,
			data: transformLimitProduct(),
			error: false,
		},

		update: {
			isLoading: false,
			error: false,
		},

		create: {
			isLoading: false,
			error: false,
		},

		delete: {
			isLoading: false,
			error: false,
		},
	},

	mutations: {
		// Get limit product list
		[GET_LIMIT_PRODUCTS_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformLimitProductList();
			state.list.meta = transformedMeta();
			state.list.error = false;
		},
		[GET_LIMIT_PRODUCTS_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformLimitProductList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_LIMIT_PRODUCTS_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},

		// Get limit product
		[GET_LIMIT_PRODUCT_REQUEST](state) {
			state.detail.isLoading = true;
			state.detail.data = transformLimitProduct();
			state.detail.error = false;
		},
		[GET_LIMIT_PRODUCT_SUCCESS](state, { data }) {
			state.detail.isLoading = false;
			state.detail.data = transformLimitProduct(data);
		},
		[GET_LIMIT_PRODUCT_FAILURE](state) {
			state.detail.isLoading = false;
			state.detail.error = true;
		},

		// Update limit product
		[UPDATE_LIMIT_PRODUCT_REQUEST](state) {
			state.update.isLoading = true;
			state.update.error = false;
		},
		[UPDATE_LIMIT_PRODUCT_SUCCESS](state) {
			state.update.isLoading = false;
		},
		[UPDATE_LIMIT_PRODUCT_FAILURE](state) {
			state.update.isLoading = false;
			state.update.error = true;
		},

		// Create limit product
		[CREATE_LIMIT_PRODUCT_REQUEST](state) {
			state.create.isLoading = true;
			state.create.error = false;
		},
		[CREATE_LIMIT_PRODUCT_SUCCESS](state) {
			state.create.isLoading = false;
		},
		[CREATE_LIMIT_PRODUCT_FAILURE](state) {
			state.create.isLoading = false;
			state.create.error = true;
		},

		// Delete limit product
		[DELETE_LIMIT_PRODUCT_REQUEST](state) {
			state.delete.isLoading = true;
			state.delete.error = false;
		},
		[DELETE_LIMIT_PRODUCT_SUCCESS](state) {
			state.delete.isLoading = false;
		},
		[DELETE_LIMIT_PRODUCT_FAILURE](state) {
			state.delete.isLoading = false;
			state.delete.error = true;
		},
	},

	actions: {
		async getLimitProducts({ commit }, params) {
			try {
				commit(GET_LIMIT_PRODUCTS_REQUEST);
				const { data } = await getLimitProductsAPI(params);
				commit(GET_LIMIT_PRODUCTS_SUCCESS, data);
			} catch (error) {
				commit(GET_LIMIT_PRODUCTS_FAILURE);
			}
		},

		async getLimitProduct({ commit }, { id }) {
			try {
				commit(GET_LIMIT_PRODUCT_REQUEST);
				const { data } = await getLimitProductAPI(id);
				commit(GET_LIMIT_PRODUCT_SUCCESS, data);
			} catch (error) {
				commit(GET_LIMIT_PRODUCT_FAILURE);
			}
		},

		async createLimitProduct({ commit, dispatch }, payload) {
			try {
				commit(CREATE_LIMIT_PRODUCT_REQUEST);
				await createLimitProductAPI(payload);
				commit(CREATE_LIMIT_PRODUCT_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('page.limitProduct.create.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_LIMIT_PRODUCT_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},

		async updateLimitProduct({ commit, dispatch }, { id, payload }) {
			try {
				commit(UPDATE_LIMIT_PRODUCT_FAILURE);
				await updateLimitProductAPI(id, payload);
				commit(UPDATE_LIMIT_PRODUCT_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				await dispatch('getLimitProduct', { id });
			} catch (error) {
				commit(UPDATE_LIMIT_PRODUCT_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},

		async deleteLimitProduct({ commit, dispatch }, { id }) {
			try {
				commit(DELETE_LIMIT_PRODUCT_FAILURE);
				await deleteLimitProductAPI(id);
				commit(DELETE_LIMIT_PRODUCT_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('page.limitProduct.delete.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(DELETE_LIMIT_PRODUCT_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
