export default {
	create: {
		generateId: 'Generate after saving',
		linkBannerInvalidFormat: 'Invalid url format',
		linkBannerHelperText: 'Leave it blank to dismiss the banner link',
		titleBannerHelperText: 'Leave it blank to dismiss the banner additional text',
		removeBanners: 'Remove banners',
		successMessage: 'New banner has been added.',
	},
	edit: {
		successMessage: 'Banner has been updated.',
	},
};
