import { ROUTE_NAME } from '../enums/route';

export const SUB_HEADER_TABS = Object.freeze([
	'HOMEPAGE',
	'CART',
]);

export const SUB_HEADER_TYPES = {
	HOMEPAGE: 'homepage',
	CART: 'cart',
};

export const SUB_HEADER_COMPONENTS = Object.freeze({
	HOMEPAGE: {
		title: 'Homepage',
		component: 'SubHeaderList',
		routerTo: ROUTE_NAME.SUB_HEADER_HOMEPAGE,
		routerToCreate: ROUTE_NAME.SUB_HEADER_HOMEPAGE_CREATE,
		routerToEdit: ROUTE_NAME.SUB_HEADER_HOMEPAGE_EDIT,
	},
	CART: {
		title: 'Cart',
		component: 'SubHeaderList',
		routerTo: ROUTE_NAME.SUB_HEADER_CART,
		routerToCreate: ROUTE_NAME.SUB_HEADER_CART_CREATE,
		routerToEdit: ROUTE_NAME.SUB_HEADER_CART_EDIT,
	},
});

export const SUB_HEADER_DEFAULT_DATA = {
	id: null,
	name: '',
	priority: 0,
	status: true,
	startDate: null,
	startTime: '00:00',
	endDate: null,
	endTime: '23:59',
	contentTh: null,
	contentEn: null,
	link: null,
	isContentVisible: false,
	backgroundColorFinal: '#FFFFFF',
	backgroundColorInitial: '#000000',
};

export const SUB_HEADER_TABLE_FIELDS = [
	{ key: 'id', label: 'ID', class: 'col-id th-mw-30' },
	{ key: 'name', label: 'Name', class: 'col-name th-mw-30' },
	{ key: 'startDateDisplay', label: 'Start date', class: 'col-date th-mw-100', cellClass: 'td-nowrap' },
	{ key: 'endDateDisplay', label: 'End date', class: 'col-date th-mw-100', cellClass: 'td-nowrap' },
	{ key: 'priority', label: 'Priority', class: 'col-priority th-mw-10' },
	{ key: 'status', label: 'Status', class: 'th-mw-10' },
];