import httpClient from './httpClient';

export const PROMOTION_ENDPOINT = '/backoffice/promotions';
export const IMPORT_COUPON_CODES_ENDPOINT = '/backoffice/coupon-codes/import';
export const LIMIT_PROMOTION_ENDPOINT = '/backoffice/promotion-coupon-metas';
export const GENERATE_CODES_ENDPOINT = '/backoffice/coupon-codes';

export const getPromotionsAPI = (params = {}) => httpClient.get(PROMOTION_ENDPOINT, { params });
export const getPromotionAPI = (id) => httpClient.get(`${PROMOTION_ENDPOINT}/${id}`);
export const postPromotionAPI = (params) => httpClient.post(`${PROMOTION_ENDPOINT}`, params);
export const updatePromotionAPI = (id, params) => httpClient.put(`${PROMOTION_ENDPOINT}/${id}`, params);
export const updateLimitPromotionAPI = (id, params) => httpClient.put(`${LIMIT_PROMOTION_ENDPOINT}/${id}`, params);
export const deletePromotionAPI = (id) => httpClient.delete(`${PROMOTION_ENDPOINT}/${id}`);
export const postPromotionRemarkAPI = (id, params) => httpClient.post(`${PROMOTION_ENDPOINT}/${id}/promotion-remarks`, params);
export const getGenerateCodesAPI = (params = {}) => httpClient.get(GENERATE_CODES_ENDPOINT, { params });
export const importCouponCodesAPI = (file, params = {}) => {
	const formData = new FormData();
	formData.append('file', file);
	return httpClient.post(
		`${IMPORT_COUPON_CODES_ENDPOINT}`,
		formData,
		params,
	);
};
export const postPromotionExport = (params = {}) => httpClient.post(`${PROMOTION_ENDPOINT}/reports/export`, params);
