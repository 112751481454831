import { pathOr } from '../helpers';

export const transformedAttributeSet = (attributeSet = {}) => {
	const id = pathOr(0, ['id'])(attributeSet);
	const name = pathOr(0, ['name'])(attributeSet);
	const attributeKeys = pathOr([], ['attribute_keys'])(attributeSet);
	return {
		name,
		id,
		attributeKeys,
	};
};

export const transformedAttributeSetList = (attributeSets = []) => {
	const data = attributeSets.map((attr) => transformedAttributeSet(attr));
	return data;
};

export const transformedAttributeStatus = (attributeSets = []) => {
	return attributeSets.map((item) => ({
		name: item.name,
		id: item.id,
		status: item.status,
	}));
};
