import { IMAGE_PLACEHOLDER_URL } from '../../../enums/general';
import {
	pathOr,
	datetimeFormat,
} from '../helpers';

/**
 * transform shipment address
 * @param {Object} shipment address
 * @returns {Object} shipment address info
 */
export const transformedShipmentAddressInfo = (address = {}) => ({
	id: address.id || null,
	firstName: address.first_name || null,
	lastName: address.last_name || null,
	phoneNumber: address.phone_number || null,
	address1: address.address_1 || null,
	address2: address.address_2 || null,
	postcode: address.postcode || null,
	province: address.province || null,
	district: address.district || null,
	subdistrict: address.subdistrict || null,
	detail: address.detail || null,
	nationalId: address.national_id || null,
	companyName: address.company_name || null,
	taxId: address.tax_id || null,
	taxEmail: address.tax_email || null,
	taxBranchCode: address.tax_branch_code || null,
	taxBranchName: address.tax_branch_name || null,
	isDefault: address.is_default || false,
	isJuristic: address.is_juristic || false,
});

/**
 * transform shipment general info
 * @param {Object} shipment detail
 * @returns {Object} shipment general info
 */
export const transformedShipmentGeneralInfo = (shipment = {}) => {
	const id = pathOr(0, ['id'])(shipment);
	const code = pathOr('-', ['code'])(shipment);
	const orderCode = pathOr('-', ['order_code'])(shipment);
	const orderStatus = pathOr(null, ['order_status'])(shipment);
	const customer = {
		id: pathOr(null, ['customer', 'id'])(shipment),
		firstName: pathOr(null, ['customer', 'first_name'])(shipment),
		lastName: pathOr(null, ['customer', 'last_name'])(shipment),
		phoneNumber: pathOr('-', ['customer', 'phone_number'])(shipment),
		email: pathOr('-', ['customer', 'email'])(shipment),
		customerGroup: {
			id: pathOr(null, ['customer', 'customer_group', 'id'])(shipment),
			name: pathOr('-', ['customer', 'customer_group', 'name'])(shipment),
		},
	};
	const customerShipment = {
		firstName: pathOr('-', ['customer_first_name'])(shipment),
		lastName: pathOr('-', ['customer_last_name'])(shipment),
		phoneNumber: pathOr('-', ['customer_phone_number'])(shipment),
		email: pathOr('-', ['customer_email'])(shipment),
	};
	const courier = pathOr('-', ['courier'])(shipment);
	const trackingNumber = pathOr('-', ['tracking_number'])(shipment);
	const status = pathOr(null, ['status'])(shipment);
	const shipmentMethod = {
		id: pathOr(null, ['shipping_method', 'id'])(shipment),
		method: pathOr(null, ['shipping_method', 'method'])(shipment),
		name: pathOr(null, ['shipping_method', 'name'])(shipment),
		type: pathOr(null, ['shipping_method', 'type'])(shipment),
	};
	const branch = {
		id: pathOr(null, ['branch', 'id'])(shipment),
		com7BranchId: pathOr('-', ['branch', 'com7_branch_id'])(shipment),
	};
	const createdAt = shipment.created_at ? datetimeFormat(shipment.created_at) : '-';
	const updatedAt = shipment.updated_at ? datetimeFormat(shipment.updated_at) : '-';

	return {
		id,
		code,
		orderCode,
		orderStatus,
		customer,
		customerShipment,
		courier,
		trackingNumber,
		status,
		shipmentMethod,
		branch,
		createdAt,
		updatedAt,
	};
};


/**
 * transform shipment's product list
 * @param {Object} product list of shipment
 * @returns {Object} product list
 */
export const transformedShipmentProductList = (product = {}) => {
	const id = pathOr(null, ['id'])(product);
	const orderItemId = pathOr(null, ['order_item_id'])(product);
	const name = pathOr(null, ['name'])(product);
	const sku = pathOr(null, ['sku'])(product);
	const thumbnail = pathOr(IMAGE_PLACEHOLDER_URL, ['thumbnail_url'])(product);
	const quantity = pathOr(0, ['quantity'])(product);
	const type = pathOr(0, ['type'])(product);

	return {
		id,
		orderItemId,
		name,
		sku,
		thumbnail,
		quantity,
		type,
	};
};

/**
 * transform shipment detail
 * @param {Object} shipment detail
 * @returns {Object} shipment detail
 */
export const transformedShipmentDetail = (shipment = {}) => {
	const orderId = pathOr('-', ['order_id'])(shipment);
	const orderNote = pathOr('-', ['order_note'])(shipment);
	const shippingAddress = transformedShipmentAddressInfo(pathOr(null, ['shipping_address'])(shipment));
	const deliveredAt = shipment.delivered_at ? datetimeFormat(shipment.delivered_at) : '-';
	const expectDeliveryAt = shipment.expect_delivery_date ? datetimeFormat(shipment.expect_delivery_date) : '-';
	const items = shipment.items && shipment.items.map(transformedShipmentProductList);
	const quantity = pathOr(0, ['quantity'])(shipment);
	const shipmentLogs = pathOr(null, ['shipment_logs'])(shipment); // TODO: Recheck this transformer in task of logging.

	return {
		...transformedShipmentGeneralInfo(shipment),
		orderId,
		orderNote,
		shippingAddress,
		deliveredAt,
		expectDeliveryAt,
		items,
		quantity,
		shipmentLogs,
	};
};

/**
 * transform shipments
 * @param {Array} shipments
 * @returns {Array} shipment list
 */
export const transformedShipmentList = (shipments = []) => {
	return shipments.map(transformedShipmentGeneralInfo);
};
