import Numeral from 'numeral';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Big from 'big.js';
import { PRODUCT_STATUSES } from '../../enums/products';
import { FILE_SIZES } from '../../enums/fileSize';
import { HUNDRED } from '../../enums/price';
import { IMAGE_PLACEHOLDER_URL } from '../../enums/general';
import { REWARD_KEYS } from '../../enums/promotions';
import { WIDGET_STORE_ID } from '../../enums/widgets';
import { PAYMENT_GATEWAY } from '../../enums/payments';

dayjs.extend(utc);

/**
 * Use for get path of object and if it invalid it's return default value
 * @param {*} defaultValue
 * @param {Array} path
 * @param {Object} data
 */
export const pathOr = (defaultValue, path = []) => (data = {}) => {
	// Cache the current object
	let current = data;

	if (path.length === 0) {
		return defaultValue;
	}

	// For each item in the path, dig into the object
	for (let i = 0; i < path.length; i++) {
		// If the item isn't found, return the default (or null)

		if (!current || current[path[i]] !== 0) {
			if (!current || !current[path[i]]) {
				return defaultValue;
			}
		}

		// Otherwise, update the current value
		current = current[path[i]];
	}

	return current;
};

/**
 * cleanObject (delete null property from object)
 * @param {Object} Object input
 * @returns {cleanObject}
 */
export const cleanObject = (object) => {
	Object.keys(object).forEach((key) => (object[key] === null) && delete object[key]);

	return object;
};

/**
 * cloning an object
 * @param {Object} object input
 * @returns {Object} cloned object
 */
export const cloneDeep = (object) => {
	return JSON.parse(JSON.stringify(object));
};

export const priceFormat = (value) => {
	return `฿${Numeral(value).format('0,0.00')}`;
};

export const priceToNumber = (value) => {
	return Numeral(value).value();
};

export const priceFromAPI = (price) => {
	if (!price && price !== 0) {
		return '';
	}

	const priceValue = new Big(price);
	return priceValue.div(HUNDRED).toFixed(2);
};

export const priceToAPI = (price) => {
	if (!price && price !== 0) {
		return null;
	}

	const priceRemovedComma = typeof price === 'string'
		? price.split(',').join('')
		: price;

	const priceValue = new Big(priceRemovedComma);
	return priceValue.mul(HUNDRED).toFixed(0);
};


export const parseNumber = (value) => {
	if (!value) {
		return 0;
	}

	return Number(`${value}`.replace(/,/g, ''));
};

export const numberDecimalFormat = (value, decimalScale = 0) => {
	if (decimalScale > 0) {
		return `${Numeral(value).format(`0,0.${'0'.repeat(decimalScale)}`)}`;
	}

	return Numeral(value).format('0,0');
};

/**
 * Format the price value
 * @param {Number|String} price Price in Satang
 * @param {Boolean} Nullable Flag to return '-' when value is null
 */
export const numberFormat = (value, nullable = false) => {
	// If value is null, return '-'
	if (value === null && nullable) {
		return '-';
	}

	// If the value have decimal, show it as 2 digits
	// Otherwise, do not show the decimal
	if (Math.round(value) !== Number(value)) {
		return Numeral(value).format('0,0.00');
	}

	return Numeral(value).format('0,0');
};

export const getBooleanQueryParameter = (value) => {
	const numVal = Number(value);
	return [0, 1].includes(numVal)
		? numVal
		: null;
};

/**
 * getCategoryChildren
 * @param {Array} category list
 * @param {Number} category id
 * @returns {getCategoryChildren}
 */
export const getCategoryChildren = (categoryLists = [], id = null) => {
	const item = categoryLists.find((category) => category.id === id) || {};

	return item.children || [];
};

/**
 * getActiveItemChildren
 * @param {Array} list
 * @returns {Array}
 */
export const getActiveItemChildren = (list = []) => {
	const item = list.find((listItem) => listItem.isActive) || {};

	return item.children || [];
};

/**
 * getProductStatusValue
 * @param {Boolean} isEOL end of life product
 * @param {Boolean} isActive active product
 * @returns {getProductStatusValue}
 */
export const getProductStatusValue = (isEOL = null, isActive = null) => {
	let status = null;

	// Mapping status param with status options
	if (isEOL === 1) {
		status = PRODUCT_STATUSES.EOL;
	} else if (isActive === 1) {
		status = PRODUCT_STATUSES.ACTIVE;
	} else if (isActive === 0) {
		status = PRODUCT_STATUSES.INACTIVE;
	}

	return status;
};

/**
 * convert file size from Byte to KB, MB
 * @param {Number} bytes
 * @param {Number} decimals
 * @returns {String}
 */
export const covertFileSize = (bytes, decimals = 2) => {
	const size = bytes || 0;
	const minus = size < 0 ? '-' : '';
	const positiveSize = size < 0 ? size * -1 : size;

	if (positiveSize === 0) {
		return `0${FILE_SIZES[1]}`; // 0KB
	}

	const decimalNumber = decimals < 0 ? 0 : decimals;
	const i = Math.floor(Math.log(positiveSize) / Math.log(1024));
	const number = parseFloat((positiveSize / (1024 ** i)).toFixed(decimalNumber));

	return `${minus}${number}${FILE_SIZES[i]}`;
};

/**
 * datetimeFormat
 * @param {String} Datetime value
 * @param {String} format
 * @returns {datetimeFormat}
 */
export const datetimeFormat = (value, format = 'DD MMM YYYY, HH:mm') => {
	if (!value) {
		return '';
	}

	return dayjs(value).format(format);
};

/**
 * convertDateTimeToUTC - Merge date and time and convert to UTC format before send to API
 * @param {String} Date date
 * @param {String} Time time
 * @returns {datetimeFormat}
 */
export const convertDateTimeToUTC = (date, time = '00:00:00') => {
	if (date && dayjs(date).isValid()) {
		const dateBangkok = new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' });
		const dateFormat = dayjs(dateBangkok).format('YYYY-MM-DD');

		/**
		 * We assume that date and time input are local time (Bangkok)
		 * so we have plus 7 hours to convert to local time and then convert to utc time
		 */
		return dayjs(`${dateFormat}T${time}+07:00`).utc().format();
	}

	return null;
};

/**
 * convertTimeToUTC - Convert time in UTC format
 * @param {String} Time time in HH:mm:ss
 * @returns {String} utc time format
 */
export const convertTimeToUTC = (time = null) => {
	if (time) {
		const date = dayjs().format('YYYY-MM-DD');
		const timeUTC = dayjs(`${date}T${time}:00+07:00`).utc().format('HH:mm:ss');
		return `${timeUTC}Z`;
	}

	return null;
};

/**
 * covertTimeFromAPI - Convert time from api
 * @param {String} Time time in HH:mm:ssZ
 * @returns {String} hh:mm
 */
export const covertTimeFromAPI = (time = null) => {
	if (time) {
		const date = dayjs().format('YYYY-MM-DD');
		const timeFormat = dayjs(`${date}T${time}`).utcOffset(7).format('HH:mm');

		return timeFormat;
	}

	return null;
};

/**
 * convertDateRangeToUTC - Covert start and end date time for date range
 * @param {Object} StartAndEndDateTime object of start and end date time
 * @returns {Object} start and end datetime in utc format
 */
export const convertDateRangeToUTC = ({ start = null, end = null }) => ({
	start: start ? convertDateTimeToUTC(start) : null,
	end: end ? convertDateTimeToUTC(end, '23:59:59') : null,
});

/**
 * dateFormat
 * @param {String} Datetime value
 * @param {String} format
 * @returns {dateFormat}
 */
export const dateFormat = (value, format = 'YYYY-MM-DD') => {
	if (!value) {
		return '';
	}

	return dayjs(value).format(format);
};

/**
 * formattedCategories
 * example output - Apple/iPhone/iPhone11, n/a
 * @param {Array} categoryItems
 * @returns {formattedCategories}
 */
export const formattedCategories = (categoryItems, key = 'name') => {
	const values = [];

	const recursiveObject = (categoryItem) => {
		if (categoryItem[key]) {
			values.push(categoryItem[key]);
		}

		if (categoryItem.parent) {
			return recursiveObject(categoryItem.parent);
		}

		return false;
	};

	recursiveObject(categoryItems);

	return values.length
		? values.reverse().join(' / ')
		: 'n/a';
};

/**
 * getFilenameFromHeader
 * example output - log.xlsx, product-ranking.xlsx
 * @param {Object} headers
 * @returns {String}
 */

export const getFilenameFromHeader = (headers) => headers['content-disposition']
	.match(/filename=(.*)/)[1]
	.replace(/"/g, '');

/**
 * getThumbnailFromProduct
 * example output - "product-thumnail.com", "/assets/images/product-placeholder.svg"
 * @param {Object} product
 * @returns {String}
 */

export const getThumbnailFromProduct = (product) => {
	return product?.files
		? pathOr(IMAGE_PLACEHOLDER_URL, ['urls', 'thumbnail'])(product.files[0])
		: IMAGE_PLACEHOLDER_URL;
};

export const getThumbnailFromMediaObject = (media) => {
	return media && pathOr(IMAGE_PLACEHOLDER_URL, ['urls', 'thumbnail'])(media);
};

export const getCategory1BannerMediumFromMediaObject = (media) => {
	return media && (media?.urls?.category1BannerMedium ?? media?.urls?.original ?? IMAGE_PLACEHOLDER_URL);
};

/**
 * randomSearchString
 * example output - "AH07"
 * @returns {String}
 */

export const randomSearchString = () => Math.random().toString(36).substring(2, 6).toUpperCase();

/**
 * mapping attribute key status by giving lists and ids
 * @param {Array} lists attributes
 * @param {Array} ids attribute ids
 * @returns {Array} attributes
 */

export const mappingAttributeStatus = (lists, ids) => {
	return lists.map((item) => ({
		...item,
		status: ids.includes(item.id),
	}));
};



// #region String

/**
 * nullableToDashFormat
 * @param {String|Number} value
 * @returns {String|Number}
 */
export const nullableToDashFormat = (value) => {
	if (!value && value !== 0) {
		return '-';
	}
	return value;
};

// #endregion
export const scrollToTop = (ref) => {
	const selector = ref || '.c-body';
	const cBodyElement = document.querySelector(selector);

	if (cBodyElement) {
		cBodyElement.scrollTo({ top: 0, behavior: 'smooth' });
	}
};

export const scrollToBottom = (ref) => {
	const selector = ref || '.c-body';
	const cBodyElement = document.querySelector(selector);

	if (cBodyElement) {
		cBodyElement.scrollTo({ top: cBodyElement.scrollHeight, behavior: 'smooth' });
	}
};

export const capitalize = (text) => {
	return text
		? text.trim().toLowerCase().replace(/^\w/, (c) => c.toUpperCase())
		: '';
};

/**
 * hasSearchFilter
 * @param {Object} Object of query params
 * @param {Array} Array of query params which will exclude from checking
 * @returns {Boolean}
 */
export const hasSearchFilter = (queryParams = {}, exclude = []) => {
	const query = cleanObject(cloneDeep(queryParams));
	let excludedParams = ['r', 'q', 'sort_by', 'sort_direction'];
	if (Array.isArray(exclude) && exclude.length) {
		excludedParams = [...excludedParams, ...exclude];
	}
	return Object.keys(query).filter((key) => !excludedParams.includes(key)).some((k) => query[k] !== null);
};


/**
 * getRewardKeysFromRewardValue
 * @param {String} rewardValue
 * @returns {Array} reward keys [APPLY_TO_KEYS, DISCOUNT_TYPE_KEYS]
 */
export const getRewardKeysFromRewardValue = (rewardValue) => {
	let result = [];

	Object.entries(REWARD_KEYS).forEach(([reward, discountTypes]) => {
		Object.entries(discountTypes).forEach(([discount, value]) => {
			if (rewardValue === value) {
				result = [reward, discount];
			}
		});
	});

	return result;
};

/**
 * getECouponCodePattern
 * @param {String, String} coupon prefix, length
 * @returns {String} coupon code pattern : BNNXXX
 */
export const getECouponCodePattern = (prefix, length, couponCodes) => {
	if (Array.isArray(couponCodes) && couponCodes.length > 0) {
		if (couponCodes.length === 1) {
			return couponCodes[0];
		}

		if (!prefix) {
			return '';
		}

		return prefix.padEnd((prefix.length + Number(length || 3)), 'X');
	}

	return '';
};

/**
 * transformCardNumber
 *
 * @param {String|Number} cardNumberLastDigits
 * @returns {String} transformedCardNumber
 */
export const transformCardNumber = (cardNumberLastDigits) => {
	return `xxxx-xxxx-xxxx-${cardNumberLastDigits}`;
};

/**
 * Merge text
 * @param {[String]} texts
 * @return {String} text
 */
export const mergeText = (texts) => {
	// Remove empty string from text array and return new string
	return texts.filter((text) => text).join(', ').trim();
};

/**
 * get selected value, use with BaseDropDown components
 * @param {Object} selectedValue
 * @param {Object} options
 */
export const getSelectedValueDropdown = (selectedValue = null, options = {}) => {
	const values = Object.values(options);
	if (values.length > 0) {
		const findValue = ({ value }) => value === selectedValue;
		return values.find(findValue);
	}
	return { name: null, value: null };
};

/**
 * Check isMicrosite section from store id
 * @param {Integer} store id
 * @return {Boolean} return true if store id is matched with microsite
 */
export const isMicrosite = (storeId = null) => (storeId === WIDGET_STORE_ID.MICROSITE);

/**
 * Check payment is Krungsri or First choice
 * @param {String} bank agent
 * @return {Boolean} return true if bank agent is Krungsri or First choice
 */
export const isBAYBankAgent = (agent = '') => (agent === PAYMENT_GATEWAY.BAY || agent === PAYMENT_GATEWAY.FIRST_CHOICE);

/**
 * Check payment is KTC flexi or ai
 * @param {String} bank agent
 * @return {Boolean} return true if bank agent is KTC flexi or ai
 */
export const isKTCBankAgent = (agent = '') => (agent === PAYMENT_GATEWAY.KTC_FLEXI || agent === PAYMENT_GATEWAY.KTC_AI);

/**
 * Validate number for text input
 * @param event
 */
export const validateNumber = (event) => {
	const regex = /^\d+$/;
	if (!regex.test(event.key)) {
		event.preventDefault();
	}
};

/**
 * Validate phone number must start with `0`
 * @param value
 * @returns {Boolean}
 */
export const phoneNumberMustStartWithZero = (value) => {
	const regex = /^0+/;
	return regex.test(value);
};

/**
 * Validate file name must be A-Z, a-z, 0-9, _, -
* @param {string} fileName - The file name to validate.
* @return {boolean} Returns true if the file name is valid, false otherwise.
 */
export const validateImageFileName = (fileName) => {
	const regex = /^[a-zA-Z0-9_-]*$/;
	return regex.test(fileName.toString().slice(0, fileName.toString().lastIndexOf('.')));
};

/**
 *
 * @param {String} checkboxKey
 * @param {Array} paymentMethods
 * @returns {Array}
 */
export const getCheckboxCheckedState = (checkboxKey = null, paymentMethods = []) => {
	if (!paymentMethods || paymentMethods.length <= 0 || !checkboxKey) {
		return [];
	}

	const state = paymentMethods.reduce((acc, current) => {
		const group = current[checkboxKey];
		const methods = current.methods
			? current.methods.map((method) => (method[checkboxKey]))
			: [group];
		return [
			...acc,
			...methods,
		];
	}, []);

	return state;
};

/**
 *
 * @param {String} checkboxKey
 * @param {Array} paymentMethods
 * @returns {Boolean}
 */
export const isIndeterminateCheckbox = (checkboxKey = null, paymentMethods = []) => {
	if (!paymentMethods || paymentMethods.length <= 0 || !checkboxKey) {
		return false;
	}

	const state = getCheckboxCheckedState(checkboxKey, paymentMethods);
	return state.some((bool) => bool) && !state.every((bool) => bool);
};

/**
 *
 * @param {String} checkboxKey
 * @param {Array} paymentMethods
 * @returns {Boolean}
 */
export const isEveryCheckboxChecked = (checkboxKey = null, paymentMethods = []) => {
	if (!paymentMethods || paymentMethods.length <= 0 || !checkboxKey) {
		return false;
	}

	const state = getCheckboxCheckedState(checkboxKey, paymentMethods);
	return state.every((bool) => bool);
};

/**
 *
 * @param {String} checkboxKey
 * @param {Array} paymentMethods
 * @param {Boolean} isChecked
 * @returns
 */
export const handleCheckboxSelectedAll = (checkboxKey = null, paymentMethods = [], isChecked) => {
	if (!paymentMethods || !Array.isArray(paymentMethods)) {
		return paymentMethods;
	}

	return paymentMethods.map((group) => {
		if (group.methods) {
			const methods = group.methods.map((method) => ({
				...method,
				[checkboxKey]: isChecked,
			}));
			return {
				...group,
				methods,
			};
		}

		return {
			...group,
			[checkboxKey]: isChecked,
		};
	});
};

/**
 *
 * @param {Array} list
 * @param {String} field
 * @returns {Array}
 */
export const removeDuplicateField = (list, field) => {
	const newList = [];
	const addedFields = [];

	list.forEach((item) => {
		if (addedFields.some((addedField) => addedField === item[field])) {
			return;
		}

		addedFields.push(item[field]);
		newList.push(item);
	});

	return newList;
};

/**
 * convertDateFormatToDateObject - convert UTC format to GMT (Indochina Time)
 * @param {String} date 2022-09-21T17:00:00.000000Z UTC
 * @returns {String} Fri Sep 23 2022 00:00:00 GMT+0700 (Indochina Time)
 */
export const convertDateFormatToDateObject = (date) => {
	if (!date) {
		return '';
	}

	return dayjs(date).toDate();
};

export const isEmptyObject = (obj) => {
	/* eslint-disable */
	if (obj == null || obj == '') {
		return true;
	}
	// case object has function return empty object { a: () => {} }
	if (typeof obj === 'object') {
		if (Array.isArray(obj)) {
		  return obj.length === 0;
		}
		return Object.keys(obj).length === 0;
	}
	return false;
};
