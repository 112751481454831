export const transformPage = ({
	id,
	name,
	title_en: titleEN,
	title_th: titleTH,
	link,
	footer_column_id: footerColumnId,
}) => {
	return {
		id,
		name,
		titleEN,
		titleTH,
		link,
		columnId: footerColumnId,
	};
};

export const transformFooter = ({ id, name, pages }) => {
	return {
		id,
		name,
		items: pages.map(transformPage),
	};
};

/**
 * Transform a list of footer objects into a more usable format.
 * @param {Object[]} payload A list of footer objects from the API.
 * @returns {Object[]} A list of transformed footer objects.
 */
export const transformedFooters = (payload = []) => {
	return payload.map(transformFooter);
};

