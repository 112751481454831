import {
	getAccordionSetsAPI,
	createAccordionSetAPI,
	deleteAccordionSetAPI,
	putAccordionSetAPI, getAccordionSetAPI,
} from '@/services/api/accordionSets.api';
import {
	GET_ACCORDION_SETS_REQUEST,
	GET_ACCORDION_SETS_SUCCESS,
	GET_ACCORDION_SET_SUCCESS,
	GET_ACCORDION_SETS_FAILURE,
	CREATE_ACCORDION_SET_REQUEST,
	CREATE_ACCORDION_SET_SUCCESS,
	CREATE_ACCORDION_SET_FAILURE,
	RESET_ERROR_CREATE_ACCORDION_SET,
	DELETE_ACCORDION_SET_REQUEST,
	DELETE_ACCORDION_SET_SUCCESS,
	DELETE_ACCORDION_SET_FAILURE,
	GET_ACCORDION_SET_FAILURE,
	UPDATE_ACCORDION_SET_REQUEST,
	UPDATE_ACCORDION_SET_SUCCESS,
	UPDATE_ACCORDION_SET_FAILURE,
	LOADING_ACCORDION_SET,
} from '../types';
import { i18n } from '../../i18n';
import { transformedAccordionSetList, transformedAccordionSet, transformedAccordionSetForm } from '../../assets/js/transform/accordionSets';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
				from: null,
				perPage: null,
				to: null,
				total: '0',
				path: '',
			},
		},
		create: {
			isSubmitting: false,
			isErrorDuplicateName: false,
		},
		edit: {
			data: {},
			isLoading: true,
			isUpdating: false,
		},
	},
	getters: {
		accordionList(state) {
			return state.list.data;
		},
	},
	mutations: {
		[GET_ACCORDION_SETS_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...transformedMeta(state.list.meta),
					currentPage: page,
				},
			};
		},
		[GET_ACCORDION_SETS_SUCCESS](state, { data, meta }) {
			state.list = {
				data,
				meta: transformedMeta(meta),
				isLoading: false,
			};
		},
		[GET_ACCORDION_SET_FAILURE](state) {
			state.edit = {
				data: {},
				isLoading: false,
			};
		},
		[GET_ACCORDION_SET_SUCCESS](state, { data }) {
			state.edit = {
				data,
				isLoading: false,
			};
		},
		[GET_ACCORDION_SETS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_ACCORDION_SET_REQUEST](state) {
			state.create = {
				...state.create,
				isSubmitting: true,
			};
		},
		[CREATE_ACCORDION_SET_SUCCESS](state) {
			state.create = {
				...state.create,
				isSubmitting: false,
			};
		},
		[CREATE_ACCORDION_SET_FAILURE](state, isErrorDuplicateName = false) {
			state.create = {
				...state.create,
				isSubmitting: false,
				isErrorDuplicateName,
			};
		},
		[RESET_ERROR_CREATE_ACCORDION_SET](state) {
			state.create = {
				...state.create,
				isErrorDuplicateName: false,
			};
		},
		[LOADING_ACCORDION_SET](state) {
			state.edit.isLoading = true;
		},
		[UPDATE_ACCORDION_SET_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_ACCORDION_SET_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_ACCORDION_SET_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_ACCORDION_SET_REQUEST]() {},
		[DELETE_ACCORDION_SET_SUCCESS]() {},
		[DELETE_ACCORDION_SET_FAILURE]() {},
	},
	actions: {
		async getAccordionSet({ commit }, id) {
			try {
				commit(LOADING_ACCORDION_SET);
				const response = await getAccordionSetAPI(id);
				const data = transformedAccordionSet(pathOr([], ['data', 'data'])(response));
				commit(GET_ACCORDION_SET_SUCCESS, { data });
			} catch (error) {
				commit(GET_ACCORDION_SET_FAILURE);
			}
		},
		async getAccordionSets({ commit }, query) {
			try {
				commit(GET_ACCORDION_SETS_REQUEST, query);
				const response = await getAccordionSetsAPI(query);
				const data = transformedAccordionSetList(pathOr([], ['data', 'data'])(response));
				const meta = pathOr({}, ['data', 'meta'])(response);
				commit(GET_ACCORDION_SETS_SUCCESS, { data, meta });
			} catch (error) {
				commit(GET_ACCORDION_SETS_FAILURE);
			}
		},
		async createAccordionSet({ commit, dispatch }, params = {}) {
			try {
				commit(CREATE_ACCORDION_SET_REQUEST);
				await createAccordionSetAPI(transformedAccordionSetForm(params));
				commit(CREATE_ACCORDION_SET_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.create.successMessage', { type: 'accordion set' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const errorDuplicateSet = 'duplicate_accordion_key_name';
				const isErrorDuplicateName = pathOr(false, ['response', 'data', 'error'])(error) === errorDuplicateSet;

				commit(CREATE_ACCORDION_SET_FAILURE, isErrorDuplicateName);

				if (!isErrorDuplicateName) {
					dispatch('toast/showToast', {
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					}, { root: true });
				}
			}
		},
		async updateAccordionSet({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_ACCORDION_SET_REQUEST);

				const { data } = await putAccordionSetAPI(id, transformedAccordionSetForm(params));

				commit(UPDATE_ACCORDION_SET_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_ACCORDION_SET_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		resetErrorCreateAccordionSet({ commit }) {
			commit(RESET_ERROR_CREATE_ACCORDION_SET);
		},
		async deleteAccordionSet({ commit, dispatch }, accordionSetId) {
			try {
				commit(DELETE_ACCORDION_SET_REQUEST);
				await deleteAccordionSetAPI(accordionSetId);
				commit(DELETE_ACCORDION_SET_SUCCESS);
			} catch (error) {
				commit(DELETE_ACCORDION_SET_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
