<template>
	<CToast
		:key="`toast-${toast.id}`"
		:class="className"
		:show="toast.isShow"
		:header="toast.header"
		:title="toast.title"
	>
		<div v-if="!Array.isArray(toast.content)">
			{{ toast.content }}
		</div>
		<ul v-else class="ml-3 p-0">
			<li
				v-for="(item, key) in toast.content"
				:key="key"
				class="mb-2 typo-body-2"
			>
				{{ item }}
			</li>
		</ul>
	</CToast>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'ToastBox',
	props: {
		toast: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			timeout: null,
		};
	},
	computed: {
		className() {
			const type = this.toast.type || 'default';
			return `toast-${type}`;
		},
	},
	mounted() {
		this.timeout = setTimeout(() => {
			this.hideToast(this.toast.id);
		}, this.toast.timeout);
	},
	beforeDestroy() {
		clearTimeout(this.timeout);
	},
	methods: {
		...mapActions({
			hideToast: 'toast/hideToast',
		}),
	},
};
</script>
