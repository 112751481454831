/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty';
import { transformedMedia } from '../transform/mediaLibrary';

import {
	datetimeFormat,
	convertDateTimeToUTC,
} from '../helpers';
import { transformedCustomerGroupListToAPI } from '../transform/promotions';
import {
	NOTIFICATION_COLOR,
	NOTIFICATION_TITLE,
} from '../../../enums/mobileNotification';

export const transformedMobileNotificationItem = (notification = {}) => {
	return {
		id: notification.id,
		name: notification.name,
		messageTitle: notification.message_title,
		status: notification.is_sent,
		sendDate: notification.scheduled_at ? datetimeFormat(notification.scheduled_at) : '-',
	};
};

export const transformedMobileNotificationList = (notifications = []) => {
	if (Array.isArray(notifications)) {
		return notifications.map(transformedMobileNotificationItem);
	}
	return [];
};

export const transformedMediaToAPI = (notification = {}) => {
	const mediaSetImage = notification?.media?.$modal?.id ?? null;

	return {
		file_ids: mediaSetImage ? [mediaSetImage] : [],
	};
};

export const transformMapConditionsToFormData = (conditions = []) => {
	if (Array.isArray(conditions)) {
		return conditions.map((value) => {
			return {
				param: value.params,
				type: value.type,
			};
		});
	}
	return [];
};

export const transformedCustomerConditionToFormData = (condition = {}, customerConditionType) => {
	if (isEmpty(condition) || isEmpty(condition.conditions)) {
		return null;
	}
	const mapCondition = {
		type: customerConditionType,
		param: {
			conditions: transformMapConditionsToFormData(condition?.conditions),
		},
	};
	return mapCondition;
};

export const transformMapConditions = (conditions = []) => {
	if (Array.isArray(conditions)) {
		return conditions.map((value) => {
			return {
				params: value.param,
				type: value.type,
			};
		});
	}
	return [];
};

export const transformedCustomerConditionToAPI = (condition = {}) => {
	if (isEmpty(condition)) {
		return {
			customer_condition: {
				type: 'ALL',
				params: {
					conditions: [],
				},
			},
		};
	}
	const mapCondition = {
		customer_condition: {
			type: condition.type,
			params: {
				conditions: transformMapConditions(condition?.param?.conditions),
			},
		},
	};
	return mapCondition;
};


export const transformedMobileNotification = (notification = {}) => {
	return {
		name: notification.name,
		url: notification.url,
		message_title: notification.messageTitle ?? null,
		message_body: notification.messageBody,
		media: transformedMediaToAPI(notification),
		scheduled_at: convertDateTimeToUTC(notification.sendDate, notification.sendTime),
		customer_group_ids: transformedCustomerGroupListToAPI(notification.customerGroups) || [],
		params: transformedCustomerConditionToAPI(notification.customerCondition),
	};
};

export const transformedNotificationToFormData = (notification = {}) => {
	return {
		id: notification.id,
		title: notification.name,
		name: notification.name,
		url: notification.url,
		messageTitle: notification.message_title,
		messageBody: notification.message_body,
		media: notification?.media?.length ? { $modal: transformedMedia(notification.media[0]) } : {},
		sendDate: new Date(datetimeFormat(notification.scheduled_at, 'YYYY-MM-DD')),
		sendTime: datetimeFormat(notification.scheduled_at, 'HH:mm'),
		createdAt: notification.created_at ? datetimeFormat(notification.created_at) : '-',
		updatedAt: notification.updated_at ? datetimeFormat(notification.updated_at) : '-',
		createdBy: notification?.created_by?.username ?? '-',
		updatedBy: notification?.updated_by?.username ?? '-',
		customerGroups: notification.customer_groups || [],
		customerCondition: transformedCustomerConditionToFormData(notification.customer_condition_params, notification.customer_condition_type),
		status: {
			color: NOTIFICATION_COLOR.STATUS[notification.is_sent],
			title: NOTIFICATION_TITLE.STATUS[notification.is_sent],
		},
		isSent: notification.is_sent === 1,
	};
};
