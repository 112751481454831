export const SEARCH_WEIGHT_TYPES = {
	brand: 'brand',
	category: 'category',
	product: 'product',
	price: 'price',
	promotion: 'promotion',
};

export const SEARCH_WEIGHT_TYPE_OPTIONS = [
	{ value: SEARCH_WEIGHT_TYPES.brand, label: 'Brands' },
	{ value: SEARCH_WEIGHT_TYPES.category, label: 'Categories' },
	{ value: SEARCH_WEIGHT_TYPES.product, label: 'SKU' },
	{ value: SEARCH_WEIGHT_TYPES.price, label: 'Price' },
	{ value: SEARCH_WEIGHT_TYPES.promotion, label: 'Promotion' },
];

export const SEARCH_WEIGHT_PRICE_CONDITION_OPTIONS = [
	{ name: 'Equal or Higher than', value: '>=' },
	{ name: 'Lower than', value: '<' },
];

export const SEARCH_WEIGHT_STATUS = {
	COMPLETED: 'completed',
	IN_PROGRESS: 'in_progress',
	FAILED: 'failed',
};


