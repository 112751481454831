<template>
	<div class="media-library-uploading d-flex flex-column align-items-center justify-content-center">
		<BaseLoading class="base-loading mb-3" />
		<h6 class="mb-3">
			{{ heading }}
		</h6>
		<button
			type="button"
			class="btn-flat btn-cancel-upload"
			@click="$emit('onCancel', type)"
		>
			Cancel
		</button>
	</div>
</template>

<script>
export default {
	name: 'MediaLibraryUploading',

	props: {
		type: {
			type: String,
			default: 'upload',
			validator: (value) => {
				return [
					'upload',
					'delete',
				].includes(value);
			},
		},
	},
	computed: {
		heading() {
			const text = this.type === 'upload'
				? 'Wait for a sec the image is uploading'
				: 'Wait for a sec the image is being deleted';

			return text;
		},
	},
};
</script>

<style lang="scss" scoped>
.media-library-uploading {
	height: rem(332);
}

.base-loading {
	::v-deep .spinner-wrapper {
		margin: 0 auto;
	}
}

.btn-cancel-upload {
	@include color-hover($color-orange, $color-orange);
}
</style>
