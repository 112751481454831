<template>
	<div
		ref="media-list"
		class="media-list-wrapper"
	>
		<ul v-if="list.length" class="media-list">
			<li
				v-for="item in list"
				:key="item.id"
				class="media-item"
			>
				<MediaLibraryListItem
					:data="item"
					@onSelect="(data) => $emit('onSelect', data)"
					@click.native.self="$emit('onActive', item.id)"
				/>
			</li>
		</ul>
		<div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
			<img
				src="/assets/images/product-placeholder.svg"
				alt="Empty image"
				width="64"
				class="empty-image"
			>
			<h6 class="mb-1">
				No image in media library yet
			</h6>
			<p class="typo-body-2 color-black-45 mb-0">
				Click “Upload image” button to get started
			</p>
		</div>

		<BaseLoading v-if="isLoadingMore" />
	</div>
</template>

<script>
import MediaLibraryListItem from './MediaLibraryListItem.vue';

export default {
	name: 'MediaLibraryList',
	components: {
		MediaLibraryListItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		isLoadingMore: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		mediaLibraryListElement() {
			return this.$refs['media-list'];
		},
	},
	mounted() {
		this.handleAddEventScroll();
	},
	destroyed() {
		this.handleRemoveEventScroll();
	},
	methods: {
		handleScroll() {
			const scrollHeight = this.mediaLibraryListElement.scrollHeight;
			const scrollTop = this.mediaLibraryListElement.scrollTop;
			const offsetHeight = this.mediaLibraryListElement.offsetHeight;

			this.$emit('onScroll', { scrollHeight, scrollTop, offsetHeight });
		},
		handleAddEventScroll() {
			this.mediaLibraryListElement.addEventListener('scroll', this.handleScroll);
		},
		handleRemoveEventScroll() {
			this.mediaLibraryListElement.removeEventListener('scroll', this.handleScroll);
		},
	},
};
</script>

<style lang="scss" scoped>
.media-list-wrapper {
	height: rem(332);
	padding: rem(24);
	overflow-y: auto;
}

.media-list {
	padding: 0;
	margin: rem(-8);
	list-style: none;
}

.media-item {
	max-width: 16.6666666667%;
	display: inline-block;
	padding: rem(8);
}

.empty-image {
	margin-bottom: rem(12);
}
</style>
