export const PRODUCT_LABEL_TYPES = {
	primary: 'primary',
	secondary: 'secondary',
	frame: 'frame',
	default: 'default',
	badge: 'badge',
	primaryBadge: 'primaryBadge', // custom type primary + badge , use for group list primary + badge for select on Default Label
};

export const PRODUCT_LABEL_SUB_TYPES = {
	freeGift: 'free_gift',
	zeroInstallment: 'zero_installment',
	newProduct: 'new_product',
	collectInOneHourPrimary: 'collect_in_one_hour_primary',
	collectInOneHourSecondary: 'collect_in_one_hour_secondary',
	normal: 'normal',
	sale: 'sale',
};

export const PRODUCT_LABEL_SUB_TYPE_LABELS = {
	freeGift: 'free gift',
	zeroInstallment: 'installment',
	newProduct: 'new product',
	sale: 'sale',
	collectInOneHourPrimary: 'collect in one hour primary',
	collectInOneHourSecondary: 'collect in one hour secondary',
};

export const PRODUCT_LABEL_STATUSES = {
	active: { name: 'Active', value: 'active' },
	inactive: { name: 'Inactive', value: 'inactive' },
};

export const VISIBILITY_ITEM_MAPPER = {
	isListVisible: 'Product list',
	isDetailVisible: 'Product details',
	isSearchVisible: 'Search',
};

export const BADGE_COLOR_OPTIONS = {
	new: '#BF4800',
	sale: '#008900',
	specialOffer: '#5856D6',
	affordability: '#0071BC',
	preOrderNotifyMe: '#336E7B',
	inStock: '#A3684E',
	storeRelated: '#707070',
};

export const BADGE_LABELS = {
	new: 'NEW',
	sale: 'SALE',
	specialOffer: 'SPECIAL OFFER',
	affordability: 'AFFORDABILITY',
	preOrderNotifyMe: 'PRE-ORDER / NOTIFY ME',
	inStock: 'IN-STOCK',
	storeRelated: 'STORE-RELATED',
};


export const BADGE_LABEL_OPTIONS = [
	{
		value: BADGE_COLOR_OPTIONS.new,
		label: BADGE_LABELS.new,
	},
	{
		value: BADGE_COLOR_OPTIONS.sale,
		label: BADGE_LABELS.sale,
	},
	{
		value: BADGE_COLOR_OPTIONS.specialOffer,
		label: BADGE_LABELS.specialOffer,
	},
	{
		value: BADGE_COLOR_OPTIONS.affordability,
		label: BADGE_LABELS.affordability,
	},
	{
		value: BADGE_COLOR_OPTIONS.preOrderNotifyMe,
		label: BADGE_LABELS.preOrderNotifyMe,
	},
	{
		value: BADGE_COLOR_OPTIONS.inStock,
		label: BADGE_LABELS.inStock,
	},
	{
		value: BADGE_COLOR_OPTIONS.storeRelated,
		label: BADGE_LABELS.storeRelated,
	},
];

export const PRODUCT_LABEL_TABS = ['primary', 'secondary', 'frame', 'default'];
export const ERROR_REMOVE_DEFAULT_LABEL_KEY = 'label_is_set_in_default_label';
