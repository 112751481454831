import {
	postSyncBranchAPI,
} from '../../services/api/syncBranch.api';
import {
	CREATE_SYNC_BRANCH_REQUEST,
	CREATE_SYNC_BRANCH_SUCCESS,
	CREATE_SYNC_BRANCH_FAILURE,
} from '../types';

export default {
	namespaced: true,

	state: {
		create: {
			isLoading: false,
		},
	},

	mutations: {
		// Create widget
		[CREATE_SYNC_BRANCH_REQUEST](state) {
			state.create = {
				...state.create,
				isLoading: true,
			};
		},
		[CREATE_SYNC_BRANCH_SUCCESS](state) {
			state.create = {
				...state.create,
				isLoading: false,
			};
		},
		[CREATE_SYNC_BRANCH_FAILURE](state) {
			state.create = {
				...state.create,
				isLoading: false,
			};
		},
	},
	actions: {
		async postSyncBranch({ commit, dispatch }, syncBranchData) {
			try {
				commit(CREATE_SYNC_BRANCH_REQUEST);
				await postSyncBranchAPI(syncBranchData);

				commit(CREATE_SYNC_BRANCH_SUCCESS);

				dispatch('toast/showCreateSuccessToast', 'sync branch', { root: true });
			} catch (error) {
				commit(CREATE_SYNC_BRANCH_FAILURE);

				dispatch('toast/showCreateErrorToast', null, { root: true });
				throw error;
			}
		},
	},
};
