export const MAXIMUM_FEE = 99999999.99;

export const SHIPPING_METHOD = {
	STANDARD: 1,
	EXPRESS: 2,
	PICKUP_AT_STORE: 3,
	PICKUP_AND_PAY: 4,
	COLLECT_IN_ONE_HOUR: 6,
};

// shipping status, following from be
export const SHIPPING_STATUS = Object.freeze({
	WAITING_FOR_PAYMENT: 'waiting_for_payment',
	WAREHOUSE_SELECTING: 'warehouse_selecting',
	READY_TO_CREATE_SHIPPING: 'ready_to_create_shipping',
	READY_TO_BOOK_LOGISTIC: 'ready_to_book_logistic',
	READY_TO_SHIP: 'ready_to_ship',
	SHIPPED: 'shipped',
	DELIVERED: 'delivered',
	CANCELED: 'canceled',
	BOOKING_FAILED: 'booking_failed',
	SHIPPING_FAILED: 'shipping_failed',
});

// shipping status option, use with dropdown ui
export const SHIPPING_STATUS_OPTIONS = [
	{
		name: 'All shipping status',
		value: null,
	},
	{
		name: 'Waiting for payment',
		value: SHIPPING_STATUS.WAITING_FOR_PAYMENT,
	},
	{
		name: 'Warehouse selecting',
		value: SHIPPING_STATUS.WAREHOUSE_SELECTING,
	},
	{
		name: 'Ready to create shipping',
		value: SHIPPING_STATUS.READY_TO_CREATE_SHIPPING,
	},
	{
		name: 'Ready to book courier',
		value: SHIPPING_STATUS.READY_TO_BOOK_LOGISTIC,
	},
	{
		name: 'Ready to ship',
		value: SHIPPING_STATUS.READY_TO_SHIP,
	},
	{
		name: 'Shipped',
		value: SHIPPING_STATUS.SHIPPED,
	},
	{
		name: 'Delivered',
		value: SHIPPING_STATUS.DELIVERED,
	},
	{
		name: 'Canceled',
		value: SHIPPING_STATUS.CANCELED,
	},
];

export const SHIPPING_METHOD_LABELS = {
	STANDARD: 'Standard',
	EXPRESS: 'Express',
	PICKUP_AT_STORE: 'Pickup at store',
	PICKUP_AND_PAY: 'Pick-up and pay (warehouse)',
	COLLECT_IN_ONE_HOUR: 'Collect at store in 1 hour',
};

export const SHIPPING_METHOD_OPTIONS = [
	{
		name: 'All method',
		value: null,
	},
	{
		name: SHIPPING_METHOD_LABELS.STANDARD,
		value: SHIPPING_METHOD.STANDARD,
	},
	{
		name: SHIPPING_METHOD_LABELS.EXPRESS,
		value: SHIPPING_METHOD.EXPRESS,
	},
	{
		name: SHIPPING_METHOD_LABELS.PICKUP_AT_STORE,
		value: SHIPPING_METHOD.PICKUP_AT_STORE,
	},
	{
		name: SHIPPING_METHOD_LABELS.PICKUP_AND_PAY,
		value: SHIPPING_METHOD.PICKUP_AND_PAY,
	},
];

export const SHIPPING_ADDITIONAL_FEE_TYPE = Object.freeze({
	CATEGORY: 'category',
	POSTCODE: 'postcode',
	PRODUCT: 'product',
});

export const SHIPPING_METHOD_LABELS_TYPE_DELIVER = [
	SHIPPING_METHOD_LABELS.STANDARD,
	SHIPPING_METHOD_LABELS.EXPRESS,
];
