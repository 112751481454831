import {
	configPurgeCache,
	purgesCache,
	purgeTagsCache, updateConfigPurgeCache,
} from '@/services/api/dateTimeCaches.api';
import {
	PURGE_CACHE_REQUEST,
	PURGE_CACHE_SUCCESS,
	PURGE_CACHE_FAILURE,
	PURGE_TAGS_CACHE_REQUEST,
	PURGE_TAGS_CACHE_SUCCESS,
	PURGE_TAGS_CACHE_FAILURE,
	CONFIG_PURGE_CACHE_REQUEST,
	CONFIG_PURGE_CACHE_SUCCESS,
	CONFIG_PURGE_CACHE_FAILURE,
	UPDATE_CONFIG_PURGE_CACHE_REQUEST,
	UPDATE_CONFIG_PURGE_CACHE_SUCCESS,
	UPDATE_CONFIG_PURGE_CACHE_FAILURE,
} from '../types';
import { i18n } from '../../i18n';

export default {
	namespaced: true,
	state: {
		isLoading: false, // for clear cache

		tags: {
			isLoading: false,
			data: null,
		},
		config: {
			data: null,
			isLoading: false,
			isUpdating: false,
		},
	},
	mutations: {
		[PURGE_CACHE_REQUEST](state) {
			state.isLoading = true;
		},
		[PURGE_CACHE_SUCCESS](state) {
			state.isLoading = false;
		},
		[PURGE_CACHE_FAILURE](state) {
			state.isLoading = false;
		},

		[PURGE_TAGS_CACHE_REQUEST](state) {
			state.tags.isLoading = true;
			state.tags.data = null;
		},
		[PURGE_TAGS_CACHE_SUCCESS](state, { data = {} }) {
			state.tags.isLoading = false;
			state.tags.data = data;
		},
		[PURGE_TAGS_CACHE_FAILURE](state) {
			state.tags.isLoading = false;
		},

		[CONFIG_PURGE_CACHE_REQUEST](state) {
			state.config.isLoading = true;
			state.config.data = null;
		},
		[CONFIG_PURGE_CACHE_SUCCESS](state, { data = {} }) {
			state.config.isLoading = false;
			state.config.data = {
				autoClearCacheAt: data.auto_clear_cache_at,
			};
		},
		[CONFIG_PURGE_CACHE_FAILURE](state) {
			state.config.isLoading = false;
		},

		[UPDATE_CONFIG_PURGE_CACHE_REQUEST](state) {
			state.config.isUpdating = true;
		},
		[UPDATE_CONFIG_PURGE_CACHE_SUCCESS](state) {
			state.config.isUpdating = false;
		},
		[UPDATE_CONFIG_PURGE_CACHE_FAILURE](state) {
			state.config.isUpdating = false;
		},
	},
	actions: {
		async purgeCacheDateTime({ commit, dispatch }, payload) {
			try {
				commit(PURGE_CACHE_REQUEST);
				await purgesCache({
					tags: payload,
				});
				commit(PURGE_CACHE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageClearDateTimeCache'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(PURGE_CACHE_FAILURE);
				dispatch('toast/showToast', {
					type: 'danger',
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleClearDateTimeCache'),
				}, { root: true });
			}
		},

		async getTags({ commit }) {
			try {
				commit(PURGE_TAGS_CACHE_REQUEST);
				const { data } = await purgeTagsCache();
				commit(PURGE_TAGS_CACHE_SUCCESS, data);
			} catch (error) {
				commit(PURGE_TAGS_CACHE_FAILURE);
			}
		},

		async getConfig({ commit }) {
			try {
				commit(CONFIG_PURGE_CACHE_REQUEST);
				const { data } = await configPurgeCache();
				commit(CONFIG_PURGE_CACHE_SUCCESS, data);
			} catch (error) {
				commit(CONFIG_PURGE_CACHE_FAILURE);
			}
		},

		async updateConfig({ commit, dispatch }, payload) {
			try {
				commit(UPDATE_CONFIG_PURGE_CACHE_REQUEST);
				await updateConfigPurgeCache(payload);
				commit(UPDATE_CONFIG_PURGE_CACHE_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.edit.successMessage', { type: 'Config purge cache' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_CONFIG_PURGE_CACHE_FAILURE);
			}
		},
	},
};
