export const FLASHSALE_TABLE_FIELDS = Object.freeze([
	{ key: 'id', label: 'ID', class: 'col-id th-mw-50' },
	{ key: 'name', label: 'Flash sale name', class: 'col-name th-mw-250' },
	{ key: 'status', label: 'Status', class: 'col-status th-mw-80' },
	{ key: 'skus', label: 'Total SKUs', class: 'col-skus th-mw-80' },
	{ key: 'startAt', label: 'Start date', class: 'col-date th-mw-150' },
	{ key: 'endAt', label: 'End date', class: 'col-date th-mw-150' },
	{ key: 'period', label: 'Period', class: 'col-period th-mw-80' },
	{ key: 'duplicateId', label: '', class: 'col-duplicate-id th-mw-100' },
]);

export const FLASHSALE_LABEL_TITLE_TABS = {
	GENERAL_INFO: 'General info',
	PRODUCT_LIST: 'Flash sale product list',
	VISIBILITY: 'Storefront visibility',
};

export const FLASHSALE_STATUSES = {
	ACTIVE: 1,
	INACTIVE: 0,
};

export const COUNTER_FONT_COLOR = [
	{
		name: 'Black',
		value: 'black',
	},
	{
		name: 'White',
		value: 'white',
	},
];

export const FLASHSALE_STATUS_OPTIONS = [
	{
		name: 'All statuses',
		value: null,
	},
	{
		name: 'Active',
		value: FLASHSALE_STATUSES.ACTIVE,
	},
	{
		name: 'Inactive',
		value: FLASHSALE_STATUSES.INACTIVE,
	},
];

export const FLASHSALE_PERIODS_STATUSES = {
	ON_GOING: 'ongoing',
	SCHEDULED: 'scheduled',
	EXPIRED: 'expired',
};

export const FLASHSALE_PERIODS_OPTIONS = [
	{ name: 'All statuses', value: null },
	{ name: 'Ongoing', value: FLASHSALE_PERIODS_STATUSES.ON_GOING },
	{ name: 'Scheduled', value: FLASHSALE_PERIODS_STATUSES.SCHEDULED },
	{ name: 'Ended', value: FLASHSALE_PERIODS_STATUSES.EXPIRED },
];

// mapping flash sale title, use with CBadge component
export const FLASHSALE_TITLE = {
	STATUS: {
		[FLASHSALE_STATUSES.ACTIVE]: 'Active',
		[FLASHSALE_STATUSES.INACTIVE]: 'Inactive',
	},
	PERIODS_STATUSES: {
		[FLASHSALE_PERIODS_STATUSES.ON_GOING]: 'Ongoing',
		[FLASHSALE_PERIODS_STATUSES.SCHEDULED]: 'Scheduled',
		[FLASHSALE_PERIODS_STATUSES.EXPIRED]: 'Ended',
	},
};

// mapping flash sale color, use with CBadge property components
export const FLASHSALE_COLOR = {
	STATUS: {
		[FLASHSALE_STATUSES.ACTIVE]: 'success',
		[FLASHSALE_STATUSES.INACTIVE]: 'alto',
	},
	PERIODS_STATUSES: {
		[FLASHSALE_PERIODS_STATUSES.ON_GOING]: 'orange',
		[FLASHSALE_PERIODS_STATUSES.SCHEDULED]: 'info',
		[FLASHSALE_PERIODS_STATUSES.EXPIRED]: 'alto',
	},
};
