import httpClient from './httpClient';

export const ROLE_ENDPOINT = '/backoffice/roles';
export const PERMISSION_ENDPOINT = '/backoffice/permissions';

export const getPermissionsAPI = () => httpClient.get(`${PERMISSION_ENDPOINT}`);
export const getRoleAPI = (id) => httpClient.get(`${ROLE_ENDPOINT}/${id}`);
export const createRoleAPI = (params) => httpClient.post(`${ROLE_ENDPOINT}`, params);
export const updateRoleAPI = (id, params) => httpClient.put(`${ROLE_ENDPOINT}/${id}`, params);
export const deleteRoleAPI = (id) => httpClient.delete(`${ROLE_ENDPOINT}/${id}`);
export const addUserToRoleAPI = (id, user) => httpClient.post(`${ROLE_ENDPOINT}/${id}/users`, { user });
export const getRoleUsersAPI = (id, params) => httpClient.get(`${ROLE_ENDPOINT}/${id}/users`, { params });
export const getRoleListAPI = (params) => httpClient.get(`${ROLE_ENDPOINT}`, { params });
export const removeUserFromRoleAPI = (roleId, userId) => httpClient.delete(`${ROLE_ENDPOINT}/${roleId}/users/${userId}`);
