import httpClient from './httpClient';

export const CUSTOMER_GROUP_ENDPOINT = '/backoffice/customer-groups';
export const getCustomerGroupListAPI = (params = {}) => httpClient.get(CUSTOMER_GROUP_ENDPOINT, { params });
export const getCustomerGroupAPI = (id) => httpClient.get(`${CUSTOMER_GROUP_ENDPOINT}/${id}`);
export const createCustomerGroupListAPI = (params = {}) => httpClient.post(CUSTOMER_GROUP_ENDPOINT, params);
export const updateCustomerGroupAPI = (id, params = {}) => httpClient.put(`${CUSTOMER_GROUP_ENDPOINT}/${id}`, params);
export const deleteCustomerGroupAPI = (id) => httpClient.delete(`${CUSTOMER_GROUP_ENDPOINT}/${id}`);

export const getCustomerGroupUsersAPI = (id, params = {}) => httpClient.get(`${CUSTOMER_GROUP_ENDPOINT}/${id}/customers`, { params });
export const addUserToGroupAPI = (customerGrupId, customer) => httpClient.post(`${CUSTOMER_GROUP_ENDPOINT}/${customerGrupId}/customers`, { customer_id: customer });
export const removeUserFromGroupAPI = (customerGroupId, userId) => httpClient.delete(`${CUSTOMER_GROUP_ENDPOINT}/${customerGroupId}/customers/${userId}`);
