<template>
	<ol class="breadcrumb border-0 mb-0">
		<li v-for="(item, key) in breadcrumbs" :key="key" class="breadcrumb-item">
			<router-link :to="{ name: item.route.name }">
				{{ item.title }}
			</router-link>
		</li>
	</ol>
</template>

<script>
export default {
	name: 'BaseBreadcrumb',
	props: {
		breadcrumbs: {
			type: [Boolean, Array],
			default: false,
		},
	},
};
</script>
