import {
	GET_PRODUCT_GROUPS_REQUEST,
	GET_PRODUCT_GROUPS_SUCCESS,
	GET_PRODUCT_GROUPS_FAILURE,
	UPDATE_PRODUCT_GROUPS_REQUEST,
	UPDATE_PRODUCT_GROUPS_SUCCESS,
	UPDATE_PRODUCT_GROUPS_FAILURE,
} from '../types';

import { transformedProductGroups } from '../../assets/js/transform/productGroups';

import {
	getProductGroupsAPI,
	putProductGroupsAPI,
} from '../../services/api/productGroups.api';

import { i18n } from '../../i18n';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		edit: {
			isLoading: false,
			isUpdating: false,
			data: [],
		},
	},
	getters: {
	},
	mutations: {
		[GET_PRODUCT_GROUPS_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_PRODUCT_GROUPS_SUCCESS](state, { data }) {
			state.edit = {
				data: transformedProductGroups(data),
				isLoading: false,
			};
		},
		[GET_PRODUCT_GROUPS_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[UPDATE_PRODUCT_GROUPS_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
			};
		},
		[UPDATE_PRODUCT_GROUPS_SUCCESS](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
		[UPDATE_PRODUCT_GROUPS_FAILURE](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
	},
	actions: {
		async getProductGroups({ commit }, slug) {
			try {
				commit(GET_PRODUCT_GROUPS_REQUEST);
				const { data } = await getProductGroupsAPI(slug);
				commit(GET_PRODUCT_GROUPS_SUCCESS, data);
			} catch (error) {
				commit(GET_PRODUCT_GROUPS_FAILURE);
			}
		},

		async updateProductGroups({ commit, dispatch }, { slug, params }) {
			try {
				commit(UPDATE_PRODUCT_GROUPS_REQUEST);

				const { data } = await putProductGroupsAPI(slug, params);

				commit(UPDATE_PRODUCT_GROUPS_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);

				commit(UPDATE_PRODUCT_GROUPS_FAILURE);
				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
	},
};
