import { PAYMENT_STATUS } from '../enums/payments';
import { SHIPPING_STATUS } from '../enums/shippings';

// order table fields, docs: https://coreui.io/vue/docs/components/table.html
export const ORDER_TABLE_FIELDS = Object.freeze([
	{
		key: 'order_code',
		label: 'Order ID',
		class: 'order-col th-mw-120',
		cellClass: 'order-cel',
	},
	{
		key: 'type',
		label: 'Type',
		class: 'order-type-col th-mw-100',
		cellClass: 'order-type-cel',
	},
	{
		key: 'status',
		label: 'Status',
		class: 'status-col th-mw-150',
		cellClass: 'status-cel',
	},
	{
		key: 'created',
		label: 'Created date',
		class: 'created-col th-mw-150',
		cellClass: 'td-nowrap',
	},
	{
		key: 'payment_expired',
		label: 'Payment expire date',
		class: 'expired-col th-mw-150',
		cellClass: 'td-nowrap',
	},
	{
		key: 'customer_name',
		label: 'Customer',
		class: 'customer-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'group_name',
		label: 'Group',
		class: 'group-col th-mw-150',
		cellClass: 'td-nowrap',
	},
	{
		key: 'phone_number',
		label: 'Phone No.',
		class: 'phone-number-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'email',
		label: 'Email',
		class: 'email-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'payment_method_group',
		label: 'Payment method',
		class: 'payment-method-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'shipping_method',
		label: 'Shipping method',
		class: 'shipping-method-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'total',
		label: 'Total',
		class: 'total-col th-mw-100',
		cellClass: 'td-nowrap text-right',
	},
	{
		key: 'payment',
		label: 'Payment',
		class: 'payment-col th-mw-80',
		cellClass: 'payment-cel',
	},
	{
		key: 'shipment',
		label: 'Shipment',
		class: 'shipment-col th-mw-160',
		cellClass: 'shipment-cel',
	},
]);

export const ORDER_DETAIL_TABLE_FIELDS = Object.freeze([
	{
		key: 'product',
		label: 'Product',
		class: 'product-col th-mw-310',
		cellClass: 'product-cel',
	},
	{
		key: 'priceSRP',
		label: 'SRP Price',
		class: 'srp-price-col th-mw-90',
		cellClass: 'srp-price-cel',
	},
	{
		key: 'priceSelling',
		label: 'Selling price',
		class: 'selling-price-col th-mw-90',
		cellClass: 'selling-price-cel',
	},
	{
		key: 'quantity',
		label: 'Quantity',
		class: 'quantity-col th-mw-50',
		cellClass: 'quantity-cel',
	},
	{
		key: 'total',
		label: 'Total',
		class: 'total-col th-mw-50',
		cellClass: 'total-cel',
	},
]);

export const ORDER_DETAIL_TABLE_FREEBIE_FIELDS = Object.freeze([
	{
		key: 'product',
		label: 'Cart freebie',
		class: 'product-col th-mw-410',
		cellClass: 'product-cel',
	},
	{
		key: 'priceSRP',
		label: '',
		class: 'srp-price-col th-mw-90',
		cellClass: 'srp-price-cel',
	},
	{
		key: 'priceSelling',
		label: '',
		class: 'selling-price-col th-mw-90',
		cellClass: 'selling-price-cel',
	},
	{
		key: 'quantity',
		label: '',
		class: 'quantity-col th-mw-50',
		cellClass: 'quantity-cel',
	},
	{
		key: 'total',
		label: '',
		class: 'total-col th-mw-50',
		cellClass: 'total-cel',
	},
]);

export const ORDER_DETAIL_SHIPMENT_TABLE_FIELDS = Object.freeze([
	{
		key: 'shipment',
		label: 'Shipment ID #',
		class: 'shipment-col',
		cellClass: 'shipment-cel',
	},
	{
		key: 'branchId',
		label: 'Warehouse',
		class: 'warehouse-col',
		cellClass: 'warehouse-cel',
	},
	{
		key: 'packedItem',
		label: 'Packed item',
		class: 'packed-item-col',
		cellClass: 'packed-item-cel',
	},
	{
		key: 'courier',
		label: 'Courier',
		class: 'courier-col',
		cellClass: 'courier-cel',
	},
	{
		key: 'trackingNumber',
		label: 'Tracking number',
		class: 'tracking-number-col',
		cellClass: 'tracking-number-cel',
	},
	{
		key: 'status',
		label: 'Status',
		class: 'status-col',
		cellClass: 'status-cel',
	},
]);

export const ORDER_DETAIL_TABLE_PREORDER_FIELDS = Object.freeze([
	{
		key: 'product',
		label: 'Product',
		class: 'product-col th-mw-310',
		cellClass: 'product-cel',
	},
	{
		key: 'priceSRP',
		label: 'SRP Price',
		class: 'srp-price-col th-mw-90',
		cellClass: 'srp-price-cel',
	},
	{
		key: 'priceSelling',
		label: 'Selling price',
		class: 'selling-price-col th-mw-90',
		cellClass: 'selling-price-cel',
	},
	{
		key: 'pricePreOrder',
		label: 'Payment',
		class: 'full-price-col th-mw-90',
		cellClass: 'full-price-cel',
	},
	{
		key: 'quantity',
		label: 'Quantity',
		class: 'quantity-col th-mw-50',
		cellClass: 'quantity-cel',
	},
	{
		key: 'total',
		label: 'Total',
		class: 'total-col th-mw-50',
		cellClass: 'total-cel',
	},
]);

// order status, following from be
export const ORDER_STATUS = Object.freeze({
	CREATED: 'created',
	PREPARING: 'preparing',
	READY_TO_PICK_UP: 'ready_to_pick_up',
	SHIPPED: 'shipped',
	PARTIALLY_SHIPPED: 'partially_shipped',
	COMPLETED: 'completed',
	EXPIRED: 'expired',
	CANCELED: 'canceled',
	PICKED_UP: 'picked_up',
});

// mapping order color, use with CBadge property components
export const ORDER_COLOR = {
	ORDER: {
		[ORDER_STATUS.CREATED]: 'info',
		[ORDER_STATUS.PREPARING]: 'orange',
		[ORDER_STATUS.READY_TO_PICK_UP]: 'orange',
		[ORDER_STATUS.SHIPPED]: 'lavender',
		[ORDER_STATUS.PARTIALLY_SHIPPED]: 'lavender',
		[ORDER_STATUS.COMPLETED]: 'success',
		[ORDER_STATUS.EXPIRED]: 'alert',
		[ORDER_STATUS.CANCELED]: 'alert',
	},
	PAYMENT: {
		[PAYMENT_STATUS.UNPAID]: 'alto',
		[PAYMENT_STATUS.PENDING]: 'orange',
		[PAYMENT_STATUS.FAILED]: 'alert',
		[PAYMENT_STATUS.EXPIRED]: 'alert',
		[PAYMENT_STATUS.PAID]: 'success',
		[PAYMENT_STATUS.COD]: 'success',
		[PAYMENT_STATUS.PAY_AT_STORE]: 'success',
	},
	SHIPPING: {
		[SHIPPING_STATUS.WAITING_FOR_PAYMENT]: 'alto',
		[SHIPPING_STATUS.WAREHOUSE_SELECTING]: 'orange',
		[SHIPPING_STATUS.READY_TO_CREATE_SHIPPING]: 'orange',
		[SHIPPING_STATUS.READY_TO_BOOK_LOGISTIC]: 'orange',
		[SHIPPING_STATUS.READY_TO_SHIP]: 'orange',
		[SHIPPING_STATUS.SHIPPED]: 'orange',
		[SHIPPING_STATUS.DELIVERED]: 'success',
		[SHIPPING_STATUS.CANCELED]: 'alto',
		[SHIPPING_STATUS.BOOKING_FAILED]: 'danger',
		[SHIPPING_STATUS.SHIPPING_FAILED]: 'danger',
	},
};

// mapping order title, use with CBadge component
export const ORDER_TITLE = {
	ORDER: {
		[ORDER_STATUS.CREATED]: 'Created',
		[ORDER_STATUS.PREPARING]: 'Preparing',
		[ORDER_STATUS.READY_TO_PICK_UP]: 'Ready to pick up',
		[ORDER_STATUS.SHIPPED]: 'Shipped',
		[ORDER_STATUS.PARTIALLY_SHIPPED]: 'Partial shipped',
		[ORDER_STATUS.COMPLETED]: 'Completed',
		[ORDER_STATUS.EXPIRED]: 'Expired',
		[ORDER_STATUS.CANCELED]: 'Canceled',
	},
	PAYMENT: {
		[PAYMENT_STATUS.UNPAID]: 'Unpaid',
		[PAYMENT_STATUS.PENDING]: 'Pending',
		[PAYMENT_STATUS.FAILED]: 'Failed',
		[PAYMENT_STATUS.EXPIRED]: 'Expired',
		[PAYMENT_STATUS.PAID]: 'Paid',
		[PAYMENT_STATUS.PARTIALLY_PAID]: 'Partially paid',
		[PAYMENT_STATUS.COD]: 'COD', // Deprecated
		[PAYMENT_STATUS.PAT_AT_STORE]: 'Pay at store', // Deprecated
	},
	SHIPPING: {
		[SHIPPING_STATUS.WAITING_FOR_PAYMENT]: 'Waiting for payment',
		[SHIPPING_STATUS.WAREHOUSE_SELECTING]: 'Warehouse selecting',
		[SHIPPING_STATUS.READY_TO_CREATE_SHIPPING]: 'Ready to create shipping',
		[SHIPPING_STATUS.READY_TO_BOOK_LOGISTIC]: 'Ready to book courier',
		[SHIPPING_STATUS.READY_TO_SHIP]: 'Ready to ship',
		[SHIPPING_STATUS.SHIPPED]: 'Shipped',
		[SHIPPING_STATUS.DELIVERED]: 'Delivered',
		[SHIPPING_STATUS.CANCELED]: 'Canceled',
		[SHIPPING_STATUS.BOOKING_FAILED]: 'Booking failed',
		[SHIPPING_STATUS.SHIPPING_FAILED]: 'Shipping failed',
	},
};

// order staus option, use with dropdown ui
export const ORDER_STATUS_OPTIONS = [
	{
		name: 'All order status',
		value: null,
	},
	{
		name: 'Created',
		value: ORDER_STATUS.CREATED,
	},
	{
		name: 'Preparing',
		value: ORDER_STATUS.PREPARING,
	},
	{
		name: 'Ready to pick up',
		value: ORDER_STATUS.READY_TO_PICK_UP,
	},
	{
		name: 'Shipped',
		value: ORDER_STATUS.SHIPPED,
	},
	{
		name: 'Partial shipped',
		value: ORDER_STATUS.PARTIALLY_SHIPPED,
	},
	{
		name: 'Completed',
		value: ORDER_STATUS.COMPLETED,
	},
	{
		name: 'Expired',
		value: ORDER_STATUS.EXPIRED,
	},
	{
		name: 'Canceled',
		value: ORDER_STATUS.CANCELED,
	},
];
export const ORDER_DETAIL_TAX_TYPE = {
	PERSONAL: 'PERSONAL',
	COMPANY: 'COMPANY',
};

export const ORDER_DETAIL_ADDRESS_TYPE = {
	BILLING: 'billing',
	SHIPPING: 'shipping',
	TAX: 'tax',
};

export const ORDER_DETAIL_TAX_LIST = [
	{ label: 'Personal', value: ORDER_DETAIL_TAX_TYPE.PERSONAL },
	{ label: 'Company', value: ORDER_DETAIL_TAX_TYPE.COMPANY },
];

export const ORDER_STORE_BNN_ID = 1;
export const ORDER_STORE_STUDIO_7_ID = 2;
export const ORDER_STORE_SHOPEE_ID = 3;
export const ORDER_STORE_LAZADA_ID = 4;
export const ORDER_STORE_USTORE_ID = 5;
export const ORDER_STORE_MOBILE_APP_ID = 6;

export const BNN_ORDER_STORES = [
	ORDER_STORE_BNN_ID,
	ORDER_STORE_STUDIO_7_ID,
	ORDER_STORE_USTORE_ID,
	ORDER_STORE_MOBILE_APP_ID,
];

export const ORDER_ITEM_TYPE = {
	FREEBIE: 'freebie',
	NORMAL: 'normal',
	PRE_ORDER: 'pre_order',
};

export const ORDER_ITEM_TYPE_TITLE = {
	[ORDER_ITEM_TYPE.FREEBIE]: 'Freebie',
	[ORDER_ITEM_TYPE.NORMAL]: 'Normal',
	[ORDER_ITEM_TYPE.PRE_ORDER]: 'Pre-order',
};

export const ORDER_TYPE_OPTIONS = [
	{ name: 'All types', value: null },
	{ name: 'Normal', value: 'normal' },
	{ name: 'Pre-order', value: 'pre_order' },
];

export const ORDER_CHANNEL_OPTIONS = [
	{ name: 'All channels', value: null },
	{ name: 'Studio7', value: ORDER_STORE_STUDIO_7_ID },
	{ name: 'Shopee', value: ORDER_STORE_SHOPEE_ID },
	{ name: 'Lazada', value: ORDER_STORE_LAZADA_ID },
	{ name: 'Studio7 Education', value: ORDER_STORE_USTORE_ID },
	{ name: 'Mobile', value: ORDER_STORE_MOBILE_APP_ID },
];

export const ORDER_DETAIL_FLASH_SALE_TABLE_FIELDS = [
	{ key: 'product', label: 'Product', class: 'text-left', cellClass: 'text-left' },
	{ key: 'quantity', label: 'Quantity', class: 'text-center', cellClass: 'text-center' },
	{ key: 'sellingPrice', label: 'Selling price', class: 'th-mw-100 text-right', cellClass: 'text-right' },
	{ key: 'flashSalePrice', label: 'Flash sale price', class: 'th-mw-100 text-right', cellClass: 'text-right' },
	{ key: 'discount', label: 'Discount', class: 'th-mw-150 text-right', cellClass: 'text-right' },
];
