import { getBackInStockAPI } from '../../services/api/backInStock.api';
import { transformedBackInStockList } from '../../assets/js/transform/backInStock';
import { transformedMeta } from '../../assets/js/transform/meta';
import {
	GET_BACK_IN_STOCK_LIST_REQUEST,
	GET_BACK_IN_STOCK_LIST_SUCCESS,
	GET_BACK_IN_STOCK_LIST_FAILURE,
} from '../types';

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: transformedBackInStockList(),
			meta: transformedMeta(),
			error: false,
		},
	},

	mutations: {
		// Get Back In Stock List
		[GET_BACK_IN_STOCK_LIST_REQUEST](state) {
			state.list.isLoading = true;
		},
		[GET_BACK_IN_STOCK_LIST_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedBackInStockList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_BACK_IN_STOCK_LIST_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
	},

	actions: {
		async getBackInStockList({ commit }, params = {}) {
			try {
				commit(GET_BACK_IN_STOCK_LIST_REQUEST);
				const { data } = await getBackInStockAPI(params);

				commit(GET_BACK_IN_STOCK_LIST_SUCCESS, data);
			} catch (error) {
				commit(GET_BACK_IN_STOCK_LIST_FAILURE);
			}
		},
	},
};
