import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { helpers, requiredIf } from 'vuelidate/lib/validators';
import isEmpty from 'lodash/isEmpty';
import password from '../../regex/password';
import { hourMinute, multipleNumber, multipleEmail, hexColor } from '../../regex/general';
import { convertDateTimeToUTC } from './helpers';

dayjs.extend(isSameOrBefore);

export const after = (fromKey, toKey) => (day, vm = {}) => {
	if (fromKey && toKey) {
		const vmFromkey = vm[fromKey] ? vm[fromKey] : null;
		const vmTokey = vm[toKey] ? vm[toKey] : null;

		if (vmFromkey && vmTokey) {
			const [timeFromHour = 0, timeFromMinute = 0] = vmFromkey.split(':');
			const [timeToHour = 0, timeToMinute = 0] = vmTokey.split(':');
			const from = dayjs().hour(timeFromHour).minute(timeFromMinute).second(0);
			const to = dayjs().hour(timeToHour).minute(timeToMinute).second(0);
			return from.isBefore(to, 'second');
		}
	}
	return true;
};

export const minTime = (fromKey, toKey) => (day, vm = {}) => {
	if (fromKey && toKey) {
		const vmFromkey = vm[fromKey] ? vm[fromKey] : null;
		const vmTokey = vm[toKey] ? vm[toKey] : null;

		if (vmFromkey && vmTokey) {
			const [timeFromHour = 0, timeFromMinute = 0] = vmFromkey.split(':');
			const [timeToHour = 0, timeToMinute = 0] = vmTokey.split(':');
			const from = dayjs().hour(timeFromHour).minute(timeFromMinute).second(0);
			const to = dayjs().hour(timeToHour).minute(timeToMinute).second(0);
			return from.isSameOrBefore(to, 'second');
		}
	}
	return true;
};

export const afterDateTime = (keys = {}) => (day, vm = {}) => {
	const {
		fromDateKey,
		fromTimeKey,
		toDateKey,
		toTimeKey,
	} = keys;
	const vmFromDate = fromDateKey ? vm[fromDateKey] : null;
	const vmFromTime = fromTimeKey ? vm[fromTimeKey] : null;
	const vmToDate = toDateKey ? vm[toDateKey] : null;
	const vmToTime = toTimeKey ? vm[toTimeKey] : null;

	if (vmFromDate && vmFromTime && vmToDate && vmToTime) {
		const from = dayjs(vmFromDate);
		const to = dayjs(vmToDate);
		const isSameDate = from.isSame(to, 'day');

		if (isSameDate) {
			return after(fromTimeKey, toTimeKey)(day, vm);
		}

		const isFromAfterTo = from.isAfter(to);

		if (isFromAfterTo) {
			return false;
		}
	}
	return true;
};

/**
 * Check wheter datetime is after
 * @param {String} Date date
 * @param {String} Time time
 * @returns {Boolean} status
 */
export const isDateTimeAfterNow = (date, time = '00:00:00') => {
	if (date && dayjs(date).isValid()) {
		const datetime = convertDateTimeToUTC(date, time);
		const now = Date.now();

		return dayjs(datetime).isAfter(now);
	}

	return false;
};

export const passwordFormat = helpers.regex('passwordFormat', password);
export const timeFormat = helpers.regex('timeFormat', hourMinute);
export const multipleEmailFormat = helpers.regex('email', multipleEmail);
export const multipleNumberFormat = helpers.regex('number', multipleNumber);
export const hexColorFormat = helpers.regex('hex', hexColor);

// #region Promotion
export const promotionCardConditionValidator = () => {
	return {
		purchaseAmount: {
			rule: { },
			value: {
				required: requiredIf((vm) => vm.rule),
			},
		},
		itemQuantity: {
			rule: { },
			value: {
				required: requiredIf((vm) => vm.rule),
			},
		},
	};
};
// #region

export const getErrorMessage = (validateItem) => {
	if (isEmpty(validateItem)) {
		return null;
	} else if (!validateItem.required) {
		return {
			key: 'global.error.required',
		};
	} else if (!validateItem.maximum255Characters) {
		return {
			key: 'global.error.lengthCharacters',
			data: { minLength: 1, maxLength: 255 },
		};
	} else if (!validateItem.minimumValue1) {
		return {
			key: 'global.error.minimumNumber',
			data: { number: 1 },
		};
	}

	return null;
};
