export const TAG_FILTER_TABLE_FIELDS = Object.freeze([
	{ key: 'id', label: 'ID', class: 'col-id th-mw-50' },
	{ key: 'name', label: 'Tag filter name', class: 'col-name th-mw-150' },
	{ key: 'status', label: 'Status', class: 'col-status th-mw-50' },
	{ key: 'startAt', label: 'Start date', class: 'col-date th-mw-150' },
	{ key: 'endAt', label: 'End date', class: 'col-date th-mw-150' },
	{ key: 'priority', label: 'Priority', class: 'col-id th-mw-50' },
	{ key: 'periodStatus', label: 'Period', class: 'col-period th-mw-50' },
]);

export const TAG_FILTER_STATUS = Object.freeze({
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
});

export const TAG_FILTER_STATUS_LABEL = {
	[TAG_FILTER_STATUS.ACTIVE]: 'Active',
	[TAG_FILTER_STATUS.INACTIVE]: 'Inactive',
};

export const TAG_FILTER_STATUS_OPTIONS = [
	{
		name: 'All',
		value: null,
	},
	{
		name: TAG_FILTER_STATUS_LABEL[TAG_FILTER_STATUS.ACTIVE],
		value: TAG_FILTER_STATUS.ACTIVE,
	},
	{
		name: TAG_FILTER_STATUS_LABEL[TAG_FILTER_STATUS.INACTIVE],
		value: TAG_FILTER_STATUS.INACTIVE,
	},
];

export const TAG_FILTER_PERIOD_STATUS = Object.freeze({
	ON_GOING: 'ON_GOING',
	SCHEDULE: 'SCHEDULE',
	ENDED: 'ENDED',
});

export const TAG_FILTER_PERIOD_STATUS_LABEL = {
	[TAG_FILTER_PERIOD_STATUS.ON_GOING]: 'Ongoing',
	[TAG_FILTER_PERIOD_STATUS.SCHEDULE]: 'Scheduled',
	[TAG_FILTER_PERIOD_STATUS.ENDED]: 'Ended',
};

export const TAG_FILTER_PERIOD_STATUS_OPTIONS = [
	{
		name: 'All',
		value: null,
	},
	{
		name: TAG_FILTER_PERIOD_STATUS_LABEL[TAG_FILTER_PERIOD_STATUS.ON_GOING],
		value: TAG_FILTER_PERIOD_STATUS.ON_GOING,
	},
	{
		name: TAG_FILTER_PERIOD_STATUS_LABEL[TAG_FILTER_PERIOD_STATUS.SCHEDULE],
		value: TAG_FILTER_PERIOD_STATUS.SCHEDULE,
	},
	{
		name: TAG_FILTER_PERIOD_STATUS_LABEL[TAG_FILTER_PERIOD_STATUS.ENDED],
		value: TAG_FILTER_PERIOD_STATUS.ENDED,
	},
];

export const TAG_FILTER_BADGE_COLOR = {
	STATUS: {
		[TAG_FILTER_STATUS.ACTIVE]: 'active',
		[TAG_FILTER_STATUS.INACTIVE]: 'inactive',
	},
	PERIOD_STATUS: {
		[TAG_FILTER_PERIOD_STATUS.ON_GOING]: 'orange',
		[TAG_FILTER_PERIOD_STATUS.SCHEDULE]: 'info',
		[TAG_FILTER_PERIOD_STATUS.ENDED]: 'alto',
	},
};

// Type for config product of tag filter
export const TAG_FILTER_PRODUCT_BY_TYPE = Object.freeze({
	SKU: 'SKU',
	CATEGORY: 'CATEGORY',
});

export const TAG_FILTER_PRODUCT_BY_TYPE_OPTIONS = [
	{ value: TAG_FILTER_PRODUCT_BY_TYPE.SKU, label: 'SKU' },
	{ value: TAG_FILTER_PRODUCT_BY_TYPE.CATEGORY, label: 'Main Category' },
];
