import {
	pathOr,
	priceFormat,
	datetimeFormat,
	getProductStatusValue,
	getThumbnailFromProduct,
	formattedCategories,
	priceFromAPI,
} from '../helpers';
import { CUSTOMER_GROUP } from '../../../enums/customerGroups';
import { transformedMedia } from '../transform/mediaLibrary';
import { PRODUCT_STATUS_OPTIONS, PRODUCT_TYPES } from '../../../enums/products';
import { transformedProductRelatedList } from './productRelated';
import { transformedProductMustHave } from './productMustHave';
import { transformedProductAppleCareList } from './productAppleCare';
import { transformedProductLabels } from './productLabels';
import { transformedUser } from './user';

export const transformedProductRemark = (remark = {}) => {
	const id = pathOr(0, ['id'])(remark);
	const productId = pathOr(0, ['product_id'])(remark);
	const message = pathOr('', ['message'])(remark);
	const user = transformedUser(pathOr(0, ['user'])(remark));
	const createdAt = remark.created_at ? datetimeFormat(remark.created_at) : '-';
	const updatedAt = remark.updated_at ? datetimeFormat(remark.updated_at) : '-';
	return {
		id,
		productId,
		message,
		user,
		createdAt,
		updatedAt,
	};
};
export const transformPriceSellings = (product = {}) => (
	pathOr([], ['price_sellings'])(product).reduce((acc, current) => ({
		...acc,
		[current.customer_group.name]: {
			customerGroupId: current.customer_group.id,
			storeId: current.store.id,
			price: priceFromAPI(current.price),
		},
	}), {})
);

export const transformedProduct = (product = {}) => {
	const id = pathOr(0, ['id'])(product);
	const thumbnail = getThumbnailFromProduct(product);
	const sku = pathOr('-', ['sku'])(product);
	const appleSku = pathOr('-', ['apple_sku'])(product);
	const uid = pathOr('-', ['uid'])(product);
	const name = pathOr('-', ['name'])(product);
	const brand = pathOr('-', ['brand'])(product);
	const priceSRP = priceFormat(priceFromAPI(pathOr(0, ['price_srp'])(product)));
	const priceSellings = transformPriceSellings(product);
	const priceSelling = priceFormat(pathOr(0, [`${CUSTOMER_GROUP.MEMBER}`, 'price'])(priceSellings));
	const priceSellingFranchise = priceFormat(pathOr(0, [`${CUSTOMER_GROUP.FRANCHISE}`, 'price'])(priceSellings));
	const isEOL = pathOr(0, ['is_eol'])(product);
	const isActive = pathOr(0, ['is_active'])(product);
	const statusValue = getProductStatusValue(isEOL, isActive);
	const status = PRODUCT_STATUS_OPTIONS.find((option) => option.value === statusValue);
	const categoryList = pathOr([], ['categories'])(product);
	const labels = transformedProductLabels(pathOr([], ['labels'])(product));
	const categories = categoryList.map((category) => formattedCategories(category));
	const attribute = pathOr('-', ['attribute_set'])(product);
	const groupName = `Group: ${pathOr('', ['group', 'name'])(product)}`;
	const groupTitle = pathOr('', ['group', 'title'])(product);
	const groupSubTitle = pathOr('', ['group', 'sub_title'])(product);
	const groupSlug = pathOr('', ['group', 'slug'])(product);
	const hasVariant = pathOr(false, ['group', 'has_variant'])(product);
	const groupProducts = transformedProductRelatedList(pathOr([], ['group', 'products'])(product));
	const relatedProducts = transformedProductRelatedList(pathOr([], ['related_products'])(product));
	const upSellProducts = transformedProductRelatedList(pathOr([], ['upsell_products'])(product));
	const crossSellProducts = transformedProductRelatedList(pathOr([], ['cross_sell_products'])(product));
	const mustHave = transformedProductMustHave(pathOr(null, ['must_have_product'])(product));
	const frequentlyBoughtTogetherProducts = transformedProductRelatedList(pathOr([], ['frequently_bought_together_products'])(product));
	const appleCare = transformedProductAppleCareList(pathOr([], ['apple_care_products'])(product));
	const mediaFiles = pathOr([], ['files'])(product);
	const createdAt = product.created_at ? datetimeFormat(product.created_at) : '-';
	const newProductUntil = product.new_until_at ? datetimeFormat(product.new_until_at, 'DD MMM YYYY') : null;
	const type = pathOr(PRODUCT_TYPES.NORMAL, ['type'])(product);
	const visibility = {
		isListVisible: pathOr(0, ['is_list_visible'])(product),
		isDetailVisible: pathOr(0, ['is_detail_visible'])(product),
		isSearchVisible: pathOr(0, ['is_search_visible'])(product),
		isTruemoveTradeIn: pathOr(0, ['is_trade_in_truemove_visible'])(product),
		alternateProductUrl: pathOr(null, ['alternate_product_url'])(product),
	};
	const stockQty = pathOr(null, ['stock_qty'])(product);
	// Use stockQty as default of stockTotal
	const stockTotal = pathOr(stockQty, ['stock', 'total'])(product);
	// Use stockQty as default of stockAvailable
	const stockAvailable = pathOr(stockQty, ['stock', 'available'])(product);
	const stockCommitted = pathOr(null, ['stock', 'committed'])(product);
	const stockReserved = pathOr(null, ['stock', 'reserved'])(product);
	const stockTotalCache = pathOr(null, ['stock_total'])(product);
	const stockAvailableCache = pathOr(null, ['stock_available'])(product);
	const stockReservedAndCommittedCache = pathOr(null, ['stock_reserved_committed'])(product);
	return {
		id,
		thumbnail,
		files: mediaFiles.map(transformedMedia),
		sku,
		appleSku,
		name,
		brand,
		status,
		visibility,
		stockTotal,
		stockAvailable,
		stockReservedAndCommitted: (stockReserved === null || stockCommitted === null)
			? null
			: stockReserved + stockCommitted,
		stockTotalCache,
		stockAvailableCache,
		stockReservedAndCommittedCache,
		priceSRP,
		priceSelling,
		priceSellingFranchise,
		categories,
		labels,
		attribute,
		groupName,
		groupTitle,
		groupSubTitle,
		groupSlug,
		groupProducts,
		hasVariant,
		relatedProducts,
		upSellProducts,
		crossSellProducts,
		frequentlyBoughtTogetherProducts,
		appleCare,
		createdAt,
		newProductUntil,
		mustHave,
		type,
		uid,
	};
};

export const transformedProductList = (products = []) => {
	return products
		.map(transformedProduct)
		.map((item) => ({
			...item,
			stockTotal: {
				realTime: item.stockTotal,
				cache: item.stockTotalCache,
			},
			stockAvailable: {
				realTime: item.stockAvailable,
				cache: item.stockAvailableCache,
			},
			stockReservedAndCommitted: {
				realTime: item.stockReservedAndCommitted,
				cache: item.stockReservedAndCommittedCache,
			},
		}));
};

export const transformedProductInfo = (product = {}) => {
	const createdDate = product.created_at ? datetimeFormat(product.created_at) : '-';
	const updatedDate = product.updated_at ? datetimeFormat(product.updated_at) : '-';
	const productType = product.type || PRODUCT_TYPES.NORMAL;

	const priceSRP = priceFormat(priceFromAPI(pathOr(0, ['price_srp'])(product)));
	const priceMin = priceFormat(priceFromAPI(pathOr(0, ['price_min'])(product)));
	const priceSellings = transformPriceSellings(product);
	const isAllowedITECOverridePrice = !!pathOr(0, ['is_allowed_itec_override_price'])(product);
	const isAllowedITECOverridePriceFranchise = !!pathOr(0, ['is_allowed_itec_override_franchise_price'])(product);
	const categories = pathOr([], ['categories'])(product);
	const primaryCategory = categories.find((category) => category.is_primary === 1) || {}; // TODO: recheck data from API is true/false or 1/0
	const primaryCategoryId = primaryCategory.id || null;
	const threeSixtyImages = pathOr([], ['three_sixty_images'])(product);
	const youtubes = pathOr([], ['youtubes'])(product);
	const alternateProductUrl = pathOr(null, ['alternate_product_url'])(product);

	return {
		...transformedProduct(product),
		slug: product.slug || '',
		remarks: (product.remarks && product.remarks.length)
			? product.remarks.map(transformedProductRemark)
			: [],
		priceSRP,
		priceMin,
		priceSellings,
		isAllowedITECOverridePrice,
		isAllowedITECOverridePriceFranchise,
		productType,
		allowFreebie: !!product.is_allowed_freebie,
		isListVisible: !!product.is_list_visible,
		isSearchVisible: !!product.is_search_visible,
		isDetailVisible: !!product.is_detail_visible,
		isTruemoveTradeIn: !!product.is_trade_in_truemove_visible,
		alternateProductUrl,
		createdDate,
		updatedDate,
		updatedBy: pathOr(null, ['updated_by'])(product),
		newProductUntil: product.new_until_at ? datetimeFormat(product.new_until_at, 'DD/MM/YYYY') : null,
		warranty: {
			warrantyCustomer: product.customer_warranty_days || null,
			warrantySupplier: product.supplier_warranty_days || null,
		},
		categories,
		primaryCategoryId,
		categoriesITEC: {
			main: product.itec_category || 'n/a',
			sub: product.itec_subcategory || 'n/a',
		},
		dimension: {
			// eslint-disable-next-line
			dimensionLength: product["length"] || 0,
			width: product.width || 0,
			height: product.height || 0,
			weight: product.weight || 0,
		},
		description: {
			short: product.short_description || '',
			full: product.full_description || '',
		},
		attributeSet: {
			id: product.attribute_set_id || null,
			name: product.attribute_set_name || '',
			attributeKeys: product.attributes || [],
			shortAttribute: product.short_attribute || '',
		},
		seo: {
			title: product.meta_title || '',
			keyword: product.meta_keyword || '',
			description: product.meta_description || '',
			isSearchEngineIndexingEnabled: !!pathOr(1, ['is_search_engine_indexing_enabled'])(product),
		},
		threeSixtyImages: threeSixtyImages.map((threeSixtyImage) => ({
			link: threeSixtyImage.link,
			file: threeSixtyImage.files[0] || null,
		})),
		youtubes: youtubes.map((youtube) => ({
			link: youtube.link,
			file: youtube.files[0] || null,
		})),
	};
};
