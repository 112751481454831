import dayjs from 'dayjs';
import {
	pathOr,
	datetimeFormat,
} from '../helpers';

export const transformedAccordionKey = (accordionKey = {}) => {
	const startAt = pathOr(null, ['start_at'])(accordionKey);
	const startDate = startAt ? dayjs(startAt).toDate() : null;
	const startTime = startAt ? datetimeFormat(startAt, 'HH:mm') : '00:00';
	const endAt = pathOr(null, ['end_at'])(accordionKey);
	const endDate = endAt ? dayjs(endAt).toDate() : null;
	const endTime = endAt ? datetimeFormat(endAt, 'HH:mm') : '23:59';
	const startDateDisplay = startAt ? datetimeFormat(startAt) : '-';
	const endDateDisplay = endAt ? datetimeFormat(endAt) : '-';
	const status = pathOr(null, ['is_visible'])(accordionKey);
	const period = pathOr('', ['period'])(accordionKey);
	const isActive = pathOr(false, ['is_active'])(accordionKey);
	return {
		id: pathOr(0, ['id'])(accordionKey),
		name: pathOr(0, ['name'])(accordionKey),
		titleTh: pathOr(0, ['title_th'])(accordionKey),
		titleEn: pathOr(0, ['title_en'])(accordionKey),
		contentTh: pathOr(0, ['content_th'])(accordionKey),
		contentEn: pathOr(0, ['content_en'])(accordionKey),
		startDate,
		startTime,
		endDate,
		endTime,
		startDateDisplay,
		endDateDisplay,
		status,
		period,
		isActive,
		// for column delete
		deleteId: pathOr(0, ['id'])(accordionKey),
	};
};

export const transformedAccordionKeyList = (accordionKeys = []) => {
	return accordionKeys.map(transformedAccordionKey);
};

export const transformedFormAccordionKey = (data = {}) => {
	return {
		name: data.name,
		title_th: data.titleTh,
		title_en: data.titleEn,
		content_th: data.contentTh,
		content_en: data.contentEn,
		is_active: data.isActive,
		start_at: data.startAt,
		end_at: data.endAt,
	};
};
