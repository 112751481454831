import {
	pathOr,
	priceFormat,
	priceFromAPI,
	datetimeFormat,
} from '../helpers';

import {
	ORDER_DETAIL_TAX_TYPE,
} from '../../../enums/orders';
import { IMAGE_PLACEHOLDER_URL } from '../../../enums/general';
import { transformedShipmentDetail } from './shipments';
import { transformedUser } from './user';


const buildCategoryParentPath = (category) => {
	let currentPath = [];
	currentPath = [category.name_en];
	if (category.parent) {
		const nextPath = buildCategoryParentPath(category.parent);
		currentPath = [
			...nextPath,
			...currentPath,
		];
	}
	return currentPath;
};

/**
 * transform order history
 * @param {Object} order history
 * @returns {Object} order history
 */

export const transformedOrderHistory = (history = {}) => ({
	id: history.id,
	level: history.level,
	message: history.message,
	orderId: history.order_id,
	timestamp: history.timestamp,
	title: history.title,
	actorId: history.actor_id || null,
	actorName: history.actor_name || null,
	actorType: history.actor_type || null,
	createdAt: history.created_at ? datetimeFormat(history.created_at) : '-',
	updatedAt: history.updated_at ? datetimeFormat(history.updated_at) : '-',
});

/**
 * transform order remark
 * @param {Object} order remark
 * @returns {Object} order remark
 */

export const transformedOrderRemark = (remark = {}) => {
	return {
		id: remark.id,
		message: remark.message,
		updatedAt: remark.updated_at ? datetimeFormat(remark.updated_at) : '-',
		createdAt: remark.created_at ? datetimeFormat(remark.created_at) : '-',
		user: transformedUser(pathOr({}, ['user'])(remark)),
	};
};

/**
 * transform order promotion
 * @param {Object} order promotion
 * @returns {Object} order promotion
 */

export const transformedOrderPromotion = (promotion = {}, orderProducts = []) => {
	const items = promotion.items.map((item) => {
		const sku = item.sku;

		// Since api just send only SKU number in promotion so we have find product resource in the cart
		const product = orderProducts.find((orderItem) => orderItem.sku === sku);

		const quantity = (item && item.quantity) || (product && product.quantity) || 0;
		const discount = priceFormat(priceFromAPI(pathOr(null, ['discount', 'amount'])(item)));

		return {
			discount,
			product,
			quantity,
		};
	});

	return {
		id: pathOr(0, ['id'])(promotion),
		type: pathOr(null, ['type'])(promotion),
		name: pathOr(null, ['name'])(promotion),
		description: pathOr(null, ['description'])(promotion),
		discount: priceFormat(priceFromAPI(pathOr(null, ['discount', 'amount'])(promotion))),
		items,
	};
};

/**
 * transform order coupon
 * @param {Object} order coupon
 * @returns {Object} order coupon
 */

export const transformedOrderCoupon = (coupon = {}) => {
	return {
		id: pathOr(0, ['id'])(coupon),
		type: pathOr(null, ['type'])(coupon),
		name: pathOr(null, ['name'])(coupon),
		code: pathOr(null, ['code'])(coupon),
		discount: priceFormat(priceFromAPI(pathOr(null, ['discount', 'amount'])(coupon))),
	};
};


/**
 * transform order shipping discount promotions
 * @param {Object} shipping discount promotion
 * @returns {Object} shipping discount promotion
 */
export const transformedShippingDiscountPromotion = (promotion = {}) => ({
	id: pathOr(0, ['id'])(promotion),
	name: pathOr(null, ['name'])(promotion),
	discount: priceFormat(priceFromAPI(pathOr(null, ['discount', 'amount'])(promotion))),
});

export const transformedOrderLog = (log = {}) => ({
	id: log.id,
	level: log.level,
	message: log.message,
	orderId: log.order_id,
	timestamp: log.timestamp,
	title: log.title,
	actorId: log.actor_id || null,
	actorName: log.actor_name || null,
	actorType: log.actor_type || null,
	createAt: log.created_at ? datetimeFormat(log.created_at) : '-',
	updatedAt: log.updated_at ? datetimeFormat(log.updatedAt) : '-',
});

/**
 * transform order address
 * @param {Object} order address
 * @returns {Object} order address info
 */
export const transformedOrderAddressInfo = (address = {}) => ({
	id: address.id || null,
	firstName: address.first_name || null,
	lastName: address.last_name || null,
	phoneNumber: address.phone_number || null,
	address1: address.address_1 || null,
	address2: address.address_2 || null,
	postcode: address.postcode || null,
	province: address.province || null,
	district: address.district || null,
	subdistrict: address.subdistrict || null,
	detail: address.detail || null,
	addressType: address.type || null,
	taxType: address.is_juristic
		? ORDER_DETAIL_TAX_TYPE.COMPANY
		: ORDER_DETAIL_TAX_TYPE.PERSONAL,
	nationalId: address.national_id || null,
	companyName: address.company_name || null,
	taxId: address.tax_id || null,
	taxEmail: address.tax_email || null,
	taxBranchCode: address.tax_branch_code || null,
	taxBranchName: address.tax_branch_name || null,
	isDefault: address.is_default || false,
	isJuristic: address.is_juristic || false,
});

/**
 * transform order installment info
 * @param {Object} paymentParam
 * @returns {Object} installment info
 */
export const transformedOrderInstallmentInfo = (paymentParam = {}) => ({
	id: pathOr(null, ['installment', 'id'])(paymentParam),
	interestRate: pathOr(null, ['installment', 'interest_rate'])(paymentParam),
	period: pathOr(null, ['installment', 'period'])(paymentParam),
	estimateAmountPerMonth: pathOr(null, ['installment', 'estimate_amount_per_month'])(paymentParam),
});

/**
 * transform order payment information
 * @param {Object} order
 * @returns {Object} payment info
 */
export const transformedOrderPaymentInfo = (orderId = null, payment = {}, binCampaignCode = '') => {
	let paymentBillingAddress = pathOr(null, ['billing_address'])(payment);
	let paymentTaxAddress = pathOr(null, ['tax_address'])(payment);

	const paymentParam = pathOr(null, ['param'])(payment);
	const paymentPaidAmount = pathOr(null, ['transaction_info', 'paid_amount'])(payment);
	const paymentRedemptionAmount = pathOr(null, ['transaction_info', 'redemption_amount'])(payment);

	if (paymentBillingAddress) {
		paymentBillingAddress = transformedOrderAddressInfo(paymentBillingAddress);
	}

	if (paymentTaxAddress) {
		paymentTaxAddress = transformedOrderAddressInfo(paymentTaxAddress);
	}

	return {
		orderId,
		method: {
			name: pathOr(null, ['payment_method', 'name_en'])(payment),
			slug: pathOr(null, ['payment_method', 'slug'])(payment),
		},
		paymentMethodName: pathOr('-', ['payment_method_name'])(payment),
		status: pathOr(null, ['status'])(payment),
		expiredAt: pathOr(null, ['expired_at'])(payment),
		paidDate: pathOr(null, ['transaction_info', 'paid_date'])(payment),
		paidAmount: paymentPaidAmount ? priceFormat(priceFromAPI(paymentPaidAmount)) : paymentPaidAmount,
		pointAmount: pathOr(null, ['transaction_info', 'point_amount'])(payment),
		redemptionAmount: paymentRedemptionAmount ? priceFormat(priceFromAPI(paymentRedemptionAmount)) : paymentRedemptionAmount,
		redemptionPoint: pathOr(null, ['transaction_info', 'redemption_point'])(payment),
		billingAddress: paymentBillingAddress,
		taxAddress: paymentTaxAddress,
		cardNumberLastDigits: pathOr(null, ['transaction_info', 'card', 'last_digits'])(payment),
		cardType: pathOr(null, ['transaction_info', 'card', 'brand'])(payment),
		cardProvider: pathOr(null, ['transaction_info', 'card', 'issuer_bank'])(payment),
		gateway: pathOr(null, ['transaction_info', 'gateway'])(payment),
		agent: pathOr(null, ['payment_method', 'agent'])(payment),
		installment: paymentParam
			? transformedOrderInstallmentInfo(paymentParam)
			: null,
		transactionMethod: pathOr(null, ['transaction_info', 'transaction_method'])(payment),
		binCampaignCode,
	};
};

/**
 * transform order payment information
 * @param {Object} order
 * @returns {Object} payment info
 */
export const transformedOrderShippingInfo = (order) => {
	let address = pathOr(null, ['shipping', 'shipping_address'])(order);

	if (address) {
		address = transformedOrderAddressInfo(address);
	}

	return {
		shipping: {
			method: {
				name: pathOr(null, ['shipping', 'shipping_method', 'name'])(order),
			},
			shippingMethodName: pathOr('-', ['shipping', 'shipping_method_name'])(order),
			status: pathOr(null, ['shipping', 'status'])(order),
			address,
		},
	};
};

/**
 * transform order general information
 * @param {Object} order
 * @returns {Object} order info
 */
export const transformedOrderGeneralInfo = (order = {}) => {
	const orderId = order.id || null;
	const binCampaignCode = pathOr('', ['meta', 'credit_card_bin_campaign_code'])(order);
	return {
		id: orderId,
		orderCode: order.code || null,
		orderType: pathOr(null, ['type'])(order),
		orderStatus: order.status || null,
		customer: {
			id: pathOr(null, ['customer', 'id'])(order),
			firstName: pathOr(pathOr('', ['customer_first_name'])(order), ['customer', 'first_name'])(order),
			lastName: pathOr(pathOr('', ['customer_last_name'])(order), ['customer', 'last_name'])(order),
			phoneNumber: pathOr('-', ['customer', 'phone_number'])(order),
			email: pathOr('-', ['customer', 'email'])(order),
			customerGroup: {
				id: pathOr(null, ['customer', 'customer_group', 'id'])(order),
				name: pathOr('-', ['customer', 'customer_group', 'name'])(order),
			},
			idCard: pathOr(null, ['pre_order', 'identity_document'])(order),
		},
		total: priceFormat(priceFromAPI(pathOr(0, ['total'])(order))),
		createdAt: order.created_at ? datetimeFormat(order.created_at) : '-',
		updatedAt: order.updated_at ? datetimeFormat(order.updated_at) : '-',
		paymentExpiredAt: order.payment && order.payment.expired_at ? datetimeFormat(order.payment.expired_at) : '-',
		payment: transformedOrderPaymentInfo(orderId, order.payment, binCampaignCode),
		paymentRemaining: order.payment_remaining ? transformedOrderPaymentInfo(orderId, order.payment_remaining, binCampaignCode) : null,
		paymentStatus: order.payment_status || null,
		isCancelable: order.is_cancelable || false,
		isOrganizationPurpose: order.is_organization_purpose || false,
		...transformedOrderShippingInfo(order),
	};
};

/**
 * transform order's product list
 * @param {Object} product list of order
 * @returns {Object} product list
 */
export const transformedOrderProductList = (product = {}) => {
	const productChildren = product.children || [];
	const id = pathOr(null, ['id'])(product);
	const name = pathOr(null, ['name'])(product);
	const sku = pathOr(null, ['sku'])(product);
	const type = pathOr(null, ['type'])(product);
	const thumbnail = pathOr(IMAGE_PLACEHOLDER_URL, ['thumbnail_url'])(product);
	const discount = priceFormat(priceFromAPI(pathOr(null, ['discount'])(product)));
	const priceSRP = priceFormat(priceFromAPI(pathOr(null, ['price_srp'])(product)));
	const priceSelling = priceFormat(priceFromAPI(pathOr(null, ['price_selling'])(product)));
	const vatSubtotal = priceFormat(priceFromAPI(pathOr(null, ['vat_subtotal'])(product)));
	const total = priceFormat(priceFromAPI(pathOr(null, ['total'])(product)));
	const subtotal = priceFormat(priceFromAPI(pathOr(null, ['subtotal'])(product)));
	const shippingFee = priceFormat(priceFromAPI(pathOr(null, ['shipping_fee'])(product)));
	const quantity = pathOr(0, ['quantity'])(product);
	const preOrder = pathOr(null, ['pre_order'])(product);
	const isFlashSale = !!pathOr(false, ['is_flash_sale'])(product);
	const stockOptionNoticeText = pathOr(null, ['stock_option_notice_text'])(product);

	// Children (freebie)
	const children = productChildren.length
		? productChildren.map((item) => transformedOrderProductList(item))
		: [];

	return {
		id,
		name,
		sku,
		type,
		thumbnail,
		priceSelling,
		priceSRP,
		discount,
		vatSubtotal,
		shippingFee,
		subtotal,
		total,
		quantity,
		children,
		preOrder: preOrder ? {
			isFullPrice: !!pathOr(false, ['is_full_price'])(preOrder),
			isReserve: !!pathOr(false, ['is_reserve'])(preOrder),
			remainingPrice: priceFormat(priceFromAPI(pathOr(null, ['remaining_price'])(preOrder))),
			initialPrice: priceFormat(priceFromAPI(pathOr(null, ['initial_price'])(preOrder))),
		}
			: null,
		isFlashSale,
		stockOptionNoticeText,
	};
};

/**
 * transform orders
 * @param {Array} order
 * @returns {Array} order list
 */
export const transformedOrderList = (orders = []) => {
	return orders.map((item) => transformedOrderGeneralInfo(item));
};

export const transformedUstoreInfo = (order = {}) => {
	const ustoreInfo = pathOr(null, ['ustore_info'])(order);

	if (!ustoreInfo) {
		return ustoreInfo;
	}

	return {
		studentDiscountAmount: priceFormat(priceFromAPI(pathOr(null, ['student_discount'])(ustoreInfo))),
		ecCode: pathOr(null, ['ec_code'])(ustoreInfo),
		university: {
			name: pathOr(null, ['company_en'])(ustoreInfo.university),
			appleCustomerId: pathOr(null, ['customer_id'])(ustoreInfo.university),
		},
	};
};

export const transformedStudentDiscount = (studentDiscount = null, orderProducts = []) => {
	if (!studentDiscount) {
		return null;
	}
	const items = studentDiscount.items.map((item) => {
		const sku = item.sku;

		const product = orderProducts.find((orderItem) => orderItem.sku === sku);

		const quantity = (item && item.quantity) || (product && product.quantity) || 0;
		const discount = priceFormat(priceFromAPI(pathOr(null, ['discount', 'amount'])(item)));

		return {
			discount,
			product,
			quantity,
		};
	});

	return {
		name: pathOr(null, ['name'])(studentDiscount),
		discount: priceFormat(priceFromAPI(pathOr(null, ['discount', 'amount'])(studentDiscount))),
		items,
	};
};

/**
 * transform order detail pre-order
 * @param {Object} preOrder pre-order data in order detail
 * @returns {Object} pre-order
 */
export const transformedOrderDetailPreOrder = (preOrder = null) => {
	if (preOrder) {
		const pickup = preOrder.pickup
			? {
				pickup: {
					branchName: preOrder.pickup.branch_name ?? '',
					date: datetimeFormat(preOrder.pickup.start_at, 'DD MMM YYYY'),
					startTime: datetimeFormat(preOrder.pickup.start_at, 'HH.mm'),
					endTime: datetimeFormat(preOrder.pickup.end_at, 'HH.mm'),
				},
			}
			: {};

		return {
			...pickup,
		};
	}
	return null;
};

/**
 * Transform order detail - cart_temporary
 * @param {Object} cartTemporary  cart_temporary data in order detail
 * @returns {Object} cartTemporary
 */
export const transformedCartTemporary = (cartTemporary = null) => {
	if (cartTemporary) {
		return {
			id: cartTemporary.id ?? '',
			staffCustomerId: cartTemporary.staff_customer_id ?? '',
			staffId: cartTemporary.staff_id ?? '',
			staffName: cartTemporary.staff_name ?? '',
			staffEmail: cartTemporary.staff_email ?? '',
			branchId: cartTemporary.staff_branch_id ?? '',
			uid: cartTemporary.uid ?? '',
		};
	}
	return null;
};

/**
 * transform order detail
 * @param {Array} order
 * @returns {Array} order detail
 */
export const transformedOrderDetail = (order = {}) => {
	// Get main product
	const items = order.items
		? order.items.map((item) => transformedOrderProductList(item))
		: [];

	// Get freebie product
	const freebies = order.freebies
		? order.freebies.map((item) => transformedOrderProductList(item))
		: [];

	const productFreebies = items.map((products) => products.children).flat();

	// Combine main product + cart freebies + product freebies
	const allProducts = [...items, ...freebies, ...productFreebies];

	// Get promotion for general reward
	const promotionsGeneral = pathOr([], ['meta', 'discount', 'promotions'])(order)
		.map((promotion) => transformedOrderPromotion(promotion, allProducts));

	// Get promotion for freebie reward
	const promotionsFreebie = pathOr([], ['meta', 'discount', 'freebies'])(order)
		.map((promotion) => transformedOrderPromotion(promotion, allProducts));

	// Get shipping discount promotions
	const shippingDiscountPromotions = pathOr([], ['meta', 'discount', 'shipping_fee_promotions'])(order)
		.map(transformedShippingDiscountPromotion);

	// Get shipping discount coupons
	const shippingDiscountCoupons = pathOr([], ['meta', 'discount', 'shipping_fee_coupons'])(order)
		.map(transformedOrderCoupon);

	// Get total shipping discount
	const shippingDiscountTotal = priceFormat(priceFromAPI(pathOr(null, ['meta', 'discount', 'total_shipping_discount'])(order)));

	// Combine all promotion
	const promotions = [...promotionsGeneral, ...promotionsFreebie];

	// flash sale products
	const flashSaleItems = pathOr([], ['meta', 'flash_sale'])(order);
	const flashSales = (Array.isArray(flashSaleItems) ? flashSaleItems : []).map((flashSale) => ({
		id: flashSale.id || null,
		name: flashSale.name || null,
		items: (flashSale.items || []).map((item) => ({
			product: allProducts.find((orderItem) => orderItem.sku === item.sku),
			flashSalePrice: priceFormat(priceFromAPI(item.flash_sale_price || 0)),
			sellingPrice: priceFormat(priceFromAPI(item.selling_price || 0)),
			discount: priceFormat(priceFromAPI(item.discount || 0)),
		})),
		totalDiscount: priceFormat(priceFromAPI(flashSale.total_discount || 0)),
	}));

	return {
		...transformedOrderGeneralInfo(order),
		store: {
			id: pathOr(null, ['store', 'id'])(order),
			name: pathOr(null, ['store', 'name'])(order),
		},
		note: order.note || null,
		items,
		quantity: pathOr(0, ['quantity'])(order),
		discount: priceFormat(priceFromAPI(pathOr(null, ['discount'])(order))),
		shippingFee: priceFormat(priceFromAPI(pathOr(null, ['shipping_fee'])(order))),
		additionalShippingFee: pathOr(null, ['additional_shipping_fee'])(order),
		subtotal: priceFormat(priceFromAPI(pathOr(null, ['subtotal'])(order))),
		total: priceFormat(priceFromAPI(pathOr(null, ['total'])(order))),
		subtotalWithDiscountExcludedShippingDiscount: priceFormat(priceFromAPI(pathOr(null, [
			'meta',
			'discount',
			'sub_total_with_discount_excluded_shipping_discount',
		])(order))),
		totalShippingFee: priceFormat(priceFromAPI(pathOr(null, ['meta', 'shipping_fee', 'additional_fee', 'total_additional_fee'])(order))),
		categoryTotalFee: priceFormat(priceFromAPI(pathOr(null, ['meta', 'shipping_fee', 'additional_fee', 'info', 'total_fee_category'])(order))),
		categoryFees: pathOr([], ['meta', 'shipping_fee', 'additional_fee', 'info', 'category_fees'])(order).map((categoryFee) => {
			const path = buildCategoryParentPath(categoryFee.categories);
			const formattedPath = path.join(' / ');
			return {
				path: formattedPath,
				amount: priceFormat(priceFromAPI(categoryFee.amount)),
			};
		}, { total: 0, fees: [] }),
		productTotalFee: priceFormat(priceFromAPI(pathOr(null, ['meta', 'shipping_fee', 'additional_fee', 'info', 'total_fee_product'])(order))),
		productFees: pathOr([], ['meta', 'shipping_fee', 'additional_fee', 'info', 'product_fees'])(order).map((productFee) => {
			return {
				product: {
					name: productFee.product.name,
					sku: productFee.product.sku,
					thumbnail: productFee.product.thumbnail_url,
				},
				amount: priceFormat(priceFromAPI(productFee.amount)),
			};
		}),
		postcodeTotalFee: priceFormat(priceFromAPI(pathOr(null, ['meta', 'shipping_fee', 'additional_fee', 'info', 'total_fee_postcode'])(order))),
		postcodeFees: pathOr([], ['meta', 'shipping_fee', 'additional_fee', 'info', 'postcode_fees'])(order).map((postcodeFee) => {
			return {
				postcode: postcodeFee.postcode,
				amount: priceFormat(priceFromAPI(postcodeFee.amount)),
			};
		}),
		baseShippingFee: priceFormat(priceFromAPI(pathOr(null, ['meta', 'shipping_fee', 'base_shipping_fee'])(order))),
		shipments: pathOr([], ['shipments'])(order).map(transformedShipmentDetail),
		histories: pathOr([], ['logs'])(order).map(transformedOrderHistory),
		remarks: pathOr([], ['remarks'])(order).map(transformedOrderRemark),
		coupons: pathOr([], ['meta', 'discount', 'coupons'])(order).map(transformedOrderCoupon),
		promotions,
		externalOrderCode: order.external_order_code || null,
		freebies,
		shippingDiscountPromotions,
		shippingDiscountCoupons,
		shippingDiscountTotal,
		allProducts,
		flashSales,
		ustoreInfo: transformedUstoreInfo(order),
		studentDiscount: transformedStudentDiscount(pathOr(null, ['meta', 'discount', 'student_discount'])(order), allProducts),
		preOrder: transformedOrderDetailPreOrder(order.pre_order),
		cartTemporary: transformedCartTemporary(order.cart_temporary),
	};
};

/**
 * transform order cancelation
 * @param {Array} order
 * @returns {Array} order cancelation list
 */
export const transformedCancelOrderReason = (orders = {}) => {
	return {
		nameEn: orders.name_en || '',
		ReasonId: orders.id || 0,
	};
};


/**
 * transform order cancel
 * @param {Array} order
 * @returns {Array} order cancel
 */
export const transformedCancelOrderList = (orders = []) => {
	return orders.map((item) => transformedCancelOrderReason(item));
};

/**
 * transform order detail product table
 * @param {Object} order
 * @returns {Object} order detail
 */

export const transformedOrderDetailProductTable = (product = {}) => ({
	id: product.id || null,
	product: {
		name: product.name || '',
		sku: product.sku || '',
		thumbnail: product.thumbnail || null,
		type: product.type || null,
		stockOptionNoticeText: product.stockOptionNoticeText,
	},
	priceSRP: product.priceSRP || 0,
	priceSelling: {
		price: product.priceSelling || 0,
		isFlashSale: product.isFlashSale,
	},
	pricePreOrder: product.preOrder
		? {
			price: product.preOrder.isFullPrice ? product.priceSelling : product.preOrder.initialPrice,
			type: product.preOrder.isFullPrice ? 'Full' : 'Reserve',
		}
		: null,
	quantity: product.quantity || 0,
	total: product.subtotal || 0,
});
