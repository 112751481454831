import httpClient from './httpClient';

export const WIDGET_ENDPOINT = '/backoffice/widgets';
export const MICROSITE_WIDGET_ENDPOINT = '/backoffice/microsite-widgets';

export const getWidgetsAPI = (params = {}) => httpClient.get(WIDGET_ENDPOINT, { params });
export const getMicrositeWidgetsAPI = (params = {}) => httpClient.get(MICROSITE_WIDGET_ENDPOINT, { params });
export const postWidgetAPI = (params) => httpClient.post(WIDGET_ENDPOINT, params);
export const postMicrositeWidgetAPI = (params) => httpClient.post(MICROSITE_WIDGET_ENDPOINT, params);
export const sortWidgetsAPI = (params) => httpClient.post(`${WIDGET_ENDPOINT}/sort`, params);
export const updateWidgetAPI = (id, params) => httpClient.put(`${WIDGET_ENDPOINT}/${id}`, params);
export const updateWidgetCategoryAPI = (id, params) => httpClient.put(`${MICROSITE_WIDGET_ENDPOINT}/${id}/category`, params);
export const updateMicrositeWidgetAPI = (id, params) => httpClient.put(`${MICROSITE_WIDGET_ENDPOINT}/${id}`, params);
export const deleteWidgetAPI = (id) => httpClient.delete(`${WIDGET_ENDPOINT}/${id}`);
export const deleteMicrositeWidgetAPI = (id) => httpClient.delete(`${MICROSITE_WIDGET_ENDPOINT}/${id}`);
export const checkWidgetsAPI = (ids = []) => httpClient.post(`${WIDGET_ENDPOINT}/check`, { widget_ids: ids });
