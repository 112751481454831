<template>
	<CButton
		:disabled="disabled"
		type="submit"
		class="transparent"
		color="primary"
		data-test-id="button"
		@click.prevent="$emit('click')"
	>
		{{ text }}
	</CButton>
</template>

<script>
export default {
	name: 'BaseSaveButton',
	props: {
		text: {
			type: String,
			default: 'Save changes',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

