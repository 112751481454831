export const CUSTOMER_GROUP = {
	FRANCHISE: 'franchise',
	MEMBER: 'member',
};

export const RANK_SLUGS = {
	NONE: null,
	GIGABYTE: 'gigabyte',
	TERABYTE: 'terabyte',
};

export const RANK_LABELS = {
	NONE: 'None',
	GIGABYTE: 'Gigabyte',
	TERABYTE: 'Terabyte',
};

export const RANK = {
	[RANK_SLUGS.NONE]: RANK_LABELS.NONE,
	[RANK_SLUGS.GIGABYTE]: RANK_LABELS.GIGABYTE,
	[RANK_SLUGS.TERABYTE]: RANK_LABELS.TERABYTE,
};

export const RANK_OPTIONS = [
	{
		value: RANK_SLUGS.NONE,
		label: RANK_LABELS.NONE,
	},
	{
		value: RANK_SLUGS.GIGABYTE,
		label: RANK_LABELS.GIGABYTE,
	},
	{
		value: RANK_SLUGS.TERABYTE,
		label: RANK_LABELS.TERABYTE,
	},
];
