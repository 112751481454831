export const NOTIFICATION_TABLE_FIELDS = Object.freeze([
	{ key: 'id', label: 'ID', class: 'col-id' },
	{ key: 'name', label: 'Name', class: 'col-name th-mw-200' },
	{ key: 'messageTitle', label: 'Message title', class: 'col-message-title th-mw-100' },
	{ key: 'sendDate', label: 'Schedule time', class: 'col-schedule-time th-mw-150' },
	{ key: 'status', label: 'Status', class: 'col-status th-mw-80' },
]);

export const NOTIFICATION_STATUSES = {
	SENT: 1,
	SCHEDULED: 0,
};

export const NOTIFICATION_TITLE = {
	STATUS: {
		[NOTIFICATION_STATUSES.SENT]: 'Sent',
		[NOTIFICATION_STATUSES.SCHEDULED]: 'Scheduled',
	},
};

// mapping status color, use with CBadge property components
export const NOTIFICATION_COLOR = {
	STATUS: {
		[NOTIFICATION_STATUSES.SENT]: 'success',
		[NOTIFICATION_STATUSES.SCHEDULED]: 'info',
	},
};
