import httpClient from './httpClient';

const SUB_HEADER_ENDPOINT = '/backoffice/sub-headers';

const getSubHeadersAPI = (params = {}) => httpClient.get(`${SUB_HEADER_ENDPOINT}`, { params });
const getSubHeaderAPI = (id) => httpClient.get(`${SUB_HEADER_ENDPOINT}/${id}`);
const createSubHeaderAPI = (params) => httpClient.post(SUB_HEADER_ENDPOINT, params);
const putSubHeaderAPI = (id, params) => httpClient.put(`${SUB_HEADER_ENDPOINT}/${id}`, params);
const deleteSubHeaderAPI = (id) => httpClient.delete(`${SUB_HEADER_ENDPOINT}/${id}`);

export {
	getSubHeadersAPI,
	getSubHeaderAPI,
	createSubHeaderAPI,
	putSubHeaderAPI,
	deleteSubHeaderAPI,
};
