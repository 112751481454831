import { numberFormat, pathOr } from '../helpers';

export const transformedMeta = (meta = {}) => {
	return {
		path: pathOr('', ['path'])(meta),
		from: pathOr(null, ['from'])(meta),
		perPage: pathOr(null, ['per_page'])(meta),
		to: pathOr(null, ['to'])(meta),
		total: numberFormat(pathOr(null, ['total'])(meta)),
		currentPage: pathOr(1, ['current_page'])(meta),
		lastPage: pathOr(0, ['last_page'])(meta),
	};
};
