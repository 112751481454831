import { pathOr } from '../helpers';

export const transformedAttributeKey = (attributeKey = {}) => {
	const id = pathOr(0, ['id'])(attributeKey);
	const name = pathOr(0, ['name'])(attributeKey);
	return {
		name,
		id,
	};
};

export const transformedAttributeKeyList = (attributeKeys = []) => {
	return attributeKeys.map(transformedAttributeKey);
};

