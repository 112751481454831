import {
	cibBehance,
	cibCcAmex,
	cibCcMastercard,
	cibCcVisa,
	cibDribbble,
	cibFacebook,
	cibFlickr,
	cibGithub,
	cibGooglePay,
	cibInstagram,
	cibLinkedin,
	cibPaypal,
	cibPinterest,
	cibReddit,
	cibSkype,
	cibStackOverflow,
	cibStripe,
	cibTumblr,
	cibTwitter,
	cibVk,
	cibXing,
	cibYoutube,
	cibYahoo,
	cibVimeo,
	cifUs,
	cifBr,
	cifIn,
	cifFr,
	cifEs,
	cifPl,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowRight,
	cilArrowThickFromBottom,
	cilArrowThickFromTop,
	cilBan,
	cilBasket,
	cilBell,
	cilBold,
	cilBookmark,
	cilCalculator,
	cilCalendar,
	cilCloudDownload,
	cilChartPie,
	cilCheck,
	cilChevronBottom,
	cilChevronLeft,
	cilChevronRight,
	cilChevronTop,
	cilCircle,
	cilCheckCircle,
	cilClipboard,
	cilCode,
	cilCommentSquare,
	cilCreditCard,
	cilCursor,
	cilCursorMove,
	cilDataTransferDown,
	cilDrop,
	cilDollar,
	cilDoor,
	cilEnvelopeClosed,
	cilEnvelopeLetter,
	cilEnvelopeOpen,
	cilEuro,
	cilGlobeAlt,
	cilGrid,
	cilFile,
	cilFilter,
	cilFullscreen,
	cilFullscreenExit,
	cilHome,
	cilHouse,
	cilInbox,
	cilIndentDecrease,
	cilIndentIncrease,
	cilInfo,
	cilInputPower,
	cilItalic,
	cilJustifyCenter,
	cilJustifyLeft,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilListNumbered,
	cilListRich,
	cilLocationPin,
	cilLockLocked,
	cilMagnifyingGlass,
	cilMap,
	cilMoney,
	cilMoon,
	cilNotes,
	cilOptions,
	cilPaperclip,
	cilPaperPlane,
	cilPencil,
	cilPeople,
	cilPhone,
	cilPlus,
	cilPrint,
	cilPuzzle,
	cilViewQuilt,
	cilSave,
	cilSettings,
	cilShare,
	cilShareAll,
	cilShareBoxed,
	cilShieldAlt,
	cilSortAscending,
	cilSortDescending,
	cilSpeech,
	cilSpeedometer,
	cilSpreadsheet,
	cilStar,
	cilSun,
	cilSync,
	cilTags,
	cilTask,
	cilTrash,
	cilTruck,
	cilUnderline,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilUserUnfollow,
	cilVerticalAlignTop,
	cilWarning,
	cilXCircle,
	cilX,
	cilStorage,
} from '@coreui/icons';


import { logo } from './logo';

export const iconsSet = {
	logo,
	cibBehance,
	cibCcAmex,
	cibCcMastercard,
	cibCcVisa,
	cibDribbble,
	cibFacebook,
	cibFlickr,
	cibGithub,
	cibGooglePay,
	cibInstagram,
	cibLinkedin,
	cibPaypal,
	cibPinterest,
	cibReddit,
	cibSkype,
	cibStackOverflow,
	cibStripe,
	cibTumblr,
	cibTwitter,
	cibVk,
	cibXing,
	cibYoutube,
	cibYahoo,
	cibVimeo,
	cifUs,
	cifBr,
	cifIn,
	cifFr,
	cifEs,
	cifPl,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowRight,
	cilArrowThickFromBottom,
	cilArrowThickFromTop,
	cilBan,
	cilBasket,
	cilBell,
	cilBold,
	cilBookmark,
	cilCalculator,
	cilCalendar,
	cilCloudDownload,
	cilChartPie,
	cilCheck,
	cilChevronBottom,
	cilChevronLeft,
	cilChevronRight,
	cilChevronTop,
	cilCircle,
	cilCheckCircle,
	cilClipboard,
	cilCode,
	cilCommentSquare,
	cilCreditCard,
	cilCursor,
	cilCursorMove,
	cilDataTransferDown,
	cilDrop,
	cilDollar,
	cilDoor,
	cilEnvelopeClosed,
	cilEnvelopeLetter,
	cilEnvelopeOpen,
	cilEuro,
	cilGlobeAlt,
	cilGrid,
	cilFile,
	cilFilter,
	cilFullscreen,
	cilFullscreenExit,
	cilHome,
	cilHouse,
	cilInbox,
	cilIndentDecrease,
	cilIndentIncrease,
	cilInfo,
	cilInputPower,
	cilItalic,
	cilJustifyCenter,
	cilJustifyLeft,
	cilLaptop,
	cilLayers,
	cilLightbulb,
	cilList,
	cilListNumbered,
	cilListRich,
	cilLocationPin,
	cilLockLocked,
	cilMagnifyingGlass,
	cilMap,
	cilMoney,
	cilMoon,
	cilNotes,
	cilOptions,
	cilPaperclip,
	cilPaperPlane,
	cilPencil,
	cilPeople,
	cilPhone,
	cilPlus,
	cilPrint,
	cilPuzzle,
	cilViewQuilt,
	cilSave,
	cilSettings,
	cilShare,
	cilShareAll,
	cilShareBoxed,
	cilShieldAlt,
	cilSortAscending,
	cilSortDescending,
	cilSpeech,
	cilSpeedometer,
	cilSpreadsheet,
	cilStar,
	cilSun,
	cilSync,
	cilTags,
	cilTask,
	cilTrash,
	cilTruck,
	cilUnderline,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilUserUnfollow,
	cilVerticalAlignTop,
	cilWarning,
	cilXCircle,
	cilX,
	cilStorage,
};
