<template>
	<div class="media-info">
		<template v-if="data">
			<a
				:href="data.imageUrls.original"
				:title="data.name"
				target="_blank"
				data-test-id="media-info-link-image-preview"
			>
				<div class="image-wrapper mb-2">
					<img :src="data.imageUrls.small" :alt="data.name">
					<CIcon name="cil-fullscreen" size="custom" class="icon-fullscreen" />
				</div>
			</a>
			<p
				v-if="data.fileName || data.name"
				class="typo-label mb-1"
			>
				{{ data.fileName || data.name }}
			</p>
			<p
				v-if="data.createdAt"
				class="typo-helper-text mb-3 color-black-45"
			>
				Uploaded: {{ data.createdAt }}
			</p>
			<p
				v-if="data.info.width && data.info.height"
				class="typo-body-2 mb-1"
			>
				Resolution: {{ data.info.width }}x{{ data.info.height }}px
			</p>
			<p
				v-if="data.info.fileSize"
				class="typo-body-2 mb-3"
			>
				File size: {{ data.info.fileSize }}
			</p>
			<button
				type="button"
				class="btn-flat btn-delete"
				@click="$emit('onDelete', data)"
			>
				Delete image
			</button>
		</template>
		<template v-else>
			<img src="/assets/images/media-library-empty.svg" class="mb-3" alt="Media library empty">
			<p class="typo-label mb-1">
				Media library
			</p>
			<p class="typo-helper-text color-black-45 mb-0">
				This is the panel to manage all image assets in your store like product image and banner.
			</p>
		</template>
	</div>
</template>

<script>
export default {
	name: 'MediaLibraryInfo',
	props: {
		data: {
			type: Object,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.media-info {
	width: rem(210);
	min-width: rem(210);
	padding: rem(16);
	background-color: $color-gray-100;
	border-left: 1px solid $color-gray-300;
	overflow-y: auto;
}

.image-wrapper {
	position: relative;
	width: rem(178);
	height: rem(178);
	border: 1px solid $color-gray-300;
	border-radius: 4px;
	background-color: $color-white;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.icon-fullscreen {
	position: absolute;
	top: rem(4);
	right: rem(4);
	z-index: 2;
	width: rem(24);
	height: rem(24);
	padding: rem(4);
	color: $color-white;
	background-color: $color-black-25;
	border-radius: 2px;
}

.btn-delete {
	@include color-hover($color-alert, $color-alert-hover);
}
</style>
