import {
	getOrdersAPI,
	getOrderAPI,
	createOrderRemarkAPI,
	updateOrderAPI,
	updateOrderPaymentExpireAPI,
	getCancelOrderAPI,
	postCancelOrderAPI,
} from '../../services/api/orders.api';
import {
	transformedOrderList,
	transformedOrderDetail,
	transformedCancelOrderList,
} from '../../assets/js/transform/order';
import { transformedMeta } from '../../assets/js/transform/meta';
import {
	GET_ORDERS_REQUEST,
	GET_ORDERS_SUCCESS,
	GET_ORDERS_FAILURE,
	GET_ORDER_REQUEST,
	GET_ORDER_SUCCESS,
	GET_ORDER_FAILURE,
	UPDATE_ORDER_REQUEST,
	UPDATE_ORDER_SUCCESS,
	UPDATE_ORDER_FAILURE,
	CREATE_ORDER_REMARK_REQUEST,
	CREATE_ORDER_REMARK_SUCCESS,
	CREATE_ORDER_REMARK_FAILURE,
	GET_CANCEL_ORDERS_REQUEST,
	GET_CANCEL_ORDERS_SUCCESS,
	GET_CANCEL_ORDERS_FAILURE,
	UPDATE_CANCEL_ORDERS_REQUEST,
	UPDATE_CANCEL_ORDERS_SUCCESS,
	UPDATE_CANCEL_ORDERS_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: transformedOrderList(),
			meta: transformedMeta(),
			error: false,
		},
		detail: {
			isLoading: false,
			data: {},
		},
		edit: {
			isUpdating: false,
		},
		remark: {
			isCreating: false,
		},
		reason: {
			isLoading: false,
			data: [],
		},
	},

	getters: {
		orderDetail(state) {
			return transformedOrderDetail(state.detail.data);
		},
		cancelReasonOptions(state) {
			return state.reason.data.map((reason) => {
				return {
					name: reason.nameEn,
					ReasonId: reason.ReasonId,
				};
			});
		},
	},

	mutations: {
		// Get Order List
		[GET_ORDERS_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformedOrderList();
			state.list.meta = transformedMeta();
			state.list.error = false;
		},
		[GET_ORDERS_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			state.list.data = transformedOrderList(data);
			state.list.meta = transformedMeta(meta);
		},
		[GET_ORDERS_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		[GET_ORDER_REQUEST](state) {
			state.detail = {
				data: {},
				isLoading: true,
			};
		},
		[GET_ORDER_SUCCESS](state, { data }) {
			state.detail = {
				data,
				isLoading: false,
			};
		},
		[GET_ORDER_FAILURE](state) {
			state.detail = {
				data: {},
				isLoading: false,
			};
		},
		[UPDATE_ORDER_REQUEST](state) {
			state.edit = {
				isUpdating: true,
			};
		},
		[UPDATE_ORDER_SUCCESS](state) {
			state.edit = {
				isUpdating: false,
			};
		},
		[UPDATE_ORDER_FAILURE](state) {
			state.edit = {
				isUpdating: false,
			};
		},
		[CREATE_ORDER_REMARK_REQUEST](state) {
			state.remark = {
				isCreating: true,
			};
		},
		[CREATE_ORDER_REMARK_SUCCESS](state) {
			state.remark = {
				isCreating: false,
			};
		},
		[CREATE_ORDER_REMARK_FAILURE](state) {
			state.remark = {
				isCreating: false,
			};
		},
		[GET_CANCEL_ORDERS_REQUEST](state) {
			state.reason.isLoading = true;
		},
		[GET_CANCEL_ORDERS_SUCCESS](state, { data }) {
			state.reason.isLoading = false;
			state.reason.data = transformedCancelOrderList(data);
		},
		[GET_CANCEL_ORDERS_FAILURE](state) {
			state.reason.isLoading = false;
		},
		[UPDATE_CANCEL_ORDERS_REQUEST](state) {
			state.edit = {
				isUpdating: true,
			};
		},
		[UPDATE_CANCEL_ORDERS_SUCCESS](state) {
			state.edit = {
				isUpdating: false,
			};
		},
		[UPDATE_CANCEL_ORDERS_FAILURE](state) {
			state.edit = {
				isUpdating: false,
			};
		},
	},

	actions: {
		async getOrders({ commit }, params = {}) {
			try {
				commit(GET_ORDERS_REQUEST);
				const { data } = await getOrdersAPI(params);
				commit(GET_ORDERS_SUCCESS, data);
			} catch (error) {
				commit(GET_ORDERS_FAILURE);
			}
		},
		async getOrder({ commit }, id) {
			try {
				commit(GET_ORDER_REQUEST);

				const { data } = await getOrderAPI(id);

				commit(GET_ORDER_SUCCESS, data);
			} catch (error) {
				commit(GET_ORDER_FAILURE);
			}
		},
		async updateOrder({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_ORDER_REQUEST);
				await updateOrderAPI(id, params);
				commit(UPDATE_ORDER_SUCCESS);

				dispatch('toast/showUpdateSuccessToast', null, { root: true });

				await dispatch('getOrder', id);
			} catch (error) {
				const errorMessage = pathOr(false, ['response', 'data', 'message'])(error);

				dispatch(
					'toast/showToast',
					{
						content: errorMessage,
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);

				commit(UPDATE_ORDER_FAILURE);
			}
		},
		async updatePickedUp({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_ORDER_REQUEST);
				await updateOrderAPI(id, params.orderStatus);

				if (params?.paymentStatus) {
					await updateOrderAPI(id, params.paymentStatus);
				}

				commit(UPDATE_ORDER_SUCCESS);

				await dispatch('getOrder', id);
			} catch (error) {
				dispatch(
					'toast/showUpdateErrorToast',
					null,
					{ root: true },
				);

				commit(UPDATE_ORDER_FAILURE);
			}
		},
		async createOrderRemark({ commit, dispatch }, { id, params, forceReload = true }) {
			try {
				commit(CREATE_ORDER_REMARK_REQUEST);
				await createOrderRemarkAPI(id, params);
				commit(CREATE_ORDER_REMARK_SUCCESS);

				if (forceReload) {
					await dispatch('getOrder', id);
				}
			} catch (error) {
				commit(CREATE_ORDER_REMARK_FAILURE);
			}
		},
		async getCancelOrder({ commit }) {
			try {
				commit(GET_CANCEL_ORDERS_REQUEST);
				const { data } = await getCancelOrderAPI();
				commit(GET_CANCEL_ORDERS_SUCCESS, data);
			} catch (error) {
				commit(GET_CANCEL_ORDERS_FAILURE);
			}
		},
		async postCancelOrder({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_CANCEL_ORDERS_REQUEST);
				await postCancelOrderAPI(id, params);
				commit(UPDATE_CANCEL_ORDERS_SUCCESS);

				await dispatch('getOrder', id);
			} catch (error) {
				commit(UPDATE_CANCEL_ORDERS_FAILURE);
			}
		},
		async updatePaymentExpire({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_ORDER_REQUEST);
				await updateOrderPaymentExpireAPI(id, params);
				commit(UPDATE_ORDER_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessage', { type: 'page' }),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);

				await dispatch('getOrder', id);
			} catch (error) {
				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);

				commit(UPDATE_ORDER_FAILURE);
			}
		},
	},
};
