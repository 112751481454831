import {
	GET_WAREHOUSE_REQUEST,
	GET_WAREHOUSE_SUCCESS,
	GET_WAREHOUSE_FAILURE,
	GET_WAREHOUSE_LIST_REQUEST,
	GET_WAREHOUSE_LIST_SUCCESS,
	GET_WAREHOUSE_LIST_FAILURE,
	CREATE_WAREHOUSE_REQUEST,
	CREATE_WAREHOUSE_SUCCESS,
	CREATE_WAREHOUSE_FAILURE,
	UPDATE_WAREHOUSE_REQUEST,
	UPDATE_WAREHOUSE_SUCCESS,
	UPDATE_WAREHOUSE_FAILURE,
	GET_WAREHOUSE_TYPES_REQUEST,
	GET_WAREHOUSE_TYPES_SUCCESS,
	GET_WAREHOUSE_TYPES_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import {
	postWarehouseAPI,
	getWarehouseListAPI,
	getWarehouseAPI,
	putWarehouseAPI,
	getWarehouseTypesAPI,
} from '../../services/api/warehouses.api';
import {
	transformedWarehouse,
	transformedWarehouseList,
	transformedWarehouseTypeList,
} from '../../assets/js/transform/warehouses';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr, getThumbnailFromMediaObject, nullableToDashFormat } from '../../assets/js/helpers';
import { DAY_MAPPER, WAREHOUSE_STATUSES } from '../../enums/warehouses';
import { IMAGE_PLACEHOLDER_URL } from '../../enums/general';

export default {
	namespaced: true,
	state: {
		list: {
			data: [],
			isLoading: false,
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		warehouseTypes: {
			data: [],
			isLoading: false,
		},
	},
	getters: {
		warehouseList(state) {
			return state.list.data;
		},
		warehouseOptions(state) {
			return state.list.data.map((warehouse) => {
				return {
					name: `${warehouse.com7BranchId} - ${warehouse.nameTH}`,
					value: warehouse.id,
					com7BranchId: warehouse.com7BranchId,
				};
			});
		},
		warehouseTableData(state, getters, rootState, rootGetters) {
			const getTableData = (warehouse = {}) => {
				const id = pathOr(0, ['id'])(warehouse);
				const logo = getThumbnailFromMediaObject(pathOr(IMAGE_PLACEHOLDER_URL, ['logo'])(warehouse));
				const com7BranchId = pathOr(null, ['com7BranchId'])(warehouse);
				const brandTypeName = pathOr('-', ['warehouseType', 'name'])(warehouse);
				const name = pathOr(null, ['nameTH'])(warehouse);
				const status = WAREHOUSE_STATUSES[pathOr(false, ['isActive'])(warehouse)];
				const address = pathOr(null, ['addressInfo', 'addressTH'])(warehouse);
				const postcode = pathOr(null, ['addressInfo', 'postcode'])(warehouse);
				const email = nullableToDashFormat(pathOr(null, ['addressInfo', 'email'])(warehouse));
				const phone = pathOr(null, ['addressInfo', 'phone'])(warehouse);
				const province = pathOr(null, ['name'])(rootGetters['addresses/getProvinceById'](pathOr(null, ['addressInfo', 'provinceId'])(warehouse)));
				const businessHours = pathOr([], ['businessHours'])(warehouse)
					.filter((businessHour) => businessHour.isActive)
					.map((businessHour) => {
						return DAY_MAPPER[businessHour.day];
					})
					.join(', ');

				return {
					id,
					logo,
					com7BranchId,
					brandTypeName,
					name,
					status,
					address,
					province,
					postcode,
					businessHours,
					email,
					phone,
				};
			};

			return state.list.data.map(getTableData);
		},
		warehouseTypes(state) {
			return transformedWarehouseTypeList(state.warehouseTypes.data);
		},
	},
	mutations: {
		[GET_WAREHOUSE_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_WAREHOUSE_SUCCESS](state, data) {
			state.edit.isLoading = false;
			state.edit.data = data;
		},
		[GET_WAREHOUSE_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[GET_WAREHOUSE_LIST_REQUEST](state) {
			state.list.isLoading = true;
		},
		[GET_WAREHOUSE_LIST_SUCCESS](state, { warehouses, meta }) {
			state.list.isLoading = false;
			state.list.data = warehouses;
			state.list.meta = meta;
		},
		[GET_WAREHOUSE_LIST_FAILURE](state) {
			state.list.isLoading = false;
		},
		[CREATE_WAREHOUSE_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_WAREHOUSE_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_WAREHOUSE_FAILURE](state) {
			state.create.isCreating = false;
		},
		[UPDATE_WAREHOUSE_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_WAREHOUSE_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_WAREHOUSE_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[GET_WAREHOUSE_TYPES_REQUEST](state) {
			state.warehouseTypes.isLoading = true;
		},
		[GET_WAREHOUSE_TYPES_SUCCESS](state, { data }) {
			state.warehouseTypes.isLoading = false;
			state.warehouseTypes.data = data;
		},
		[GET_WAREHOUSE_TYPES_FAILURE](state) {
			state.warehouseTypes.isLoading = false;
		},
	},
	actions: {
		async getWarehouseList({ commit }, params) {
			try {
				commit(GET_WAREHOUSE_LIST_REQUEST);
				const { data } = await getWarehouseListAPI(params);
				commit(GET_WAREHOUSE_LIST_SUCCESS, { warehouses: transformedWarehouseList(data.data), meta: transformedMeta(data.meta) });
			} catch (e) {
				commit(GET_WAREHOUSE_LIST_FAILURE);
			}
		},
		async createWarehouse({ commit, dispatch }, params) {
			try {
				commit(CREATE_WAREHOUSE_REQUEST);
				await postWarehouseAPI(params);
				commit(CREATE_WAREHOUSE_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (e) {
				commit(CREATE_WAREHOUSE_FAILURE);

				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(e);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
				throw e;
			}
		},
		async getWarehouse({ commit }, id) {
			try {
				commit(GET_WAREHOUSE_REQUEST);
				const { data } = await getWarehouseAPI(id);
				commit(GET_WAREHOUSE_SUCCESS, transformedWarehouse(data.data));
			} catch (e) {
				commit(GET_WAREHOUSE_FAILURE);
			}
		},
		async getWarehouseTypes({ commit }, params = {}) {
			try {
				commit(GET_WAREHOUSE_TYPES_REQUEST);
				const { data } = await getWarehouseTypesAPI(params);
				commit(GET_WAREHOUSE_TYPES_SUCCESS, data);
			} catch (e) {
				commit(GET_WAREHOUSE_TYPES_FAILURE);
			}
		},
		async updateWarehouse({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_WAREHOUSE_REQUEST);
				await putWarehouseAPI(id, params);
				commit(UPDATE_WAREHOUSE_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (e) {
				commit(UPDATE_WAREHOUSE_FAILURE);
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(e);

				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));

					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
	},
};
