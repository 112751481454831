<template>
	<div>
		<CButtonGroup
			v-if="transformedList.length"
			class="button-group"
		>
			<CButton
				v-for="item in transformedList"
				:key="item.value"
				:color="item.buttonType"
				:disabled="item.isDisabled"
				:class="['button-group-item', { 'is-active': item.isActive }]"
				data-test-id="level-button"
				@click="$emit('input', item.value)"
			>
				{{ item.label }}
			</CButton>
		</CButtonGroup>
	</div>
</template>

<script>
export default {
	name: 'BaseButtonGroup',
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		list: {
			type: Array,
			default: () => [],
		},
		disabledList: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: null,
		},
	},
	computed: {
		transformedList() {
			return this.list.map((listItem) => {
				const isActive = listItem.value === this.value;

				return {
					...listItem,
					isActive,
					isDisabled: this.disabled || this.disabledList.includes(listItem.value),
					buttonType: isActive ? 'secondary' : 'tertiary',
				};
			});
		},
	},
};
</script>
