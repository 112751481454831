import httpClient from './httpClient';

export const FILES_ENDPOINT = '/backoffice/files';
export const FILES_HTML_ENDPOINT = '/backoffice/files/html5';

export const headerUpload = { 'content-type': 'multipart/form-data' };

export const getFilesAPI = (params = {}) => httpClient.get(FILES_ENDPOINT, { params });
export const uploadFileAPI = (formData = null, source = null) => httpClient.post(
	FILES_ENDPOINT,
	formData,
	{
		header: headerUpload,
		cancelToken: source ? source.token : null,
	},
);
export const deleteFileAPI = (id, source) => httpClient.delete(
	`${FILES_ENDPOINT}/${id}`,
	{ cancelToken: source ? source.token : null },
);
export const uploadZipAPI = (formData = null, source = null) => httpClient.post(
	FILES_HTML_ENDPOINT,
	formData,
	{
		header: headerUpload,
		cancelToken: source ? source.token : null,
	},
);
