import { SET_PAGE_TITLE } from '../types';

const state = {
	title: '',
	isShow: false,
};

/* eslint-disable */
const mutations = {
	[SET_PAGE_TITLE](state, config) {
		state.title = config?.title
		state.isShow = config?.isShow
	}
};
const actions = {
	setPageTitle({commit},config) {
		commit(SET_PAGE_TITLE,config)
	}
}
/* eslint-enable */

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
