import dayjs from 'dayjs';
import { pathOr, datetimeFormat } from '../helpers';
import { transformedWidgetList } from './widgets';
import { transformedMedia } from './mediaLibrary';
import { MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE, ATTRIBUTE_STATUS, MAPPING_STATUS_TO_SWITCH_VALUE } from '../../../enums/marketings';
import { transformedBrandList } from './brand';
import { transformedPromotionList } from './promotions';

export const transformedMarketingLog = (log = {}) => {
	const id = pathOr(null, ['id'])(log);
	const actorId = pathOr(null, ['actor_id'])(log);
	const actorName = pathOr(null, ['actor_name'])(log);
	const actorType = pathOr(null, ['actor_type'])(log);
	const createdAt = pathOr(null, ['created_at'])(log);
	const updatedAt = pathOr(null, ['updated_at'])(log);
	const marketingCampaignId = pathOr(null, ['marketing_category_id'])(log);

	return {
		id,
		actorId,
		actorName,
		actorType,
		createdAt,
		updatedAt,
		marketingCampaignId,
	};
};

export const transformedSEO = (seo = {}) => {
	const descriptionEn = pathOr(null, ['description_en'])(seo);
	const descriptionTh = pathOr(null, ['description_th'])(seo);
	const keywordEn = pathOr(null, ['keyword_en'])(seo);
	const keywordTh = pathOr(null, ['keyword_th'])(seo);
	const titleEn = pathOr(null, ['title_en'])(seo);
	const titleTh = pathOr(null, ['title_th'])(seo);
	return {
		descriptionEn,
		descriptionTh,
		keywordEn,
		keywordTh,
		titleEn,
		titleTh,
	};
};

export const transformedMarketingCampaign = (marketingCampaign = {}) => {
	let items = [];
	let skus = [];
	const id = pathOr(null, ['id'])(marketingCampaign);
	const log = pathOr(null, ['log'])(marketingCampaign);
	const periodStatus = pathOr(null, ['period_status'])(marketingCampaign);
	const totalSKU = pathOr(0, ['total_sku'])(marketingCampaign);
	const type = pathOr(null, ['type'])(marketingCampaign);
	const nameEn = pathOr(null, ['name_en'])(marketingCampaign);
	const nameTh = pathOr(null, ['name_th'])(marketingCampaign);
	const slug = pathOr(null, ['slug'])(marketingCampaign);
	const isActive = !!pathOr(false, ['is_active'])(marketingCampaign);
	const isVisible = !!pathOr(false, ['is_visible'])(marketingCampaign);
	const widgets = pathOr([], ['widgets'])(marketingCampaign).map(transformedWidgetList);
	const banners = pathOr([], ['banners'])(marketingCampaign).map(transformedMedia);
	const descriptionBottom = pathOr(null, ['description_bottom'])(marketingCampaign);
	const descriptionTop = pathOr(null, ['description_top'])(marketingCampaign);
	const seo = transformedSEO(pathOr({}, ['seo'])(marketingCampaign));
	const createdAt = pathOr(null, ['created_at'])(marketingCampaign);
	const updatedAt = pathOr(0, ['updated_at'])(marketingCampaign);
	const startAt = pathOr(null, ['start_at'])(marketingCampaign);
	const endAt = pathOr(null, ['end_at'])(marketingCampaign);
	const logs = pathOr([], ['logs'])(marketingCampaign).map(transformedMarketingLog);
	const startDate = startAt ? dayjs(startAt).toDate() : null;
	const startTime = startAt ? datetimeFormat(startAt, 'HH:mm') : '00:00';
	const endDate = endAt ? dayjs(endAt).toDate() : null;
	const endTime = endAt ? datetimeFormat(endAt, 'HH:mm') : '00:00';
	const syncedAt = marketingCampaign.synced_at ? datetimeFormat(marketingCampaign.synced_at) : '-';
	const syncStatus = pathOr(null, ['sync_status'])(marketingCampaign);

	switch (type) {
		case MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.SKU:
			items = pathOr([], ['items'])(marketingCampaign);
			skus = items;
			break;
		case MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.BRAND:
			items = transformedBrandList(pathOr([], ['items'])(marketingCampaign));
			skus = pathOr([], ['skus'])(marketingCampaign);
			break;
		case MARKETING_CAMPAIGN_PRODUCT_TYPE_VALUE.PROMOTION:
			items = transformedPromotionList(pathOr([], ['items'])(marketingCampaign));
			skus = pathOr([], ['skus'])(marketingCampaign);
			break;
		default:
			break;
	}

	return {
		id,
		log,
		periodStatus,
		totalSKU,
		type,
		nameEn,
		nameTh,
		slug,
		isActive,
		isVisible,
		startAt,
		endAt,
		startDate,
		startTime,
		endDate,
		endTime,
		widgets,
		banners,
		descriptionBottom,
		descriptionTop,
		seo,
		createdAt,
		updatedAt,
		logs,
		items,
		skus,
		syncedAt,
		syncStatus,
	};
};

export const transformedManageFilterItem = (item = {}) => ({
	name: item.name || null,
	data: {
		id: item.id || null,
		status: MAPPING_STATUS_TO_SWITCH_VALUE[item.action] || MAPPING_STATUS_TO_SWITCH_VALUE[ATTRIBUTE_STATUS.OFF],
	},
});

export const transformedManageFilterList = (list = []) => list.map(transformedManageFilterItem);
