import sortBy from 'lodash/sortBy';
import {
	getWidgetsAPI,
	getMicrositeWidgetsAPI,
	postWidgetAPI,
	postMicrositeWidgetAPI,
	sortWidgetsAPI,
	updateWidgetAPI,
	updateMicrositeWidgetAPI,
	updateWidgetCategoryAPI,
	deleteWidgetAPI,
	deleteMicrositeWidgetAPI,
} from '../../services/api/widgets.api';
import {
	GET_WIDGETS_REQUEST,
	GET_WIDGETS_SUCCESS,
	GET_WIDGETS_FAILURE,
	SORT_WIDGETS_REQUEST,
	SORT_WIDGETS_SUCCESS,
	SORT_WIDGETS_FAILURE,
	CREATE_WIDGET_REQUEST,
	CREATE_WIDGET_SUCCESS,
	CREATE_WIDGET_FAILURE,
	UPDATE_WIDGET_REQUEST,
	UPDATE_WIDGET_SUCCESS,
	UPDATE_WIDGET_FAILURE,
	UPDATE_WIDGET_CATEGORY_REQUEST,
	UPDATE_WIDGET_CATEGORY_SUCCESS,
	UPDATE_WIDGET_CATEGORY_FAILURE,
	DELETE_WIDGET_REQUEST,
	DELETE_WIDGET_SUCCESS,
	DELETE_WIDGET_FAILURE,
} from '../types';
import { transformedWidgetList } from '../../assets/js/transform/widgets';
import { WIDGET_TYPE } from '../../enums/widgets';
import { i18n } from '../../i18n';

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: [],
		},
		sort: {
			isLoading: false,
		},
		create: {
			isLoading: false,
		},
		edit: {
			isLoading: false,
		},
		delete: {
			isLoading: false,
		},
	},

	getters: {
		getDefaultWidget(state) {
			return state.list.data.filter((item) => item.type === WIDGET_TYPE.hero);
		},
		getWidgetCategory(state) {
			return state.list.data.find((item) => item.type === WIDGET_TYPE.category);
		},
		getOtherWidgets(state) {
			const items = state.list.data
				.filter((item) => item.type !== WIDGET_TYPE.hero && item.type !== WIDGET_TYPE.category)
				.map((item) => ({ ...item, draggable: true }));

			return sortBy(items, (item) => item.order);
		},
	},

	mutations: {
		// Get widgets
		[GET_WIDGETS_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_WIDGETS_SUCCESS](state, { data = [] }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
			};
		},
		[GET_WIDGETS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},

		// Sort widgets
		[SORT_WIDGETS_REQUEST](state) {
			state.sort = {
				...state.sort,
				isLoading: true,
			};
		},
		[SORT_WIDGETS_SUCCESS](state, { data = [] }) {
			state.list = {
				...state.list,
				data,
			};

			state.sort = {
				...state.sort,
				isLoading: false,
			};
		},
		[SORT_WIDGETS_FAILURE](state) {
			state.sort = {
				...state.sort,
				isLoading: false,
			};
		},

		// Create widget
		[CREATE_WIDGET_REQUEST](state) {
			state.create = {
				...state.create,
				isLoading: true,
			};
		},
		[CREATE_WIDGET_SUCCESS](state) {
			state.create = {
				...state.create,
				isLoading: false,
			};
		},
		[CREATE_WIDGET_FAILURE](state) {
			state.create = {
				...state.create,
				isLoading: false,
			};
		},

		// Update widget
		[UPDATE_WIDGET_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[UPDATE_WIDGET_SUCCESS](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[UPDATE_WIDGET_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},

		// Delete banner
		[DELETE_WIDGET_REQUEST](state) {
			state.delete = {
				...state.delete,
				isLoading: true,
			};
		},
		[DELETE_WIDGET_SUCCESS](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
			};
		},
		[DELETE_WIDGET_FAILURE](state) {
			state.delete = {
				...state.delete,
				isLoading: false,
			};
		},

		[SORT_WIDGETS_FAILURE]() {},
	},
	actions: {
		async getWidgets({ commit }, params = {}) {
			try {
				commit(GET_WIDGETS_REQUEST);
				const { data } = await getWidgetsAPI(params);

				commit(GET_WIDGETS_SUCCESS, {
					...data,
					data: data.data.map(transformedWidgetList),
				});
			} catch (error) {
				commit(GET_WIDGETS_FAILURE);
			}
		},

		async getMicrositeWidgets({ commit }, params = {}) {
			try {
				commit(GET_WIDGETS_REQUEST);
				const { data } = await getMicrositeWidgetsAPI(params);

				commit(GET_WIDGETS_SUCCESS, {
					...data,
					data: data.data.map(transformedWidgetList),
				});
			} catch (error) {
				commit(GET_WIDGETS_FAILURE);
			}
		},

		async sortWidgets({ commit, dispatch }, widget_ids = []) {
			try {
				commit(SORT_WIDGETS_REQUEST);
				const { data } = await sortWidgetsAPI({ widget_ids });

				commit(SORT_WIDGETS_SUCCESS, {
					...data,
					data: data.data.map(transformedWidgetList),
				});

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessage'),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(SORT_WIDGETS_FAILURE);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);
			}
		},

		async postWidget({ commit, dispatch }, { params = {}, isMicrosite = false }) {
			try {
				commit(CREATE_WIDGET_REQUEST);

				if (isMicrosite) {
					await postMicrositeWidgetAPI(params);
				} else {
					await postWidgetAPI(params);
				}

				commit(CREATE_WIDGET_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageCreate', {
							type: 'widget',
						}),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(CREATE_WIDGET_FAILURE);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleCreate'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},

		async editWidget({ commit, dispatch }, { id, params = {}, isMicrosite = false }) {
			try {
				commit(UPDATE_WIDGET_REQUEST);

				if (isMicrosite) {
					await updateMicrositeWidgetAPI(id, params);
				} else {
					await updateWidgetAPI(id, params);
				}

				commit(UPDATE_WIDGET_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessage', {
							type: 'widget',
						}),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				commit(UPDATE_WIDGET_FAILURE);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);

				throw error;
			}
		},

		async deleteWidget({ commit, dispatch }, { id, isMicrosite = false }) {
			try {
				commit(DELETE_WIDGET_REQUEST);

				if (isMicrosite) {
					await deleteMicrositeWidgetAPI(id);
				} else {
					await deleteWidgetAPI(id);
				}

				commit(DELETE_WIDGET_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageDelete', {
							type: 'Widget',
						}),
						header: i18n.t('global.successMessageTitleDelete'),
					},
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_WIDGET_FAILURE);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleDelete'),
						type: 'danger',
					},
					{ root: true },
				);
			}
		},

		async editWidgetCategory({ commit, dispatch }, { widgetId, categoryId }) {
			try {
				commit(UPDATE_WIDGET_CATEGORY_REQUEST);

				await updateWidgetCategoryAPI(widgetId, {
					category_id: categoryId,
				});

				commit(UPDATE_WIDGET_CATEGORY_SUCCESS);
			} catch (error) {
				commit(UPDATE_WIDGET_CATEGORY_FAILURE);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);
			}
		},
	},
};
