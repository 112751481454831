import {
	SHOW_TOAST,
	HIDE_TOAST,
} from '../types';
import { i18n } from '../../i18n';
import { HTTP_STATUS_CODE } from '../../enums/http';

let toastId = 0;

export default {
	namespaced: true,
	state: {
		list: [],
		config: {
			position: 'top-right',
		},
	},
	mutations: {
		[SHOW_TOAST](state, toast, config) {
			state.config = {
				...state.config,
				...config,
			};

			state.list = [
				...state.list,
				{
					id: toast.id,
					title: toast.title,
					content: toast.content,
					header: toast.header,
					type: toast.type,
					timeout: toast.timeout || 3000,
					isShow: true,
				},
			];
		},

		[HIDE_TOAST](state, id) {
			state.list = state.list.map((toast) => {
				return toast.id === id
					? { ...toast, isShow: false }
					: toast;
			});
		},
	},
	actions: {
		showToast({ commit }, data, config) {
			const toast = {
				id: toastId++,
				...data,
			};
			commit(SHOW_TOAST, toast, config);
		},

		hideToast({ commit }, id) {
			commit(HIDE_TOAST, id);
		},
		showCreateSuccessToast({ dispatch }, type = null) {
			dispatch(
				'showToast',
				{
					content: i18n.t('global.successMessageCreate', { type }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				},
			);
		},
		showCreateErrorToast({ dispatch }) {
			dispatch(
				'showToast',
				{
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleCreate'),
					type: 'danger',
				},
			);
		},
		showUpdateSuccessToast({ dispatch }) {
			dispatch(
				'showToast',
				{
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				},
			);
		},
		showUpdateErrorToast({ dispatch }) {
			dispatch(
				'showToast',
				{
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				},
			);
		},
		showDeleteSuccessToast({ dispatch }, type = null) {
			dispatch(
				'showToast',
				{
					content: i18n.t('global.successMessageDelete', { type }),
					header: i18n.t('global.successMessageTitleDelete'),
				},
			);
		},
		showDeleteErrorToast({ dispatch }) {
			dispatch(
				'showToast',
				{
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleDelete'),
					type: 'danger',
				},
			);
		},
		showMultipleErrorsToast({ dispatch }, error = null) {
			let errorMessage = i18n.t('global.errorMessage');
			const errors = error?.response?.data?.errors;

			if (errors) {
				const errorMessages = Object.values(errors).reduce((accErrors, values) => {
					return [
						...accErrors,
						...(Array.isArray(values) ? values : [values]),
					];
				}, []);

				if (errorMessages && errorMessages.length > 1) {
					errorMessage = errorMessages;
				} else if (errorMessages.length === 1) {
					errorMessage = errorMessages[0]; // use exactly first error message as string
				}
			}

			dispatch('showToast', {
				header: i18n.t('global.errorMessageTitleEdit'),
				content: errorMessage,
				type: 'danger',
			});
		},
		showAddUserSuccessToast({ dispatch }) {
			dispatch('toast/showToast', {
				content: 'New user has been added',
				header: i18n.t('global.successMessageTitle'),
				type: 'success',
			}, { root: true });
		},
		showAddUserErrorToast({ dispatch }, error = {}) {
			const errorStatus = error?.response?.status;
			const userNotFound = errorStatus === HTTP_STATUS_CODE.NOT_FOUND;
			const userAlreadyExists = errorStatus === HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY;

			/**
			 * Show error toast when api return 404 or 422
			 */
			if (userNotFound || userAlreadyExists) {
				dispatch('toast/showToast', {
					type: 'danger',
					header: 'Unable to add',
					content: userNotFound
						// 404 - User not found
						? 'User not found'
						// 422 - In cases, user already exist or user is not in system
						: error.response?.data?.message ?? 'The given data was invalid.',
				}, { root: true });
			}
		},
	},
};
