import isEmpty from 'lodash/isEmpty';
import { datetimeFormat, convertDateTimeToUTC } from '../helpers';
import {
	BUNDLE_SET_TITLE,
	BUNDLE_SET_COLOR,
	CONDITION_TYPE,
} from '../../../enums/bundleSet';
import { transformedCustomerGroupListToAPI } from '../../../assets/js/transform/promotions';
import { DEFAULT_SYSTEM_ADMIN_NAME } from '../../../enums/general';
import { transformedMedia } from '../transform/mediaLibrary';

export const transformedBundleSet = (bundle = {}) => {
	const id = bundle?.id || null;
	/* eslint-disable camelcase */
	const name = bundle?.name || bundle?.name_en || null;
	const nameTH = bundle?.name_th || null;
	const status = bundle?.status;
	const skus = bundle?.total_sku || 0;
	const startAt = bundle.start_at ? datetimeFormat(bundle.start_at) : '-';
	const endAt = bundle.end_at ? datetimeFormat(bundle.end_at) : '-';
	const createdAt = bundle.created_at
		? datetimeFormat(bundle.created_at)
		: '-';
	const updatedAt = bundle.updated_at
		? datetimeFormat(bundle.updated_at)
		: '-';
	const periodValue = bundle?.period_status || null;
	const createdBy = bundle?.created_by?.username || DEFAULT_SYSTEM_ADMIN_NAME;
	const updatedBy = bundle?.updated_by?.username || DEFAULT_SYSTEM_ADMIN_NAME;
	/* eslint-enable camelcase */
	const period = {
		title: BUNDLE_SET_TITLE.PERIODS_STATUSES[periodValue],
		color: BUNDLE_SET_COLOR.PERIODS_STATUSES[periodValue],
	};

	return {
		id,
		duplicateId: id,
		name,
		nameTH,
		status,
		skus,
		startAt,
		endAt,
		createdAt,
		updatedAt,
		period,
		periodValue,
		createdBy,
		updatedBy,
	};
};

export const transformedBundleSetList = (bundles = []) => {
	return bundles.map(transformedBundleSet);
};

export const transformedBundleSetMediaToAPI = (bundle = {}) => {
	const bundleSetImage = bundle?.bundleSetImage?.id ?? null;
	const bundlePrimarySetImage = bundle?.bundlePrimarySetImage?.id ?? null;
	const desktopBanner = bundle?.desktopBanner?.id ?? null;
	const mobileBanner = bundle?.mobileBanner?.id ?? null;

	return {
		bundle_set_image: bundleSetImage ? [bundleSetImage] : [],
		bundle_primary_set_image: bundlePrimarySetImage ? [bundlePrimarySetImage] : [],
		desktop_banner: desktopBanner ? [desktopBanner] : [],
		mobile_banner: mobileBanner ? [mobileBanner] : [],
	};
};

/**
 * Transform form data bundle set conditions to API create/edit bundle set params format
 * @param {Array} conditions bundle set conditions
 * @returns {Array}
 */
export const transformedFormDataBundleSetConditionsToAPI = (conditions = []) => {
	if (Array.isArray(conditions)) {
		return conditions.map((condition) => {
			return {
				name: condition?.name,
				is_required: condition?.isRequired ?? false,
				condition: {
					type: condition?.productConditions?.[0]?.type?.toLocaleLowerCase(), // TODO: When BE fix type 'sku' to 'SKU', will remove ?.toLocaleLowerCase()
					skus: condition?.productConditions?.[0]?.value ?? [],
				},
				rule: {
					type: condition?.rule?.key?.value,
					key: condition?.rule?.rule?.value,
					value: condition?.rule?.value ? Number(condition?.rule?.value) : undefined,
				},
				display: {
					type: condition?.display?.type,
					sku: condition?.display?.primarySku,
				},
			};
		});
	}
	return [];
};

/**
 * Transform form data bundle set condition from API to form data format
 * @param {Object} bundleSet bundle set condition data from API
 * @returns {Object} transformed bundle set condition for form data
 */
export const transformedFormDataBundleSetConditions = (conditions = []) => {
	if (Array.isArray(conditions)) {
		return conditions.map((condition) => {
			return {
				name: condition?.name,
				// eslint-disable-next-line camelcase
				isRequired: condition?.is_required ?? false,
				productConditions: [
					{
						// TODO: When BE fix type 'sku' to 'SKU', will remove ?.toUpperCase()
						type: condition?.condition?.type?.toUpperCase(),
						// TODO: Is one of is hardcode, will remove when API is ready
						rule: CONDITION_TYPE.IS_ONE_OF,
						value: condition?.condition?.skus ?? [],
					},
				],
				rule: {
					key: {
						label: condition?.rule?.type,
						value: condition?.rule?.type,
					},
					rule: {
						label: condition?.rule?.key,
						value: condition?.rule?.key,
					},
					value: condition?.rule?.value,
				},
				display: {
					type: condition?.display?.type,
					primarySku: condition?.display?.sku,
					specificNameEn: null,
					specificNameTh: null,
					specificNameImage: null,
				},
			};
		});
	}
	return [];
};

/**
 * Transform form data bundle set to API create/edit bundle set params format
 * @param {Array} steps steps data
 * @returns {Object}
 */
export const transformedFormDataBundleSetToAPI = (steps = []) => {
	if (isEmpty(steps)) {
		return null;
	}
	const step1 = steps?.[0]?.formData;
	const step2 = steps?.[1]?.formData;

	return {
		name_en: step1.nameEn,
		name_th: step1.nameTh,
		status: step1.status,
		start_at: convertDateTimeToUTC(step1.startDate, step1.startTime),
		end_at: convertDateTimeToUTC(step1.endDate, step1.endTime),
		media: transformedBundleSetMediaToAPI(step1),
		banner_link: step1.bannerLink,
		description_en: step1.descriptionEn,
		description_th: step1.descriptionTh,
		info_name_en: step1.infoNameEn,
		info_name_th: step1.infoNameTh,
		info_description_en: step1.infoDescriptionEn,
		info_description_th: step1.infoDescriptionTh,
		info_description_bottom_en: step1.infoDescriptionBottomEn,
		info_description_bottom_th: step1.infoDescriptionBottomTh,
		reward_description_en: step1.rewardDescriptionEn,
		reward_description_th: step1.rewardDescriptionTh,
		groups: transformedFormDataBundleSetConditionsToAPI(step2?.conditions),
		customer_group_ids: transformedCustomerGroupListToAPI(step1.customerGroups) || [],
	};
};

/**
 * Transform form data bundle set from API to form data format
 * @param {Object} bundleSet bundle set data from API
 * @returns {Object} transformed bundle set for form data
 */
export const transformedBundleSetToFormData = (bundleSet = {}) => {
	if (isEmpty(bundleSet)) {
		return null;
	}

	return {
		step1: {
			id: bundleSet.id,
			bundleSetImage: bundleSet.bundle_set_image[0] ? transformedMedia(bundleSet.bundle_set_image[0]) : null,
			bundlePrimarySetImage: bundleSet.bundle_primary_set_image[0] ? transformedMedia(bundleSet.bundle_primary_set_image[0]) : null,
			nameEn: bundleSet.name_en,
			nameTh: bundleSet.name_th,
			status: bundleSet.status,
			periodValue: bundleSet.period_status,
			startDate: new Date(datetimeFormat(bundleSet.start_at)),
			startTime: datetimeFormat(bundleSet.start_at, 'HH:mm'),
			endDate: new Date(datetimeFormat(bundleSet.end_at)),
			endTime: datetimeFormat(bundleSet.end_at, 'HH:mm'),
			desktopBanner: bundleSet.banner_images.desktop[0] ? transformedMedia(bundleSet.banner_images.desktop[0]) : null,
			mobileBanner: bundleSet.banner_images.mobile[0] ? transformedMedia(bundleSet.banner_images.mobile[0]) : null,
			bannerLink: bundleSet.banner_link,
			descriptionEn: bundleSet.description_en,
			descriptionTh: bundleSet.description_th,
			infoNameEn: bundleSet.info_name_en,
			infoNameTh: bundleSet.info_name_th,
			infoDescriptionEn: bundleSet.info_description_en,
			infoDescriptionTh: bundleSet.info_description_th,
			infoDescriptionBottomEn: bundleSet.info_description_bottom_en,
			infoDescriptionBottomTh: bundleSet.info_description_bottom_th,
			rewardDescriptionEn: bundleSet.reward_description_en,
			rewardDescriptionTh: bundleSet.reward_description_th,
			customerGroups: bundleSet.customer_groups || [],
		},
		step2: {
			conditions: transformedFormDataBundleSetConditions(bundleSet.groups),
		},
	};
};
