<template>
	<div
		role="group"
		class="custom-control custom-checkbox"
	>
		<input
			:id="uid"
			v-model="localChecked"
			:indeterminate.prop="indeterminate"
			type="checkbox"
			class="custom-control-input"
		>
		<label
			:for="uid"
			class="custom-control-label -bold"
		>
			{{ label }}
		</label>
	</div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';

export default {
	name: 'BaseIndeterminateCheckbox',
	props: {
		checked: {
			type: Boolean,
			default: false,
		},
		indeterminate: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			uid: null,
		};
	},
	computed: {
		localChecked: {
			get() {
				// If indeterminate state, It's mean partial selected
				// When click on indeterminate state, It will emit checked = false
				// UI flow is Partial selected -> Clear selected -> Selected all
				if (this.indeterminate) {
					return true;
				}
				return this.checked;
			},
			set(newValue) {
				this.$emit('update:checked', newValue);
			},
		},
	},
	mounted() {
		this.uid = uniqueId('base_indeterminate_checkbox_');
	},
};
</script>
