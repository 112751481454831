import { i18n } from '@/i18n';
// For Store
import {
	GET_ORDER_AUTO_COMPLETE_LIST_REQUEST,
	GET_ORDER_AUTO_COMPLETE_LIST_SUCCESS,
	GET_ORDER_AUTO_COMPLETE_LIST_FAILURE,
	UPDATE_ORDER_AUTO_COMPLETE_LIST_REQUEST,
	UPDATE_ORDER_AUTO_COMPLETE_LIST_SUCCESS,
	UPDATE_ORDER_AUTO_COMPLETE_LIST_FAILURE,
} from '../types';
import {
	transformedOrderAutoComplete,
	transformedOrderAutoCompletePayloadToAPI,
} from '../../assets/js/transform/orderAutoComplete';
import {
	getOrderAutoCompleteListAPI,
	updateOrderAutoCompleteListAPI,
} from '../../services/api/orderAutoComplete.api';

export default {
	namespaced: true,
	state: {
		// For list page
		list: {
			isLoading: false,
			data: transformedOrderAutoComplete(),
			error: false,
		},
	},
	getters: {},
	mutations: {
		// Get Order Auto Complete List
		[GET_ORDER_AUTO_COMPLETE_LIST_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformedOrderAutoComplete();
			state.list.error = false;
		},
		[GET_ORDER_AUTO_COMPLETE_LIST_SUCCESS](state, { data = [] }) {
			state.list.isLoading = false;
			state.list.data = transformedOrderAutoComplete(data);
		},
		[GET_ORDER_AUTO_COMPLETE_LIST_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
		// Update Order Auto Complete List
		[UPDATE_ORDER_AUTO_COMPLETE_LIST_REQUEST](state) {
			state.list.isLoading = true;
			state.list.data = transformedOrderAutoComplete();
			state.list.error = false;
		},
		[UPDATE_ORDER_AUTO_COMPLETE_LIST_SUCCESS](state, { data = [] }) {
			state.list.isLoading = false;
			state.list.data = transformedOrderAutoComplete(data);
		},
		[UPDATE_ORDER_AUTO_COMPLETE_LIST_FAILURE](state) {
			state.list.isLoading = false;
			state.list.error = true;
		},
	},
	actions: {
		async getOrderAutoCompleteList({ commit, dispatch }, params = {}) {
			try {
				commit(GET_ORDER_AUTO_COMPLETE_LIST_REQUEST);
				const { data } = await getOrderAutoCompleteListAPI(params);
				commit(GET_ORDER_AUTO_COMPLETE_LIST_SUCCESS, data);
			} catch (error) {
				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleGetData'),
						type: 'danger',
					},
					{ root: true },
				);
				commit(GET_ORDER_AUTO_COMPLETE_LIST_FAILURE);
			}
		},
		async updateOrderAutoCompleteList({ commit, dispatch }, payload) {
			try {
				commit(UPDATE_ORDER_AUTO_COMPLETE_LIST_REQUEST);
				const transformedPayload = transformedOrderAutoCompletePayloadToAPI(payload);
				const { data } = await updateOrderAutoCompleteListAPI(transformedPayload);
				commit(UPDATE_ORDER_AUTO_COMPLETE_LIST_SUCCESS, data);
				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessage', { type: 'page' }),
						header: i18n.t('global.successMessageTitle'),
						type: 'success',
					},
					{ root: true },
				);
			} catch (error) {
				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.errorMessage'),
						header: i18n.t('global.errorMessageTitleEdit'),
						type: 'danger',
					},
					{ root: true },
				);
				commit(UPDATE_ORDER_AUTO_COMPLETE_LIST_FAILURE);
			}
		},
	},
};
