import { i18n } from '@/i18n';
import {
	getSearchWeightsAPI,
	getSearchWeightAPI,
	postSearchWeightAPI,
	updateSearchWeightAPI,
	deleteSearchWeightAPI,
	reSyncSearchWeightAPI,
} from '../../services/api/searchWeights.api';
import {
	GET_SEARCH_WEIGHTS_REQUEST,
	GET_SEARCH_WEIGHTS_SUCCESS,
	GET_SEARCH_WEIGHTS_FAILURE,
	GET_SEARCH_WEIGHT_REQUEST,
	GET_SEARCH_WEIGHT_SUCCESS,
	GET_SEARCH_WEIGHT_FAILURE,
	CREATE_SEARCH_WEIGHT_REQUEST,
	CREATE_SEARCH_WEIGHT_SUCCESS,
	CREATE_SEARCH_WEIGHT_FAILURE,
	UPDATE_SEARCH_WEIGHT_REQUEST,
	UPDATE_SEARCH_WEIGHT_SUCCESS,
	UPDATE_SEARCH_WEIGHT_FAILURE,
	DELETE_SEARCH_WEIGHT_REQUEST,
	DELETE_SEARCH_WEIGHT_SUCCESS,
	DELETE_SEARCH_WEIGHT_FAILURE,
	SEARCH_WEIGHTS_RESYNC_REQUEST,
	SEARCH_WEIGHTS_RESYNC_SUCCESS,
	SEARCH_WEIGHTS_RESYNC_FAILURE,
} from '../types';
import { transformedSearchWeight, transformedSearchWeightList } from '../../assets/js/transform/searchWeights';

export default {
	namespaced: true,
	state: {
		create: {
			isCreating: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
		},
		list: {
			data: [],
			lastUpdated: null,
			isLoading: false,
		},
	},
	getters: {
		searchWeightDetails(state) {
			return transformedSearchWeight(state.edit.data);
		},
		searchWeightList(state) {
			return transformedSearchWeightList(state.list.data);
		},
	},
	mutations: {
		[GET_SEARCH_WEIGHTS_REQUEST](state) {
			state.list.isLoading = true;
		},
		[GET_SEARCH_WEIGHTS_SUCCESS](state, payload) {
			state.list.data = payload.data;
			state.list.lastUpdated = payload.algolia_synced_at;
			state.list.isLoading = false;
		},
		[GET_SEARCH_WEIGHTS_FAILURE](state) {
			state.list.isLoading = false;
		},
		[GET_SEARCH_WEIGHT_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_SEARCH_WEIGHT_SUCCESS](state, { data }) {
			state.edit.data = data;
			state.edit.isLoading = false;
		},
		[GET_SEARCH_WEIGHT_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[CREATE_SEARCH_WEIGHT_REQUEST](state) {
			state.create.isCreating = true;
		},
		[CREATE_SEARCH_WEIGHT_SUCCESS](state) {
			state.create.isCreating = false;
		},
		[CREATE_SEARCH_WEIGHT_FAILURE](state) {
			state.create.isCreating = false;
		},
		[UPDATE_SEARCH_WEIGHT_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_SEARCH_WEIGHT_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_SEARCH_WEIGHT_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_SEARCH_WEIGHT_REQUEST]() { },
		[DELETE_SEARCH_WEIGHT_SUCCESS]() { },
		[DELETE_SEARCH_WEIGHT_FAILURE]() { },
		[SEARCH_WEIGHTS_RESYNC_REQUEST]() { },
		[SEARCH_WEIGHTS_RESYNC_SUCCESS]() { },
		[SEARCH_WEIGHTS_RESYNC_FAILURE]() { },
	},
	actions: {
		async getSearchWeights({ commit }) {
			try {
				commit(GET_SEARCH_WEIGHTS_REQUEST);

				const { data } = await getSearchWeightsAPI();

				commit(GET_SEARCH_WEIGHTS_SUCCESS, data);
			} catch (error) {
				commit(GET_SEARCH_WEIGHTS_FAILURE);
			}
		},
		async getSearchWeight({ commit }, id) {
			try {
				commit(GET_SEARCH_WEIGHT_REQUEST);

				const { data } = await getSearchWeightAPI(id);

				commit(GET_SEARCH_WEIGHT_SUCCESS, data);
			} catch (error) {
				commit(GET_SEARCH_WEIGHT_FAILURE);
			}
		},
		async createSearchWeight({ commit, dispatch }, params) {
			try {
				commit(CREATE_SEARCH_WEIGHT_REQUEST);
				await postSearchWeightAPI(params);
				commit(CREATE_SEARCH_WEIGHT_SUCCESS);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_SEARCH_WEIGHT_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async updateSearchWeight({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_SEARCH_WEIGHT_REQUEST);

				const { data } = await updateSearchWeightAPI(id, params);

				commit(UPDATE_SEARCH_WEIGHT_SUCCESS, data);
				dispatch('getSearchWeight', id);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(UPDATE_SEARCH_WEIGHT_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async deleteSearchWeight({ commit, dispatch }, id) {
			try {
				commit(DELETE_SEARCH_WEIGHT_REQUEST);

				await deleteSearchWeightAPI(id);

				commit(DELETE_SEARCH_WEIGHT_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageDelete', { type: 'Rule' }),
					header: i18n.t('global.successMessageTitleDelete'),
				}, { root: true });
			} catch (error) {
				commit(DELETE_SEARCH_WEIGHT_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
				throw error;
			}
		},
		async reSyncSearchWeight({ commit }, { id }) {
			try {
				commit(SEARCH_WEIGHTS_RESYNC_REQUEST);
				const { data } = await reSyncSearchWeightAPI(id);
				commit(SEARCH_WEIGHTS_RESYNC_SUCCESS, data);
			} catch (error) {
				commit(SEARCH_WEIGHTS_RESYNC_FAILURE, error);
			}
		},
	},
};
