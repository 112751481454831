export const WIDGET_VISIBILITY = {
	true: { name: 'On', value: 'on' },
	false: { name: 'Off', value: 'off' },
};

export const WIDGET_TYPE = {
	hero: 'hero_banner',
	highlight: 'highlight_banner',
	small: 'small_banner',
	shortcut: 'shortcut_banner',
	content: 'content_banner',
	brandBanner: 'brand_banner',
	shopByBrand: 'shop_by_brand_banner',

	category: 'category',

	flashSale: 'flash_sale',

	products: 'products',
	productHighlight: 'product_highlight',
	productCategory: 'product_category',

	highlightContentBlock: 'highlight_content_block',
	stripeContentBlock: 'stripe_content_block',
	textWithImageContentBlock: 'text_with_image',
};

export const WIDGET_TYPE_LABEL = {
	[WIDGET_TYPE.hero]: 'Hero banner',
	[WIDGET_TYPE.highlight]: 'Highlight banner',
	[WIDGET_TYPE.small]: 'Small banner',
	[WIDGET_TYPE.flashSale]: 'Flash sale',
	[WIDGET_TYPE.products]: 'Product',
	[WIDGET_TYPE.shortcut]: 'Shortcut banner',
	[WIDGET_TYPE.content]: 'Content banner',
	[WIDGET_TYPE.brandBanner]: 'Brand banner',
	[WIDGET_TYPE.productHighlight]: 'Product highlight banner',
	[WIDGET_TYPE.shopByBrand]: 'Shop by brand banner',
	[WIDGET_TYPE.highlightContentBlock]: 'Highlight content block',
	[WIDGET_TYPE.textWithImageContentBlock]: 'Text with image content block',
	[WIDGET_TYPE.stripeContentBlock]: 'Stripe content block',
	[WIDGET_TYPE.productCategory]: 'Product category banner',
};

export const WIDGET_TYPE_EDITABLE = [
	WIDGET_TYPE.highlight,
	WIDGET_TYPE.small,
	WIDGET_TYPE.flashSale,
	WIDGET_TYPE.shortcut,
	WIDGET_TYPE.content,
	WIDGET_TYPE.products,
	WIDGET_TYPE.brandBanner,
	WIDGET_TYPE.productHighlight,
	WIDGET_TYPE.shopByBrand,
	WIDGET_TYPE.highlightContentBlock,
	WIDGET_TYPE.textWithImageContentBlock,
	WIDGET_TYPE.stripeContentBlock,
];

export const WIDGET_STATUSES = {
	ongoing: 'Ongoing',
	scheduled: 'Scheduled',
	active: 'Active',
	inactive: 'Inactive',
};

export const WIDGET_STORE_ID = {
	BNN: 1,
	MICROSITE: 2,
};
