import {
	getFilesAPI,
} from '../../services/api/files.api';
import {
	GET_FILES_REQUEST,
	GET_FILES_SUCCESS,
	GET_FILES_FAILURE,
} from '../types';
import { transformedMedia } from '../../assets/js/transform/mediaLibrary';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: true,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 1,
				total: 0,
			},
		},
		upload: {
			isLoading: false,
		},
	},

	getters: {
		mediaLists(state) {
			return state.list.data.map(transformedMedia);
		},
	},

	mutations: {
		// Get media
		[GET_FILES_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_FILES_SUCCESS](state, { data = [], meta = {}, isAppend = false }) {
			const mergedData = isAppend ? [...state.list.data, ...data] : data;

			state.list = {
				...state.list,
				isLoading: false,
				data: mergedData,
				meta: {
					...state.list.meta,
					currentPage: meta.current_page,
					lastPage: meta.last_page,
					total: meta.total,
				},
			};
		},
		[GET_FILES_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
	},

	actions: {
		async getFiles({ commit }, { query = {}, isAppend = false }) {
			try {
				commit(GET_FILES_REQUEST);

				const { data } = await getFilesAPI(query);
				commit(GET_FILES_SUCCESS, { ...data, isAppend });
			} catch (error) {
				commit(GET_FILES_FAILURE);
			}
		},
	},
};
