export default {
	create: {
		generateId: 'Generate after saving',
		linkInvalidFormat: 'Invalid url format',
		linkHelperText: 'Leave it blank to dismiss the content block link',
		titleHelperText: 'Leave it blank to dismiss the content block additional text',
		removeContentBlocks: 'Remove content blocks',
		successMessage: 'New content block has been added.',
	},
	edit: {
		successMessage: 'Content block has been updated.',
	},
};
