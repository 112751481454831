import {
	pathOr,
	datetimeFormat,
} from '../helpers';

/**
 * transform back in stock information
 * @param {Object} item
 * @returns {Object} back in stock info
 */

export const transformedBackInStockInfo = (item = {}) => {
	const id = pathOr(null, ['id'])(item);
	const itecSku = pathOr(null, ['itec_sku'])(item);
	const firstName = pathOr(null, ['first_name'])(item);
	const lastName = pathOr(null, ['last_name'])(item);
	const phoneNumber = pathOr(null, ['phone_number'])(item);
	const email = pathOr(null, ['email'])(item);
	const createdAt = pathOr(null, ['created_at'])(item) ? datetimeFormat(item.created_at) : '-';
	const updatedAt = pathOr(null, ['updated_at'])(item) ? datetimeFormat(item.updated_at) : '-';

	return {
		id,
		itecSku,
		firstName,
		lastName,
		phoneNumber,
		email,
		createdAt,
		updatedAt,
	};
};

/**
 * transform back in stock list
 * @param {Array} list of back in stock
 * @returns {Array} back in stock list
 */
export const transformedBackInStockList = (list = []) => {
	return list?.map((item) => transformedBackInStockInfo(item)) ?? [];
};
