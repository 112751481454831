import httpClient from './httpClient';

export const LABEL_ENDPOINT = '/backoffice/labels';

const headerUpload = { 'content-type': 'multipart/form-data' };

export const getProductLabelsAPI = (params = {}) => httpClient.get(LABEL_ENDPOINT, { params });
export const getProductLabelAPI = (id) => httpClient.get(`${LABEL_ENDPOINT}/${id}`);
export const postProductLabelAPI = (formData = null) => httpClient.post(
	LABEL_ENDPOINT,
	formData,
	{ header: headerUpload },
);
export const postProductLabelDefaultAPI = (labels = null) => httpClient.post(`${LABEL_ENDPOINT}/default`, { labels });
export const updateProductLabelAPI = (id, formData = null) => httpClient.post(
	`${LABEL_ENDPOINT}/${id}`,
	formData,
	{ header: headerUpload },
);

export const sortProductLabelsAPI = (params = {}) => httpClient.post(`${LABEL_ENDPOINT}/sort`, params);
export const deleteProductLabelAPI = (id) => httpClient.delete(`${LABEL_ENDPOINT}/${id}`);

export const putProductLabelBulkAttachAPI = (params = {}) => httpClient.put(`${LABEL_ENDPOINT}/update-bulk`, params);
