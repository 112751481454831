import { getProductStatusValue, getThumbnailFromProduct } from '../helpers';
import { PRODUCT_STATUS_OPTIONS } from '../../../enums/products';

export const transformedProductAppleCare = (productAppleCare = {}) => {
	const id = productAppleCare?.id ?? 0;
	const uid = productAppleCare?.uid ?? 0;
	const name = productAppleCare?.name ?? '';
	const slug = productAppleCare?.slug ?? '';
	const sku = productAppleCare?.sku ?? '';
	// eslint-disable-next-line camelcase
	const isEOL = productAppleCare?.is_eol ?? 0;
	// eslint-disable-next-line camelcase
	const isActive = productAppleCare?.is_active ?? 0;
	const statusValue = getProductStatusValue(isEOL, isActive);
	const status = PRODUCT_STATUS_OPTIONS.find((option) => option.value === statusValue);
	const thumbnail = getThumbnailFromProduct(productAppleCare);

	return {
		id,
		uid,
		name,
		slug,
		sku,
		thumbnail,
		status,
	};
};

export const transformedProductAppleCareList = (productAppleCareList = []) => {
	if (Array.isArray(productAppleCareList)) {
		return productAppleCareList.map(transformedProductAppleCare);
	}
	return [];
};
