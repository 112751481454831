import {
	pathOr,
	datetimeFormat,
} from '../helpers';

export const transformedAuditLog = (audit = {}) => {
	const id = pathOr(null, ['id'])(audit);
	const date = audit.created_at ? datetimeFormat(audit.created_at) : '-';
	const userName = pathOr(null, ['user', 'username'])(audit);
	const firstName = pathOr(null, ['user', 'profile', 'first_name'])(audit);
	const lastName = pathOr(null, ['user', 'profile', 'last_name'])(audit);
	const event = pathOr(null, ['event'])(audit);
	const entityId = pathOr(null, ['model_id'])(audit);
	const entity = pathOr(null, ['model_type'])(audit);
	const ipAddress = pathOr(null, ['ip_address'])(audit);
	const userAgent = pathOr(null, ['user_agent'])(audit);
	const oldValues = pathOr([], ['old_values'])(audit);
	const newValues = pathOr([], ['new_values'])(audit);
	const fullName = `${firstName} ${lastName}`;
	const entities = `(${entityId}) ${entity}`;

	return {
		id,
		date,
		userName,
		fullName,
		event,
		entityId,
		entity,
		ipAddress,
		userAgent,
		entities,
		oldValues,
		newValues,
	};
};

export const transformedAuditLogList = (audits = []) => {
	return audits.map(transformedAuditLog);
};
