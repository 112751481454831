<template>
	<div class="media-library-container">
		<CModal
			:show.sync="isShow"
			class="modal-media-library"
			title="Select image"
			centered
		>
			<template #default>
				<div class="flex-fill">
					<div v-if="!isUploading && !isDeleting" class="search-area d-flex">
						<MediaLibraryButtonUpload
							@onChange="handleUploadFiles"
						/>
						<MediaLibrarySearch
							:key="`media-library-search-${isShow}`"
							v-model.trim="keywords"
							@onSubmit="handleSearch"
						/>
					</div>
					<BaseLoading
						v-if="isLoading && !isLoadingMore"
						class="loading d-flex align-items-center"
					/>
					<MediaLibraryLoading
						v-else-if="isUploading || isDeleting"
						:type="isUploading ? 'upload' : 'delete'"
						@onCancel="handleCancel"
					/>
					<MediaLibraryList
						v-else
						:list="transformedList"
						:is-loading-more="isLoadingMore"
						@onSelect="handleSelectListItem"
						@onActive="handleActiveListItem"
						@onScroll="handleLoadMore"
					/>
				</div>
				<MediaLibraryInfo
					:data="activeItem"
					@onDelete="handleDeleteFile"
				/>
			</template>

			<template #footer>
				<template v-if="type === 'multiple'">
					<div v-if="selectedImages.length" class="media-actions">
						<CButton
							color="transparent"
							class="btn-delete -min-width"
							@click="handleDeleteFiles"
						>
							Delete selected
						</CButton>
						<CButton
							color="transparent"
							class="btn-clear-selection -min-width"
							@click="clearSelection"
						>
							Clear selections
						</CButton>
					</div>
					<span
						class="typo-body-1 mr-4"
						:class="{ 'color-black-45': selectedImages.length === 0 }"
					>
						<template v-if="selectedImages.length">{{ selectedImages.length }} Selected</template>
						<template v-else>Please select at least 1 image</template>
					</span>
				</template>
				<CButton
					color="tertiary"
					class="mr-3 -min-width"
					data-test-id="close-media"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					data-test-id="confirmMediaLibrary"
					:disabled="!selectedImages.length || isUploading"
					color="primary"
					class="-min-width"
					@click="handleSubmit"
				>
					Confirm
				</CButton>
			</template>
		</CModal>
		<ModalDeleteImage
			ref="modal-delete-image"
			:selected-delete-images="selectedDeleteImages"
			@onConfirmDeleteImage="handleConfirmDeleteFile"
		/>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import axios from 'axios';
import MediaLibraryButtonUpload from './MediaLibraryButtonUpload.vue';
import MediaLibrarySearch from './MediaLibrarySearch.vue';
import MediaLibraryList from './MediaLibraryList.vue';
import MediaLibraryLoading from './MediaLibraryLoading.vue';
import MediaLibraryInfo from './MediaLibraryInfo.vue';
import ModalDeleteImage from './ModalDeleteImage.vue';
import { pathOr } from '../assets/js/helpers';
import { uploadFileAPI, deleteFileAPI } from '../services/api/files.api';

export default {
	name: 'ModalMediaLibrary',
	components: {
		MediaLibraryButtonUpload,
		MediaLibrarySearch,
		MediaLibraryList,
		MediaLibraryLoading,
		MediaLibraryInfo,
		ModalDeleteImage,
	},
	props: {
		closeAfterSubmit: {
			type: Boolean,
			default: true,
		},
		type: {
			type: String,
			default: 'multiple',
			validator: (value) => {
				return [
					'single',
					'multiple',
				].includes(value);
			},
		},
	},
	data() {
		return {
			isShow: false,
			isLoadingMore: false,
			isUploading: false,
			isDeleting: false,
			keywords: null,
			selectedImages: [],
			selectedDeleteImages: [],
			activeId: null,
			uploadingSource: null,
			deletingSource: null,
			isCancel: false,
		};
	},
	computed: {
		...mapState('mediaLibrary', {
			list: 'list',
			upload: 'upload',
		}),
		...mapGetters({
			mediaLists: 'mediaLibrary/mediaLists',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		currentPage() {
			return pathOr(1, ['meta', 'currentPage'])(this.list);
		},
		lastPage() {
			return pathOr(1, ['meta', 'lastPage'])(this.list);
		},

		transformedList() {
			return this.mediaLists.map((listItem) => ({
				...listItem,
				isSelected: this.selectedImages.some((image) => image.id === listItem.id),
				isActive: listItem.id === this.activeId,
			}));
		},
		activeItem() {
			return this.transformedList.find((listItem) => listItem.isActive) || null;
		},
	},
	watch: {
		isShow: {
			immediate: true,
			async handler(value) {
				if (value) {
					const query = { page: 1 };
					const isAppend = false;
					await this.getFiles({ query, isAppend });
				}

				// clear data when open/close
				this.isLoadingMore = false;
				this.keywords = null;
				this.selectedImages = [];
				this.handleActiveFirstList();
			},
		},
	},
	methods: {
		...mapActions({
			getFiles: 'mediaLibrary/getFiles',
			showToast: 'toast/showToast',
		}),
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
		handleSubmit() {
			this.$emit('onSubmit', this.selectedImages);

			if (this.closeAfterSubmit) {
				this.close();
			}
		},
		async handleSearch() {
			const query = {
				q: this.keywords,
				page: 1,
			};
			const isAppend = false;
			await this.getFiles({ query, isAppend });

			this.handleActiveFirstList();
		},
		async handleUploadFiles({ files = [], error = null }) {
			if (error) {
				this.showToast({
					type: 'danger',
					header: error.header,
					content: error.content,
				});
			} else {
				this.isUploading = true;
				await this.uploadFiles({ files });
				await this.getFiles({ query: { page: 1 }, isAppend: false });
				this.isCancel = false;
				this.isUploading = false;
			}
		},
		handleActiveFirstList() {
			this.activeId = pathOr(null, [0, 'id'])(this.mediaLists);
		},
		handleSelectListItem(data = {}) {
			const id = data.id;
			const isExistImage = this.selectedImages.some((image) => image.id === id);

			if (isExistImage) {
				this.selectedImages = this.selectedImages.filter((value) => value.id !== id);
			} else {
				this.selectedImages = this.type === 'multiple'
					? [...this.selectedImages, data]
					: [data];
			}
		},
		handleActiveListItem(id = null) {
			this.activeId = id;
		},
		handleCancel(type = null) {
			this.isCancel = true;

			if (type === 'upload') {
				this.isUploading = false;
				this.uploadingSource.cancel();
			} else if (type === 'delete') {
				this.isDeleting = false;
				this.deletingSource.cancel();
			}
		},
		// Lazy load on scroll
		async handleLoadMore({ scrollHeight = 0, scrollTop = 0, offsetHeight = 0 }) {
			if (!this.isLoading && (this.lastPage > this.currentPage)) {
				if ((scrollTop + offsetHeight) >= (scrollHeight * 0.7)) {
					this.isLoadingMore = true;

					// Get data
					const query = {
						q: this.keywords,
						page: this.currentPage + 1,
					};
					const isAppend = true;
					await this.getFiles({ query, isAppend });

					this.isLoadingMore = false;
				}
			}
		},
		async uploadFiles({ files }) {
			this.uploadingSource = axios.CancelToken.source();

			for (const file of files) {
				try {
					const formData = new FormData();
					formData.append('file', file);

					// eslint-disable-next-line
					await uploadFileAPI(formData, this.uploadingSource);
				} catch (error) {
					// Handle error code 'invalid_image_name'
					if (!this.isCancel) {
						this.showToast({
							type: 'danger',
							header: this.$t('components.modalMediaLibrary.error.uploadFailedHeader'),
							content: error?.response?.data?.message ?? this.$t('components.modalMediaLibrary.error.uploadFailedContent'),
						});
					}
				}
			}
		},
		async deleteFiles(files) {
			this.deletingSource = axios.CancelToken.source();

			for (const file of files) {
				try {
					// eslint-disable-next-line
					await deleteFileAPI(file.id, this.deletingSource);
					this.selectedImages = this.selectedImages.filter((value) => value.id !== file.id);
				} catch {
					if (!this.isCancel) {
						this.showToast({
							type: 'danger',
							header: this.$t('components.modalMediaLibrary.error.deleteFailedHeader'),
							content: this.$t('components.modalMediaLibrary.error.deleteFailedContent'),
						});
					}
				}
			}
		},
		async handleConfirmDeleteFile(files) {
			this.isDeleting = true;
			await this.deleteFiles(files);
			await this.getFiles({ query: { page: 1 }, isAppend: false });
			this.isCancel = false;
			this.isDeleting = false;
		},
		// Delete multiple image
		handleDeleteFiles() {
			this.selectedDeleteImages = this.selectedImages;
			this.openDeleteModal();
		},
		// Delete one image
		handleDeleteFile(data) {
			this.selectedDeleteImages = [data];
			this.openDeleteModal();
		},
		openDeleteModal() {
			this.$refs['modal-delete-image'].open();
		},
		clearSelection() {
			this.selectedImages = [];
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .modal-media-library {
	// overwrite
	.modal-dialog {
		max-width: rem(912);
	}

	.modal-body {
		display: flex;
		padding: 0;
	}

	.modal-footer {
		box-shadow: $box-shadow-component;
	}
}

.search-area {
	height: rem(66);
	padding: rem(16) rem(24);
	border-bottom: 1px solid $color-gray-300;
}

.loading {
	height: rem(332);
}

.media-actions {
	margin-left: rem(-10);
	margin-right: auto;

	.btn {
		@include typo-body-1;

		font-weight: 400;

		&-delete {
			color: $color-alert;
		}

		&-clear-selection {
			color: $color-black-45;
		}
	}
}
</style>
