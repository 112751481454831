import { pathOr } from '../helpers';

export const transformedAccordionSetProductSku = (list = []) => {
	return list.map((x) => x.itec_sku);
};

export const transformedAccordionSet = (accordionSet = {}) => {
	return {
		id: pathOr(0, ['id'])(accordionSet),
		name: pathOr(0, ['name'])(accordionSet),
		products: transformedAccordionSetProductSku(pathOr([], ['products'])(accordionSet)),
		keys: pathOr([], ['keys'])(accordionSet),
		// for column delete
		deleteId: pathOr(0, ['id'])(accordionSet),
	};
};

export const transformedAccordionSetList = (accordionSets = []) => {
	return accordionSets.map(transformedAccordionSet);
};

export const transformedAccordionSetForm = (data = {}) => {
	return {
		name: data.name,
		product_skus: data.products,
		product_accordion_key_ids: data.keys.map((x) => x.id),
	};
};
