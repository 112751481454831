import {
	getProductComparesAPI,
	getProductCompareAPI,
	postProductCompareAPI,
	putProductCompareAPI,
	deleteProductCompareAPI,
} from '../../services/api/productCompare.api';
import { transformedProductCompareList, transformedProductCompareData } from '../../assets/js/transform/productCompare';
import { transformedMeta } from '../../assets/js/transform/meta';
import {
	GET_PRODUCT_COMPARE_LIST_REQUEST,
	GET_PRODUCT_COMPARE_LIST_SUCCESS,
	GET_PRODUCT_COMPARE_LIST_FAILURE,

	GET_PRODUCT_COMPARE_REQUEST,
	GET_PRODUCT_COMPARE_SUCCESS,
	GET_PRODUCT_COMPARE_FAILURE,

	CREATE_PRODUCT_COMPARE_REQUEST,
	CREATE_PRODUCT_COMPARE_SUCCESS,
	CREATE_PRODUCT_COMPARE_FAILURE,

	UPDATE_PRODUCT_COMPARE_REQUEST,
	UPDATE_PRODUCT_COMPARE_SUCCESS,
	UPDATE_PRODUCT_COMPARE_FAILURE,

	DELETE_PRODUCT_COMPARE_REQUEST,
	DELETE_PRODUCT_COMPARE_SUCCESS,
	DELETE_PRODUCT_COMPARE_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: true,
			data: [],
			meta: [],
			error: false,
		},
		create: {
			isCreating: false,
			isLoading: false,
			error: false,
		},
		edit: {
			data: {},
			isLoading: false,
			isUpdating: false,
			error: false,
		},
		delete: {
			isLoading: false,
			isSuccess: false,
			error: false,
		},
	},
	mutations: {
		// #region state list
		[GET_PRODUCT_COMPARE_LIST_REQUEST](state) {
			state.list.data = [];
			state.list.meta = [];
			state.list.isLoading = true;
			state.list.error = false;
		},
		[GET_PRODUCT_COMPARE_LIST_SUCCESS](state, { data = [], meta = {} }) {
			state.list.isLoading = false;
			data.forEach((item) => {
				state.list.data.push(transformedProductCompareList(item));
			});
			state.list.meta = transformedMeta(meta);
		},
		[GET_PRODUCT_COMPARE_LIST_FAILURE](state) {
			state.edit.isLoading = false;
			state.list.error = true;
		},
		// #endregion state list

		// #region state get edit data
		[GET_PRODUCT_COMPARE_REQUEST](state) {
			state.edit.data = {};
			state.edit.isLoading = true;
			state.edit.error = false;
		},
		[GET_PRODUCT_COMPARE_SUCCESS](state, { data = {} }) {
			state.edit.isLoading = false;
			state.edit.data = transformedProductCompareData(data);
		},
		[GET_PRODUCT_COMPARE_FAILURE](state) {
			state.edit.isLoading = false;
			state.edit.error = true;
		},
		// #endregion state get edit data

		// #region state create
		[CREATE_PRODUCT_COMPARE_REQUEST](state) {
			state.create.isCreating = true;
			state.create.isLoading = true;
			state.create.error = false;
		},
		[CREATE_PRODUCT_COMPARE_SUCCESS](state) {
			state.create.isCreating = false;
			state.create.isLoading = false;
			state.create.error = false;
		},
		[CREATE_PRODUCT_COMPARE_FAILURE](state) {
			state.create.isCreating = false;
			state.create.isLoading = false;
			state.create.error = true;
		},
		// #endregion state create

		// #region state update
		[UPDATE_PRODUCT_COMPARE_REQUEST](state) {
			state.edit.isLoading = true;
			state.edit.isUpdating = true;
			state.edit.error = false;
		},
		[UPDATE_PRODUCT_COMPARE_SUCCESS](state) {
			state.edit.isLoading = false;
			state.edit.isUpdating = false;
			state.edit.error = false;
		},
		[UPDATE_PRODUCT_COMPARE_FAILURE](state) {
			state.edit.isLoading = false;
			state.edit.isUpdating = false;
			state.edit.error = true;
		},
		// #endregion state update

		// #regoin state delete
		[DELETE_PRODUCT_COMPARE_REQUEST](state) {
			state.delete.isLoading = true;
			state.delete.isSuccess = false;
			state.delete.error = false;
		},
		[DELETE_PRODUCT_COMPARE_SUCCESS](state) {
			state.delete.isLoading = false;
			state.delete.isSuccess = true;
			state.delete.error = false;
		},
		[DELETE_PRODUCT_COMPARE_FAILURE](state) {
			state.delete.isLoading = false;
			state.delete.isSuccess = false;
			state.delete.error = true;
		},
		// #regoin state delete
	},
	actions: {
		async getProductCompares({ commit }, query) {
			try {
				commit(GET_PRODUCT_COMPARE_LIST_REQUEST, query);
				const { data } = await getProductComparesAPI(query);
				commit(GET_PRODUCT_COMPARE_LIST_SUCCESS, data);
			} catch (error) {
				commit(GET_PRODUCT_COMPARE_LIST_FAILURE);
			}
		},
		async getProductCompare({ commit }, id) {
			try {
				commit(GET_PRODUCT_COMPARE_REQUEST);
				const { data } = await getProductCompareAPI(id);
				commit(GET_PRODUCT_COMPARE_SUCCESS, data);
			} catch (error) {
				commit(GET_PRODUCT_COMPARE_FAILURE);
			}
		},
		async createProductCompare({ commit, dispatch }, params) {
			try {
				commit(CREATE_PRODUCT_COMPARE_REQUEST);
				const { data } = await postProductCompareAPI(params);
				commit(CREATE_PRODUCT_COMPARE_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);
				commit(CREATE_PRODUCT_COMPARE_FAILURE);
				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));
					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
		async updateProductCompare({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_PRODUCT_COMPARE_REQUEST);
				const { data } = await putProductCompareAPI(id, params);
				commit(UPDATE_PRODUCT_COMPARE_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);
				commit(UPDATE_PRODUCT_COMPARE_FAILURE);
				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));
					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
		async deleteProductCompare({ commit, dispatch }, id) {
			try {
				commit(DELETE_PRODUCT_COMPARE_REQUEST);
				const { data } = await deleteProductCompareAPI(id);
				commit(DELETE_PRODUCT_COMPARE_SUCCESS, data);
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				const errorMessages = [];
				const errors = pathOr(null, ['response', 'data', 'errors'])(error);
				commit(DELETE_PRODUCT_COMPARE_FAILURE);
				if (errors) {
					Object.keys(errors).forEach((key) => errorMessages.push(errors[key][0]));
					dispatch('toast/showToast', {
						type: 'danger',
						header: i18n.t('global.errorMessageTitleEdit'),
						content: errorMessages,
					}, { root: true });
				}
			}
		},
	},
};
