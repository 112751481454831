import { i18n } from '@/i18n';
import {
	getPagesAPI,
	postSortPagesAPI,
	getPageAPI,
	postPageAPI,
	putPageAPI,
	deletePageAPI,
} from '../../services/api/pages.api';

import {
	getFooterColumnsAPI,
} from '../../services/api/footers.api';
import {
	GET_PAGES_REQUEST,
	GET_PAGES_SUCCESS,
	GET_PAGES_FAILURE,
	SELECT_PAGE,

	GET_PAGE_REQUEST,
	GET_PAGE_SUCCESS,
	GET_PAGE_FAILURE,

	CREATE_PAGE_REQUEST,
	CREATE_PAGE_SUCCESS,
	CREATE_PAGE_FAILURE,

	UPDATE_PAGE_REQUEST,
	UPDATE_PAGE_SUCCESS,
	UPDATE_PAGE_FAILURE,

	DELETE_PAGE_REQUEST,
	DELETE_PAGE_SUCCESS,
	DELETE_PAGE_FAILURE,

	SORT_PAGES_REQUEST,
	SORT_PAGES_SUCCESS,
	SORT_PAGES_FAILURE,

	GET_FOOTER_COLUMNS_REQUEST,
	GET_FOOTER_COLUMNS_SUCCESS,
	GET_FOOTER_COLUMNS_FAILURE,
} from '../types';
import { PAGE_LEVEL } from '../../enums/pages';
import { transformedPageList, transformedPageDetails, transfromedPageSection } from '../../assets/js/transform/pages';
import { pathOr } from '../../assets/js/helpers';

const getActiveIdsNested = (id = null, level = null) => {
	switch (level) {
		case PAGE_LEVEL.ONE:
			return {
				[PAGE_LEVEL.ONE]: id,
				[PAGE_LEVEL.TWO]: null,
			};

		case PAGE_LEVEL.TWO:
			return {
				[PAGE_LEVEL.TWO]: id,
			};

		default:
			return {};
	}
};

const defaultErrors = {
	slug: false,
};

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: [],
			activeIds: {
				[PAGE_LEVEL.ONE]: null,
				[PAGE_LEVEL.TWO]: null,
			},
		},
		create: {
			isLoading: false,
			error: false,
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			error: false,
			data: {},
		},
		delete: {
			isLoading: false,
			isSuccess: false,
			error: false,
		},
		sort: {
			isLoading: false,
			error: false,
		},
		editAttrStatus: {
			isLoading: false,
			isSuccess: false,
			error: false,
		},
		footerColumn: {
			isLoading: false,
			isError: false,
			data: [],
		},
	},

	getters: {
		pageList(state) {
			return state.list.data.map(transformedPageList);
		},
		pageDetails(state) {
			return transformedPageDetails(state.edit.data);
		},
	},

	mutations: {
		[GET_PAGES_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_PAGES_SUCCESS](state, { data = [] }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
			};
		},
		[GET_PAGES_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[GET_PAGE_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_PAGE_SUCCESS](state, { data = {} }) {
			state.edit = {
				...state.edit,
				isLoading: false,
				data,
			};
		},
		[GET_PAGE_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[SELECT_PAGE](state, { pageId = null, pageLevel = null }) {
			if (pageLevel) {
				state.list = {
					...state.list,
					activeIds: {
						...state.list.activeIds,
						...getActiveIdsNested(pageId, pageLevel),
					},
				};
			}
		},
		[CREATE_PAGE_REQUEST](state) {
			state.create = {
				...state.create,
				isLoading: true,
			};
		},
		[CREATE_PAGE_SUCCESS](state) {
			state.create = {
				...state.create,
				isLoading: false,
				error: false,
				fieldErrors: defaultErrors,
				isSuccess: true,
			};
		},
		[CREATE_PAGE_FAILURE](state) {
			state.create.isLoading = false;
		},
		// Update category
		[UPDATE_PAGE_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
			};
		},
		[UPDATE_PAGE_SUCCESS](state, { data = {} }) {
			state.edit = {
				...state.edit,
				isUpdating: false,
				error: false,
				data,
			};
		},
		[UPDATE_PAGE_FAILURE](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
				error: true,
			};
		},
		// Sort
		[SORT_PAGES_REQUEST](state) {
			state.sort = {
				...state.sort,
				isLoading: true,
				error: false,
			};
		},
		[SORT_PAGES_SUCCESS](state, { data = [] }) {
			state.list = {
				...state.list,
				data,
			};
			state.sort = {
				...state.sort,
				isLoading: false,
			};
		},
		[SORT_PAGES_FAILURE](state) {
			state.sort = {
				...state.sort,
				isLoading: false,
				error: true,
			};
		},
		[DELETE_PAGE_REQUEST]() {},
		[DELETE_PAGE_SUCCESS]() {},
		[DELETE_PAGE_FAILURE]() {},
		// footer column
		[GET_FOOTER_COLUMNS_REQUEST](state) {
			state.footerColumn = {
				...state.footerColumn,
				isLoading: true,
				isError: false,
			};
		},
		[GET_FOOTER_COLUMNS_SUCCESS](state, { data = [] }) {
			state.footerColumn = {
				...state.footerColumn,
				data,
				isLoading: false,
			};
		},
		[GET_FOOTER_COLUMNS_FAILURE](state) {
			state.footerColumn = {
				...state.footerColumn,
				isLoading: false,
				isError: true,
			};
		},
	},
	actions: {
		async getPages({ commit }) {
			try {
				commit(GET_PAGES_REQUEST);

				const response = await getPagesAPI();
				const data = pathOr([], ['data', 'data'])(response);
				commit(GET_PAGES_SUCCESS, { data });
			} catch (error) {
				commit(GET_PAGES_FAILURE);

				throw error;
			}
		},
		async getPage({ commit }, id) {
			try {
				commit(GET_PAGE_REQUEST);

				const response = await getPageAPI(id);
				const data = pathOr([], ['data', 'data'])(response);
				commit(GET_PAGE_SUCCESS, { data });
			} catch (error) {
				commit(GET_PAGE_FAILURE);
			}
		},
		selectPage({ commit, state }, { pageId = null, pageLevel = PAGE_LEVEL.ONE }) {
			if (pageId && pageId === state.list.activeIds[pageLevel]) {
				// When select actived item, de-active item
				commit(SELECT_PAGE, { pageId: null, pageLevel });
			} else {
				commit(SELECT_PAGE, { pageId, pageLevel });
			}
		},
		async updateOrderPages({ commit }, pageIds = []) {
			try {
				commit(SORT_PAGES_REQUEST);

				const response = await postSortPagesAPI({
					page_ids: pageIds,
				});
				const data = pathOr([], ['data', 'data'])(response);
				commit(SORT_PAGES_SUCCESS, { data });
			} catch (error) {
				commit(SORT_PAGES_FAILURE);
			}
		},
		async createPage({ commit, dispatch }, params) {
			try {
				commit(CREATE_PAGE_REQUEST);

				await postPageAPI(params);
				commit(CREATE_PAGE_SUCCESS);

				// Show toast
				dispatch('toast/showToast', {
					content: i18n.t('global.successMessageCreate', { type: 'page' }),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_PAGE_FAILURE, error);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleCreate'),
					type: 'danger',
				}, { root: true });

				throw error;
			}
		},
		async updatePage({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_PAGE_REQUEST);

				const response = await putPageAPI(id, params);
				const data = pathOr({}, ['data', 'data'])(response);
				commit(UPDATE_PAGE_SUCCESS, { data });

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				await dispatch('getPage', id);
			} catch (error) {
				commit(UPDATE_PAGE_FAILURE, error);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });

				throw error;
			}
		},
		async deletePage({ commit, dispatch }, id) {
			try {
				commit(DELETE_PAGE_REQUEST);

				await deletePageAPI(id);
				commit(DELETE_PAGE_SUCCESS);

				dispatch(
					'toast/showToast',
					{
						content: i18n.t('global.successMessageDelete', { type: 'Page' }),
						header: i18n.t('global.successMessageTitleDelete'),
					},
					{ root: true },
				);
			} catch (error) {
				commit(DELETE_PAGE_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleDelete'),
					type: 'danger',
				}, { root: true });

				throw error;
			}
		},

		async getFooterColumns({ commit }) {
			try {
				commit(GET_FOOTER_COLUMNS_REQUEST);

				const response = await getFooterColumnsAPI();
				const data = pathOr([], ['data', 'data'])(response);
				commit(GET_FOOTER_COLUMNS_SUCCESS, { data: transfromedPageSection(data) });
			} catch (error) {
				commit(GET_FOOTER_COLUMNS_FAILURE);

				throw error;
			}
		},
	},
};
