export default {
	label: {
		titleTh: 'Title TH{required}',
		titleEn: 'Title EN{required}',
		descriptionTh: 'Description TH{required}',
		descriptionEn: 'Description EN{required}',
		buttonTextTh: 'Button Text TH{required}',
		buttonTextEn: 'Button Text EN{required}',
		linkText: 'Link{required}',
	},
};
