export default {
	login: 'Log in',
	unexpectedError: 'An unexpected error occurred. Please try again.',
	requireField: 'Required field',
	invalidFormat: 'Invalide format',
	invalidMinValue: 'Invalid min value: {minValue}',
	userNameOrPassWordIsInCorrect: 'Username or password is incorrect.',
	tooManyAttempts: 'Temporary disable, please try again later',
	accountInactive: 'This account is inactive. Please contact the admin.',
};
