export const transformSubdistrict = (subdistrict = {}) => {
	return {
		id: subdistrict.id,
		name: subdistrict.name,
		postcode: subdistrict.postcode,
	};
};

export const transformDistrict = (district = {}) => {
	return {
		id: district.id,
		name: district.name,
		subdistricts: district.subdistricts.map(transformSubdistrict),
	};
};


export const transformProvince = (province = {}) => {
	return {
		id: province.id,
		name: province.name,
		districts: province.districts.map(transformDistrict),
	};
};

export const transformProvinceList = (provinces = []) => {
	return provinces.map(transformProvince);
};
