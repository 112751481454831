import { pathOr, datetimeFormat } from '../helpers';
import { PRODUCT_IMPORT_HISTORY_STATUSES } from '../../../enums/productImportHistory';

export const transformedProductImportHistory = (productImportHistory = {}) => {
	const filename = pathOr('', ['name'])(productImportHistory);
	const originalUrl = pathOr('', ['original_url'])(productImportHistory);
	const file = { name: filename, url: originalUrl };
	const type = pathOr('', ['type'])(productImportHistory);
	const uploader = pathOr('', ['user', 'username'])(productImportHistory);
	const createdAt = productImportHistory.created_at ? datetimeFormat(productImportHistory.created_at) : '-';
	const status = PRODUCT_IMPORT_HISTORY_STATUSES[productImportHistory.status];
	const logfile = productImportHistory.status === 'failed'
		? pathOr('', ['log_url'])(productImportHistory)
		: '';

	return {
		file,
		type,
		logfile,
		uploader,
		createdAt,
		status,
	};
};

export const transformedProductImportHistoryList = (productImportHistories = []) => {
	const data = productImportHistories.map((productImportHistory) => transformedProductImportHistory(productImportHistory));
	return data;
};
