<template>
	<div class="time-table p-4 mb-4">
		<div class="label mb-3">
			Delivery on these days
		</div>
		<CRow>
			<CCol md="3">
				<CInputCheckbox
					:checked.sync="hasDays.MONDAY"
					custom
					class="mb-3"
					label="Monday"
				/>
			</CCol>
			<CCol md="3">
				<CInputCheckbox
					:checked.sync="hasDays.TUESDAY"
					custom
					class="mb-3"
					label="Tuesday"
				/>
			</CCol>
			<CCol md="3">
				<CInputCheckbox
					:checked.sync="hasDays.WEDNESDAY"
					custom
					class="mb-3"
					label="Wednesday"
				/>
			</CCol>
			<CCol md="3">
				<CInputCheckbox
					:checked.sync="hasDays.THURSDAY"
					custom
					class="mb-3"
					label="Thursday"
				/>
			</CCol>
			<CCol md="3">
				<CInputCheckbox
					:checked.sync="hasDays.FRIDAY"
					custom
					class="mb-3"
					label="Friday"
				/>
			</CCol>
			<CCol md="3">
				<CInputCheckbox
					:checked.sync="hasDays.SATURDAY"
					custom
					class="mb-3"
					label="Saturday"
				/>
			</CCol>
			<CCol md="3">
				<CInputCheckbox
					:checked.sync="hasDays.SUNDAY"
					custom
					class="mb-3"
					label="Sunday"
				/>
			</CCol>
		</CRow>
		<div v-if="!isValidDays" class="typo-error-text">
			Selection required
		</div>
		<div class="label mb-3 mt-4">
			Delivery on these days
		</div>
		<div class="d-flex">
			<BaseInputTime
				:value="deliveryTimeFrom"
				:is-valid="isValidDeliveryTimeFrom"
				:invalid-feedback="invalidFeedbackDeliveryTimeFrom"
				:disabled="isAllDay"
				@input="(value) => $emit('update:deliveryTimeFrom', value)"
			/>
			<div class="mx-3 mt-1">
				to
			</div>
			<BaseInputTime
				:value="deliveryTimeTo"
				:is-valid="isValidDeliveryTimeTo"
				:invalid-feedback="invalidFeedbackDeliveryTimeTo"
				:disabled="isAllDay"
				@input="(value) => $emit('update:deliveryTimeTo', value)"
			/>
			<CInputCheckbox
				:checked="isAllDay"
				custom
				class="checkbox-allday mt-1 position-absolute"
				label="All day (24 hrs)"
				@update:checked="(value) => $emit('update:isAllDay', value)"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseInputTimeTable',
	props: {
		days: {
			type: Array,
			default: () => [],
		},
		isAllDay: {
			type: Boolean,
			default: false,
		},
		deliveryTimeFrom: {
			type: String,
			default: null,
		},
		deliveryTimeTo: {
			type: String,
			default: null,
		},
		isValidDays: {
			type: Boolean,
			default: false,
		},
		isValidDeliveryTimeFrom: {
			type: Boolean,
			default: false,
		},
		isValidDeliveryTimeTo: {
			type: Boolean,
			default: false,
		},
		invalidFeedbackDeliveryTimeFrom: {
			type: String,
			default: null,
		},
		invalidFeedbackDeliveryTimeTo: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			hasDays: {
				MONDAY: false,
				TUESDAY: false,
				WEDNESDAY: false,
				THURSDAY: false,
				FRIDAY: false,
				SATURDAY: false,
				SUNDAY: false,
			},
		};
	},
	watch: {
		hasDays: {
			deep: true,
			handler(data) {
				const nameOfDays = Object.entries(data)
					.filter((day) => day[1])
					.map((day) => day[0]);
				this.$emit('update:days', nameOfDays);
			},
		},
	},
	mounted() {
		const mappedDay = this.days.reduce((acc, day) => ({ ...acc, [day]: true }), {});
		this.hasDays = { ...mappedDay };
	},
};
</script>

<style lang="scss" scoped>
	.checkbox-allday {
		left: rem(250);
	}
</style>
