import httpClient from './httpClient';

export const PREORDER_ENDPOINT = '/backoffice/pre-orders';

export const getPreOrdersAPI = (params = {}) => httpClient.get(PREORDER_ENDPOINT, { params });
export const getPreOrderAPI = (id) => httpClient.get(`${PREORDER_ENDPOINT}/${id}`);
export const postPreOrderAPI = (params) => httpClient.post(`${PREORDER_ENDPOINT}`, params);
export const updatePreOrderAPI = (id, params) => httpClient.put(`${PREORDER_ENDPOINT}/${id}`, params);
export const deletePreOrderAPI = (id) => httpClient.delete(`${PREORDER_ENDPOINT}/${id}`);
// Pre-order pickup at store
export const postPreOrderPickupRuleAPI = (preOrderId, params) => httpClient.post(`${PREORDER_ENDPOINT}/${preOrderId}/pickup-rules`, params);
export const getPreOrderPickupRuleAPI = (preOrderId, preOrderPickupId) => httpClient.get(`${PREORDER_ENDPOINT}/${preOrderId}/pickup-rules/${preOrderPickupId}`);
export const updatePreOrderPickupRuleAPI = (preOrderId, preOrderPickupId, params) => httpClient.put(`${PREORDER_ENDPOINT}/${preOrderId}/pickup-rules/${preOrderPickupId}`, params);

export const getPreOrderProductBySKUAPI = (params) => httpClient.get(`${PREORDER_ENDPOINT}/sku`, { params });

export const deletePreOrderPickupAtStoreRuleAPI = (preOrderId, preOrderPickupId) => httpClient.delete(`${PREORDER_ENDPOINT}/${preOrderId}/pickup-rules/${preOrderPickupId}`);
