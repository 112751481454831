import Vue from 'vue';
import {
	datetimeFormat,
	dateFormat,
	nullableToDashFormat,
	numberFormat,
	priceFormat,
} from '../assets/js/helpers';

Vue.filter('datetimeFormat', datetimeFormat);
Vue.filter('dateFormat', dateFormat);
Vue.filter('nullableToDashFormat', nullableToDashFormat);
Vue.filter('numberFormat', numberFormat);
Vue.filter('priceFormat', priceFormat);
