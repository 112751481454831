<template>
	<div>
		<div v-if="label" class="label">
			{{ label }}
		</div>
		<div :class="{ 'is-invalid': !isValid }">
			<Cleave
				:id="id"
				:options="options"
				:value="value"
				:raw="false"
				:disabled="disabled"
				class="form-control form-control-time text-center"
				placeholder="hh:mm"
				@input="handleInput"
			/>
		</div>
		<div
			v-if="!isValid"
			class="invalid-feedback position-absolute"
		>
			{{ invalidFeedback }}
		</div>
	</div>
</template>

<script>
import Cleave from 'vue-cleave-component';

export default {
	name: 'BaseInputTime',
	components: {
		Cleave,
	},
	props: {
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		value: {
			type: [String, Number],
			default: null,
		},
		invalidFeedback: {
			type: String,
			default: null,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			options: {
				raw: true,
				time: true,
				timePattern: ['h', 'm'],
			},
		};
	},
	methods: {
		handleInput(number) {
			this.$emit('input', number);
		},
	},
};
</script>

<style lang="scss" scoped>
	.label {
		margin-bottom: rem(8);
		line-height: 1.43;
	}

	.form-control-time {
		max-width: rem(75);
	}

	.is-invalid {
		.form-control {
			border: rem(1) solid $color-alert;

			&:focus {
				box-shadow: none;
			}
		}
	}
</style>

