import {
	getAttributeSetsAPI,
	getAttributeSetAPI,
	postAttributeSetAPI,
	putAttributeSetAPI,
	deleteAttributeSetAPI,
} from '@/services/api/attributeSets.api';
import {
	GET_ATTRIBUTE_SET_LIST_REQUEST,
	GET_ATTRIBUTE_SET_LIST_SUCCESS,
	GET_ATTRIBUTE_SET_LIST_FAILURE,
	GET_ATTRIBUTE_SET_REQUEST,
	GET_ATTRIBUTE_SET_SUCCESS,
	GET_ATTRIBUTE_SET_FAILURE,
	CREATE_ATTRIBUTE_SET_REQUEST,
	CREATE_ATTRIBUTE_SET_SUCCESS,
	CREATE_ATTRIBUTE_SET_FAILURE,
	UPDATE_ATTRIBUTE_SET_REQUEST,
	UPDATE_ATTRIBUTE_SET_SUCCESS,
	UPDATE_ATTRIBUTE_SET_FAILURE,
	DELETE_ATTRIBUTE_SET_REQUEST,
	DELETE_ATTRIBUTE_SET_SUCCESS,
	DELETE_ATTRIBUTE_SET_FAILURE,
} from '../types';
import { i18n } from '../../i18n';
import {
	transformedAttributeSetList,
	transformedAttributeSet,
} from '../../assets/js/transform/attributeSets';
import { transformedMeta } from '../../assets/js/transform/meta';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		create: {
			isSubmitting: false,
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			data: {},
		},
		delete: {
			isSubmitting: false,
		},
	},
	getters: {
		attributeList(state) {
			return state.list.data;
		},
		attributeInfo(state) {
			return state.edit.data;
		},
	},
	mutations: {
		[GET_ATTRIBUTE_SET_LIST_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...state.list.meta,
					currentPage: page,
				},
			};
		},
		[GET_ATTRIBUTE_SET_LIST_SUCCESS](state, { data, meta }) {
			state.list = {
				data,
				meta: transformedMeta(meta),
				isLoading: false,
			};
		},
		[GET_ATTRIBUTE_SET_LIST_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_ATTRIBUTE_SET_REQUEST](state) {
			state.create.isSubmitting = true;
		},
		[CREATE_ATTRIBUTE_SET_SUCCESS](state) {
			state.create.isSubmitting = false;
		},
		[CREATE_ATTRIBUTE_SET_FAILURE](state) {
			state.create.isSubmitting = false;
		},
		[GET_ATTRIBUTE_SET_REQUEST](state) {
			state.edit.isLoading = true;
		},
		[GET_ATTRIBUTE_SET_SUCCESS](state, data) {
			state.edit.isLoading = false;
			state.edit.data = data;
		},
		[GET_ATTRIBUTE_SET_FAILURE](state) {
			state.edit.isLoading = false;
		},
		[UPDATE_ATTRIBUTE_SET_REQUEST](state) {
			state.edit.isUpdating = true;
		},
		[UPDATE_ATTRIBUTE_SET_SUCCESS](state) {
			state.edit.isUpdating = false;
		},
		[UPDATE_ATTRIBUTE_SET_FAILURE](state) {
			state.edit.isUpdating = false;
		},
		[DELETE_ATTRIBUTE_SET_REQUEST](state) {
			state.delete.isSubmitting = true;
		},
		[DELETE_ATTRIBUTE_SET_SUCCESS](state) {
			state.delete.isSubmitting = false;
		},
		[DELETE_ATTRIBUTE_SET_FAILURE](state) {
			state.delete.isSubmitting = false;
		},
	},
	actions: {
		async getAttributeSets({ commit }, query) {
			try {
				commit(GET_ATTRIBUTE_SET_LIST_REQUEST, query);
				const response = await getAttributeSetsAPI(query);
				const data = transformedAttributeSetList(pathOr([], ['data', 'data'])(response));
				const meta = pathOr([], ['data', 'meta'])(response);
				commit(GET_ATTRIBUTE_SET_LIST_SUCCESS, { data, meta });
			} catch (error) {
				commit(GET_ATTRIBUTE_SET_LIST_FAILURE);
			}
		},
		async getAttributeSet({ commit }, id) {
			try {
				commit(GET_ATTRIBUTE_SET_REQUEST, id);
				const response = await getAttributeSetAPI(id);
				const data = transformedAttributeSet(pathOr({}, ['data', 'data'])(response));
				commit(GET_ATTRIBUTE_SET_SUCCESS, data);
			} catch (error) {
				commit(GET_ATTRIBUTE_SET_FAILURE);
			}
		},
		async createAttributeSet({ commit, dispatch }, name) {
			try {
				commit(CREATE_ATTRIBUTE_SET_REQUEST);
				await postAttributeSetAPI(name);
				commit(CREATE_ATTRIBUTE_SET_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('page.attributeSets.create.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(CREATE_ATTRIBUTE_SET_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		async updateAttributeSet({ commit, dispatch }, { id, params = {} }) {
			try {
				commit(UPDATE_ATTRIBUTE_SET_REQUEST);

				const { data } = await putAttributeSetAPI(id, params);

				commit(UPDATE_ATTRIBUTE_SET_SUCCESS, data);

				dispatch('toast/showToast', {
					content: i18n.t('global.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				await dispatch('getAttributeSet', id);
			} catch (error) {
				commit(UPDATE_ATTRIBUTE_SET_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
		async deleteAttributeSet({ commit, dispatch }, id) {
			try {
				commit(DELETE_ATTRIBUTE_SET_REQUEST);
				await deleteAttributeSetAPI(id);
				commit(DELETE_ATTRIBUTE_SET_SUCCESS);

				dispatch('toast/showToast', {
					content: i18n.t('page.attributeSets.delete.successMessage'),
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (error) {
				commit(DELETE_ATTRIBUTE_SET_FAILURE);
				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: i18n.t('global.errorMessageTitleEdit'),
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
