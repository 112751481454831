import isEmpty from 'lodash/isEmpty';
import {
	priceToAPI,
	priceFromAPI,
	pathOr,
	datetimeFormat,
	convertDateTimeToUTC,
} from '../helpers';
import { FLASHSALE_TITLE, FLASHSALE_COLOR } from '../../../enums/flashSales';
import {
	transformedCustomerGroupListToAPI,
	transformedExcludePromotionListToAPI,
	transformedPromotionRemarkList,
	transformedPromotionList,
} from '../../../assets/js/transform/promotions';
import { transformedProduct } from './products';
import { transformedMedia } from '../transform/mediaLibrary';

export const transformedFlashSale = (flashsale = {}) => {
	const id = pathOr(null, ['id'])(flashsale);
	const name = pathOr(null, ['name'])(flashsale);
	const status = pathOr(null, ['is_active'])(flashsale);
	const skus = pathOr(null, ['total_sku'])(flashsale);
	const startAt = flashsale.start_at ? datetimeFormat(flashsale.start_at) : '-';
	const endAt = flashsale.end_at ? datetimeFormat(flashsale.end_at) : '-';
	const createdAt = flashsale.created_at ? datetimeFormat(flashsale.created_at) : '-';
	const updatedAt = flashsale.updated_at ? datetimeFormat(flashsale.updated_at) : '-';
	const periodValue = pathOr(null, ['period'])(flashsale);
	const period = {
		title: FLASHSALE_TITLE.PERIODS_STATUSES[periodValue],
		color: FLASHSALE_COLOR.PERIODS_STATUSES[periodValue],
	};
	const duplicateId = id;

	return {
		id,
		name,
		status,
		skus,
		startAt,
		endAt,
		createdAt,
		updatedAt,
		period,
		periodValue,
		duplicateId,
	};
};

export const transformedFlashSaleList = (flashsales = []) => {
	return flashsales.map(transformedFlashSale);
};

/**
 * transformedFlashSaleProductListToAPI
 * @param {Array} list of object
 * @returns {Array} list of object || []
 */
export const transformedFlashSaleProductListToAPI = (flashsales = []) => {
	return flashsales.map((flashsale) => {
		return {
			id: flashsale.id,
			sku: flashsale.sku,
			name: flashsale.name,
			flash_sale_stock: flashsale.flashSaleStock,
			flash_sale_price: priceToAPI(flashsale.flashSalePrice),
			manipulate_sold_stock: flashsale.flashSaleManipulateSoldStock,
			purchase_quantity_limit: flashsale.flashSaleLimitUser,
			purchase_quantity_limit_per_order: flashsale.flashSaleLimitOrder,
		};
	});
};

/**
 * transformedFlashSaleBannerToAPI
 * @param {Object} list of object
 * @returns {Object} list of object || {}
 */
export const transformedFlashSaleBannerToAPI = (flashsales = {}) => {
	const flashSaleDesktopBanner = pathOr(null, ['flashSaleDesktopBanner', 'id'])(flashsales);
	const flashSaleMobileBanner = pathOr(null, ['flashSaleMobileBanner', 'id'])(flashsales);
	const flashSaleBackgroundDesktopBanner = pathOr(null, ['flashSaleBackgroundDesktopBanner', 'id'])(flashsales);
	const flashSaleBackgroundMobileBanner = pathOr(null, ['flashSaleBackgroundMobileBanner', 'id'])(flashsales);
	const flashSaleIconDesktopBanner = pathOr(null, ['flashSaleIconDesktopBanner', 'id'])(flashsales);
	const flashSaleIconMobileBanner = pathOr(null, ['flashSaleIconMobileBanner', 'id'])(flashsales);
	const flashSaleBackgroundMoileAppBanner = pathOr(null, ['flashSaleBackgroundMoileAppBanner', 'id'])(flashsales);
	const flashSaleIconMoileAppBanner = pathOr(null, ['flashSaleIconMoileAppBanner', 'id'])(flashsales);

	return {
		flash_sale_desktop_banner: flashSaleDesktopBanner ? [flashSaleDesktopBanner] : [],
		flash_sale_mobile_banner: flashSaleMobileBanner ? [flashSaleMobileBanner] : [],
		flash_sale_background_desktop_banner: flashSaleBackgroundDesktopBanner ? [flashSaleBackgroundDesktopBanner] : [],
		flash_sale_background_mobile_banner: flashSaleBackgroundMobileBanner ? [flashSaleBackgroundMobileBanner] : [],
		flash_sale_icon_desktop_banner: flashSaleIconDesktopBanner ? [flashSaleIconDesktopBanner] : [],
		flash_sale_icon_mobile_banner: flashSaleIconMobileBanner ? [flashSaleIconMobileBanner] : [],
		flash_sale_background_mobile_app_banner: flashSaleBackgroundMoileAppBanner ? [flashSaleBackgroundMoileAppBanner] : [],
		flash_sale_icon_mobile_app_banner: flashSaleIconMoileAppBanner ? [flashSaleIconMoileAppBanner] : [],
	};
};

/**
 * transformedFlashSaleDataToAPI
 * @param {Object} flashsale information data
 * @returns {Object} transformed flashsale data to be request payload
 */
export const transformedFlashSaleDataToAPI = (data) => {
	if (isEmpty(data)) {
		return null;
	}

	const { steps } = data;

	const { formData: step1 } = steps[1];
	const { formData: step2 } = steps[2];
	const { formData: step3 } = steps[3] || {};

	const postData = {
		...(step1.id ? { id: step1.id } : null),
		name: step1.name,
		slug: step1.slug,
		is_active: step1.status,
		start_at: convertDateTimeToUTC(step1.startDate, step1.startTime),
		end_at: convertDateTimeToUTC(step1.endDate, step1.endTime),
		excluded_customer_group_ids: transformedCustomerGroupListToAPI(step1.customerGroups) || [],
		excluded_promotion_ids: transformedExcludePromotionListToAPI(step1.excludePromotions) || [],
		products: transformedFlashSaleProductListToAPI(step2.flashSaleSkus),
		media: transformedFlashSaleBannerToAPI(step3),
		time_counter_font_color: step3.counterFontColor,
		time_counter_background_color: step3.counterBackgroundColor,
		description_top: step3.descriptionTop,
		description_bottom: step3.descriptionBottom,
	};

	return postData;
};

/**
 * transformedFlashSaleInfoToDataMultipleStep
 * @param {Object} flashsale information data
 * @returns {Object} transformed flashsale data
 */
export const transformedFlashSaleInfoToDataMultipleStep = async (data = {}) => {
	if (isEmpty(data)) {
		return null;
	}

	const flashsaleData = {
		steps: {
			1: { formData: {} },
			2: { formData: {} },
			3: { formData: {} },
		},
	};
	flashsaleData.steps[1].formData = {
		id: data.id,
		name: data.name,
		slug: data.slug,
		status: !!data.is_active,
		period: data.period,
		startDate: new Date(datetimeFormat(data.start_at)),
		startTime: datetimeFormat(data.start_at, 'HH:mm'),
		endDate: new Date(datetimeFormat(data.end_at)),
		endTime: datetimeFormat(data.end_at, 'HH:mm'),
		customerGroups: data.excluded_customer_groups ? data.excluded_customer_groups : [],
		excludePromotions: data.excluded_promotions ? transformedPromotionList(data.excluded_promotions) : [],
		remarks: transformedPromotionRemarkList(data.remarks || []),
	};

	flashsaleData.steps[2].formData = {
		flashSaleSkus: data.products.map((flashsale) => {
			const transProduct = transformedProduct(flashsale);
			return {
				id: flashsale.id,
				sku: flashsale.sku,
				name: flashsale.name,
				thumbnail: transProduct.thumbnail,
				flashSaleStock: flashsale.flash_sale_stock,
				flashSalePrice: priceFromAPI(flashsale.flash_sale_price),
				flashSaleManipulateSoldStock: flashsale.manipulate_sold_stock,
				flashSaleLimitUser: flashsale.purchase_quantity_limit,
				flashSaleLimitOrder: flashsale.purchase_quantity_limit_per_order,
				flashSaleSold: flashsale.sold_stock || 0,
				flashSaleRemaining: flashsale.remaining_stock || 0,
				priceSelling: transProduct.priceSelling,
				stockAvailable: transProduct.stockAvailable,
			};
		}),
	};

	flashsaleData.steps[3].formData = {
		homepageDesktopBanner: data.homepage_banner_images.desktop[0] ? transformedMedia(data.homepage_banner_images.desktop[0]) : null,
		homepageMobileBanner: data.homepage_banner_images.mobile[0] ? transformedMedia(data.homepage_banner_images.mobile[0]) : null,
		flashSaleBackgroundDesktopBanner: data.flash_sale_background_banner_image.desktop[0] ? transformedMedia(data.flash_sale_background_banner_image.desktop[0]) : null,
		flashSaleBackgroundMobileBanner: data.flash_sale_background_banner_image.mobile[0] ? transformedMedia(data.flash_sale_background_banner_image.mobile[0]) : null,
		flashSaleIconDesktopBanner: data.flash_sale_icon_banner_image.desktop[0] ? transformedMedia(data.flash_sale_icon_banner_image.desktop[0]) : null,
		flashSaleIconMobileBanner: data.flash_sale_icon_banner_image.mobile[0] ? transformedMedia(data.flash_sale_icon_banner_image.mobile[0]) : null,
		flashSaleDesktopBanner: data.flash_sale_banner_images.desktop[0] ? transformedMedia(data.flash_sale_banner_images.desktop[0]) : null,
		flashSaleMobileBanner: data.flash_sale_banner_images.mobile[0] ? transformedMedia(data.flash_sale_banner_images.mobile[0]) : null,
		flashSaleBackgroundMoileAppBanner: data.flash_sale_background_mobile_app_images[0] ? transformedMedia(data.flash_sale_background_mobile_app_images[0]) : null,
		flashSaleIconMoileAppBanner: data.flash_sale_icon_mobile_app_images[0] ? transformedMedia(data.flash_sale_icon_mobile_app_images[0]) : null,
		descriptionTop: data.description_top,
		descriptionBottom: data.description_bottom,
		counterFontColor: data.time_counter_font_color,
		counterBackgroundColor: data.time_counter_background_color,
	};

	return flashsaleData;
};
