
import { pathOr, datetimeFormat, covertTimeFromAPI } from '../helpers';
import { transformedUser } from '../../../assets/js/transform/user';

export const transformedFeed = (feed = {}) => {
	const id = pathOr(0, ['id'])(feed);
	const partner = pathOr(null, ['name'])(feed);
	const status = !!pathOr(false, ['is_active'])(feed);
	const regenerateTimes = pathOr([], ['regenerate_times'])(feed).map(({ time }) => covertTimeFromAPI(time));
	const baseUrl = pathOr(null, ['base_url'])(feed);
	const slug = pathOr(null, ['slug'])(feed);
	const fileName = pathOr(null, ['file_name'])(feed);
	const lastUpdated = feed.updated_at ? datetimeFormat(feed.updated_at) : '-';

	return {
		id,
		partner,
		status,
		regenerateTimes,
		baseUrl,
		slug,
		fileName,
		lastUpdated,
	};
};

/**
 * transformedFeedRemarkDetails
 * @param {Object} product feed remark data
 * @returns {Object} transformed product feed remark data
 */
export const transformedFeedRemarkDetails = (remark = {}) => ({
	id: remark.id || null,
	message: remark.message || null,
	createdAt: datetimeFormat(remark.created_at),
	user: remark?.user ? transformedUser(remark.user) : null,
});

/**
 * transformedFeedRemarkList
 * @param {Array} product feed remarks data
 * @returns {Array} transformed product feed remarks data
 */
export const transformedFeedRemarkList = (remarks = []) => remarks.map(transformedFeedRemarkDetails);

/**
 * transformedFeedDetails
 * @param {Object} product feed details data
 * @returns {Object} transformed product details data
 */
export const transformedFeedDetails = (feed = {}) => {
	const id = pathOr(0, ['id'])(feed);
	const partner = pathOr(null, ['name'])(feed);
	const status = !!pathOr(false, ['is_active'])(feed);
	const regenerateTimes = pathOr([], ['regenerate_times'])(feed).map(({ time }) => covertTimeFromAPI(time));
	const slug = pathOr(null, ['slug'])(feed);
	const baseUrl = pathOr(null, ['base_url'])(feed);
	const fileName = pathOr(null, ['file_name'])(feed);
	const template = pathOr(null, ['template'])(feed);
	const isDeletable = !!pathOr(null, ['is_deletable'])(feed);
	const updatedBy = pathOr(null, ['updated_by', 'username'])(feed);
	const lastUpdated = feed.updated_at ? datetimeFormat(feed.updated_at) : '-';
	const remarks = transformedFeedRemarkList(feed.remarks || []);
	const condition = pathOr(null, ['item_condition_param'])(feed);

	return {
		id,
		partner,
		baseUrl,
		slug,
		fileName,
		status,
		template,
		isDeletable,
		regenerateTimes,
		lastUpdated,
		updatedBy,
		remarks,
		condition,
	};
};

/**
 * transformedFeedList
 * @param {Array} product feed list data
 * @returns {Array} transformed product feed list data
 */
export const transformedFeedList = (feeds = []) => {
	return feeds.map(transformedFeed);
};
