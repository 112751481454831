import router from '../../router';
import { i18n } from '../../i18n';
import {
	createCustomerAPI,
	getCustomerAPI,
	resendVerificationLinkAPI,
	updateCustomerAPI,
	getCustomerListAPI,
} from '../../services/api/customers.api';
import { transformedMeta } from '../../assets/js/transform/meta';

import {
	GET_CUSTOMER_REQUEST,
	GET_CUSTOMER_SUCCESS,
	GET_CUSTOMER_FAILURE,
	GET_CUSTOMER_LIST_REQUEST,
	GET_CUSTOMER_LIST_SUCCESS,
	GET_CUSTOMER_LIST_FAILURE,
	CREATE_CUSTOMER_REQUEST,
	CREATE_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_FAILURE,
	UPDATE_CUSTOMER_REQUEST,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_FAILURE,
	RESEND_VERIFICATION_LINK_REQUEST,
	RESEND_VERIFICATION_LINK_SUCCESS,
	RESEND_VERIFICATION_LINK_FAILURE,
} from '../types';
import { transformedCustomer } from '../../assets/js/transform/customers';

export default {
	namespaced: true,
	state: {
		list: {
			isLoading: false,
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
			data: [],
		},
		create: {
			isCreating: false,
		},
		edit: {
			isLoading: false,
			isSubmitting: false,
			data: {},
		},
	},
	getters: {},
	mutations: {
		[GET_CUSTOMER_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
			};
		},
		[GET_CUSTOMER_SUCCESS](state, data) {
			state.edit = {
				...state.edit,
				isLoading: false,
				data,
			};
		},
		[GET_CUSTOMER_FAILURE](state) {
			state.edit = {
				...state.edit,
				isLoading: false,
			};
		},
		[GET_CUSTOMER_LIST_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_CUSTOMER_LIST_SUCCESS](state, { data, meta }) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
				meta: {
					...state.list.meta,
					...meta,
				},
			};
		},
		[GET_CUSTOMER_LIST_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[CREATE_CUSTOMER_REQUEST](state) {
			state.create = {
				isCreating: true,
			};
		},
		[CREATE_CUSTOMER_SUCCESS](state) {
			state.create = {
				isCreating: false,
			};
		},
		[CREATE_CUSTOMER_FAILURE](state) {
			state.create = {
				isCreating: false,
			};
		},
		[UPDATE_CUSTOMER_REQUEST](state) {
			state.edit = {
				...state.edit,
				isSubmitting: true,
			};
		},
		[UPDATE_CUSTOMER_SUCCESS](state) {
			state.edit = {
				...state.edit,
				isSubmitting: false,
			};
		},
		[UPDATE_CUSTOMER_FAILURE](state) {
			state.edit = {
				...state.edit,
				isSubmitting: false,
			};
		},
		[RESEND_VERIFICATION_LINK_REQUEST]() {},
		[RESEND_VERIFICATION_LINK_SUCCESS]() {},
		[RESEND_VERIFICATION_LINK_FAILURE]() {},
	},
	actions: {
		async getCustomer({ commit }, id) {
			try {
				commit(GET_CUSTOMER_REQUEST);
				const { data } = await getCustomerAPI(id);
				const customer = transformedCustomer(data?.data || {});
				commit(GET_CUSTOMER_SUCCESS, customer);
			} catch (e) {
				commit(GET_CUSTOMER_FAILURE);
			}
		},
		async getCustomerList({ commit }, query) {
			try {
				commit(GET_CUSTOMER_LIST_REQUEST);
				const { data } = await getCustomerListAPI(query);
				const customers = (data?.data || []).map(transformedCustomer);
				const meta = transformedMeta(data?.meta || {});
				commit(GET_CUSTOMER_LIST_SUCCESS, { data: customers, meta });
			} catch (e) {
				commit(GET_CUSTOMER_LIST_FAILURE);
			}
		},
		async createCustomer({ commit, dispatch }, params) {
			try {
				commit(CREATE_CUSTOMER_REQUEST);
				await createCustomerAPI(params);
				commit(CREATE_CUSTOMER_SUCCESS);

				dispatch('toast/showToast', {
					content: 'Verification link has been sent, email has been sent to customer email.',
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });

				router.push({ name: 'CustomerList' });
			} catch (e) {
				commit(CREATE_CUSTOMER_FAILURE);

				dispatch('toast/showMultipleErrorsToast', e,	{ root: true });
			}
		},
		async updateCustomer({ commit, dispatch }, { id, params }) {
			try {
				commit(UPDATE_CUSTOMER_REQUEST);
				await updateCustomerAPI(id, params);
				commit(UPDATE_CUSTOMER_SUCCESS);

				dispatch('toast/showUpdateSuccessToast', null, { root: true });
			} catch (error) {
				commit(UPDATE_CUSTOMER_FAILURE);

				dispatch('toast/showMultipleErrorsToast', error, { root: true });
			}
		},
		async resendVerificationLink({ commit, dispatch }, id) {
			try {
				commit(RESEND_VERIFICATION_LINK_REQUEST);
				await resendVerificationLinkAPI(id);
				commit(RESEND_VERIFICATION_LINK_SUCCESS);

				dispatch('toast/showToast', {
					content: 'Verification link has been resent.',
					header: i18n.t('global.successMessageTitle'),
					type: 'success',
				}, { root: true });
			} catch (e) {
				commit(RESEND_VERIFICATION_LINK_FAILURE);

				dispatch('toast/showToast', {
					content: i18n.t('global.errorMessage'),
					header: 'Unable to resend',
					type: 'danger',
				}, { root: true });
			}
		},
	},
};
