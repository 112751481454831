import { ROUTE_NAME } from '../enums/route';

export const ROUTE_PERMISSION_KEY = {
	ORDER: 'orders',
	SHIPMENT: 'shipments',
	PRODUCT: 'products',
	CATEGORY: 'categories',
	PRODUCT_LABEL: 'product_labels',
	ATTRIBUTE_SET: 'attribute_sets',
	ATTRIBUTE_KEY: 'attribute_keys',
	PRODUCT_FAMILIES: 'product_families',
	CUSTOMER: 'customers',
	CUSTOMER_GROUP: 'customer_groups',
	WAREHOUSE: 'warehouses',
	STATIC_PAGE: 'static_pages',
	SUB_HEADERS: 'sub_headers',
	HOMEPAGE: 'homepage',
	CAMPAIGN_MANAGEMENT: 'campaign_management',
	PAYMENT: 'payment_settings',
	SHIPPING: 'shipping_settings',
	SEARCH_WEIGHT: 'search_score',
	SEARCH_SYNONYMS: 'search_synonyms',
	PROMOTION: 'promotions',
	FLASH_SALE: 'flash_sales',
	USER: 'users',
	ROLE_PERMISSION: 'roles_and_permissions',
	IMPORT_HISTORY: 'import_histories',
	PRE_ORDER: 'pre_orders',
	FEED: 'product_feeds',
	AUDIT_LOG: 'audit_logs',
	MICROSITE: 'microsite_apple',
	ABANDONED_CART: 'abandoned_carts',
	FORBIDDEN: '403',
	BRANCH_SHIPMENTS: 'branch_shipments',
	BUNDLE_SET: 'bundle_sets',
	BACK_IN_STOCK_NOTIFICATIONS: 'back_in_stock_notifications',
	LIMIT_PRODUCT_PER_ACCOUNT: 'product_limits',
	MOBILE_NOTIFICATION: 'mobile_notification_schedules',
	TAG_FILTER: 'tag_filters',
	SEARCH_RESULT_CACHE: 'search_result_cache',
	PRODUCT_DETAIL_ACCORDION_KEY: 'product_accordion_keys',
	PRODUCT_DETAIL_ACCORDION_SET: 'product_accordion_sets',
	PROMOTION_BANNER_SET: 'product_promotion_banner_sets',
	PROMOTION_BANNER_KEY: 'product_promotion_banner_keys',
	FOOTER: 'footers',
	VARIANT_INFO: 'variants',
	ORDER_AUTO_COMPLETE: 'order_auto_complete_settings',
	DATE_TIME_CACHE: 'cache_purges',
};

export const ROUTE_PERMISSION_NAME = {
	[ROUTE_PERMISSION_KEY.ORDER]: ROUTE_NAME.ORDER_LIST,
	[ROUTE_PERMISSION_KEY.SHIPMENT]: ROUTE_NAME.SHIPMENT_LIST,
	[ROUTE_PERMISSION_KEY.BRANCH_SHIPMENTS]: ROUTE_NAME.SHIPMENT_LIST,
	[ROUTE_PERMISSION_KEY.PRODUCT]: ROUTE_NAME.PRODUCTS_LISTS,
	[ROUTE_PERMISSION_KEY.CATEGORY]: ROUTE_NAME.CATEGORY_LISTS,
	[ROUTE_PERMISSION_KEY.PRODUCT_LABEL]: ROUTE_NAME.PRODUCT_LABEL_LIST,
	[ROUTE_PERMISSION_KEY.ATTRIBUTE_SET]: ROUTE_NAME.ATTRIBUTE_SET_LIST,
	[ROUTE_PERMISSION_KEY.ATTRIBUTE_KEY]: ROUTE_NAME.ATTRIBUTE_KEY_LIST,
	[ROUTE_PERMISSION_KEY.PRODUCT_FAMILIES]: ROUTE_NAME.PRODUCT_FAMILY_LIST,
	[ROUTE_PERMISSION_KEY.CUSTOMER]: ROUTE_NAME.CUSTOMER_LIST,
	[ROUTE_PERMISSION_KEY.CUSTOMER_GROUP]: ROUTE_NAME.CUSTOMER_GROUP_LIST,
	[ROUTE_PERMISSION_KEY.WAREHOUSE]: ROUTE_NAME.WAREHOUSE_LIST,
	[ROUTE_PERMISSION_KEY.STATIC_PAGE]: ROUTE_NAME.PAGES,
	[ROUTE_PERMISSION_KEY.SUB_HEADERS]: ROUTE_NAME.SUB_HEADERS,
	[ROUTE_PERMISSION_KEY.HOMEPAGE]: ROUTE_NAME.WIDGET_LIST,
	[ROUTE_PERMISSION_KEY.CAMPAIGN_MANAGEMENT]: ROUTE_NAME.MARKETING_CAMPAIGN_LIST,
	[ROUTE_PERMISSION_KEY.PAYMENT]: ROUTE_NAME.PAYMENT_LIST,
	[ROUTE_PERMISSION_KEY.SHIPPING]: ROUTE_NAME.SHIPPING_SETTING,
	[ROUTE_PERMISSION_KEY.SEARCH_WEIGHT]: ROUTE_NAME.SEARCH_WEIGHT_LIST,
	[ROUTE_PERMISSION_KEY.SEARCH_SYNONYMS]: ROUTE_NAME.SEARCH_SYNONYMS_LIST,
	[ROUTE_PERMISSION_KEY.PROMOTION]: ROUTE_NAME.PROMOTION_LIST,
	[ROUTE_PERMISSION_KEY.FLASH_SALE]: ROUTE_NAME.FLASH_SALE_LIST,
	[ROUTE_PERMISSION_KEY.USER]: ROUTE_NAME.USER_LISTS,
	[ROUTE_PERMISSION_KEY.ROLE_PERMISSION]: ROUTE_NAME.USER_ROLE,
	[ROUTE_PERMISSION_KEY.IMPORT_HISTORY]: ROUTE_NAME.PRODUCT_IMPORT_HISTORY,
	[ROUTE_PERMISSION_KEY.PRE_ORDER]: ROUTE_NAME.PRE_ORDER_LIST,
	[ROUTE_PERMISSION_KEY.FEED]: ROUTE_NAME.PRODUCT_FEED_LIST,
	[ROUTE_PERMISSION_KEY.AUDIT_LOG]: ROUTE_NAME.AUDIT_LOGS_LIST,
	[ROUTE_PERMISSION_KEY.MICROSITE]: ROUTE_NAME.MICROSITE_WIDGETS,
	[ROUTE_PERMISSION_KEY.ABANDONED_CART]: ROUTE_NAME.ABANDONED_CART_LIST,
	[ROUTE_PERMISSION_KEY.BUNDLE_SET]: ROUTE_NAME.BUNDLE_SET_LIST,
	[ROUTE_PERMISSION_KEY.BACK_IN_STOCK_NOTIFICATIONS]: ROUTE_NAME.BACK_IN_STOCK_NOTIFICATIONS,
	[ROUTE_PERMISSION_KEY.MOBILE_NOTIFICATION]: ROUTE_NAME.MOBILE_NOTIFICATION,
	[ROUTE_PERMISSION_KEY.TAG_FILTER]: ROUTE_NAME.TAG_FILTER_LIST,
	[ROUTE_PERMISSION_KEY.SEARCH_RESULT_CACHE]: ROUTE_NAME.SEARCH_RESULT_CACHE,
	[ROUTE_PERMISSION_KEY.PRODUCT_DETAIL_ACCORDION_KEY]: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_KEY,
	[ROUTE_PERMISSION_KEY.PRODUCT_DETAIL_ACCORDION_SET]: ROUTE_NAME.PRODUCT_DETAIL_ACCORDION_SET,
	[ROUTE_PERMISSION_KEY.PROMOTION_BANNER_SET]: ROUTE_NAME.PROMOTION_BANNER_SET,
	[ROUTE_PERMISSION_KEY.PROMOTION_BANNER_KEY]: ROUTE_NAME.PROMOTION_BANNER_KEY,
	[ROUTE_PERMISSION_KEY.FOOTER]: ROUTE_NAME.FOOTERS,
	[ROUTE_PERMISSION_KEY.VARIANT_INFO]: ROUTE_NAME.VARIANT_INFO_LIST,
	[ROUTE_PERMISSION_KEY.ORDER_AUTO_COMPLETE]: ROUTE_NAME.ORDER_AUTO_COMPLETE,
	[ROUTE_PERMISSION_KEY.DATE_TIME_CACHE]: ROUTE_NAME.DATE_TIME_CACHE,
	[ROUTE_PERMISSION_KEY.FORBIDDEN]: ROUTE_NAME.FORBIDDEN,
};

export const ROUTE_PERMISSION_TYPE = {
	MANAGE: 'manage',
	VIEW: 'view',
};
