<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="modalTitle"
					hide-close-button
				/>
			</template>
			<template #default>
				<CRow>
					<CCol>
						<BaseLoading />
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<!-- Need an empty div to hide cancel and ok button from CModal -->
				<div></div>
			</template>
		</CModal>
	</div>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';

export default {
	name: 'BaseModalLoading',

	components: {
		ModalHeaderWrapper,
	},
	props: {
		modalTitle: {
			type: String,
			default: 'Loading',
		},
	},
	data() {
		return {
			isShow: false,
		};
	},
	methods: {
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .modal-footer {
		display: none;
	}
</style>
