import {
	getBrandListAPI,
} from '../../services/api/brands.api';
import {
	GET_BRANDS_REQUEST,
	GET_BRANDS_SUCCESS,
	GET_BRANDS_FAILURE,
} from '../types';
import { transformedBrandList } from '../../assets/js/transform/brand';
import { pathOr } from '../../assets/js/helpers';

export default {
	namespaced: true,

	state: {
		list: {
			isLoading: false,
			data: [],
		},
	},

	getters: {
		getBrandOptions(state) {
			return state.list.data.map((brand) => ({
				name: brand.name,
				value: brand.id,
			}));
		},
		getBrandList(state) {
			return state.list.data;
		},
	},

	mutations: {
		// Get brands
		[GET_BRANDS_REQUEST](state) {
			state.list = {
				...state.list,
				isLoading: true,
			};
		},
		[GET_BRANDS_SUCCESS](state, data = []) {
			state.list = {
				...state.list,
				isLoading: false,
				data,
			};
		},
		[GET_BRANDS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
	},

	actions: {
		async getBrands({ commit }, params) {
			try {
				commit(GET_BRANDS_REQUEST);
				// "{per_page: 'all'}" is getting all brands
				// "{q: 'search_term'}" is filtered by name and return result as pagination
				const response = await getBrandListAPI(params);
				const data = transformedBrandList(pathOr([], ['data', 'data'])(response));
				commit(GET_BRANDS_SUCCESS, data);
			} catch (error) {
				commit(GET_BRANDS_FAILURE);
			}
		},
	},
};
