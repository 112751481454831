export const REMOVE = 'REMOVE';
export const IMAGE_PLACEHOLDER_URL = '/assets/images/product-placeholder.svg';
export const DEFAULT_BUSINESS_HOURS = [
	{
		day: 'MONDAY',
		isActive: true,
		from: '08:00',
		to: '17:30',
	},
	{
		day: 'TUESDAY',
		isActive: true,
		from: '08:00',
		to: '17:30',
	},
	{
		day: 'WEDNESDAY',
		isActive: true,
		from: '08:00',
		to: '17:30',
	},
	{
		day: 'THURSDAY',
		isActive: true,
		from: '08:00',
		to: '17:30',
	},
	{
		day: 'FRIDAY',
		isActive: true,
		from: '08:00',
		to: '17:30',
	},
	{
		day: 'SATURDAY',
		isActive: false,
		from: '',
		to: '',
	},
	{
		day: 'SUNDAY',
		isActive: false,
		from: '',
		to: '',
	},
];
export const DEFAULT_SYSTEM_ADMIN_NAME = 'back office system';
